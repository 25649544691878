import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/modal';
import { TextInput } from 'components/inputs';
import Button from 'components/button';
import Box from 'components/box';
import { Text } from 'components/text';
import { StackHorizontal, StackVertical } from '../../../components/eligible-household-elements';
import { omit } from 'lodash';
import {
    createEligibleHousehold,
} from 'store/eligible-households/eligible-households-actions';
import { addToast as addToastAction } from 'store/toasts/toasts-actions';
import { connect } from 'react-redux';
import Select from 'react-select';
import { ADMIN_DIVISIONS } from 'lib/config';
import { find } from 'lodash/fp';

const EMPTY_VALUE = '-1';

class AddEligibleHouseholdModal extends Component {
    state = {
        enrollmentPeriod: null,
        zoneId: null,
        woredaId: null,
        expectedHouseholds: '',
        errors: {},
        woredaOptions: [
            { value: "All", label: 'All Woredas', level: ADMIN_DIVISIONS.WOREDA },
        ]
    };
    static mapDispatchToProps = dispatch => ({
        createEligibleHousehold: household => dispatch(createEligibleHousehold(household)),
        addToast: (message, type) => dispatch(addToastAction(message, type)),
    })


    handleInputChange = (field) => (event) => {
        let value = event.target.value;
        if (isNaN(value)) return;
        // Check if the value contains non-digit characters, and if so, return immediately
        if (value.match(/[^0-9]/)) return;

        if (value < 0) {
            value = 0; // or you can set it to the previous value or whatever you see fit
        }
        this.setState({ [field]: value });

    };

    handleStateChange = (value, key) => {
        const { errors } = this.state;
        const newErrors = omit(key, errors);

        this.setState({ [key]: value, errors: newErrors });
    }

    validate = () => {
        const { enrollmentPeriod, expectedHouseholds, } = this.state;
        let errors = {};

        if (!enrollmentPeriod) errors.enrollmentPeriod = 'Enrollment Period is required';
        // if (!zoneId) errors.zoneId = 'Zone ID is required';
        // if (!woredaId) errors.woredaId = 'Woreda ID is required';
        if (!expectedHouseholds) errors.expectedHouseholds = 'Eligible Households is required';

        this.setState({ errors });
        return Object.keys(errors).length === 0; // Returns true if no errors
    };

    handleSubmit = () => {
        if (!this.validate()) return; // Don't proceed with submit if there are validation errors

        const { enrollmentPeriod, zoneId, woredaId, expectedHouseholds } = this.state;

        this.handleAddEligibleHousehold({
            enrollment_period_id: enrollmentPeriod?.value,
            administrative_division_id: woredaId === "All" || !woredaId ? zoneId : woredaId,
            expected_households: expectedHouseholds,
        });
    };

    handleCancel = () => {
        const { onRequestClose } = this.props;
        onRequestClose();
    };


    handleAddEligibleHousehold = (params) => {
        const { createEligibleHousehold, addToast, onRequestClose } = this.props;
        createEligibleHousehold(params).then((action) => {
            if (action?.errorMessage) {
                // Handle error
                this.setState({ serverError: true });
                const errorMessage = action.errorMessage === "Request failed with status code 422" ? `Eligible household could not be added. Enrollment period has already been taken.` : `Eligible household could not be added. Check again.`
                addToast({
                    message: errorMessage
                });
            } else {
                onRequestClose();
                addToast({ message: `Eligible household added successfully.` });
            }
        });
    };

    selectedDivisions = () => {
        const { administrativeDivisions, administrativeDivisionId } = this.props;
        const ancestors = [];
        let ancestor = find(ad => ad.id === administrativeDivisionId)(administrativeDivisions);
        // don't include region divisions because they aren't included in the filters
        const findParent = find(ad => ad.id === ancestor.parentId && ad.level !== 'region');
        while (ancestor) {
            ancestors.unshift(ancestor.id);
            ancestor = findParent(administrativeDivisions);
        }
        return ancestors;
    }

    handleZoneChange = (value) => {
        const { administrativeDivisions } = this.props;
        const zoneId = value.value;
        const changedWoredaOptions = administrativeDivisions
            .filter(adminDivision => adminDivision.parentId === zoneId)
            .map(division => ({
                value: division.id,
                label: division.name,
                level: division.level,
            }));
        const woredaOptions = [
            { value: "All", label: 'All Woredas', level: ADMIN_DIVISIONS.WOREDA },
            ...changedWoredaOptions,
        ]
        this.setState({ zoneId, woredaOptions });
    }

    handleWoredaChange = (value) => {
        const woredaId = value.value;
        this.setState({ woredaId });
    }

    render() {
        const selectedDivisions = this.selectedDivisions();
        const zoneId = selectedDivisions.shift();
        const { administrativeDivisions, onRequestClose, enrollmentPeriodOptions, isLoading } = this.props;
        const { enrollmentPeriod, expectedHouseholds, errors } = this.state;
        const isSubmitDisabled = !enrollmentPeriod || !expectedHouseholds || !zoneId || isLoading;
        const isCancelDisabled = isLoading;

        const zoneOptions = administrativeDivisions
            .filter(adminDivision => adminDivision.level === ADMIN_DIVISIONS.ZONE)
            .map(division => ({
                value: division.id,
                label: division.name,
                level: division.level,
            }));


        const zoneDropdownOptions = [
            ...zoneOptions,
        ];


        const findZone = id => zoneDropdownOptions.find(z => z.value === id);
        const findWoreda = id => this.state.woredaOptions.find(z => z.value === id);

        return (
            <Modal maxWidth="400px" title="Add Eligible Household" onRequestClose={onRequestClose} >
                <StackVertical spaceY="1rem">
                    <StackVertical spaceY="0.5rem">
                        <Text>
                            Zone
                        </Text>
                        <Select
                            key={ADMIN_DIVISIONS.ZONE}
                            value={findZone(this.state.zoneId || EMPTY_VALUE)}
                            options={zoneDropdownOptions}
                            isClearable={false}
                            onChange={this.handleZoneChange}
                            placeholder="Member Zone *"
                            name='zone'
                        />
                    </StackVertical>
                    <StackVertical spaceY="0.5rem">
                        <Text>
                            Woreda
                        </Text>
                        <Select
                            key={ADMIN_DIVISIONS.WOREDA}
                            value={[findWoreda(this.state.woredaId || EMPTY_VALUE)]}
                            options={this.state.woredaOptions}
                            isClearable={false}
                            onChange={this.handleWoredaChange}
                            placeholder="Member Woreda *"
                        />
                    </StackVertical>
                    <StackVertical spaceY="0.5rem">
                        <Text>
                            Enrollment Period
                        </Text>
                        <Select
                            placeholder="Select Enrollment Period"
                            value={enrollmentPeriod || ''}
                            label="Enrollment Period"
                            name="enrollmentPeriod"
                            options={enrollmentPeriodOptions}
                            getOptionLabel={(option) => option?.name}
                            getOptionValue={(option) => option?.value}
                            onChange={e => this.setState({ enrollmentPeriod: e })}
                            error={errors.enrollmentPeriod}
                        />

                    </StackVertical>
                    <StackVertical spaceY="0.5rem">
                        <Text>
                            Eligible Households
                        </Text>
                        <TextInput
                            type="number"
                            min="0"
                            pattern="\d*"  // Add this line

                            label="Eligible Households"
                            placeholder="Enter Eligible Households"
                            value={expectedHouseholds}
                            onChange={this.handleInputChange('expectedHouseholds')}
                            error={errors.expectedHouseholds}
                        />
                    </StackVertical>
                    <Box marginTop="medium">
                        <StackHorizontal spaceX="0.5rem">
                            <Button onClick={this.handleCancel} disabled={isCancelDisabled}>
                                Cancel
                            </Button>

                            <Button primary onClick={this.handleSubmit} disabled={isSubmitDisabled}>
                                {
                                    isLoading ? "Submitting..." : "Submit"
                                }
                            </Button>
                        </StackHorizontal>
                    </Box>
                </StackVertical>
            </Modal>
        );
    }
}

export default connect(
    AddEligibleHouseholdModal.mapStateToProps,
    AddEligibleHouseholdModal.mapDispatchToProps,
)(AddEligibleHouseholdModal);

AddEligibleHouseholdModal.propTypes = {
    onRequestClose: PropTypes.func.isRequired,
    enrollmentPeriodOptions: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
    })).isRequired,
    administrativeDivisionOptions: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
    })).isRequired,
    administrativeDivisions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    administrativeDivisionId: PropTypes.number,
    isLoading: PropTypes.bool.isRequired,
    createEligibleHousehold: PropTypes.func.isRequired,
    addToast: PropTypes.func.isRequired,
};

AddEligibleHouseholdModal.defaultProps = {
    enrollmentPeriodOptions: [],
    administrativeDivisionOptions: [],
    administrativeDivisionId: undefined,
};