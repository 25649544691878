export const ROUTES = {
  CHECK_IN: { base_url: '/check-in', route_match: '/check-in' },
  ENROLL_MEMBERS: { base_url: '/enroll/members', route_match: '/enroll/members' },
  CLAIMS: { base_url: '/claims', route_match: '/claims' },
  CLAIMS_EXTERNAL: { base_url: '/claims', route_match: '/claims/:adjudicationState(external)?' },
  ENROLLMENT_REPORTING: { base_url: '/enrollment-reporting', route_match: '/enrollment-reporting' },
  MEMBERS: { base_url: '/members', route_match: '/members' },
  REIMBURSEMENTS: { base_url: '/reimbursements', route_match: '/reimbursements/:route(created)?/:id?' },
  SUBMISSIONS: { base_url: '/submissions', route_match: '/submissions' },
  INDIGENTS_PAYMENTS: { base_url: '/indigents_payments', route_match: '/indigents_payments' },
  SUBMISSIONS_EDIT: { base_url: '/submissions', route_match: '/submissions/:id/edit' },
  SUMMARY: { base_url: '/summary', route_match: '/summary' },
  USERS: { base_url: '/users', route_match: '/users' },
  LOGIN: { base_url: '/login', route_match: '/login' },
  LOGOUT: { base_url: '/logout', route_match: '/logout' },
  STATUS: { base_url: '/status', route_match: '/status' },
  REPORTING: { base_url: '/reporting', route_match: '/reporting' },
  REPORTING_UTILIZATION: { base_url: '/reporting', route_match: '/reporting/:report(utilization)' },
  REPORTING_CONTRIBUTION: { base_url: '/reporting', route_match: '/reporting/:report(contribution)' },
  REPORTING_ENROLLMENT: { base_url: '/reporting', route_match: '/reporting/:report(enrollment)' },
  ELIGIBLE_HOUSEHOLDS: { base_url: '/eligible-households', route_match: '/eligible-households' },
  AGE_CHANGES: { base_url: '/reporting', route_match: '/reporting/:report(age-changes)' },
  NUMBER_OF_CLAIMS_ADJUDICATED: { base_url: '/reporting', route_match: '/reporting/:report(number-of-claims-adjudicated)' },
  CLAIMS_FILLED_BY_PROVIDERS: { base_url: '/reporting', route_match: '/reporting/:report(claims-filled-by-providers)' },
  OPERATIONAL_REPORT_ON_REFERRALS: { base_url: '/reporting', route_match: '/reporting/:report(operational-report-on-referrals)' },
  CLAIMS_SUMMARY: { base_url: '/reporting', route_match: '/reporting/:report(claims-summary)' },
  REFERRED_IN: { base_url: '/reporting', route_match: '/reporting/:report(referred-in)' },
  REFERRED_OUT: { base_url: '/reporting', route_match: '/reporting/:report(referred-out)' },
  BENEFICIARY_HISTORY: { base_url: '/reporting', route_match: '/reporting/:report(beneficiary-history)' },
  OWED_AND_REIMBURSED: { base_url: '/reporting', route_match: '/reporting/:report(owed-and-reimbursed)' },
  PAYEE_WISE_SUMMARY: { base_url: '/reporting', route_match: '/reporting/:report(payee-wise-summary)' },
  STATUS_WISE_SUMMARY: { base_url: '/reporting', route_match: '/reporting/:report(status-wise-summary)' },
  AGGREGATE_REPORTS: { base_url: '/reporting', route_match: '/reporting/:report(aggregate-reports)' },
  REPORT_ON_REIMBURSEMENTS: { base_url: '/reporting', route_match: '/reporting/:report(report-on-reimbursements)' },
  ENROLLMENT_DASHBOARD: { base_url: '/reporting', route_match: '/reporting/:report(enrollment-dashboard)' },
  HOUSEHOLD_NUMBER: { base_url: '/reporting', route_match: '/reporting/:report(household-number)' },
  FACILITY_DASHBOARD: { base_url: '/facility-dashboard', route_match: '/facility-dashboard' },
  BRANCH_DASHBOARD: { base_url: '/branch-dashboard', route_match: '/branch-dashboard' },
  IMPORT_CLAIMS: { base_url: '/import', route_match: '/import/claims' },
  IMPORT_MEMBERS: { base_url: '/import/members', route_match: '/import/members' },
  CHANGE_PASSWORD: { base_url: '/change_password', route_match: '/change_password' },
  MEMBERS_LIST: { base_url: '/members_list', route_match: '/members_list' },
  CLAIMS_REPORTS: { base_url: '/claims_reports', route_match: '/claims_reports' },
  CLAIMS_REPORTS_BACKEND: { base_url: '/claims_reports', route_match: '/metabase/claims_reports' },
  GET_DRUG_NAME_BACKEND: { base_url: '/billables', route_match: '/billables/get_drug' },
};
