import React from 'react';
import { Route, Switch } from 'react-router-dom';
import EnrollMemberContainer from 'containers/check-in/renew-member/enroll-member-container';


const EnrollMemberView = () => (
  <Switch>
    <Route
      path="/enroll/members"
      render={() => (<EnrollMemberContainer />)}
    />
  </Switch>
);

export default EnrollMemberView;