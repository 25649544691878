import React from 'react';

const ErrorAlert = ({ errors }) => {
  return (
    <div className="tw-flex tw-p-4 tw-mb-4 tw-text-sm tw-text-red-800 tw-rounded-lg tw-bg-red-50 dark:tw-bg-gray-800 dark:tw-text-red-400" role="alert">
      <svg className="tw-flex-shrink-0 tw-inline tw-w-4 tw-h-4 tw-me-3 tw-mt-[2px]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
      </svg>
      <span className="tw-sr-only">Error</span>
      <div>
        <span className="tw-font-medium">Errors:</span>
        <ul className="tw-mt-1.5 tw-list-disc tw-list-inside">
          {errors.map((error, index) => (
            <li key={index}>{error}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export const SuccessAlert = ({ message }) => {
  return (
    <div className="tw-flex tw-p-4 tw-mb-4 tw-text-sm tw-text-green-800 tw-rounded-lg tw-bg-green-50 dark:tw-bg-gray-800 dark:tw-text-green-400" role="alert">
      <svg className="tw-flex-shrink-0 tw-inline tw-w-4 tw-h-4 tw-me-3 tw-mt-[2px]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
      </svg>
      <div className="tw-font-bold">{message}</div>
    </div>
  );
};

export default ErrorAlert;
