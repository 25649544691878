/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert } from 'components/alerts';
import MemberListResultsTable from 'components/member/member-list-results-table';


class MemberListResult extends Component {
  static mapStateToProps = state => ({
    isLoadingMemberList: state.members.isLoadingMemberList,
    memberListError: state.members.memberListError,
    memberList: state.members.memberList,
  });


  render() {
    const { isLoadingMemberList, memberListError, memberList } = this.props;
    if (isLoadingMemberList) {
      return <div>Loading ...</div>;
    }
    if (memberListError) {
      return <Alert type="error">{memberListError}</Alert>;
    }
    return (
      <MemberListResultsTable data={memberList} />
    );
  }
}
export default
connect(MemberListResult.mapStateToProps)(MemberListResult);
