// configureStore.js
import { configureStore } from '@reduxjs/toolkit';
import { get } from 'lodash';
import rootReducer from 'store/reducers';
import { persistStore, persistReducer } from 'redux-persist';
import api from 'lib/api';
import withApi from 'store/api-middleware/api';
import persistConfig from './persist-config';
import { baseApiSlice } from './api/baseApiSlice';

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default function configureAppStore() {
  const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware => getDefaultMiddleware({
      serializableCheck: false,
    }).concat(baseApiSlice.middleware, withApi(api)), // Add the API middleware
    devTools: process.env.NODE_ENV !== 'production',
  });

  const persistor = persistStore(store);

  if (module.hot) {
    module.hot.accept('./reducers', () => {
      // eslint-disable-next-line global-require
      const nextRootReducer = require('./reducers').default;
      store.replaceReducer(nextRootReducer);
    });
  }

  return { store, persistor };
}
