import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import PhotoModal from '../photo-modal';


const StyledPhoto = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  cursor: pointer;
  transform: translate(-50%, -50%);
`;

const PopupPhoto = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default function MemberPhotoModal(props) {
  const { src, alt } = props;
  const [open, setOpen] = React.useState(false);


  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <StyledPhoto src={src} alt={alt} {...props} onClick={handleOpen} />
      {open && (
      <PhotoModal
        title="Confirm Mass Approval"
        onRequestClose={handleClose}
      >
        <PopupPhoto src={src} alt={alt} {...props} />

      </PhotoModal>
      )}
    </div>
  );
}

MemberPhotoModal.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};
