import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AdministrativeDivisionPicker from 'components/inputs/administrative-division-picker';

class AddressField extends PureComponent {
  static mapStateToProps = state => ({
    adminDivisions: state.administrativeDivisions.administrativeDivisions,
  })

  state = {
    selectedAdminDivisionId: -1,
  }

  onSelectionChange(id) {
    this.setState({ selectedAdminDivisionId: id });
  }

  render() {
    const { handleChange, adminDivisions } = this.props;
    const { selectedAdminDivisionId } = this.state;
    const ads = Object.keys(adminDivisions)
      .map(k => adminDivisions[k]);

    return (
      <div>
        <AdministrativeDivisionPicker
          handleChange={(e) => {
            handleChange({ target: { value: e, name: 'address' } });
            this.onSelectionChange(e);
          }}
          administrativeDivisions={ads}
          administrativeDivisionId={selectedAdminDivisionId}
        />
        <div style={{ height: 300 }} />
      </div>
    );
  }
}

export default connect(
  AddressField.mapStateToProps,
  null,
)(AddressField);

AddressField.propTypes = {
  adminDivisions: PropTypes.shape({}),
  errors: PropTypes.shape({}).isRequired,
  handleChange: PropTypes.func.isRequired,
};

AddressField.defaultProps = {
  adminDivisions: {},
};
