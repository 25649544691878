import React, { useEffect } from 'react';
import Select from 'react-select';
import { useGetEnrollmentPeriodsQuery } from '../../store/api/baseApiSlice';
import { formatDate } from 'lib/formatters/date';

const EnrollmentPeriodSelector = ({ goteId, setSelectedEnrollmentPeriod }) => {
  const { data: enrollmentPeriods, error, isLoading, refetch } = useGetEnrollmentPeriodsQuery(goteId, {
    skip: !goteId,
  });
  
  useEffect(() => {
    if (goteId) {
      refetch();
    }
  }, [goteId, refetch]);

  const handleEnrollmentPeriodChange = (selectedOption) => {
    setSelectedEnrollmentPeriod(selectedOption);
  };

  return (
    <div className="">
      {isLoading ? (
        <p>Loading enrollment periods...</p>
      ) : error ? (
        <p className="tw-text-red-600">Error fetching enrollment periods</p>
      ) : (
        <Select
          placeholder="Select Enrollment Period"
          options={enrollmentPeriods?.map(period => ({ value: period.id, label: `${period.administrative_division.name} ${formatDate(period.start_date)}` }))}
          onChange={handleEnrollmentPeriodChange}
          isDisabled={!goteId}
        />
      )}
    </div>
  );
};

export default EnrollmentPeriodSelector;
