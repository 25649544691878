import React, { Component } from 'react';
import Container from 'components/container';
import { LayoutWithHeader } from 'components/layouts';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TextTableCell } from 'components/table';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import Button from 'components/button';
import Modal from 'components/modal';
import { CurrencyInput, TextInput } from 'components/inputs';

import Box from 'components/box';
import { fetchAdministrativeDivisions } from 'store/administrative-divisions/administrative-divisions-actions';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LoadingIndicator from 'components/loading-indicator';
import { fetchEnrollmentPeriods } from 'store/enrollment/enrollment-actions';
import moment from 'moment';
import { fetchIndigentPayments, postIndigentPayments } from 'store/indigent-payments/indigent-payments-actions';
import { formatCurrencyWithLabel } from 'lib/formatters/currency';

class IndigentsPaymentsView extends Component {
  static mapDispatchToProps = dispatch => ({
    loadAdministrativeDivisions() {
      dispatch(fetchAdministrativeDivisions());
    },
    loadEnrollmentPeriods() {
      dispatch(fetchEnrollmentPeriods());
    },
    loadIndigentPayments() {
      dispatch(fetchIndigentPayments());
    },
    createIndigentPayment(params) {
      dispatch(postIndigentPayments(params)).then(() => dispatch(fetchIndigentPayments()));
    },
  })

  static mapStateToProps = (state) => {
    const isLoading = state.administrativeDivisions.isLoadingAdministrativeDivisions
      || state.enrollment.isLoadingEnrollmentPeriods
      || state.indigentPayments.isLoadingIndigentPayments;
    return {
      isLoading,
      administrativeDivisions: state.administrativeDivisions.administrativeDivisions,
      enrollmentPeriods: state.enrollment.enrollmentPeriods,
      indigentPayments: state.indigentPayments.indigentPayments,
    };
  }

  constructor(props) {
    super(props);

    const user = JSON.parse(localStorage.getItem('authUser'));

    this.state = {
      showAddPaymentModal: false,
      newPaymentAmount: 0,
      newPaymentReceipt: '',
      user,
    };
  }

  componentDidMount() {
    const { loadAdministrativeDivisions, loadEnrollmentPeriods, loadIndigentPayments } = this.props;
    loadAdministrativeDivisions();
    loadEnrollmentPeriods();
    loadIndigentPayments();
  }

  onNewPaymentAmountChange(e) {
    this.setState({ newPaymentAmount: e });
  }

  onNewPaymentReceiptChange(e) {
    this.setState({ newPaymentReceipt: e.target.value });
  }

  onAcceptNewPayment(administrativeDivision, currentEnrollmentPeriod) {
    const { newPaymentAmount, newPaymentReceipt } = this.state;
    const { createIndigentPayment } = this.props;

    createIndigentPayment({
      enrollmentPeriodId: currentEnrollmentPeriod.id,
      paymentAmount: newPaymentAmount,
      receipt: newPaymentReceipt,
      administrativeDivisionId: administrativeDivision.id,
    });

    this.setState({ showAddPaymentModal: false });
  }

  onShowNewPaymentModal() {
    this.setState({ showAddPaymentModal: true, newPaymentAmount: 0, newPaymentReceipt: '' });
  }

  handleCancel() {
    this.setState({ showAddPaymentModal: false });
  }

  render() {
    const { showAddPaymentModal, newPaymentAmount, newPaymentReceipt, user } = this.state;
    const { administrativeDivisions, isLoading, enrollmentPeriods, indigentPayments } = this.props;

    if (isLoading || administrativeDivisions[user.administrativeDivisionId] == null) {
      return (
        <Box>
          <LoadingIndicator noun="data" />
        </Box>
      );
    }

    const administrativeDivision = administrativeDivisions[user.administrativeDivisionId];
    const enrollmentPeriodId = parseInt(Object.keys(enrollmentPeriods).find((k) => {
      const today = moment();
      const item = enrollmentPeriods[k];
      return today.isBetween(moment(item?.startDate), moment(item?.endDate));
    }), 10);


    const currentEnrollmentPeriod = enrollmentPeriods[enrollmentPeriodId];
    const indigentPayment = indigentPayments.find(ip => ip.administrativeDivisionId === administrativeDivision.id
      && ip.enrollmentPeriodId === enrollmentPeriodId);

    return (
      <LayoutWithHeader pageTitle="Indigents Payments" steps={[{ title: 'Indigents Payments', href: '/indigents_payments' }]}>
        <Container>
          <Button primary inline onClick={() => this.onShowNewPaymentModal()}>Add Payment</Button>
          <Table>
            <TableHead>
              <TableRow>
                <TextTableCell>Enrollment Period</TextTableCell>
                <TextTableCell>Area</TextTableCell>
                <TextTableCell>Indigent Households</TextTableCell>
                <TextTableCell>Receipt</TextTableCell>
                <TextTableCell>Amount</TextTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow key={1}>
                <TextTableCell>
                  {currentEnrollmentPeriod?.startDate}
                  &nbsp;
                  to
                  &nbsp;
                  {currentEnrollmentPeriod?.endDate}
                </TextTableCell>
                <TextTableCell>{administrativeDivision.name}</TextTableCell>
                <TextTableCell>
                  {indigentPayment ? indigentPayment.indigentHouseholds : 'N/A'}
                </TextTableCell>
                <TextTableCell>
                  {indigentPayment ? indigentPayment.receipt : 'N/A'}
                </TextTableCell>

                <TextTableCell>
                  {formatCurrencyWithLabel(parseInt(indigentPayment ? indigentPayment.paymentAmount : '0', 10))}
                </TextTableCell>
              </TableRow>
            </TableBody>
          </Table>
          {showAddPaymentModal && (
            <Modal title="Add Indigents Payment" onRequestClose={() => this.handleCancel()}>
              <Box>
                At&nbsp;
                {administrativeDivision.name}
              </Box>
              <Box marginTop={5}>
                For period
                &nbsp;
                {currentEnrollmentPeriod?.startDate}
                &nbsp;
                to
                &nbsp;
                {currentEnrollmentPeriod?.endDate}
              </Box>
              <Box marginTop={5}>
                <CurrencyInput
                  defaultValue={0}
                  label="Amount"
                  name="amount"
                  value={newPaymentAmount}
                  onChange={e => this.onNewPaymentAmountChange(e)}
                />
              </Box>

              <Box marginTop={5}>
                <label>Receipt</label>
                <TextInput
                  name="receipt"
                  value={newPaymentReceipt}
                  onChange={e => this.onNewPaymentReceiptChange(e)}
                />
              </Box>


              <Box marginTop={5} flex justifyContent="flex-end">
                <Button small inline onClick={() => this.handleCancel()}>
                  Cancel
                </Button>
                <Box marginLeft={3}>
                  <Button small inline primary type="button" onClick={() => this.onAcceptNewPayment(administrativeDivision, currentEnrollmentPeriod)} disabled={!newPaymentAmount || newPaymentAmount < 0}>
                    Save
                  </Button>
                </Box>
              </Box>
            </Modal>
          )}
        </Container>
      </LayoutWithHeader>
    );
  }
}

export default connect(
  IndigentsPaymentsView.mapStateToProps,
  IndigentsPaymentsView.mapDispatchToProps,
)(IndigentsPaymentsView);

IndigentsPaymentsView.propTypes = {
  administrativeDivisions: PropTypes.shape({}),
  enrollmentPeriods: PropTypes.shape({}),
  indigentPayments: PropTypes.arrayOf(PropTypes.shape({})),
  loadAdministrativeDivisions: PropTypes.func.isRequired,
  loadEnrollmentPeriods: PropTypes.func.isRequired,
  loadIndigentPayments: PropTypes.func.isRequired,
  createIndigentPayment: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

IndigentsPaymentsView.defaultProps = {
  administrativeDivisions: {},
  enrollmentPeriods: {},
  indigentPayments: [],
};
