import {
  pick,
} from 'lodash/fp';

import { formatDate } from 'lib/formatters/date';
import { formatCurrency } from 'lib/formatters/currency';
import {
  formatShortId,
} from 'lib/formatters';
import {
  getFileSystemSafeName,
  downloadTextFile,
  downloadObjectAsJson,
} from 'lib/utils';
import moment from 'moment';

import {
  currentUserProviderSelector,
} from 'store/providers/providers-selectors';

import {
  encounterWithExtrasByIdSelector,
} from 'store/encounters/encounters-selectors';

import { unsyncedDeltasByModelType } from 'store/deltas/deltas-selectors';

const rowsForEncounterWithExtras = (encounterWithExtras) => {
  const {
    claimId,
    providerComment,
    reimbursalAmount,
    member,
    id: encounterId,
    preparedAt,
    occurredAt,
    inboundReferralDate,
  } = encounterWithExtras;

  const nonDateMemberFields = [
    'fullName',
    'membershipNumber',
    'cardId',
    'gender',
    'birthateAccuracy',
    'birthdate',
    'age',
    'medicalRecordNumber',
  ];

  const memberInfo = {
    ...pick(nonDateMemberFields)(member),
    birthdate: formatDate(member.birthdate),
  };

  const visitInformation = {
    ...pick(['visitReason', 'visitType', 'patientOutcome', 'dischargeDate'])(member),
    inboundReferralDate: formatDate(inboundReferralDate),
    serviceDate: formatDate(occurredAt),
    preparataionDate: formatDate(preparedAt),
    dischargeDate: formatDate(encounterWithExtras.dischargeDate),
    referrals: encounterWithExtras.referrals.map(r => ({ ...r, date: formatDate(r.date) })),
  };

  const diagnoses = encounterWithExtras.diagnoses.map(d => d.description);

  const encounterItems = encounterWithExtras.encounterItems.map((ei) => {
    const { stockout, billable, priceSchedule } = ei;
    if (stockout) {
      return {
        ...pick(['quantity', 'stockout'])(ei),
        billable: [billable.type, billable.name, billable.unit, billable.composition].join(' '),
        // No need to show price if stockout.
      };
    }
    return {
      ...pick(['quantity'])(ei),
      billable: [billable.type, billable.name, billable.unit, billable.composition].join(' '),
      price: formatCurrency(priceSchedule.price),
    };
  });

  return [
    `Claim ID: ${formatShortId(claimId)}`,
    `Submission ID ${encounterId}`,
    `Member: ${JSON.stringify(memberInfo, undefined, 2)}`,
    `Visit Information: ${JSON.stringify(visitInformation, undefined, 2)}`,
    `Diagnoses: ${JSON.stringify(diagnoses, undefined, 2)}`,
    `Encounter Items: ${JSON.stringify(encounterItems, undefined, 2)}`,
    `Comment: ${providerComment}`,
    `Total Claimed: ${formatCurrency(reimbursalAmount)}`,
  ];
};

const transformJson = (unsyncedEncounterWithExtras) => {
  return unsyncedEncounterWithExtras.map((encounterWithExtras) => {
    const birthdate = encounterWithExtras?.member?.birthdate?.split("T")[0];
    const referalDate = encounterWithExtras?.referrals?.[0]?.date?.split("T")[0];

    return {
      encounter: {
        adjudication_state: encounterWithExtras.adjudicationState,
        backdated_occurred_at: encounterWithExtras.backdatedOccurredAt,
        claim_id: encounterWithExtras.claimId,
        diagnosis_ids: [...encounterWithExtras.diagnosisIds],
        id: encounterWithExtras.claimId,
        identification_event_id: encounterWithExtras.identificationEventId,
        inbound_referral_date: encounterWithExtras.inboundReferralDate,
        member_id: encounterWithExtras.memberId,
        occurred_at: encounterWithExtras.occurredAt,
        patient_outcome: encounterWithExtras.patientOutcome,
        prepared_at: encounterWithExtras.preparedAt,
        provider_comment: encounterWithExtras.providerComment,
        submission_state: encounterWithExtras.submissionState,
        submitted_at: encounterWithExtras.submittedAt,
        visit_reason: encounterWithExtras.visitReason,
        visit_type: encounterWithExtras.visitType,
        provider_id: encounterWithExtras.providerId,
      },
      encounter_item_relations: encounterWithExtras.encounterItems.map((encounterItem) => {
        return {
          billable_with_price_schedule: {
            billable: {
              active: encounterItem.billable.active,
              composition: encounterItem.billable.composition,
              id: encounterItem.billable.id,
              name: encounterItem.billable.name,
              type: encounterItem.billable.type,
              unit: encounterItem.billable.unit,
            },
            prev_price_schedule: {
              billable_id: encounterItem.originalPriceSchedule.billableId,
              id: encounterItem.originalPriceSchedule.id,
              issued_at: encounterItem.originalPriceSchedule.issuedAt,
              price: encounterItem.originalPriceSchedule.price,
              provider_id: encounterItem.originalPriceSchedule.providerId,
            },
            price_schedule: {
              billable_id: encounterItem.priceSchedule.billableId,
              id: encounterItem.priceSchedule.id,
              issued_at: encounterItem.priceSchedule.issuedAt,
              previous_price_schedule_id: encounterItem.originalPriceSchedule.id,
              price: encounterItem.priceSchedule.price,
              provider_id: encounterItem.priceSchedule.providerId,
            },
          },
          encounter_item: {
            encounter_id: encounterWithExtras.claimId,
            id: encounterItem.id,
            price_schedule_id: encounterItem.priceScheduleId,
            price_schedule_issued: encounterItem.priceScheduleIssued,
            quantity: encounterItem.quantity,
            stockout: encounterItem.stockout,
          },
        }
      }),
      identification_event: {
        clinic_number: 0,
        clinic_number_type: null,
        dismissed: false,
        fingerprints_verification_confidence: 0.0,
        fingerprints_verification_result_code: 0,
        fingerprints_verification_tier: null,
        id: encounterWithExtras.identificationEventId,
        member_id: encounterWithExtras.memberId,
        occurred_at: encounterWithExtras?.occurredAt,
        search_method: "MANUAL_ENTRY",
        through_member_id: null,
        provider_id: encounterWithExtras.providerId,
      },
      member: {
        birthdate,
        birthdate_accuracy: encounterWithExtras.member.birthdateAccuracy,
        enrolled_at: encounterWithExtras.member.enrolledAt,
        gender: encounterWithExtras.member.gender,
        id: encounterWithExtras.member.id,
        local_membership_number: encounterWithExtras.member.localMembershipNumber,
        membership_number: null,
        medical_record_number: encounterWithExtras.member.medicalRecordNumber,
        full_name: encounterWithExtras.member.fullName,
        relationship_to_head: !encounterWithExtras.manualSubmission ? "SELF" : null,
      },
      referal: encounterWithExtras?.referrals?.length > 0 ? {
        data: referalDate,
        encounter_id: encounterWithExtras?.claimId,
        id: encounterWithExtras?.referrals?.[0]?.id,
        number: encounterWithExtras?.referrals?.[0]?.number,
        reason: encounterWithExtras?.referrals?.[0]?.reason,
        receiving_facility: encounterWithExtras?.referrals?.[0]?.receivingFacility,
      } : null,
    };
  })
}

export const exportData = () => (
  (dispatch, getState) => {
    const state = getState();
    const formattedNow = formatDate(moment());
    const provider = currentUserProviderSelector(state);

    // Get all the unsynced claims.
    // Loop through each one and append that claim's rows to the rows array.
    const rows = [];
    rows.push(`Here are all the unsynced claims as of ${formattedNow} for ${provider ? provider.name : 'All Providers'}`);
    rows.push('');
    rows.push('');

    const unsyncedEncounterDeltas = unsyncedDeltasByModelType(state, 'Encounter');

    const unsyncedIdEventsDeltas = unsyncedDeltasByModelType(state, 'IdentificationEvent');
    // This list will be used for the json export.
    const unsyncedEncounterWithExtras = [];
    unsyncedEncounterDeltas.forEach((encounterDelta) => {
      const encounterWithExtras = encounterWithExtrasByIdSelector(state, encounterDelta.modelId);
      let idEvent;
      // match encounterWithExtras.identification_event_id with unsyncedIdEventsDeltas.map((idEventDelta) => idEventDelta.modelId)
      unsyncedIdEventsDeltas.forEach((idEventDelta) => {
        if (idEventDelta?.modelId === encounterWithExtras?.identificationEventId) {
          idEvent = idEventDelta;
        }
      });
      encounterWithExtras.identificationEvent = idEvent;

      unsyncedEncounterWithExtras.push(encounterWithExtras);
      rowsForEncounterWithExtras(encounterWithExtras).forEach(row => rows.push(row));
      // Add two empty lines between claims.
      rows.push('');
      rows.push('');
    });

    const transformedJson = transformJson(unsyncedEncounterWithExtras);
    downloadTextFile(rows, `readable_unsynced_claims_export_${getFileSystemSafeName(formattedNow)}.txt`);
    downloadObjectAsJson(transformedJson, `json_unsynced_claims_export_${getFileSystemSafeName(formattedNow)}.json`);
  }
);

export default exportData;
