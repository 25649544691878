import { isNil } from 'lodash/fp';
import moment from 'moment';
import axios, { CancelToken } from 'axios';
import session from 'lib/session';
import { apiConfig, ROUTES } from 'lib/config';
import { formatTimestamp, formatInternationalDate } from 'lib/formatters/date';
import { stripWhitespace } from 'lib/string-utils';
import { camelCaseObject, getFileSystemSafeName, matchesRole, snakeCaseObject, omitUndefined } from 'lib/utils';


/**
 * Constants
 */

const { BASE_URL, ALLOWED_USER_ROLES } = apiConfig;

/**
 * Default Configurations
 */

export const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  },
  transformResponse: [
    ...axios.defaults.transformResponse,
    data => camelCaseObject(data),
  ],
});

/**
 * Helper functions
 */

const tokenAuth = (tokenWithExpiry = session.getTokenWithExpiry()) => ({ Authorization: `Token ${tokenWithExpiry.token}` });
const isAllowedRole = matchesRole(ALLOWED_USER_ROLES);

const downloadFile = (data, filename) => {
  const downloadLink = document.createElement('a');
  const universalBOM = '\uFEFF';
  downloadLink.href = `data:text/csv;charset=utf-8,${encodeURIComponent(universalBOM + data)}`;
  downloadLink.download = filename;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

const downloadExcelFile = (response, filename) => {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
};


/**
 * Error handling
 */

// The API allows all users to authenticate successfully (e.g. to handle clinic app users logging in),
// so we need to perform this check for allowed roles on our side.
function checkUserRole(responseBody) {
  if (responseBody.user && responseBody.user.role && isAllowedRole(responseBody.user.role)) {
    return Promise.resolve(responseBody);
  }

  // eslint-disable-next-line prefer-promise-reject-errors
  return Promise.reject({ response: { status: 403 } });
}

const cancelTokenSources = {};
function cancelTokenAndGenerateNewToken(apiMethodName) {
  if (cancelTokenSources[apiMethodName]) {
    cancelTokenSources[apiMethodName].cancel();
  }
  const newTokenSource = CancelToken.source();
  cancelTokenSources[apiMethodName] = newTokenSource;
  return newTokenSource.token;
}
/**
 * API functions
 */

export default {
  login(username, password) {
    if (isNil(username) || isNil(password)) {
      return Promise.reject(new TypeError('Must pass \'username\' and \'password\' to api.login()'));
    }

    const basicAuthCredentials = { username, password };

    return axiosInstance.post('/authentication_token', null, { auth: basicAuthCredentials })
      .then(response => response.data)
      .then(checkUserRole);
  },

  logout(authTokenToRevoke) {
    const headers = tokenAuth(authTokenToRevoke);

    return axiosInstance.delete('/authentication_token', { headers })
      .then(() => undefined);
  },

  fetchAdministrativeDivisions() {
    const headers = tokenAuth();

    return axiosInstance.get('/administrative_divisions', { headers })
      .then(response => response.data);
  },

  fetchEnrollerAdministrativeDivisions() {
    const headers = tokenAuth();

    return axiosInstance.get('/administrative_divisions/enroller_address', { headers })
      .then(response => response.data);
  },

  fetchPremia(enrollmentPeriodId) {
    const headers = tokenAuth();

    return axiosInstance.get(`/enrollment_periods/${enrollmentPeriodId}/premia`, { headers })
      .then(response => response.data);
  },

  replaceCard(householdEnrollmentRecordId, receiptNumber, paymentDate, premiumId, cardReplacementFee) {
    const headers = tokenAuth();
    const data = snakeCaseObject({
      householdEnrollmentRecordId, receiptNumber, paymentDate, premiumId, cardReplacementFee: cardReplacementFee.amount,
    });

    return axiosInstance.post('membership_payments', data, { headers })
      .then(response => response.data);
  },

  fetchDiagnoses() {
    const headers = tokenAuth();

    return axiosInstance.get('/diagnoses', { headers })
      .then(response => response.data);
  },

  fetchBillables(providerId) {
    const headers = tokenAuth();
    const cancelToken = cancelTokenAndGenerateNewToken('fetchBillables');
    return axiosInstance.get(`/providers/${providerId}/billables`, { headers, cancelToken })
      .then(response => response.data);
  },

  postPriceSchedule(priceSchedule, providerId) {
    if (isNil(priceSchedule)) {
      return Promise.reject(new TypeError('Must pass \'priceSchedule\' to api.postPriceSchedule()'));
    } if (isNil(providerId)) {
      return Promise.reject(new TypeError('Must pass \'providerId\' to api.postPriceSchedule()'));
    }

    const headers = tokenAuth();
    const data = snakeCaseObject(priceSchedule);

    return axiosInstance.post(`providers/${providerId}/price_schedules`, data, { headers })
      .then(response => response.data);
  },

  fetchMembers(memberData) {
    const headers = tokenAuth();
    const params = snakeCaseObject(memberData);

    return axiosInstance.get('/members/search', {
      headers,
      params,
    }).then(response => response.data);
  },

  fetchHouseholdMembers(memberData) {
    const headers = tokenAuth();
    const params = snakeCaseObject(memberData);

    return axiosInstance.get('/households/search', {
      headers,
      params,
    }).then(response => response.data);
  },

  fetchOpenIdentificationEvents(providerId) {
    const headers = tokenAuth();
    const cancelToken = cancelTokenAndGenerateNewToken('fetchOpenIdentificationEvents');
    return axiosInstance.get(`/providers/${providerId}/identification_events/open`, { headers, cancelToken })
      .then(response => response.data);
  },

  postIdentificationEvent(identificationEvent, providerId) {
    if (isNil(identificationEvent)) {
      return Promise.reject(new TypeError('Must pass \'identification event\' object to api.postIdentificationEvent()'));
    }

    const headers = tokenAuth();
    const data = snakeCaseObject(identificationEvent);

    return axiosInstance.post(`/providers/${providerId}/identification_events`, data, { headers })
      .then(response => response.data);
  },

  patchIdentificationEvent(identificationEvent) {
    if (isNil(identificationEvent) || isNil(identificationEvent.id)) {
      return Promise.reject(new TypeError('Must pass object with identification event changes and ID to api.patchIdentificationEvent()'));
    }

    const headers = tokenAuth();
    const data = snakeCaseObject(identificationEvent);

    return axiosInstance.patch(`identification_events/${identificationEvent.id}`, data, { headers })
      .then(response => response.data);
  },

  fetchEncounters() {
    const headers = tokenAuth();

    const cancelToken = cancelTokenAndGenerateNewToken('fetchEncounters');
    return axiosInstance.get('/encounters', { headers, cancelToken })
      .then(response => response.data);
  },

  postEncounter(encounter, providerId) {
    if (isNil(encounter)) {
      return Promise.reject(new TypeError('Must pass \'encounter\' object to api.postEncounter()'));
    }

    const headers = tokenAuth();
    const data = snakeCaseObject(encounter);

    return axiosInstance.post(`providers/${providerId}/encounters`, data, { headers })
      .then(response => response.data);
  },

  patchEncounter(encounter) {
    if (isNil(encounter)) {
      return Promise.reject(new TypeError('Must pass \'encounter\' object to api.updateEncounter()'));
    }

    const headers = tokenAuth();
    const data = snakeCaseObject(encounter);

    return axiosInstance.patch(`/encounters/${encounter.id}`, data, { headers })
      .then(response => response.data);
  },

  fetchEnrollmentRecords() {
    const headers = tokenAuth();

    return axiosInstance.get('/enrollment_records', { headers })
      .then(response => response.data);
  },

  fetchEnrollmentPeriods() {
    const headers = tokenAuth();

    return axiosInstance.get('/enrollment_periods', { headers })
      .then(response => response.data);
  },

  fetchEnrollmentPeriodsByAdministrativeDivision(administrativeDivisionId) {
    const headers = tokenAuth();
    const params = snakeCaseObject({ administrativeDivisionId });

    return axiosInstance.get(`/administrative_divisions/${administrativeDivisionId}/enrollment_periods`, { headers, params })
      .then(response => response.data);
  },

  fetchEnrollmentReportingStats(filters) {
    const headers = tokenAuth();

    return axiosInstance.get('/enrollment_reporting_stats', {
      headers,
      params: snakeCaseObject(omitUndefined(filters)),
    }).then(response => response.data);
  },

  fetchEnrollmentReportingDetailCSV(filters) {
    const headers = tokenAuth();

    // TODO: Make this code less hacky.
    return axios({
      method: 'get',
      baseURL: BASE_URL,
      url: '/enrollment_reporting_stats/csv',
      responseType: 'stream',
      params: snakeCaseObject(omitUndefined(filters)),
      headers,
    }).then((response) => {
      const filename = `enrollment_export_${getFileSystemSafeName(formatTimestamp(moment()))}.csv`;
      downloadFile(response.data, filename);
    });
  },

  fetchFacilityReimbursementReportCSV(providerId, startDate, endDate) {
    const headers = tokenAuth();

    // TODO: Make this code less hacky.
    const params = snakeCaseObject({ startDate, endDate });
    return axios({
      method: 'get',
      baseURL: BASE_URL,
      url: `provider_reporting_stats/${providerId}/csv`,
      headers,
      params,
    }).then((response) => {
      const filename = `facility_reimbursement_report_${providerId}.csv`;
      downloadFile(response.data, filename);
    });
  },

  fetchReimbursementReportCSV(reimbursementId) {
    const headers = tokenAuth();

    // TODO: Make this code less hacky.
    return axios({
      method: 'get',
      baseURL: BASE_URL,
      url: `reimbursement_reporting/${reimbursementId}/csv`,
      headers,
    }).then((response) => {
      const filename = `reimbursement_${reimbursementId}.csv`;
      downloadFile(response.data, filename);
    });
  },

  fetchEnrollmentReportCSV(startDate, endDate, enrollmentPeriodId, isBranch) {
    const headers = tokenAuth();
    const params = snakeCaseObject({ startDate, endDate, enrollmentPeriodId });
    return axios({
      method: 'get',
      baseURL: BASE_URL,
      url: 'enrollment_reporting/csv',
      headers,
      params,
    }).then((response) => {
      const filename = isBranch
        ? `branch_enrollment_reporting_${startDate}_${endDate}.csv`
        : `woreda_enrollment_reporting_${startDate}_${endDate}.csv`;
      downloadFile(response.data, filename);
    });
  },

  fetchContributionReportCSV(startDate, endDate, isBranch) {
    const headers = tokenAuth();
    const params = snakeCaseObject({ startDate, endDate });
    return axios({
      method: 'get',
      baseURL: BASE_URL,
      url: 'contribution_reporting/csv',
      headers,
      params,
    }).then((response) => {
      const filename = isBranch
        ? `branch_contribution_report_${startDate}_${endDate}.csv`
        : `woreda_contribution_report_${startDate}_${endDate}.csv`;
      downloadFile(response.data, filename);
    });
  },

  fetchUtilizationReportCSV(startDate, endDate) {
    const headers = tokenAuth();
    const params = snakeCaseObject({ startDate, endDate });
    return axios({
      method: 'get',
      baseURL: BASE_URL,
      url: 'utilization_reporting/csv',
      headers,
      params,
    }).then((response) => {
      const filename = `utilization_report_${startDate}_${endDate}.csv`;
      downloadFile(response.data, filename);
    });
  },

  fetchProviders() {
    const headers = tokenAuth();

    return axiosInstance.get('/providers', { headers })
      .then(response => response.data);
  },

  updateMemberCardId(memberId, cardId) {
    if (isNil(memberId) || isNil(cardId)) {
      return Promise.reject(new TypeError('Must pass \'memberId\' and \'cardId\' to api.updateMemberCardId()'));
    }

    // The backend API is a bit picky about what it accepts so we need to transform the
    // card ID to avoid a set of "invalid card ID" errors.
    const transformedCardId = stripWhitespace(cardId).toUpperCase();
    const headers = tokenAuth();
    const data = { card_id: transformedCardId };

    return axiosInstance.patch(`/members/${memberId}`, data, { headers })
      .then(response => response.data);
  },

  postMember(member) {
    if (isNil(member)) {
      return Promise.reject(new TypeError('Must pass \'member\' object to api.postMember()'));
    }

    const headers = tokenAuth();
    const data = snakeCaseObject(member);

    return axiosInstance.post('/members', data, { headers })
      .then(response => response.data);
  },

  updateMember(memberChanges) {
    if (isNil(memberChanges) || isNil(memberChanges.id)) {
      return Promise.reject(new TypeError('Must pass object with member changes and member ID to api.updateMember()'));
    }

    const headers = tokenAuth();
    const data = snakeCaseObject(memberChanges);

    return axiosInstance.patch(`/members/${memberChanges.id}`, data, { headers })
      .then(response => response.data);
  },

  updateMemberPhoto(memberId, photo) {
    const headers = {
      ...tokenAuth(),
      'content-type': 'multipart/form-data',
    };
    const data = new FormData();
    data.append('photo', photo);

    return axiosInstance.patch(`/members/${memberId}`, data, { headers })
      .then(response => response.data);
  },

  fetchReimbursements(providerId) {
    const headers = tokenAuth();

    const params = snakeCaseObject({ providerId });
    return axiosInstance.get('reimbursements', { headers, params })
      .then(response => response.data);
  },

  fetchReimbursementClaims(reimbursementId) {
    const headers = tokenAuth();

    return axiosInstance.get(`/reimbursements/${reimbursementId}/claims`, { headers })
      .then(response => response.data);
  },

  fetchReimbursableClaimsMetaData(providerId, endDate, reimbursementId = null) {
    const headers = tokenAuth();
    const formattedDate = formatInternationalDate(endDate);
    const url = `reimbursements/reimbursable_claims_metadata?provider_id=${providerId}&reimbursement_id=${reimbursementId}&end_date=${formattedDate}`;

    return axiosInstance.get(url, { headers })
      .then(response => response.data);
  },


  createReimbursement(reimbursement, providerId) {
    if (isNil(reimbursement)) {
      return Promise.reject(new TypeError('Must pass \'reimbursement\' to api.createReimbursement()'));
    } if (isNil(providerId)) {
      return Promise.reject(new TypeError('Must pass \'providerId\' to api.createReimbursement()'));
    }

    const headers = tokenAuth();
    const data = snakeCaseObject(reimbursement);

    return axiosInstance.post(`providers/${providerId}/reimbursements`, data, { headers })
      .then(response => response.data);
  },

  updateReimbursement(reimbursement) {
    if (isNil(reimbursement)) {
      return Promise.reject(new TypeError('Must pass reimbursement to api.updateReimbursement()'));
    }

    const headers = tokenAuth();
    const data = snakeCaseObject(reimbursement);

    return axiosInstance.patch(`/reimbursements/${reimbursement.id}`, data, { headers })
      .then(response => response.data);
  },

  fetchProviderReportingStats(providerId, startDate, endDate) {
    const headers = tokenAuth();

    const params = snakeCaseObject({ startDate, endDate });
    return axiosInstance.get(`/provider_reporting_stats/${providerId}`, { headers, params })
      .then(response => response.data);
  },

  fetchReimbursementStats(providerId) {
    const headers = tokenAuth();

    return axiosInstance.get(`/reimbursements/stats?provider_id=${providerId || ''}`, { headers })
      .then(response => response.data);
  },

  // fetch initial page of claims using params constructed by the client
  fetchClaims(queryParams) {
    const headers = tokenAuth();
    const params = snakeCaseObject(queryParams);

    const cancelToken = cancelTokenAndGenerateNewToken('fetchClaims');
    return axiosInstance.get('/claims', { headers, params, cancelToken })
      .then(response => response.data);
  },

  // fetch subsequent pages of claims using a url provided by the server
  fetchClaimsByUrl(url) {
    const headers = tokenAuth();

    return axiosInstance.get(url, { headers })
      .then(response => response.data);
  },

  fetchClaimsReportCSV(queryParams) {
    const headers = tokenAuth();
    const params = snakeCaseObject(queryParams);

    return axios({
      method: 'get',
      baseURL: BASE_URL,
      url: 'claims.csv',
      responseType: 'stream',
      headers,
      params,
    }).then((response) => {
      const filename = `claims_report_${getFileSystemSafeName(formatTimestamp(moment()))}.csv`;
      downloadFile(response.data, filename);
    });
  },

  fetchClaim(id) {
    const headers = tokenAuth();

    return axiosInstance.get(`/claims/${id}`, { headers })
      .then(response => response.data);
  },

  fetchMemberClaims(id) {
    const headers = tokenAuth();
    return axiosInstance.get(`/members/${id}/claims`, { headers })
      .then(response => response.data);
  },

  massClaimApproval(claimIds) {
    const headers = tokenAuth();
    const data = snakeCaseObject(claimIds);

    return axiosInstance.post('/claims/mass_approval', { encounterIds: data }, { headers })
      .then(response => response.data);
  },

  findDuplicateClaim(claimId) {
    const headers = tokenAuth();

    return axiosInstance.post('/claims/find_duplicates', { claimId }, { headers })
      .then(response => response.data);
  },

  // Metabase requests
  fetchAgeChanges(startDate, endDate) {
    const headers = tokenAuth();

    return axiosInstance.post('/metabase/age_changes', { startDate, endDate }, { headers })
      .then(response => response.data);
  },

  fetchNumberOfClaimsAdjudicated(startDate, endDate) {
    const headers = tokenAuth();

    return axiosInstance.post('/metabase/adjudicated_claim_by_adjudicator', { startDate, endDate }, { headers })
      .then(response => response.data);
  },

  fetchClaimsFilledByProviders(startDate, endDate) {
    const headers = tokenAuth();

    return axiosInstance.post('/metabase/claims_filled_by_providers', { startDate, endDate }, { headers })
      .then(response => response.data);
  },

  fetchOperationalReportOnReferrals(startDate, endDate) {
    const headers = tokenAuth();

    return axiosInstance.post('/metabase/operational_report_on_referrals', { startDate, endDate }, { headers })
      .then(response => response.data);
  },

  fetchClaimsSummary(startDate, endDate) {
    const headers = tokenAuth();

    return axiosInstance.post('/metabase/claims_summary', { startDate, endDate }, { headers })
      .then(response => response.data);
  },

  fetchReferredIn(startDate, endDate) {
    const headers = tokenAuth();

    return axiosInstance.post('/metabase/referred_in', { startDate, endDate }, { headers })
      .then(response => response.data);
  },

  fetchReferredOut(startDate, endDate) {
    const headers = tokenAuth();

    return axiosInstance.post('/metabase/referred_out', { startDate, endDate }, { headers })
      .then(response => response.data);
  },

  fetchBeneficiaryHistory(id) {
    const headers = tokenAuth();

    return axiosInstance.post('/metabase/beneficiary_history', { id }, { headers })
      .then(response => response.data);
  },

  fetchOwedAndReimbursed(startDate, endDate) {
    const headers = tokenAuth();

    return axiosInstance.post('/metabase/owed_and_reimbursed', { startDate, endDate }, { headers })
      .then(response => response.data);
  },

  fetchPayeeWiseSummary(startDate, endDate) {
    const headers = tokenAuth();

    return axiosInstance.post('/metabase/payee_wise_summary', { startDate, endDate }, { headers })
      .then(response => response.data);
  },

  fetchStatusWiseSummary(startDate, endDate) {
    const headers = tokenAuth();

    return axiosInstance.post('/metabase/status_wise_summary', { startDate, endDate }, { headers })
      .then(response => response.data);
  },

  fetchAggregateReports(startDate, endDate) {
    const headers = tokenAuth();

    return axiosInstance.post('/metabase/aggregate_reports', { startDate, endDate }, { headers })
      .then(response => response.data);
  },

  fetchReportOnReimbursements(providerId, startDate, endDate) {
    const headers = tokenAuth();

    return axiosInstance.post('/metabase/report_on_reimbursements', { providerId, startDate, endDate }, { headers })
      .then(response => response.data);
  },

  fetchHouseholdNumber() {
    const headers = tokenAuth();

    return axiosInstance.get('metabase/household_number', { headers })
      .then(response => response.data);
  },

  fetchEnrollmentDashboard(enrollmentPeriodId, startDate, endDate) {
    const headers = tokenAuth();

    return axiosInstance.post('/metabase/enrollment_dashboard', { enrollmentPeriodId, startDate, endDate }, { headers })
      .then(response => response.data);
  },

  fetchFacilityDashboard(startDate, endDate) {
    const headers = tokenAuth();

    return axiosInstance.post('/metabase/facility_dashboard', { startDate, endDate }, { headers })
      .then(response => response.data);
  },

  fetchBranchDashboard(startDate, endDate) {
    const headers = tokenAuth();

    return axiosInstance.post('/metabase/branch_dashboard', { startDate, endDate }, { headers })
      .then(response => response.data);
  },

  changePassword(currentPassword, newPassword) {
    const headers = tokenAuth();

    return axiosInstance.post('/users/change_password', { currentPassword, newPassword }, { headers })
      .then(response => response.data);
  },

  fetchMembersList(filters) {
    const headers = tokenAuth();
    const params = snakeCaseObject(omitUndefined(filters));

    return axiosInstance.get('/members/list', { headers, params })
      .then(response => response.data);
  },

  fetchMembersByUrl(url) {
    const headers = tokenAuth();

    return axiosInstance.get(url, { headers })
      .then(response => response.data);
  },

  fetchMemberListCSV(filters) {
    const headers = tokenAuth();

    // TODO: Make this code less hacky.
    return axios({
      method: 'get',
      baseURL: BASE_URL,
      url: '/members/list_csv',
      responseType: 'stream',
      params: snakeCaseObject(omitUndefined(filters)),
      headers,
    }).then((response) => {
      const filename = `members_list${getFileSystemSafeName(formatTimestamp(moment()))}.csv`;
      downloadFile(response.data, filename);
    });
  },


  fetchClaimsReportsCSV(filters) {
    const headers = tokenAuth();
    return axios({
      method: 'get',
      baseURL: BASE_URL,
      url: ROUTES.CLAIMS_REPORTS_BACKEND.route_match,
      responseType: 'blob',
      params: snakeCaseObject(omitUndefined(filters)),
      headers,
    }).then((response) => {
      const filename = `drug-list${getFileSystemSafeName(formatTimestamp(moment()))}.xlsx`;
      downloadExcelFile(response, filename);
    });
  },

  fetchBillablesDrug() {
    const headers = tokenAuth();

    return axiosInstance.get(ROUTES.GET_DRUG_NAME_BACKEND.route_match, { headers })
      .then(response => response.data);
  },

  fetchFlags() {
    const headers = tokenAuth();

    return axiosInstance.get('/flags', { headers })
      .then(response => response.data);
  },

  postIndigentPayments({ enrollmentPeriodId, paymentAmount, receipt, administrativeDivisionId }) {
    const headers = tokenAuth();

    return axiosInstance.post('/indigent_payments', snakeCaseObject({ enrollmentPeriodId, paymentAmount, receipt, administrativeDivisionId }), { headers })
      .then(response => response.data);
  },

  fetchIndigentPayments() {
    const headers = tokenAuth();

    return axiosInstance.get('/indigent_payments', { headers })
      .then(response => response.data);
  },

  // Fetch all eligible households
  fetchEligibleHouseholds() {
    const headers = tokenAuth();
    const url = 'eligible_households';

    return axiosInstance.get(url, { headers })
      .then(response => response.data);
  },

  // Create a new eligible household
  createEligibleHousehold(eligibleHousehold) {
    if (!eligibleHousehold) {
      return Promise.reject(new TypeError('Must pass \'eligibleHousehold\' to api.createEligibleHousehold()'));
    }

    const headers = tokenAuth();
    const data = snakeCaseObject(eligibleHousehold);

    return axiosInstance.post('eligible_households', data, { headers })
      .then(response => response.data);
  },

  // Update an existing eligible household
  updateEligibleHousehold(eligibleHousehold) {
    if (!eligibleHousehold?.eligibleHousehold?.expected_households || !eligibleHousehold?.id) {
      return Promise.reject(new TypeError('Must pass eligibleHousehold with an id to api.updateEligibleHousehold()'));
    }

    const headers = tokenAuth();
    const data = snakeCaseObject(eligibleHousehold?.eligibleHousehold);

    return axiosInstance.patch(`/eligible_households/${eligibleHousehold?.id}`, data, { headers })
      .then(response => response.data);
  },

  // Delete an eligible household
  deleteEligibleHousehold(eligibleHouseholdId) {
    if (!eligibleHouseholdId) {
      return Promise.reject(new TypeError('Must pass eligibleHouseholdId to api.deleteEligibleHousehold()'));
    }

    const headers = tokenAuth();

    return axiosInstance.delete(`/eligible_households/${eligibleHouseholdId}`, { headers })
      .then(response => response.data);
  },

};
