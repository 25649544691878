export const RELATIONSHIPS = {
    SELF: 'Self',
    WIFE: 'Wife',
    HUSBAND: 'Husband',
    SON: 'Son',
    DAUGHTER: 'Daughter',
    PAYING_BENEFICIARY: 'Paying Beneficiary',
    OTHER: 'Other',
  };
  
  export const PROFESSIONS = {
    STUDENT: 'Student',
    MERCHANT: 'Merchant',
    TRADER_OR_MERCHANT: 'Trader or merchant',
    JOB_SEEKER: 'Job seeker',
    HOUSEWIFE: 'Housewife',
    STAY_HOME_HUSBAND: 'Stay at home husband',
    BABY: 'Baby',
    PRIVATE: 'Private',
    DAILY_LABORER: 'Daily laborer',
    DISABLED: 'Disabled',
    OTHER: 'Other',
    FARMER: 'Farmer',
  };
  