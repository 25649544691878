import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Box from 'components/box';
import Button from 'components/button';

import { SearchInput } from 'components/inputs';

class SearchBar extends Component {
  handleClear = () => {
    const { handleSearchClear } = this.props;
    handleSearchClear();
  }

  handleClick = () => {
    const { handleSearchButtonClick, value } = this.props;
    handleSearchButtonClick(value);
  }

  handleChange = (e) => {
    const { handleSearchQueryChange } = this.props;
    handleSearchQueryChange(e.target.value);
  }

  submitOnEnter = (e) => {
    const { value, handleSearchButtonClick } = this.props;
    if (e.keyCode === 13) {
      handleSearchButtonClick(value);
    }
  }

  render() {
    const { placeholder, disabled, globalSearchQuery, value } = this.props;
    return (
      <Box marginBottom={4} flex>
        <Box width="30%">
          <SearchInput
            value={value}
            placeholder={placeholder}
            onChange={this.handleChange}
            onKeyDown={this.submitOnEnter}
          />
        </Box>
        <Box marginLeft={4}>
          <Button primary inline disabled={disabled} onClick={this.handleClick}>Search</Button>
        </Box>
        {/* Clear search button */}
        <Box marginLeft={4}>
          {globalSearchQuery !== '' && (<Button inline disabled={disabled} onClick={this.handleClear}>Clear </Button>)}
        </Box>
      </Box>
    );
  }
}


SearchBar.propTypes = {
  handleSearchButtonClick: PropTypes.func.isRequired,
  handleSearchQueryChange: PropTypes.func.isRequired,
  handleSearchClear: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  globalSearchQuery: PropTypes.string,
  disabled: PropTypes.bool,
};

SearchBar.defaultProps = {
  placeholder: 'Search',
  disabled: false,
  value: '',
  globalSearchQuery: '',
};

export default SearchBar;
