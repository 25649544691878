
export const enrollmentPermissions = [
  'enrollment_workflow',
  'read:enrollment_periods',
  'create:members',
  'update:members',
  'search:households',
  'read:membership_payments',
  'create:membership_payments',
  'read:household_enrollment',
  'create:household_enrollment',
  'create:member_enrollment',
  'read:administrative_divisions',
];

export const membershipPermissions = [
  'membership',
  'search:households',
  'read:members',
  'update:members',
  'read:enrollment_periods',
  'read:household_enrollment',
  'read:administrative_division',
];

export const memberViewOnlyPermissions = [
  'member_view_only',
  'search:households',
  'read:members',
  'read:enrollment_periods',
  'read:household_enrollment',
  'read:administrative_division',
];

export const identificationPermissions = [
  'identification_workflow',
  'create:id_event',
  'update:id_event',
  'create:encounters_provider',
  'read:encounters_provider',
  'update:encounters_provider',
  'search:households',
  'read:members',
  'create:members',
  'update:members',
  'update:member_photo',
  // TODO: verify if identification should be able to edit all info
  'read:enrollment_periods',
  'read:household_enrollment',
  'read:administrative_division',
  'register:members'
];

export const claimsViewOnlyPermissions = [
  'claims_view_only',
  'read:price_schedules_provider',
  'read:billables_provider',
  'read:encounters_provider',
  'read:members',
  'read:users',
  'read:diagnoses',
];

export const claimsPreparationPermissions = [
  // TODO: Technically this always includes being able to manually prepare a claim
  // which will create an identification event. We will need to consider that when we move
  // this to the backend.
  'claims_preparation_workflow',
  'create:price_schedules_provider',
  'read:price_schedules_provider',
  'create:billables_provider',
  'read:billables_provider',
  'read:encounters_provider',
  'create:encounters_provider',
  'read:members',
  'read:users',
  'read:diagnoses',
];

export const claimsResubmissionPermissions = [
  'claims_resubmission_workflow',
  'create:price_schedules_provider',
  'read:price_schedules_provider',
  'create:billables_provider',
  'read:billables_provider',
  'read:encounters_provider',
  'update:encounters_provider',
  'read:members',
  'read:users',
  'read:diagnoses',
];

export const claimsApprovalPermissions = [
  'claims_approval_workflow',
  'read:price_schedules_provider',
  'read:billables_provider',
  'read:encounters_provider',
  'update:encounters_provider',
  'read:members',
  'read:users',
  'read:diagnoses',
];

export const claimsAdjudicationPermissions = [
  'claims_adjudication_workflow',
  'read:encounters_all',
  'update:encounters_all',
  'read:enrollment_periods',
  'read:diagnoses',
  'read:administrative_divisions',
  'read:household_enrollment',
  'read:billables_all',
  'read:price_schedules_all',
  'read:members',
  'read:users',
  'read:providers',
];

export const reimbursementPaymentPermissions = [
  'reimbursement_payment_workflow',
  'read:encounters_all',
  'read:reimbursements',
  'update:reimbursements',
  'read:billables_all',
  'read:price_schedules_all',
  'read:providers',
];

export const claimsReimbursementPermissions = [
  'claims_reimbursement_workflow',
  'read:encounters_all',
  'update:encounters_all',
  'read:reimbursements',
  'update:reimbursements',
  'create:reimbursements',
  'read:billables_all',
  'read:price_schedules_all',
  'read:providers',
];

export const reimbursementsViewOnlyPermissions = [
  'reimbursement_view_only',
  'read:encounters_all',
  'read:reimbursements',
  'read:billables_all',
  'read:price_schedules_all',
  'read:providers',
];

export const enrollmentStatisticsPermissions = [
  'enrollment_statistics',
  'read:enrollment_stats',
  'read:administrative_divisions',
];

export const claimsStatisticsPermissions = [
  'claims_statistics',
  'read:claims_stats',
  'read:administrative_divisions',
];

export const reimbursementStatisticsPermissions = [
  'reimbursement_statistics',
  'read:reimbursement_stats',
  'read:administrative_divisions',
];

export const externalClaimsViewPermissions = [
  'read_external_claims',
  'read:encounters_all',
  'read:enrollment_periods',
  'read:diagnoses',
  'read:administrative_divisions',
  'read:household_enrollment',
  'read:billables_all',
  'read:price_schedules_all',
  'read:members',
  'read:users',
  'read:providers',
];

export const utilizationReportingPermissions = [
  'utilization_reporting',
];

export const enrollmentReportingPermissions = [
  'enrollment_reporting',
];

export const contributionReportingPermissions = [
  'contribution_reporting',
];

export const ageChangeReportingPermissions = [
  'age_change_reporting',
];

export const numberOfClaimsAdjudicatedReportingPermissions = [
  'number_of_claims_adjudicated',
];

export const claimsFilledByProvidersReportingPermissions = [
  'claims_filled_by_providers',
];

export const operationalReportOnReferralsReportingPermissions = [
  'operation_report_on_referrals',
];

export const claimsSummaryReportingPermissions = [
  'claims_summary',
];

export const referredInReportingPermissions = [
  'referred_in',
];

export const referredOutReportingPermissions = [
  'referred_out',
];

export const beneficiaryHistoryReportingPermissions = [
  'beneficiary_history',
];

export const owedAndReimbursedReportingPermissions = [
  'owed_and_reimbursed',
];

export const payeeWiseSummaryReportingPermissions = [
  'payee_wise_summary',
];

export const statusWiseSummaryReportingPermissions = [
  'status_wise_summary',
];

export const aggregateReportsReportingPermissions = [
  'aggregate_reports',
];

export const reportOnReimbursementsReportingPermissions = [
  'report_on_reimbursements',
];

export const householdNumberReportingPermissions = [
  'household_number',
];

export const facilityDashboard = [
  'view:facility_dashboard',
];

export const branchDashboard = [
  'view:branch_dashboard',
];

export const enrollmentDashboard = [
  'view:enrollment_dashboard',
];

export const importClaimsPermissions = [
  'import_claims',
];

export const changePasswordPermissions = [
  'change_password',
];

export const memberListPermissions = [
  'members_list',
];

export const claimsReportsPermissions = [
  'claims_reports',
];

export const indigentsPaymentsPermissions = [
  'indigents_payments',
];

// ROLES

export const ROLES = {
  PROVIDER: 'provider',
  ENROLLMENT_WORKER: 'enrollment_worker',
  CARD_ROOM_WORKER: 'card_room_worker',
  CLAIMS_PREPARER: 'claims_preparer',
  FACILITY_HEAD: 'facility_head',
  // new roles
  SYSTEM_ADMIN: 'system_admin',
  MEMBER_MANAGER: 'member_manager',
  CLAIMS_MANAGER: 'claims_manager',
  FINANCE_MANAGER: 'finance_manager',
  FACILITY_FINANCE_MANAGER: 'facility_finance_manager',
  REPORT_MANAGER: 'report_manager',
  BRANCH_MANAGER: 'branch_manager',
  CLAIMS_REVIEWER: 'claims_reviewer',
  FACILITY_DIRECTOR: 'facility_director',
  CLAIMS_OFFICER: 'claims_officer',
  RECEPTIONIST: 'receptionist',
  ENROLLMENT_MANAGER: 'enrollment_manager',
  ENROLLMENT_OFFICER: 'enrollment_officer',
  MEMBER_IMPORTER: 'member_importer'
};

export const ALLOWED_USER_ROLES = [
  ROLES.PROVIDER,
  ROLES.ENROLLMENT_WORKER,
  ROLES.CARD_ROOM_WORKER,
  ROLES.CLAIMS_PREPARER,
  ROLES.FACILITY_HEAD,
  ROLES.MEMBER_MANAGER,
  ROLES.CLAIMS_MANAGER,
  ROLES.FINANCE_MANAGER,
  ROLES.FACILITY_FINANCE_MANAGER,
  ROLES.REPORT_MANAGER,
  ROLES.BRANCH_MANAGER,
  ROLES.CLAIMS_REVIEWER,
  ROLES.FACILITY_DIRECTOR,
  ROLES.CLAIMS_OFFICER,
  ROLES.RECEPTIONIST,
  ROLES.ENROLLMENT_MANAGER,
  ROLES.ENROLLMENT_OFFICER,
  ROLES.MEMBER_IMPORTER
];

export const ROLE_PERMISSIONS = {
  provider: [
    ROLES.PROVIDER,
    ...claimsAdjudicationPermissions,
    ...claimsStatisticsPermissions,
    ...externalClaimsViewPermissions,
    ...facilityDashboard,
    ...changePasswordPermissions,
  ],
  facility_head: [
    ROLES.FACILITY_HEAD,
    ...claimsApprovalPermissions,
    ...claimsViewOnlyPermissions,
    ...claimsStatisticsPermissions,
    ...externalClaimsViewPermissions,
    ...changePasswordPermissions,
  ],
  card_room_worker: [
    ROLES.CARD_ROOM_WORKER,
    ...identificationPermissions,
    ...changePasswordPermissions,
  ],
  claims_preparer: [
    ROLES.CLAIMS_PREPARER,
    ...claimsPreparationPermissions,
    ...claimsViewOnlyPermissions,
    ...claimsStatisticsPermissions,
    ...externalClaimsViewPermissions,
    ...changePasswordPermissions,
  ],
  enrollment_worker: [
    ROLES.ENROLLMENT_WORKER,
    ...enrollmentPermissions,
    ...enrollmentStatisticsPermissions,
    ...changePasswordPermissions,
    ...memberListPermissions,
  ],
  system_admin: [
    // All admin operations done through the admin panel
    ROLES.SYSTEM_ADMIN,
  ],
  member_manager: [
    ROLES.MEMBER_MANAGER,
    ...enrollmentStatisticsPermissions,
    ...memberViewOnlyPermissions,
    // TODO: CRUD indigent, CBHI eligibility etc [ability doesn't exist yet]
    ...contributionReportingPermissions,
    ...enrollmentReportingPermissions,
    ...changePasswordPermissions,
  ],
  claims_manager: [
    ROLES.CLAIMS_MANAGER,
    ...memberViewOnlyPermissions,
    ...claimsAdjudicationPermissions,
    // TODO: audit, "park" permissions [ability doesn't exist yet]
    ...claimsStatisticsPermissions,
    // TODO: private provider adjudication permissions [ability doesn't exist yet]
    ...reimbursementStatisticsPermissions,
    ...claimsReimbursementPermissions,
    ...reimbursementPaymentPermissions,
    ...importClaimsPermissions,
    ...changePasswordPermissions,
  ],
  report_manager: [
    // Only MetaBase access not sure if we need this role here
    // maybe we want a route to have a link out to metabase?
    ROLES.REPORT_MANAGER,
    ...utilizationReportingPermissions,
    ...contributionReportingPermissions,
    ...enrollmentReportingPermissions,
    ...changePasswordPermissions,
  ],
  branch_manager: [
    ROLES.BRANCH_MANAGER,
    ...enrollmentStatisticsPermissions,
    ...memberViewOnlyPermissions,
    ...claimsViewOnlyPermissions,
    ...claimsStatisticsPermissions,
    ...claimsAdjudicationPermissions,
    ...reimbursementStatisticsPermissions,
    ...reimbursementsViewOnlyPermissions,
    ...utilizationReportingPermissions,
    ...contributionReportingPermissions,
    ...enrollmentReportingPermissions,
    ...numberOfClaimsAdjudicatedReportingPermissions,
    ...claimsFilledByProvidersReportingPermissions,
    ...operationalReportOnReferralsReportingPermissions,
    ...claimsSummaryReportingPermissions,
    ...referredInReportingPermissions,
    ...referredOutReportingPermissions,
    ...owedAndReimbursedReportingPermissions,
    ...statusWiseSummaryReportingPermissions,
    ...reportOnReimbursementsReportingPermissions,
    ...branchDashboard,
    ...enrollmentDashboard,
    ...importClaimsPermissions,
    ...changePasswordPermissions,
    ...memberListPermissions,
    ...claimsReportsPermissions,
    ...indigentsPaymentsPermissions,
  ],
  claims_reviewer: [
    ROLES.CLAIMS_REVIEWER,
    ...memberViewOnlyPermissions,
    ...claimsAdjudicationPermissions,
    // TODO: audit, "park" permissions [ability doesn't exist yet]
    ...claimsStatisticsPermissions,
    ...reimbursementStatisticsPermissions,
    ...claimsReimbursementPermissions,
    ...reimbursementPaymentPermissions,
    ...importClaimsPermissions,
    ...changePasswordPermissions,
  ],
  facility_director: [
    ROLES.FACILITY_DIRECTOR,
    ...reimbursementsViewOnlyPermissions,
    ...changePasswordPermissions,
  ],
  receptionist: [
    ROLES.RECEPTIONIST,
    ...identificationPermissions,
    ...changePasswordPermissions,
  ],
  claims_officer: [
    ROLES.CLAIMS_OFFICER,
    ...claimsPreparationPermissions,
    ...claimsViewOnlyPermissions,
    // TODO: view historical claims implies limiting view elsewhere?
    ...claimsStatisticsPermissions,
    ...externalClaimsViewPermissions,
    ...changePasswordPermissions,
  ],
  finance_manager: [
    ROLES.FINANCE_MANAGER,
    ...reimbursementStatisticsPermissions,
    ...reimbursementsViewOnlyPermissions,
    ...reimbursementPaymentPermissions,
    ...utilizationReportingPermissions,
    ...enrollmentStatisticsPermissions,
    ...changePasswordPermissions,
  ],
  // TODO: should be scoped to facility only
  facility_finance_manager: [
    ROLES.FACILITY_FINANCE_MANAGER,
    ...memberViewOnlyPermissions,
    ...reimbursementStatisticsPermissions,
    ...reimbursementsViewOnlyPermissions,
    ...changePasswordPermissions,
  ],
  enrollment_manager: [
    ROLES.ENROLLMENT_MANAGER,
    ...enrollmentStatisticsPermissions,
    ...membershipPermissions,
    ...utilizationReportingPermissions,
    ...contributionReportingPermissions,
    ...enrollmentReportingPermissions,
    ...ageChangeReportingPermissions,
    ...beneficiaryHistoryReportingPermissions,
    ...payeeWiseSummaryReportingPermissions,
    ...aggregateReportsReportingPermissions,
    ...householdNumberReportingPermissions,
    ...enrollmentDashboard,
    ...changePasswordPermissions,
  ],
  enrollment_officer: [
    ROLES.ENROLLMENT_OFFICER,
    ...enrollmentPermissions,
    ...enrollmentStatisticsPermissions,
    ...changePasswordPermissions,
  ],
};
