/* eslint-disable max-len */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import { REPORTS } from 'lib/config';
import { isNil, min, reduce } from 'lodash/fp';

import moment from 'moment';

import { formatDate } from 'lib/formatters/date';

import {
  fetchEnrollmentReport as fetchEnrollmentReportAction,
  fetchContributionReport as fetchContributionReportAction,
  fetchUtilizationReport as fetchUtilizationReportAction,
} from 'store/reporting/reporting-actions';
import { fetchProviders } from 'store/providers/providers-actions';
import { providersKeyedByIdSelector } from 'store/providers/providers-selectors';
import { fetchAdministrativeDivisions } from 'store/administrative-divisions/administrative-divisions-actions';
import { fetchEnrollmentPeriods } from 'store/enrollment/enrollment-actions';
import {
  userSelector,
  allowedReportsSelector,
} from 'store/auth/auth-selectors';
import { isBranchOrHQUserSelector } from 'store/administrative-divisions/administrative-divisions-selectors';
import {
  enrollmentPeriodOptionsSelector,
  pastAndPresentEnrollmentPeriodsKeyedByIdSelector,
} from 'store/enrollment/enrollment-selectors';
import {
  fetchAgeChanges,
  fetchNumberOfClaimsAdjudicated,
  fetchClaimsFilledByProviders,
  fetchOperationalReportOnReferrals,
  fetchClaimsSummary,
  fetchReferredIn,
  fetchReferredOut,
  fetchBeneficiaryHistory,
  fetchOwedAndReimbursed,
  fetchPayeeWiseSummary,
  fetchStatusWiseSummary,
  fetchAggregateReports,
  fetchReportOnReimbursements,
  fetchHouseholdNumber,
  fetchEnrollmentDashboard,
} from 'store/metabase/metabase-actions';

import { LayoutWithHeader } from 'components/layouts';
import { PillLink } from 'components/links';
import { Alert } from 'components/alerts';
import LoadingIndicator from 'components/loading-indicator';
import Box from 'components/box';
import Button from 'components/button';
import { DatePicker, SelectField, TextField } from 'components/inputs';
import { isNull } from 'util';

const EMPTY_VALUE = -1;

class ReportingContainer extends Component {
  static mapStateToProps = state => ({
    user: userSelector(state),
    allowedReports: allowedReportsSelector(state),
    isBranchOrHQUser: isBranchOrHQUserSelector(state),
    enrollmentPeriods: pastAndPresentEnrollmentPeriodsKeyedByIdSelector(state),
    enrollmentPeriodOptions: enrollmentPeriodOptionsSelector(state),
    providersById: providersKeyedByIdSelector(state),
    isLoadingAgeChanges: state.metabase.isLoadingAgeChanges,
    ageChanges: state.metabase.ageChanges,
    ageChangesError: state.metabase.ageChangesError,
    isLoadingNumberOfClaimsAdjudicated:
      state.metabase.isLoadingNumberOfClaimsAdjudicated,
    numberOfClaimsAdjudicated: state.metabase.numberOfClaimsAdjudicated,
    numberOfClaimsAdjudicatedError:
      state.metabase.numberOfClaimsAdjudicatedError,
    isLoadingClaimsFilledByProviders:
      state.metabase.isLoadingClaimsFilledByProviders,
    claimsFilledByProviders: state.metabase.claimsFilledByProviders,
    claimsFilledByProvidersError: state.metabase.claimsFilledByProvidersError,
    isLoadingOperationalReportOnReferrals:
      state.metabase.isLoadingOperationalReportOnReferrals,
    operationalReportOnReferralsError:
      state.metabase.operationalReportOnReferralsError,
    operationalReportOnReferrals: state.metabase.operationalReportOnReferrals,
    isLoadingClaimsSummary: state.metabase.isLoadingClaimsSummary,
    claimsSummaryError: state.metabase.claimsSummaryError,
    claimsSummary: state.metabase.claimsSummary,
    isLoadingReferredIn: state.metabase.isLoadingReferredIn,
    referredInError: state.metabase.referredInError,
    referredIn: state.metabase.referredIn,
    isLoadingReferredOut: state.metabase.isLoadingReferredOut,
    referredOutError: state.metabase.referredOutError,
    referredOut: state.metabase.referredOut,
    isLoadingBeneficiaryHistory: state.metabase.isLoadingBeneficiaryHistory,
    beneficiaryHistoryError: state.metabase.beneficiaryHistoryError,
    beneficiaryHistory: state.metabase.beneficiaryHistory,
    isLoadingOwedAndReimbursed: state.metabase.isLoadingOwedAndReimbursed,
    owedAndReimbursedError: state.metabase.owedAndReimbursedError,
    owedAndReimbursed: state.metabase.owedAndReimbursed,
    isLoadingPayeeWiseSummary: state.metabase.isLoadingPayeeWiseSummary,
    payeeWiseSummaryError: state.metabase.payeeWiseSummaryError,
    payeeWiseSummary: state.metabase.payeeWiseSummary,
    isLoadingStatusWiseSummary: state.metabase.isLoadingStatusWiseSummary,
    statusWiseSummaryError: state.metabase.statusWiseSummaryError,
    statusWiseSummary: state.metabase.statusWiseSummary,
    isLoadingAggregateReports: state.metabase.isLoadingAggregateReports,
    aggregateReportsError: state.metabase.aggregateReportsError,
    aggregateReports: state.metabase.aggregateReports,
    isLoadingReportOnReimbursements:
      state.metabase.isLoadingReportOnReimbursements,
    reportOnReimbursementsError: state.metabase.reportOnReimbursementsError,
    reportOnReimbursements: state.metabase.reportOnReimbursements,
    isLoadingHouseholdNumber: state.metabase.isLoadingHouseholdNumber,
    householdNumberError: state.metabase.householdNumberError,
    householdNumber: state.metabase.householdNumber,
    isLoadingEnrollmentDashboard: state.metabase.isLoadingEnrollmentDashboard,
    enrollmentDashboardError: state.metabase.enrollmentDashboardError,
    enrollmentDashboard: state.metabase.enrollmentDashboard,
  });

  static mapDispatchToProps = dispatch => ({
    loadData() {
      dispatch(fetchProviders());
      dispatch(fetchAdministrativeDivisions());
      dispatch(fetchEnrollmentPeriods());
    },
    fetchEnrollmentReport: (startDate, endDate, branch) => dispatch(fetchEnrollmentReportAction(startDate, endDate, branch)),
    fetchContributionReport: (startDate, endDate, branch) => dispatch(fetchContributionReportAction(startDate, endDate, branch)),
    fetchUtilizationReport: (startDate, endDate) => dispatch(fetchUtilizationReportAction(startDate, endDate)),
    loadAgeChanges: (startDate, endDate) => dispatch(fetchAgeChanges(startDate, endDate)),
    loadNumberOfClaimsAdjudicated: (startDate, endDate) => dispatch(fetchNumberOfClaimsAdjudicated(startDate, endDate)),
    loadClaimsFilledByProviders: (startDate, endDate) => dispatch(fetchClaimsFilledByProviders(startDate, endDate)),
    loadOperationalReportOnReferrals: (startDate, endDate) => dispatch(fetchOperationalReportOnReferrals(startDate, endDate)),
    loadClaimsSummary: (startDate, endDate) => dispatch(fetchClaimsSummary(startDate, endDate)),
    loadReferredIn: (startDate, endDate) => dispatch(fetchReferredIn(startDate, endDate)),
    loadhReferredOut: (startDate, endDate) => dispatch(fetchReferredOut(startDate, endDate)),
    loadBeneficiaryHistory: id => dispatch(fetchBeneficiaryHistory(id)),
    loadOwedAndReimbursed: (startDate, endDate) => dispatch(fetchOwedAndReimbursed(startDate, endDate)),
    loadPayeeWiseSummary: (startDate, endDate) => dispatch(fetchPayeeWiseSummary(startDate, endDate)),
    loadStatusWiseSummary: (startDate, endDate) => dispatch(fetchStatusWiseSummary(startDate, endDate)),
    loadAggregateReports: (startDate, endDate) => dispatch(fetchAggregateReports(startDate, endDate)),
    loadReportOnReimbursements: (id, startDate, endDate) => dispatch(fetchReportOnReimbursements(id, startDate, endDate)),
    loadHouseholdNumber: () => dispatch(fetchHouseholdNumber()),
    loadEnrollmentDashboard: (enrollmentPeriodId, startDate, endDate) => dispatch(fetchEnrollmentDashboard(enrollmentPeriodId, startDate, endDate)),

  });

  state = {
    enrollmentPeriodId: EMPTY_VALUE,
    startDate: null,
    endDate: null,
    id: null,
    selectedProviderId: null,
  };

  componentDidMount() {
    const { loadData } = this.props;
    loadData();
  }

  handleSelectEnrollmentPeriod = (enrollmentPeriodId) => {
    const { enrollmentPeriods } = this.props;
    const enrollmentPeriod = enrollmentPeriods[enrollmentPeriodId];
    const currentTime = moment();

    this.setState({
      enrollmentPeriodId,
      startDate: enrollmentPeriod.startDate,
      endDate: min([enrollmentPeriod.endDate, currentTime]),
    });
    const { match } = this.props;
    const route = match.params.report !== undefined ? match.params.report : null;
    if (route === REPORTS.ENROLLMENT_DASHBOARD) {
      this.handleEnrollmentDashboard();
    }
  };

  handleIdChange = (event) => {
    this.setState({ id: event.target.value });
  };

  handleProviderChange = (e) => {
    const selectedProviderId = parseInt(e.target.value, 10) || null;
    this.setState({ selectedProviderId });
    const { loadReportOnReimbursements } = this.props;
    const { startDate, endDate } = this.state;
    loadReportOnReimbursements(selectedProviderId, startDate, endDate);
  };

  handleDateChange = (gregorianDate, ethiopianDate, isStart) => {
    if (isStart) {
      this.setState({ startDate: gregorianDate });
    } else {
      this.setState({ endDate: gregorianDate });
    }
    const { match } = this.props;
    const route = match.params.report !== undefined ? match.params.report : null;
    if (route === REPORTS.AGE_CHANGES) {
      this.handleAgeChange();
    } else if (route === REPORTS.NUMBER_OF_CLAIMS_ADJUDICATED) {
      this.handleNumberOfClaimsAdjudicated();
    } else if (route === REPORTS.CLAIMS_FILLED_BY_PROVIDERS) {
      this.handleClaimsFilledByProviders();
    } else if (route === REPORTS.OPERATIONAL_REPORT_ON_REFERRALS) {
      this.handleOperationalReportOnReferrals();
    } else if (route === REPORTS.CLAIMS_SUMMARY) {
      this.handleClaimsSummary();
    } else if (route === REPORTS.REFERRED_IN) {
      this.handleReferredIn();
    } else if (route === REPORTS.REFERRED_OUT) {
      this.handleReferredOut();
    } else if (route === REPORTS.OWED_AND_REIMBURSED) {
      this.handleOwedAndReimbursed();
    } else if (route === REPORTS.PAYEE_WISE_SUMMARY) {
      this.handlePayeeWiseSummary();
    } else if (route === REPORTS.AGGREGATE_REPORTS) {
      this.handleAggregateReports();
    } else if (route === REPORTS.STATUS_WISE_SUMMARY) {
      this.handleStatusWiseSummary();
    } else if (route === REPORTS.REPORT_ON_REIMBURSEMENTS) {
      this.handleReportOnReimbursements();
    } else if (route === REPORTS.ENROLLMENT_DASHBOARD) {
      this.handleEnrollmentDashboard();
    }
  };

  renderUtilizationReporting = () => {
    const { fetchUtilizationReport } = this.props;
    const { startDate, endDate } = this.state;
    const disabled = !startDate || !endDate;
    return (
      <Box>
        <Button
          inline
          primary
          disabled={disabled}
          onClick={() => fetchUtilizationReport(startDate, endDate)}
        >
          Export Utilization
        </Button>
      </Box>
    );
  };

  renderContributionReporting = () => {
    const { fetchContributionReport, isBranchOrHQUser } = this.props;
    const { startDate, endDate } = this.state;

    const disabled = !startDate || !endDate;
    return (
      <Box>
        <Button
          inline
          primary
          disabled={disabled}
          onClick={() => fetchContributionReport(startDate, endDate, isBranchOrHQUser)
          }
        >
          Export Contribution
        </Button>
      </Box>
    );
  };

  renderEnrollmentReporting = () => {
    const { fetchEnrollmentReport, isBranchOrHQUser } = this.props;
    const { enrollmentPeriodId, startDate, endDate } = this.state;
    const disabled = !enrollmentPeriodId || !startDate || !endDate;
    return (
      <Box>
        <Button
          inline
          primary
          disabled={disabled}
          onClick={() => fetchEnrollmentReport(
            startDate,
            endDate,
            enrollmentPeriodId,
            isBranchOrHQUser,
          )
          }
        >
          Export Enrollment
        </Button>
      </Box>
    );
  };

  renderAgeChangesReporting = () => {
    const { ageChanges, isLoadingAgeChanges, ageChangesError } = this.props;

    if (isLoadingAgeChanges) return <LoadingIndicator noun="Metabase Dashboard " />;

    if (ageChangesError) {
      return (
        <Box marginBottom={4}>
          <Alert>{ageChangesError}</Alert>
        </Box>
      );
    }

    return (
      <iframe
        src={ageChanges.iframeUrl}
        frameBorder="0 "
        width="1000"
        height="500"
        allowTransparency
        title="Metabase Dashboard"
      />
    );
  };

  rendernumberOfClaimsAdjudicated = () => {
    const {
      numberOfClaimsAdjudicated,
      isLoadingNumberOfClaimsAdjudicated,
      numberOfClaimsAdjudicatedError,
    } = this.props;
    const { iframeUrl } = numberOfClaimsAdjudicated;
    if (isLoadingNumberOfClaimsAdjudicated) return <LoadingIndicator noun="Metabase Dashboard" />;

    if (numberOfClaimsAdjudicatedError) {
      return (
        <Box marginBottom={4}>
          <Alert>
            {' '}
            {numberOfClaimsAdjudicatedError}
          </Alert>
        </Box>
      );
    }

    return (
      <iframe
        src={iframeUrl}
        frameBorder="0"
        width="1000"
        height="500"
        allowTransparency
        title="Metabase Dashboard"
      />
    );
  };

  renderClaimsFilledByProviders = () => {
    const {
      claimsFilledByProviders,
      isLoadingClaimsFilledByProviders,
      claimsFilledByProvidersError,
    } = this.props;

    if (isLoadingClaimsFilledByProviders) return <LoadingIndicator noun="Metabase Dashboard" />;

    if (claimsFilledByProvidersError) {
      return (
        <Box marginBottom={4}>
          <Alert>{claimsFilledByProvidersError}</Alert>
        </Box>
      );
    }

    return (
      <iframe
        src={claimsFilledByProviders.iframeUrl}
        frameBorder="0"
        width="1000"
        height="800"
        allowTransparency
        title="Metabase Dashboard"
      />
    );
  };

  renderOperationalReportOnReferrals = () => {
    const {
      operationalReportOnReferrals,
      isLoadingOperationalReportOnReferrals,
      operationalReportOnReferralsError,
    } = this.props;

    if (isLoadingOperationalReportOnReferrals) return <LoadingIndicator noun="Metabase Reports" />;

    if (operationalReportOnReferralsError) {
      return (
        <Box marginBottom={4}>
          <Alert>{operationalReportOnReferralsError}</Alert>
        </Box>
      );
    }

    return (
      <iframe
        src={operationalReportOnReferrals.iframeUrl}
        frameBorder="0"
        width="1000"
        height="500"
        allowTransparency
        title="Metabase Dashboard"
      />
    );
  };

  renderClaimsSummary = () => {
    const {
      claimsSummary,
      isLoadingClaimsSummary,
      claimsSummaryError,
    } = this.props;

    if (isLoadingClaimsSummary) return <LoadingIndicator noun="Metabase Reports" />;

    if (claimsSummaryError) {
      return (
        <Box marginBottom={4}>
          <Alert>{claimsSummaryError}</Alert>
        </Box>
      );
    }

    return (
      <iframe
        src={claimsSummary.iframeUrl}
        frameBorder="0"
        width="1000"
        height="500"
        allowTransparency
        title="Metabase Dashboard"
      />
    );
  };

  renderReferredIn = () => {
    const { referredIn, isLoadingReferredIn, referredInError } = this.props;

    if (isLoadingReferredIn) return <LoadingIndicator noun="Metabase Reports" />;

    if (referredInError) {
      return (
        <Box marginBottom={4}>
          <Alert>{referredInError}</Alert>
        </Box>
      );
    }

    return (
      <iframe
        src={referredIn.iframeUrl}
        frameBorder="0"
        width="1000"
        height="500"
        allowTransparency
        title="Metabase Dashboard"
      />
    );
  };

  renderReferredOut = () => {
    const { referredOut, isLoadingReferredOut, referredOutError } = this.props;

    if (isLoadingReferredOut) return <LoadingIndicator noun="Metabase Reports" />;

    if (referredOutError) {
      return (
        <Box margOutBottom={4}>
          <Alert>{referredOutError}</Alert>
        </Box>
      );
    }

    return (
      <iframe
        src={referredOut.iframeUrl}
        frameBorder="0"
        width="1000"
        height="500"
        allowTransparency
        title="Metabase Dashboard"
      />
    );
  };

  renderBeneficiaryHistory = () => {
    const {
      beneficiaryHistory,
      isLoadingBeneficiaryHistory,
      beneficiaryHistoryError,
    } = this.props;

    if (isLoadingBeneficiaryHistory) return <LoadingIndicator noun="Metabase Reports" />;

    if (beneficiaryHistoryError) {
      return (
        <Box margOutBottom={4}>
          <Alert>{beneficiaryHistoryError}</Alert>
        </Box>
      );
    }

    return (
      <iframe
        src={beneficiaryHistory.iframeUrl}
        frameBorder="0"
        width="1000"
        height="500"
        allowTransparency
        title="Metabase Dashboard"
      />
    );
  };

  renderOwedAndReimbursed = () => {
    const {
      owedAndReimbursed,
      isLoadingOwedAndReimbursed,
      owedAndReimbursedError,
    } = this.props;

    if (isLoadingOwedAndReimbursed) return <LoadingIndicator noun="Metabase Reports" />;

    if (owedAndReimbursedError) {
      return (
        <Box margOutBottom={4}>
          <Alert>{owedAndReimbursedError}</Alert>
        </Box>
      );
    }

    return (
      <iframe
        src={owedAndReimbursed.iframeUrl}
        frameBorder="0"
        width="1000"
        height="500"
        allowTransparency
        title="Metabase Dashboard"
      />
    );
  };

  renderPayeeWiseSummary = () => {
    const {
      payeeWiseSummary,
      isLoadingPayeeWiseSummary,
      payeeWiseSummaryError,
    } = this.props;

    if (isLoadingPayeeWiseSummary) return <LoadingIndicator noun="Metabase Reports" />;

    if (payeeWiseSummaryError) {
      return (
        <Box margOutBottom={4}>
          <Alert>{payeeWiseSummaryError}</Alert>
        </Box>
      );
    }

    return (
      <iframe
        src={payeeWiseSummary.iframeUrl}
        frameBorder="0"
        width="1000"
        height="500"
        allowTransparency
        title="Metabase Dashboard"
      />
    );
  };

  renderAggregateReports = () => {
    const {
      aggregateReports,
      isLoadingAggregateReports,
      aggregateReportsError,
    } = this.props;

    if (isLoadingAggregateReports) return <LoadingIndicator noun="Metabase Reports" />;

    if (aggregateReportsError) {
      return (
        <Box margOutBottom={4}>
          <Alert>{aggregateReportsError}</Alert>
        </Box>
      );
    }

    return (
      <iframe
        src={aggregateReports.iframeUrl}
        frameBorder="0"
        width="1000"
        height="500"
        allowTransparency
        title="Metabase Dashboard"
      />
    );
  };

  renderStatusWiseSummary = () => {
    const {
      statusWiseSummary,
      isLoadingStatusWiseSummary,
      statusWiseSummaryError,
    } = this.props;

    if (isLoadingStatusWiseSummary) return <LoadingIndicator noun="Metabase Reports" />;

    if (statusWiseSummaryError) {
      return (
        <Box margOutBottom={4}>
          <Alert>{statusWiseSummaryError}</Alert>
        </Box>
      );
    }

    return (
      <iframe
        src={statusWiseSummary.iframeUrl}
        frameBorder="0"
        width="1000"
        height="500"
        allowTransparency
        title="Metabase Dashboard"
      />
    );
  };

  renderReportOnReimbursements = () => {
    const {
      reportOnReimbursements,
      isLoadingReportOnReimbursements,
      reportOnReimbursementsError,
    } = this.props;

    if (isLoadingReportOnReimbursements) return <LoadingIndicator noun="Metabase Reports" />;

    if (reportOnReimbursementsError) {
      return (
        <Box margOutBottom={4}>
          <Alert>{reportOnReimbursementsError}</Alert>
        </Box>
      );
    }

    return (
      <iframe
        src={reportOnReimbursements.iframeUrl}
        frameBorder="0"
        width="1000"
        height="500"
        allowTransparency
        title="Metabase Dashboard"
      />
    );
  };

  renderHouseholdNumber = () => {
    const { householdNumber, isLoadingHouseholdNumber, householdNumberError } = this.props;

    if (isLoadingHouseholdNumber) return <LoadingIndicator noun="Metabase Reports" />;

    if (householdNumberError) {
      return (
        <Box margOutBottom={4}>
          <Alert>{householdNumberError}</Alert>
        </Box>
      );
    }

    return (
      <iframe
        src={householdNumber.iframeUrl}
        frameBorder="0"
        width="1000"
        height="500"
        allowTransparency
        title="Metabase Dashboard"
      />
    );
  };

  renderEnrollmentDashboard = () => {
    const { enrollmentDashboard, isLoadingEnrollmentDashboard, enrollmentDashboardError } = this.props;

    if (isLoadingEnrollmentDashboard) return <LoadingIndicator noun="Metabase Reports" />;

    if (enrollmentDashboardError) {
      return (
        <Box margOutBottom={4}>
          <Alert>{enrollmentDashboardError}</Alert>
        </Box>
      );
    }

    return (
      <iframe
        src={enrollmentDashboard.iframeUrl}
        frameBorder="0"
        width="1000"
        height="500"
        allowTransparency
        title="Metabase Dashboard"
      />
    );
  };

  handleAgeChange = () => {
    const { loadAgeChanges } = this.props;
    const { startDate, endDate } = this.state;
    loadAgeChanges(startDate, endDate);
  };

  handleNumberOfClaimsAdjudicated = () => {
    const { loadNumberOfClaimsAdjudicated } = this.props;
    const { startDate, endDate } = this.state;
    loadNumberOfClaimsAdjudicated(startDate, endDate);
  };

  handleClaimsFilledByProviders = () => {
    const { loadClaimsFilledByProviders } = this.props;
    const { startDate, endDate } = this.state;
    loadClaimsFilledByProviders(startDate, endDate);
  };

  handleOperationalReportOnReferrals = () => {
    const { loadOperationalReportOnReferrals } = this.props;
    const { startDate, endDate } = this.state;
    loadOperationalReportOnReferrals(startDate, endDate);
  };

  handleClaimsSummary = () => {
    const { loadClaimsSummary } = this.props;
    const { startDate, endDate } = this.state;
    loadClaimsSummary(startDate, endDate);
  };

  handleReferredIn = () => {
    const { loadReferredIn } = this.props;
    const { startDate, endDate } = this.state;
    loadReferredIn(startDate, endDate);
  };

  handleReferredOut = () => {
    const { loadhReferredOut } = this.props;
    const { startDate, endDate } = this.state;
    loadhReferredOut(startDate, endDate);
  };

  handleBeneficiaryHistory = () => {
    const { loadBeneficiaryHistory } = this.props;
    const { id } = this.state;
    loadBeneficiaryHistory(id);
  };

  handleOwedAndReimbursed = () => {
    const { loadOwedAndReimbursed } = this.props;
    const { startDate, endDate } = this.state;
    loadOwedAndReimbursed(startDate, endDate);
  };

  handlePayeeWiseSummary = () => {
    const { loadPayeeWiseSummary } = this.props;
    const { startDate, endDate } = this.state;
    loadPayeeWiseSummary(startDate, endDate);
  };

  handleAggregateReports = () => {
    const { loadAggregateReports } = this.props;
    const { startDate, endDate } = this.state;
    loadAggregateReports(startDate, endDate);
  };

  handleStatusWiseSummary = () => {
    const { loadStatusWiseSummary } = this.props;
    const { startDate, endDate } = this.state;
    loadStatusWiseSummary(startDate, endDate);
  };

  handleReportOnReimbursements = () => {
    const { loadReportOnReimbursements } = this.props;
    const { startDate, endDate, selectedProviderId } = this.state;
    loadReportOnReimbursements(selectedProviderId, startDate, endDate);
  };

  handleHouseholdNumber = () => {
    const { loadHouseholdNumber } = this.props;
    loadHouseholdNumber();
  }

  handleEnrollmentDashboard = () => {
    const { loadEnrollmentDashboard } = this.props;
    const { startDate, endDate, enrollmentPeriodId } = this.state;
    loadEnrollmentDashboard(enrollmentPeriodId, startDate, endDate);
  };

  render() {
    const { match, allowedReports, enrollmentPeriodOptions, enrollmentPeriods, providersById } = this.props;
    const { enrollmentPeriodId, startDate, endDate, id } = this.state;
    const route = match.params.report !== undefined ? match.params.report : null;
    const enrollmentPeriod = enrollmentPeriods[enrollmentPeriodId];
    const providerOptions = reduce((opts, p) => [...opts, { ...p, value: p.id }], [{ name: 'Select Providers', value: 0 }],
    )(providersById);
    const ethiopianCurrentDate = formatDate(moment());
    const ethiopianEnrollmentPeriodStartDate = enrollmentPeriod ? formatDate(enrollmentPeriod.startDate) : undefined;
    const ethiopianEnrollmentPeriodEndDate = enrollmentPeriod ? formatDate(enrollmentPeriod.endDate) : undefined;
    const ethiopianStartDate = startDate ? formatDate(startDate) : undefined;
    const ethiopianEndDate = endDate ? formatDate(endDate) : undefined;

    if (isNil(route) && allowedReports[0]) {
      return <Redirect to={`/reporting/${allowedReports[0]}`} />;
    }
    if (isNil(route)) {
      return <Redirect to="/" />;
    }

    return (
      <LayoutWithHeader pageTitle="Reporting" steps={[{ title: 'Reporting', href: '/reporting' }]}>
        <Box
          flex
          flexDirection="row"
          justifyContent="space-between"
          marginBottom={1}
        >
          <Box flex marginBottom={5}>
            {allowedReports.includes(REPORTS.UTILIZATION) && (
              <Box marginRight={3}>
                <PillLink exact to="/reporting/utilization">
                  Utilization
                </PillLink>
              </Box>
            )}
            {allowedReports.includes(REPORTS.CONTRIBUTION) && (
              <Box marginRight={3}>
                <PillLink to="/reporting/contribution">Contribution</PillLink>
              </Box>
            )}
            {allowedReports.includes(REPORTS.ENROLLMENT) && (
              <PillLink to="/reporting/enrollment">Enrollment</PillLink>
            )}
            {allowedReports.includes(REPORTS.AGE_CHANGES) && (
              <PillLink
                onClick={() => this.handleAgeChange()}
                to="/reporting/age-changes"
              >
                Age Changes
              </PillLink>
            )}
            {allowedReports.includes(REPORTS.BENEFICIARY_HISTORY) && (
              <PillLink to="/reporting/beneficiary-history">
                Beneficiary History
              </PillLink>
            )}
            {allowedReports.includes(REPORTS.NUMBER_OF_CLAIMS_ADJUDICATED) && (
              <PillLink
                onClick={() => this.handleNumberOfClaimsAdjudicated()}
                to="/reporting/number-of-claims-adjudicated"
              >
                Claims Adjudicated
              </PillLink>
            )}
            {allowedReports.includes(REPORTS.CLAIMS_FILLED_BY_PROVIDERS) && (
              <PillLink
                onClick={() => this.handleClaimsFilledByProviders()}
                to="/reporting/claims-filled-by-providers"
              >
                Claims by providers
              </PillLink>
            )}
            {allowedReports.includes(
              REPORTS.OPERATIONAL_REPORT_ON_REFERRALS,
            ) && (
              <PillLink
                onClick={() => this.handleOperationalReportOnReferrals()}
                to="/reporting/operational-report-on-referrals"
              >
                Referral Reports
              </PillLink>
            )}
            {allowedReports.includes(REPORTS.CLAIMS_SUMMARY) && (
              <PillLink
                onClick={() => this.handleClaimsSummary()}
                to="/reporting/claims-summary"
              >
                Claims Summary
              </PillLink>
            )}
            {allowedReports.includes(REPORTS.PAYEE_WISE_SUMMARY) && (
              <PillLink
                onClick={() => this.handlePayeeWiseSummary()}
                to="/reporting/payee-wise-summary"
              >
                Payee Wise Summary
              </PillLink>
            )}
            {allowedReports.includes(REPORTS.AGGREGATE_REPORTS) && (
              <PillLink
                onClick={() => this.handleAggregateReports()}
                to="/reporting/aggregate-reports"
              >
                Aggregate Reports
              </PillLink>
            )}
          </Box>
        </Box>
        <Box flex marginBottom={5}>
          {allowedReports.includes(REPORTS.REFERRED_IN) && (
            <PillLink
              onClick={() => this.handleReferredIn()}
              to="/reporting/referred-in"
            >
              Referred In
            </PillLink>
          )}
          {allowedReports.includes(REPORTS.REFERRED_OUT) && (
            <PillLink
              onClick={() => this.handleReferredOut()}
              to="/reporting/referred-out"
            >
              Referred Out
            </PillLink>
          )}
          {allowedReports.includes(REPORTS.OWED_AND_REIMBURSED) && (
            <PillLink
              onClick={() => this.handleOwedAndReimbursed()}
              to="/reporting/owed-and-reimbursed"
            >
              Owed And Reimbursed
            </PillLink>
          )}
          {allowedReports.includes(REPORTS.STATUS_WISE_SUMMARY) && (
            <PillLink
              onClick={() => this.handleStatusWiseSummary()}
              to="/reporting/status-wise-summary"
            >
              Status Wise Summary
            </PillLink>
          )}
          {allowedReports.includes(REPORTS.REPORT_ON_REIMBURSEMENTS) && (
            <PillLink
              onClick={() => this.handleReportOnReimbursements()}
              to="/reporting/report-on-reimbursements"
            >
              Reimbursal Report
            </PillLink>
          )}
          {allowedReports.includes(REPORTS.HOUSEHOLD_NUMBER) && (
            <PillLink
              onClick={() => this.handleHouseholdNumber()}
              to="/reporting/household-number"
            >
              Household Number
            </PillLink>
          )}
          {allowedReports.includes(REPORTS.ENROLLMENT_DASHBOARD) && (
            <PillLink
              onClick={() => this.handleEnrollmentDashboard()}
              to="/reporting/enrollment-dashboard"
            >
              Enrollment Dashboard
            </PillLink>
          )}
        </Box>
        {route === REPORTS.BENEFICIARY_HISTORY && (
          <>
            <Grid container spacing={24}>
              <Grid item xs={6}>
                <Box marginBottom={5}>
                  <TextField
                    label="CBHI / Card ID"
                    name="id"
                    value={id}
                    onChange={e => this.handleIdChange(e)}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box>
                  <Button
                    inline
                    primary
                    onClick={this.handleBeneficiaryHistory}
                  >
                    Search Beneficiary
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </>
        )}
        {(route === REPORTS.ENROLLMENT || route === REPORTS.ENROLLMENT_DASHBOARD) && (
          <>
            <Box paddingBottom={3}>
              <SelectField
                label="Enrollment period"
                key="enrollmentPeriod"
                name="enrollmentPeriod"
                options={enrollmentPeriodOptions}
                value={enrollmentPeriodId}
                onChange={e => this.handleSelectEnrollmentPeriod(e.target.value)
                }
              />
            </Box>
            <Box flex flexDirection="row" marginBottom={4}>
              <Box marginRight={2} style={{ width: '200px' }}>
                <DatePicker
                  label="Start date"
                  defaultDate={ethiopianStartDate}
                  onChange={(gregorianDate, ethiopianDate) => this.handleDateChange(gregorianDate, ethiopianDate, true)
                  }
                  minDate={ethiopianEnrollmentPeriodStartDate}
                  maxDate={ethiopianEndDate}
                  name="start-date"
                  disabled={enrollmentPeriodId === EMPTY_VALUE}
                />
              </Box>
              <Box marginRight={2} style={{ width: '200px' }}>
                <DatePicker
                  label="End date"
                  defaultDate={ethiopianEndDate}
                  onChange={(gregorianDate, ethiopianDate) => this.handleDateChange(gregorianDate, ethiopianDate, false)
                  }
                  minDate={ethiopianStartDate}
                  maxDate={ethiopianEnrollmentPeriodEndDate}
                  name="end-date"
                  disabled={enrollmentPeriodId === EMPTY_VALUE}
                />
              </Box>
            </Box>
          </>
        )}
        {route !== REPORTS.HOUSEHOLD_NUMBER && route !== REPORTS.BENEFICIARY_HISTORY && route !== REPORTS.ENROLLMENT && route !== REPORTS.ENROLLMENT_DASHBOARD && (
          <Box flex flexDirection="row" marginBottom={4}>
            {route === REPORTS.REPORT_ON_REIMBURSEMENTS && (
              <Box flex flexDirection="column" width="30%" marginRight={3}>
                <SelectField
                  key="provider"
                  name="provider"
                  options={providerOptions}
                  onChange={this.handleProviderChange}
                />
              </Box>
            )}
            <Box marginRight={2} style={{ width: '200px' }}>
              <DatePicker
                defaultDate={ethiopianStartDate}
                label="Start date"
                onChange={(gregorianDate, ethiopianDate) => this.handleDateChange(gregorianDate, ethiopianDate, true)
                }
                maxDate={ethiopianEndDate || ethiopianCurrentDate}
                name="start-date"
              />
            </Box>
            <Box marginRight={2} style={{ width: '200px' }}>
              <DatePicker
                label="End date"
                defaultDate={ethiopianEndDate}
                onChange={(gregorianDate, ethiopianDate) => this.handleDateChange(gregorianDate, ethiopianDate, false)
                }
                minDate={ethiopianStartDate}
                maxDate={ethiopianCurrentDate}
                name="end-date"
                disabled={isNull(startDate)}
              />
            </Box>
          </Box>
        )}
        {route === REPORTS.UTILIZATION && this.renderUtilizationReporting()}
        {route === REPORTS.CONTRIBUTION && this.renderContributionReporting()}
        {route === REPORTS.ENROLLMENT && this.renderEnrollmentReporting()}
        {route === REPORTS.AGE_CHANGES && this.renderAgeChangesReporting()}
        {route === REPORTS.NUMBER_OF_CLAIMS_ADJUDICATED && this.rendernumberOfClaimsAdjudicated()}
        {route === REPORTS.CLAIMS_FILLED_BY_PROVIDERS && this.renderClaimsFilledByProviders()}
        {route === REPORTS.OPERATIONAL_REPORT_ON_REFERRALS && this.renderOperationalReportOnReferrals()}
        {route === REPORTS.CLAIMS_SUMMARY && this.renderClaimsSummary()}
        {route === REPORTS.REFERRED_IN && this.renderReferredIn()}
        {route === REPORTS.REFERRED_OUT && this.renderReferredOut()}
        {route === REPORTS.BENEFICIARY_HISTORY && this.renderBeneficiaryHistory()}
        {route === REPORTS.OWED_AND_REIMBURSED && this.renderOwedAndReimbursed()}
        {route === REPORTS.PAYEE_WISE_SUMMARY && this.renderPayeeWiseSummary()}
        {route === REPORTS.STATUS_WISE_SUMMARY && this.renderStatusWiseSummary()}
        {route === REPORTS.AGGREGATE_REPORTS && this.renderAggregateReports()}
        {route === REPORTS.REPORT_ON_REIMBURSEMENTS && this.renderReportOnReimbursements()}
        {route === REPORTS.HOUSEHOLD_NUMBER && this.renderHouseholdNumber()}
        {route === REPORTS.ENROLLMENT_DASHBOARD && this.renderEnrollmentDashboard()}


      </LayoutWithHeader>
    );
  }
}

export default connect(
  ReportingContainer.mapStateToProps,
  ReportingContainer.mapDispatchToProps,
)(ReportingContainer);

ReportingContainer.propTypes = {
  providersById: PropTypes.shape({}).isRequired,
  enrollmentPeriods: PropTypes.shape({}).isRequired,
  enrollmentPeriodOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  allowedReports: PropTypes.arrayOf(PropTypes.string).isRequired,
  fetchEnrollmentReport: PropTypes.func.isRequired,
  fetchContributionReport: PropTypes.func.isRequired,
  fetchUtilizationReport: PropTypes.func.isRequired,
  loadData: PropTypes.func.isRequired,
  loadAgeChanges: PropTypes.func.isRequired,
  loadClaimsFilledByProviders: PropTypes.func.isRequired,
  loadOperationalReportOnReferrals: PropTypes.func.isRequired,
  loadClaimsSummary: PropTypes.func.isRequired,
  loadReferredIn: PropTypes.func.isRequired,
  loadhReferredOut: PropTypes.func.isRequired,
  loadBeneficiaryHistory: PropTypes.func.isRequired,
  loadOwedAndReimbursed: PropTypes.func.isRequired,
  loadPayeeWiseSummary: PropTypes.func.isRequired,
  loadAggregateReports: PropTypes.func.isRequired,
  loadStatusWiseSummary: PropTypes.func.isRequired,
  loadReportOnReimbursements: PropTypes.func.isRequired,
  loadHouseholdNumber: PropTypes.func.isRequired,
  loadEnrollmentDashboard: PropTypes.func.isRequired,
  ageChanges: PropTypes.string,
  ageChangesError: PropTypes.string.isRequired,
  isLoadingAgeChanges: PropTypes.bool.isRequired,
  loadNumberOfClaimsAdjudicated: PropTypes.func.isRequired,
  numberOfClaimsAdjudicated: PropTypes.string,
  numberOfClaimsAdjudicatedError: PropTypes.string.isRequired,
  isLoadingNumberOfClaimsAdjudicated: PropTypes.bool.isRequired,
  claimsFilledByProviders: PropTypes.string,
  claimsFilledByProvidersError: PropTypes.string.isRequired,
  isLoadingClaimsFilledByProviders: PropTypes.bool.isRequired,
  operationalReportOnReferrals: PropTypes.string,
  operationalReportOnReferralsError: PropTypes.string.isRequired,
  isLoadingOperationalReportOnReferrals: PropTypes.bool.isRequired,
  claimsSummary: PropTypes.string,
  claimsSummaryError: PropTypes.string.isRequired,
  isLoadingClaimsSummary: PropTypes.bool.isRequired,
  referredIn: PropTypes.string,
  referredInError: PropTypes.string.isRequired,
  isLoadingReferredIn: PropTypes.bool.isRequired,
  referredOut: PropTypes.string,
  referredOutError: PropTypes.string.isRequired,
  isLoadingReferredOut: PropTypes.bool.isRequired,
  beneficiaryHistory: PropTypes.string,
  beneficiaryHistoryError: PropTypes.string.isRequired,
  isLoadingBeneficiaryHistory: PropTypes.bool.isRequired,
  owedAndReimbursed: PropTypes.string,
  owedAndReimbursedError: PropTypes.string.isRequired,
  isLoadingOwedAndReimbursed: PropTypes.bool.isRequired,
  payeeWiseSummary: PropTypes.string,
  payeeWiseSummaryError: PropTypes.string.isRequired,
  isLoadingPayeeWiseSummary: PropTypes.bool.isRequired,
  aggregateReports: PropTypes.string,
  aggregateReportsError: PropTypes.string.isRequired,
  isLoadingAggregateReports: PropTypes.bool.isRequired,
  statusWiseSummary: PropTypes.string,
  statusWiseSummaryError: PropTypes.string.isRequired,
  isLoadingStatusWiseSummary: PropTypes.bool.isRequired,
  reportOnReimbursements: PropTypes.string,
  reportOnReimbursementsError: PropTypes.string.isRequired,
  isLoadingReportOnReimbursements: PropTypes.bool.isRequired,
  householdNumber: PropTypes.string,
  householdNumberError: PropTypes.string.isRequired,
  isLoadingHouseholdNumber: PropTypes.bool.isRequired,
  enrollmentDashboard: PropTypes.string,
  enrollmentDashboardError: PropTypes.string.isRequired,
  isLoadingEnrollmentDashboard: PropTypes.bool.isRequired,
  isBranchOrHQUser: PropTypes.bool.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      report: PropTypes.string,
    }),
  }).isRequired,
  user: PropTypes.shape({}).isRequired,
};

ReportingContainer.defaultProps = {
  ageChanges: {},
  numberOfClaimsAdjudicated: {},
  claimsFilledByProviders: {},
  operationalReportOnReferrals: {},
  claimsSummary: {},
  referredIn: {},
  referredOut: {},
  beneficiaryHistory: {},
  owedAndReimbursed: {},
  payeeWiseSummary: {},
  aggregateReports: {},
  statusWiseSummary: {},
  reportOnReimbursements: {},
  householdNumber: {},
  enrollmentDashboard: {},

};
