/* eslint-disable react/no-unused-prop-types */
/* eslint-disable max-len */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { filter } from 'lodash/fp';
import {
  fetchEligibleHouseholds,
  createEligibleHousehold,
  updateEligibleHousehold,
  deleteEligibleHousehold,
} from 'store/eligible-households/eligible-households-actions';
import {
  administrativeDivisionsByIdSelector,
  viewableAdminDivisionIdsSelector,
} from 'store/administrative-divisions/administrative-divisions-selectors';
import { fetchAdministrativeDivisions } from 'store/administrative-divisions/administrative-divisions-actions';
import Box from 'components/box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { Text } from 'components/text';
import Button from 'components/button';
import { TextTableCell } from 'components/table';
import { SearchInput } from 'components/inputs';
import LoadingIndicator from 'components/loading-indicator';
import _ from 'lodash';
import {
  futureEnrollmentPeriodOptionsSelector,
  pastAndPresentEnrollmentPeriodsKeyedByIdSelector,
} from 'store/enrollment/enrollment-selectors';
import { SearchAndButtonContainer, StackHorizontal } from '../../components/eligible-household-elements';
import EditEligibleHouseholdModal from './components/EditEligibleHouseholdModal';
import AddEligibleHouseholdModal from './components/AddEligibleHouseholdModal';
import { compareDateWithNow, convertGregorianToEthiopian } from '../../lib/utils';
import DetailEligibleHouseholdModal from './components/DetailEligibleHouseholdModal';

let administrativeDivisionsLoaded = false;

class EligibleHouseholdsContainer extends Component {
    static mapDispatchToProps = dispatch => ({
      loadAdministrativeDivisions: () => dispatch(fetchAdministrativeDivisions()),
      fetchEligibleHouseholds: () => dispatch(fetchEligibleHouseholds()),
      createEligibleHousehold: household => dispatch(createEligibleHousehold(household)),
      updateEligibleHousehold: household => dispatch(updateEligibleHousehold(household)),
      deleteEligibleHousehold: householdId => dispatch(deleteEligibleHousehold(householdId)),
    });

    static mapStateToProps = (state) => {
      const viewableAdminDivisionIds = viewableAdminDivisionIdsSelector(state);
      const filteredAdministrativeDivisions = filter(
        ad => viewableAdminDivisionIds.includes(ad.id),
      )(administrativeDivisionsByIdSelector(state));

      return {
        administrativeDivisions: filteredAdministrativeDivisions,
        eligibleHouseholds: state.eligibleHouseholds.eligibleHouseholds,
        isLoading: state.eligibleHouseholds.isLoading,
        user: state.auth.user,
        enrollmentPeriods: pastAndPresentEnrollmentPeriodsKeyedByIdSelector(state),
        enrollmentPeriodOptions: futureEnrollmentPeriodOptionsSelector(state),
      };
    };


    constructor(props) {
      super(props);
      administrativeDivisionsLoaded = false;

      this.state = {
        administrativeDivisionId: undefined,
      };
    }


    state = {
      showModal: false,
      showEditModal: false,
      showDetailModal: false,
      householdToEdit: null,
      householdToDetail: null,
    };

    componentDidMount() {
      const { loadAdministrativeDivisions } = this.props;
      loadAdministrativeDivisions();
      fetchEligibleHouseholds();
    }

    handleSearchInputChange = () => {
    }

    render() {
      const { showModal, showEditModal, showDetailModal, householdToEdit, householdToDetail } = this.state;
      const { eligibleHouseholds, isLoading, enrollmentPeriodOptions, administrativeDivisionOptions, administrativeDivisions } = this.props;

      if (administrativeDivisions.length > 0 && !administrativeDivisionsLoaded) {
        administrativeDivisionsLoaded = true;
        const authUser = JSON.parse(localStorage.getItem('authUser'));
        if (authUser.role === 'enrollment_worker' || authUser.role === 'enrollment_manager') {
          const selectedadministrativeDivision = _.find(administrativeDivisions, { id: authUser.administrativeDivisionId });
          if (selectedadministrativeDivision) {
            this.setState({ administrativeDivisionId: authUser.administrativeDivisionId });
          }
        }
      }
      const { administrativeDivisionId } = this.state;
      const { sortDirection, sortField } = this.props;
      const hasData = this?.props?.eligibleHouseholds && this?.props?.eligibleHouseholds?.length > 0;
      const cols = [
        { id: 'expected_households', label: 'Expected Households', alignLeft: true, padding: false },
        { id: 'enrolled_households', label: 'Enrolled Households', alignLeft: true, padding: false },
        { id: 'enrolled_rate', label: 'Enrolled Rate (%)', alignLeft: true, padding: false },
        { id: 'enrollment_period', label: 'Enrollment Period', alignLeft: true, padding: false },
        { id: 'administrative_division.name', label: 'Administrative Division', alignLeft: true, padding: false },
      ];

      if (isLoading && !showModal && !showEditModal && !showDetailModal) {
        return (
          <Box>
            <LoadingIndicator noun="data" />
          </Box>
        );
      }

      return (
        <>
          <SearchAndButtonContainer>
            <div style={{ visibility: 'hidden' }}>
              <Box width="30%">
                <SearchInput
                  placeholder="Search"
                  onChange={this.handleSearchInputChange}
                />
              </Box>
            </div>
            <Button primary inline onClick={() => this.setState({ showModal: true })}>Add Eligible Household</Button>
          </SearchAndButtonContainer>
          {showModal && (
            <AddEligibleHouseholdModal
              onRequestClose={() => this.setState({ showModal: false })}
              enrollmentPeriodOptions={enrollmentPeriodOptions}
              administrativeDivisionOptions={administrativeDivisionOptions}
              administrativeDivisions={administrativeDivisions}
              administrativeDivisionId={administrativeDivisionId}
              isLoading={isLoading}
            />
          )}
          {showEditModal && (
            <EditEligibleHouseholdModal
              onRequestClose={() => this.setState({ showEditModal: false, householdToEdit: null })}
              householdToEdit={householdToEdit}
              isLoading={isLoading}
            />
          )}
          {showDetailModal && (
            <DetailEligibleHouseholdModal
              onRequestClose={() => this.setState({ showDetailModal: false, householdToDetail: null })}
              householdToDetail={householdToDetail}
              isLoading={isLoading}
            />
          )}
          <Box flex justifyContent="center">
            <Table>
              <TableHead>
                <TableRow>
                  {cols.map(col => (
                    <TableCell
                      key={col.id}
                      padding="checkbox"
                      sortDirection={sortField === col.id ? sortDirection : false}
                      align={col.alignLeft ? 'left' : 'right'}
                    >
                      <TableSortLabel
                        active={sortField === col.id}
                        direction={sortDirection}
                      >
                        <Text fontSize={3} fontFamily="sans">{col.label}</Text>
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {!hasData && <TableRow><TextTableCell align="center" colSpan={cols.length}>No data available</TextTableCell></TableRow>}
                {hasData && eligibleHouseholds?.map((eligibleHousehold) => {
                  const isEndDateOlderThanNow = compareDateWithNow(eligibleHousehold?.enrollmentPeriod?.endDate);
                  return (
                    <TableRow
                      key={eligibleHousehold?.id}
                      hover
                    >
                      <TableCell
                        align="left"
                      >
                        <Text fontSize={3} fontFamily="sans">{eligibleHousehold?.expectedHouseholds}</Text>
                      </TableCell>
                      <TableCell
                        align="left"
                      >
                        <Text fontSize={3} fontFamily="sans">{eligibleHousehold?.enrolledHousehold}</Text>
                      </TableCell>
                      <TableCell
                        align="left"
                      >
                        <Text fontSize={3} fontFamily="sans">
                          {eligibleHousehold?.enrolledRate}
                          %
                        </Text>
                      </TableCell>
                      <TableCell
                        align="left"
                      >
                        <Text fontSize={3} fontFamily="sans">
                          {`${convertGregorianToEthiopian(eligibleHousehold?.enrollmentPeriod?.startDate)} - ${convertGregorianToEthiopian(eligibleHousehold?.enrollmentPeriod?.endDate)}`}
                        </Text>
                      </TableCell>
                      <TableCell
                        align="left"
                      >
                        <Text fontSize={3} fontFamily="sans">{eligibleHousehold?.administrativeDivision?.name}</Text>
                      </TableCell>
                      <TableCell align="left">
                        <StackHorizontal>
                          <Button
                            onClick={() => this.setState({ showDetailModal: true, householdToDetail: eligibleHousehold })}
                          >
                            Detail
                          </Button>
                          <Button
                            disabled={!isEndDateOlderThanNow}
                            onClick={() => isEndDateOlderThanNow && this.setState({ showEditModal: true, householdToEdit: eligibleHousehold })}
                          >
                            Edit
                          </Button>
                        </StackHorizontal>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>

            </Table>
          </Box>
        </>
      );
    }
}

export default connect(
  EligibleHouseholdsContainer.mapStateToProps,
  EligibleHouseholdsContainer.mapDispatchToProps,
)(EligibleHouseholdsContainer);

EligibleHouseholdsContainer.propTypes = {
  eligibleHouseholds: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    enrollmentPeriodId: PropTypes.number,
    administrativeDivisionId: PropTypes.number,
    expectedHouseholds: PropTypes.string,
    versions: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
    })),
    enrollmentPeriod: PropTypes.shape({
      administrativeDivisionId: PropTypes.number.isRequired,
      coverageEndDate: PropTypes.string.isRequired,
      coverageStartDate: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      updatedAt: PropTypes.string.isRequired,
      endDate: PropTypes.string.isRequired,
      startDate: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    }).isRequired,
    administrativeDivision: PropTypes.shape({
      branchId: PropTypes.any,
      code: PropTypes.string,
      name: PropTypes.string.isRequired,
      level: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      updatedAt: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      parentId: PropTypes.number.isRequired,
    }).isRequired,
    // ... other properties
  })).isRequired,
  isLoading: PropTypes.bool.isRequired,
  fetchEligibleHouseholds: PropTypes.func.isRequired,
  loadAdministrativeDivisions: PropTypes.func.isRequired,
  user: PropTypes.shape({}),
  enrollmentPeriodOptions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  administrativeDivisionOptions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  administrativeDivisions: PropTypes.arrayOf(PropTypes.shape({})),
  sortDirection: PropTypes.string.isRequired,
  sortField: PropTypes.string.isRequired,
};

EligibleHouseholdsContainer.defaultProps = {
  user: {},
  administrativeDivisions: [],
};
