import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import Modal from 'components/modal';
import Box from 'components/box';
import { DatePicker, TextField } from 'components/inputs';
import Button from 'components/button';
import { formatCurrency } from 'lib/formatters/currency';
import { CURRENCY_FORMATS } from 'lib/config';

class MemberCardReplacement extends Component {
  state = {
    receiptNumber: '',
    paymentDate: null,
  }

  componentWillReceiveProps() {
    this.setState({
      receiptNumber: '',
      paymentDate: null,
    });
  }

  onReceiptNumberChange = (e) => {
    this.setState({ receiptNumber: e.target.value });
  }

  onPaymentDateChange = (e) => {
    this.setState({ paymentDate: e });
  }

  onAccept = () => {
    const { receiptNumber, paymentDate } = this.state;
    const { handleAccept } = this.props;

    handleAccept({ receiptNumber, paymentDate });
  }

  render() {
    const { isModalShown, handleCancel, fee } = this.props;
    const { receiptNumber, paymentDate } = this.state;

    return (
      <Fragment>
        {isModalShown && (
          <Modal title="Replace Card" onRequestClose={handleCancel}>
            <Box>
              <DatePicker
                defaultDate=""
                label="Payment Date"
                name="paymentDate"
                value={paymentDate}
                onChange={this.onPaymentDateChange}
              />
            </Box>
            <Box marginTop={2}>
              <TextField
                value={receiptNumber}
                label="Receipt Number"
                name="receiptNumber"
                onChange={this.onReceiptNumberChange}
              />
            </Box>
            <Box marginTop={8}>
              <span>Fee: </span>
              {formatCurrency(fee.amount, CURRENCY_FORMATS.ETHIOPIAN)}
            </Box>
            <Box marginTop={5} flex justifyContent="flex-end">
              <Button small inline onClick={handleCancel}>
                Cancel
              </Button>
              <Box marginLeft={3}>
                <Button small inline primary type="button" onClick={this.onAccept} disabled={!receiptNumber || !paymentDate}>
                  Save
                </Button>
              </Box>
            </Box>
          </Modal>
        )}
      </Fragment>
    );
  }
}

export default MemberCardReplacement;

MemberCardReplacement.propTypes = {
  fee: PropTypes.shape({
    amount: PropTypes.number,
  }),
  isModalShown: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleAccept: PropTypes.func.isRequired,
};

MemberCardReplacement.defaultProps = {
  fee: {
    amount: 0,
  },
};
