import React, { useEffect, useState } from 'react';
import AdministrativeDivisionSelector from 'containers/check-in/renew-member/AdministrativeDivisionSelector';
import { PremiumDetailsCard, PremiumSelector } from 'containers/check-in/renew-member/PremiaSelector';
import { useCreateHouseholdEnrollmentRecordMutation, useCreateMemberEnrollmentRecordMutation, useCreateMembershipPaymentMutation } from 'store/api/baseApiSlice';
import { CenteredModal, isEligibleToRegister } from './member-renew-container';
import ErrorAlert, { SuccessAlert } from 'containers/member-import/ErrorAlert';
import Button from 'components/button';
import { DatePicker } from 'components/inputs';
import { formatDate } from 'lib/formatters/date';
import { isEmpty, isNull } from 'lodash';
import { snakeCaseObject } from 'lib/utils';

// EnrollmentTypeSelector Component
const EnrollmentTypeSelector = ({ enrollmentType, handleEnrollmentTypeChange }) => (
  <div className="tw-mt-6 tw-mb-6">
    <span className="tw-block tw-text-gray-700 tw-font-medium tw-mb-2">Enrollment Type</span>
    <div className="tw-grid tw-grid-cols-2 tw-gap-4">
      <EnrollmentTypeOption
        id="indigent-radio"
        label="Indigent"
        value="Indigent"
        enrollmentType={enrollmentType}
        onChange={handleEnrollmentTypeChange}
      />
      <EnrollmentTypeOption
        id="paying-radio"
        label="Paying"
        value="Paying"
        enrollmentType={enrollmentType}
        onChange={handleEnrollmentTypeChange}
      />
    </div>
  </div>
);

// EnrollmentTypeOption Component
const EnrollmentTypeOption = ({ id, label, value, enrollmentType, onChange }) => (
  <div className="tw-flex tw-items-center tw-px-4 tw-border tw-border-gray-200 tw-rounded dark:tw-border-gray-700 tw-w-1/2">
    <input
      id={id}
      type="radio"
      value={value}
      name="enrollmentType"
      checked={enrollmentType === value}
      onChange={() => onChange(value)}
      className="tw-w-4 tw-h-4 tw-text-blue-600 tw-bg-gray-100 tw-border-gray-300 tw-focus:ring-blue-500 dark:tw-focus:ring-blue-600 dark:tw-ring-offset-gray-800 tw-focus:tw-ring-2 dark:tw-bg-gray-700 dark:tw-border-gray-600"
    />
    <label
      htmlFor={id}
      className="tw-w-full tw-py-4 tw-ml-2 tw-text-sm tw-font-medium tw-text-gray-900 dark:tw-text-gray-300"
    >
      {label}
    </label>
  </div>
);

const PaymentDetailsForm = ({
  paymentDate,
  receiptNumber,
  otherPayment,
  previouslyUnpaidAmount,
  setPaymentDate,
  setReceiptNumber,
  setOtherPayment,
  setPreviouslyUnpaidAmount
}) => (
  <>
    <div className="tw-mb-4">
      <label className="tw-block tw-text-gray-700 tw-font-medium tw-mb-1">
        Payment Date
      </label>
      <DatePicker
        label="Payment Date"
        onChange={(gregorianDate, ethiopianDate) => setPaymentDate(ethiopianDate)}
        defaultDate={paymentDate}
        maxDate={formatDate(new Date())}
        overlayCalendar={true}
      />
    </div>
    <InputField label="Receipt Number" type="number" value={receiptNumber} onChange={setReceiptNumber} />
    <InputField label="Previously Unpaid Amount (ዉዝፍ)" type="number" value={previouslyUnpaidAmount} onChange={setPreviouslyUnpaidAmount} />
    <InputField label="Other Payment" type="number" value={otherPayment} onChange={setOtherPayment} />
  </>
);


// InputField Component
const InputField = ({ label, type, value, onChange }) => (
  <div className="tw-mb-4">
    <label className="tw-block tw-text-gray-700 tw-font-medium tw-mb-1">{label}</label>
    <input
      type={type}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      className="tw-w-full tw-p-2 tw-border tw-border-gray-300 tw-rounded focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-blue-500"
    />
  </div>
);

// PaymentSummary Component with updated sections
const PaymentSummary = ({
  renewal,
  enrollmentType,
  selectedPremium,
  paymentDate,
  receiptNumber,
  otherPayment,
  numberOfQualifyingBeneficiaries,
  previouslyUnpaidAmount,
  payAdditionalPayment,
}) => {
  const annualContributionFee = !payAdditionalPayment ? selectedPremium?.payment_types?.find(pt => pt.payment_name === 'annual_contribution_fee')?.amount || 0 : 0;
  const registrationFee = !renewal ? selectedPremium?.payment_types?.find(pt => pt.payment_name === 'registration_fee')?.amount || 0 : 0;
  const qualifyingBeneficiariesFee = selectedPremium?.payment_types?.find(pt => pt.payment_name === 'qualifying_beneficiaries_fee')?.amount || 0;

  // Total includes otherPayment
  const totalAmount = (annualContributionFee + registrationFee + (qualifyingBeneficiariesFee * numberOfQualifyingBeneficiaries) + (otherPayment * 100) + (previouslyUnpaidAmount * 100)) / 100;

  return (
    <div className="tw-mb-6">      
      {/* Monetary Values Section */}
      <div className="tw-mb-4">
        <span className="tw-block tw-text-gray-800 tw-font-bold tw-mb-2">Payment Details</span>
        <ul className="tw-bg-white tw-p-4 tw-rounded tw-shadow">
          {!payAdditionalPayment && <SummaryItem label="Annual Contribution Fee" value={formatCurrency(annualContributionFee)} />}
          {(renewal && !payAdditionalPayment) && <SummaryItem label="Registration Fee" value={formatCurrency(registrationFee)} />}
          <SummaryItem label="Qualifying Beneficiaries Fee" value={formatCurrency(qualifyingBeneficiariesFee * numberOfQualifyingBeneficiaries)} />
          <SummaryItem label="Previously Unpaid Amount (ዉዝፍ)" value={formatCurrency(previouslyUnpaidAmount * 100)} />
          <SummaryItem label="Other Payment" value={formatCurrency(otherPayment * 100)} />
          {/* Total Summary Line */}
          <li className="tw-flex tw-justify-between tw-py-2 tw-mt-4 tw-border-t tw-border-gray-300 tw-font-bold tw-text-lg">
            <span>Total Amount:</span>
            <span>{formatCurrency(totalAmount * 100)}</span>
          </li>
        </ul>
      </div>

      {/* Informational Text Section */}
      <div>
        <span className="tw-block tw-text-gray-800 tw-font-bold tw-mb-2">Informational Text</span>
        <ul className="tw-bg-white tw-p-4 tw-rounded tw-shadow">
          <SummaryItem label="Enrollment Type" value={enrollmentType || 'N/A'} />
          <SummaryItem label="Payment Date" value={paymentDate || 'N/A'} />
          <SummaryItem label="Receipt Number" value={receiptNumber || 'N/A'} />
        </ul>
      </div>
    </div>
  );
};

// SummaryItem Component
const SummaryItem = ({ label, value }) => (
  <li className="tw-flex tw-justify-between tw-py-1">
    <span>{label}:</span>
    <span className="tw-font-bold">{value}</span>
  </li>
);

// Helper function to format currency values
const formatCurrency = (amount) => {
  return `$${(amount / 100).toFixed(2)}`;
};


const SidePanel = ({
  dependents,
  setDependents,
  setRenewalProgress,
  administrativeDivisions,
  selectedGote,
  setSelectedGote,
  selectedPremium,
  setSelectedPremium,
  selectedEnrollmentPeriod,
  setSelectedEnrollmentPeriod,
  householdEnrollmentRecord,
  householdHead,
  refetch,
}) => {
  const [enrollmentType, setEnrollmentType] = useState(null);
  const [alert, setAlert] = useState({ message: null, type: null });
  const [paymentDate, setPaymentDate] = useState(null);
  const [receiptNumber, setReceiptNumber] = useState('');
  const [otherPayment, setOtherPayment] = useState(0);
  const [previouslyUnpaidAmount, setPreviouslyUnpaidAmount] = useState(0);
  const [createMemberEnrollmentRecord] = useCreateMemberEnrollmentRecordMutation();
  const [createHouseholdEnrollmentRecord] = useCreateHouseholdEnrollmentRecordMutation();
  const [createMembershipPayment] = useCreateMembershipPaymentMutation();
  const [numberOfQualifyingBeneficiaries, setNumberOfQualifyingBeneficiaries] = useState(0);
  
  const renewal = householdEnrollmentRecord !== null;
  const registerButtonText = householdEnrollmentRecord === null 
    ? 'Enroll Beneficiaries' 
    : householdHead?.active 
      ? 'Pay for Additional Beneficiary' 
      : 'Renew Beneficiaries';

  const countQualifyingBeneficiaries = (dependents) => dependents.filter(member => !isEligibleToRegister(snakeCaseObject(member)) && member.enabled && !member.active).length;

  const hasEligibleMembers = () => dependents.some(member => member.enabled && !member.active);

  const displayError = (message) => {
    setAlert({ message, type: 'alert' });
  };

  const handlePaymentCreation = async (paymentData) => {
    return await createMembershipPayment(paymentData);
  };

  const handleMemberEnrollment = async (member) => {
    const memberEnrollmentData = {
      member_id: member.id,
      user_id: 456, // Replace with actual user ID
      enrollment_period_id: selectedEnrollmentPeriod.id,
      enrolled_at: new Date(),
    };
    return await createMemberEnrollmentRecord(memberEnrollmentData).unwrap();
  };

  const processDependents = async (dependents) => {
    for (const member of dependents) {
      if ((member.enabled && !member.active) || enrollmentType === 'Indigent') {
        setRenewalProgress((prev) => ({ ...prev, [member.id]: 'loading' }));
        try {
          await handleMemberEnrollment(member);
          setRenewalProgress((prev) => ({ ...prev, [member.id]: 'success' }));
          setDependents((prev) =>
            prev.map((dep) => (dep.id === member.id ? { ...dep, active: true } : dep))
          );
        } catch (error) {
          setRenewalProgress((prev) => ({ ...prev, [member.id]: 'error' }));
        }
      }
    }
  };

  const payForAdditionalBeneficiaries = async () => {
    if (enrollmentType === 'Paying' && !receiptNumber) {
      displayError('Please select the receipt number.');
      return;
    }
    if (enrollmentType === 'Paying' && !paymentDate) {
      displayError('Please select the payment date.');
      return;
    }
    if (!window.confirm('Are you sure you want to enable all household members?')) return;

    const membershipPaymentData = {
      premium_id: selectedPremium?.id,
      receipt_number: receiptNumber || '',
      payment_date: paymentDate || '',
      qualifying_beneficiaries_fee: (selectedPremium?.payment_types?.find(pt => pt.payment_name === 'qualifying_beneficiaries_fee')?.amount || 0) * numberOfQualifyingBeneficiaries,
      other_fee: otherPayment * 100,
      previously_unpaid_amount: previouslyUnpaidAmount * 100,
      household_enrollment_record_id: householdEnrollmentRecord.id,
    };

    await handlePaymentCreation(membershipPaymentData);
    await processDependents(dependents);
    setAlert({ message: 'Household members renewal process completed.', type: 'success' });
    refetch();
  };

  const renewHouseholdMembers = async () => {
    if (!hasEligibleMembers()) {
      displayError('No eligible members to register.');
      return;
    }
  
    if (householdEnrollmentRecord?.paying && registerButtonText === 'Pay for Additional Beneficiary') {
      payForAdditionalBeneficiaries();
      return;
    }

    if (!selectedGote) {
      displayError('Please select the Gote Division.');
      return;
    }

    if (!enrollmentType) {
      displayError('Please select the enrollment type.');
      return;
    }

    if (enrollmentType === 'Paying' && !receiptNumber) {
      displayError('Please select the receipt number.');
      return;
    }

    if (enrollmentType === 'Paying' && !paymentDate) {
      displayError('Please select the payment date.');
      return;
    }

    if (!window.confirm('Are you sure you want to enable all household members?')) return;

    try {
      const enrollmentRecordData = {
        household_id: dependents[0].householdId,
        user_id: 456, // Replace with actual user ID
        enrollment_period_id: selectedEnrollmentPeriod.id,
        administrative_division_id: selectedGote.value,
        enrolled_at: new Date(),
        paying: enrollmentType === 'Paying',
        renewal,
      };

      const createdHouseholdEnrollmentRecord = await createHouseholdEnrollmentRecord(enrollmentRecordData).unwrap();

      if (enrollmentType === 'Paying') {
        const membershipPaymentData = {
          premium_id: selectedPremium?.id,
          receipt_number: receiptNumber || '',
          payment_date: paymentDate || '',
          annual_contribution_fee: selectedPremium?.payment_types?.find(pt => pt.payment_name === 'annual_contribution_fee')?.amount || 0,
          registration_fee: selectedPremium?.payment_types?.find(pt => pt.payment_name === 'registration_fee')?.amount || 0,
          qualifying_beneficiaries_fee: selectedPremium?.payment_types?.find(pt => pt.payment_name === 'qualifying_beneficiaries_fee')?.amount || 0,
          other_fee: otherPayment * 100,
          previously_unpaid_amount: previouslyUnpaidAmount * 100,
          household_enrollment_record_id: createdHouseholdEnrollmentRecord.id,
        };

        await handlePaymentCreation(membershipPaymentData);
      }

      await processDependents(dependents);
      setAlert({ message: 'Household members renewal process completed.', type: 'success' });
      refetch();
    } catch (error) {
      displayError('An error occurred while renewing household members. Please try again.');
    }
  };

  useEffect(() => {
    setNumberOfQualifyingBeneficiaries(countQualifyingBeneficiaries(dependents));
  }, [dependents]);

  const handleEnrollmentTypeChange = (type) => {
    setEnrollmentType(type);
    if (type !== 'Paying') {
      setPaymentDate(null);
      setReceiptNumber('');
      setOtherPayment(0);
      setPreviouslyUnpaidAmount(0);
      setSelectedPremium(null);
    }
  };

  const allDependentsActive = dependents.every((member) => member.active);

  if (allDependentsActive) {
  return (
    <div className="tw-w-full lg:tw-w-5/12 tw-pl-0 lg:tw-pl-4 tw-mt-6 lg:tw-mt-0">
      <div className="tw-flex tw-flex-col tw-bg-white tw-h-full tw-rounded tw-p-4">
      <PremiumSelector
          administrativeDivisions={administrativeDivisions}
          setSelectedPremium={setSelectedPremium}
          setSelectedEnrollmentPeriod={setSelectedEnrollmentPeriod}
        />
      <div className="tw-mt-[300px] tw-bg-green-100 tw-border tw-border-green-400 tw-text-green-700 tw-px-4 tw-py-3 tw-rounded tw-text-center tw-shadow-md">
            {/* Check Icon */}
            <div className="tw-flex tw-justify-center tw-mb-2">
              <svg
                className="tw-h-8 tw-w-8 tw-text-green-500"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
              </svg>
            </div>
            {/* Success Message */}
            <strong className="tw-font-bold">Success! </strong>
            <span className="tw-block sm:tw-inline">All members enrolled/registered.</span>
          </div>
      </div>
    </div>
  );
}



  return (
    <div className="tw-w-full lg:tw-w-5/12 tw-pl-0 lg:tw-pl-4 tw-mt-6 lg:tw-mt-0">
      <div className="tw-flex tw-flex-col tw-bg-white tw-h-full tw-rounded tw-p-4">
        {registerButtonText !== 'Pay for Additional Beneficiary' && (
          <AdministrativeDivisionSelector
            data={administrativeDivisions}
            onGoteChange={setSelectedGote}
            selectedGote={selectedGote}
          />
        )}

        {alert.message && (
          <CenteredModal onClose={() => setAlert({ message: null, type: null })}>
            {alert.type === 'alert' && <ErrorAlert errors={[alert.message]} />}
            {alert.type === 'success' && <SuccessAlert message={alert.message} />}
          </CenteredModal>
        )}

        <PremiumSelector
          administrativeDivisions={administrativeDivisions}
          setSelectedPremium={setSelectedPremium}
          setSelectedEnrollmentPeriod={setSelectedEnrollmentPeriod}
        />

        {!householdHead?.active && (
          <EnrollmentTypeSelector
            enrollmentType={enrollmentType}
            handleEnrollmentTypeChange={handleEnrollmentTypeChange}
          />
        )}

        {registerButtonText === 'Pay for Additional Beneficiary' && (
          <>
            <PaymentDetailsForm
              paymentDate={paymentDate}
              receiptNumber={receiptNumber}
              otherPayment={otherPayment}
              previouslyUnpaidAmount={previouslyUnpaidAmount}
              setPaymentDate={(gregorianDate, ethiopianDate) => setPaymentDate(gregorianDate)}
              setReceiptNumber={setReceiptNumber}
              setOtherPayment={setOtherPayment}
              setPreviouslyUnpaidAmount={setPreviouslyUnpaidAmount}
            />
            <PaymentSummary
              payAdditionalPayment={registerButtonText === 'Pay for Additional Beneficiary'}
              renewal={renewal}
              enrollmentType={enrollmentType}
              selectedPremium={selectedPremium}
              paymentDate={paymentDate}
              receiptNumber={receiptNumber}
              otherPayment={otherPayment}
              previouslyUnpaidAmount={previouslyUnpaidAmount}
              numberOfQualifyingBeneficiaries={numberOfQualifyingBeneficiaries}
            />
          </>
        )}

        {enrollmentType === 'Paying' && (
          <>
            <PaymentDetailsForm
              paymentDate={paymentDate}
              receiptNumber={receiptNumber}
              otherPayment={otherPayment}
              previouslyUnpaidAmount={previouslyUnpaidAmount}
              setPaymentDate={(gregorianDate, ethiopianDate) => setPaymentDate(gregorianDate)}
              setReceiptNumber={setReceiptNumber}
              setOtherPayment={setOtherPayment}
              setPreviouslyUnpaidAmount={setPreviouslyUnpaidAmount}
            />
            <PaymentSummary
              payAdditionalPayment={registerButtonText === 'Pay for Additional Beneficiary'}
              renewal={renewal}
              enrollmentType={enrollmentType}
              selectedPremium={selectedPremium}
              paymentDate={paymentDate}
              receiptNumber={receiptNumber}
              otherPayment={otherPayment}
              previouslyUnpaidAmount={previouslyUnpaidAmount}
              numberOfQualifyingBeneficiaries={numberOfQualifyingBeneficiaries}
            />
          </>
        )}

        <Button small inline primary onClick={renewHouseholdMembers}>
          {registerButtonText}
        </Button>
      </div>
    </div>
  );
};


export default SidePanel;
