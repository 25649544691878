import React from 'react';
import PropTypes from 'prop-types';
import MessageAlert from './message-alert';

const FlagList = ({ flags }) => (
  <div>
    {flags.map(flag => (
      <MessageAlert
        type="warning"
        title={flag.name}
        description={flag.message}
      />
    ))}
  </div>
);

FlagList.propTypes = {
  flags: PropTypes.shape([{}]).isRequired,
};

export default FlagList;
