/* eslint-disable react/prop-types */
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import Downshift from 'downshift';
import SearchInput from 'components/inputs/search-input';
import theme from 'styles/theme';


const ulStyle = {
  listStyleType: 'none',
  border: '1px solid #f7f7f7',
  marginTop: '0px',
  borderRadius: '10px',
};

const liStyle = {
  cursor: 'pointer',
  padding: '0.5rem 0.75rem',
  marginLeft: '-40px',
  fontSize: '14px',
  border: `1px ${theme.colors.gray[10]} solid`,
};


export default class DrugPicker extends Component {
  render() {
    const { items, handleInputChange, inputValue } = this.props;
    return (
      <Downshift
        onChange={selection => handleInputChange(selection.value)}
        itemToString={item => (item ? item.value : '')}
      >
        {({
          getInputProps,
          getItemProps,
          getMenuProps,
          isOpen,
        }) => (
          <div>
            <SearchInput
              {...getInputProps({
                placeholder: 'Search Drug Name',
                onChange: (e) => {
                  handleInputChange(e.target.value);
                },
              })}
            />
            <ul style={ulStyle} {...getMenuProps()}>
              {isOpen
                ? items
                  .filter(item => !inputValue || item.value.includes(inputValue))
                  .map((item, index) => (
                    <li
                      style={liStyle}
                      {...getItemProps({
                        key: item.value,
                        index,
                        item,

                      })}
                    >
                      {item.value}
                    </li>
                  ))
                : null}
            </ul>
          </div>
        )}
      </Downshift>
    );
  }
}
