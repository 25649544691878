/**
 * Fetch enrollment periods
 */
import { getErrorMessage } from 'lib/utils';


export const FETCH_ENROLLMENT_PERIODS_REQUEST = 'FETCH_ENROLLMENT_PERIODS_REQUEST';
export const FETCH_ENROLLMENT_PERIODS_SUCCESS = 'FETCH_ENROLLMENT_PERIODS_SUCCESS';
export const FETCH_ENROLLMENT_PERIODS_FAILURE = 'FETCH_ENROLLMENT_PERIODS_FAILURE';

export const FETCH_ENROLLMENT_PERIODS_BY_ADMINISTRATIVE_DIVISION_REQUEST = 'FETCH_ENROLLMENT_PERIODS_BY_ADMINISTRATIVE_DIVISION_REQUEST';
export const FETCH_ENROLLMENT_PERIODS_BY_ADMINISTRATIVE_DIVISION_SUCCESS = 'FETCH_ENROLLMENT_PERIODS_BY_ADMINISTRATIVE_DIVISION_SUCCESS';
export const FETCH_ENROLLMENT_PERIODS_BY_ADMINISTRATIVE_DIVISION_FAILURE = 'FETCH_ENROLLMENT_PERIODS_BY_ADMINISTRATIVE_DIVISION_FAILURE';
export const CLEAR_ENROLLMENT_PERIODS_BY_ADMINISTRATIVE_DIVISION = 'CLEAR_ENROLLMENT_PERIODS_BY_ADMINISTRATIVE_DIVISION';


export const fetchEnrollmentPeriods = (id, opts = {}) => ({
  CALL_API: {
    // eslint-disable-next-line consistent-return
    beforeRequest(_, getState) {
      const storedEnrollmentPeriods = getState().enrollment.enrollmentPeriods;

      if (Object.keys(storedEnrollmentPeriods).length > 0 && opts.force !== true) {
        return Promise.resolve(storedEnrollmentPeriods);
      }
    },
    call: api => api.fetchEnrollmentPeriods(),
    types: [FETCH_ENROLLMENT_PERIODS_REQUEST, FETCH_ENROLLMENT_PERIODS_SUCCESS, FETCH_ENROLLMENT_PERIODS_FAILURE],
  },
});

// retrieve self and enrollment peroids based on administrative division
export const fetchEnrollmentPeriodsByAdministrativeDivision = administrativeDivisionId => ({
  CALL_API: {
    call: api => api.fetchEnrollmentPeriodsByAdministrativeDivision(administrativeDivisionId),
    transformError: err => getErrorMessage(err),
    types: [FETCH_ENROLLMENT_PERIODS_BY_ADMINISTRATIVE_DIVISION_REQUEST,
      FETCH_ENROLLMENT_PERIODS_BY_ADMINISTRATIVE_DIVISION_SUCCESS,
      FETCH_ENROLLMENT_PERIODS_BY_ADMINISTRATIVE_DIVISION_FAILURE],
  },
});
