// Action types
export const FETCH_ELIGIBLE_HOUSEHOLDS_REQUEST = 'FETCH_ELIGIBLE_HOUSEHOLDS_REQUEST';
export const FETCH_ELIGIBLE_HOUSEHOLDS_SUCCESS = 'FETCH_ELIGIBLE_HOUSEHOLDS_SUCCESS';
export const FETCH_ELIGIBLE_HOUSEHOLDS_FAILURE = 'FETCH_ELIGIBLE_HOUSEHOLDS_FAILURE';

export const CREATE_ELIGIBLE_HOUSEHOLD_REQUEST = 'CREATE_ELIGIBLE_HOUSEHOLD_REQUEST';
export const CREATE_ELIGIBLE_HOUSEHOLD_SUCCESS = 'CREATE_ELIGIBLE_HOUSEHOLD_SUCCESS';
export const CREATE_ELIGIBLE_HOUSEHOLD_FAILURE = 'CREATE_ELIGIBLE_HOUSEHOLD_FAILURE';

export const UPDATE_ELIGIBLE_HOUSEHOLD_REQUEST = 'UPDATE_ELIGIBLE_HOUSEHOLD_REQUEST';
export const UPDATE_ELIGIBLE_HOUSEHOLD_SUCCESS = 'UPDATE_ELIGIBLE_HOUSEHOLD_SUCCESS';
export const UPDATE_ELIGIBLE_HOUSEHOLD_FAILURE = 'UPDATE_ELIGIBLE_HOUSEHOLD_FAILURE';

export const DELETE_ELIGIBLE_HOUSEHOLD_REQUEST = 'DELETE_ELIGIBLE_HOUSEHOLD_REQUEST';
export const DELETE_ELIGIBLE_HOUSEHOLD_SUCCESS = 'DELETE_ELIGIBLE_HOUSEHOLD_SUCCESS';
export const DELETE_ELIGIBLE_HOUSEHOLD_FAILURE = 'DELETE_ELIGIBLE_HOUSEHOLD_FAILURE';

// Action creators
export const fetchEligibleHouseholds = () => ({
    CALL_API: {
        call: api => api.fetchEligibleHouseholds(),
        types: [FETCH_ELIGIBLE_HOUSEHOLDS_REQUEST, FETCH_ELIGIBLE_HOUSEHOLDS_SUCCESS, FETCH_ELIGIBLE_HOUSEHOLDS_FAILURE],
    },
});

export const createEligibleHousehold = (data) => ({
    CALL_API: {
        call: api => api.createEligibleHousehold(data),
        types: [CREATE_ELIGIBLE_HOUSEHOLD_REQUEST, CREATE_ELIGIBLE_HOUSEHOLD_SUCCESS, CREATE_ELIGIBLE_HOUSEHOLD_FAILURE],
    },
});

export const updateEligibleHousehold = (data) => ({
    CALL_API: {
        call: api => api.updateEligibleHousehold(data),
        types: [UPDATE_ELIGIBLE_HOUSEHOLD_REQUEST, UPDATE_ELIGIBLE_HOUSEHOLD_SUCCESS, UPDATE_ELIGIBLE_HOUSEHOLD_FAILURE],
    },
});

export const deleteEligibleHousehold = (id) => ({
    CALL_API: {
        call: api => api.deleteEligibleHousehold(id),
        types: [DELETE_ELIGIBLE_HOUSEHOLD_REQUEST, DELETE_ELIGIBLE_HOUSEHOLD_SUCCESS, DELETE_ELIGIBLE_HOUSEHOLD_FAILURE],
    },
});
