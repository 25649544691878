import styled from '@emotion/styled';

export const Container = styled.div`
    display: flex;
    width: 100%;
`;

export const MainSection = styled.div`
    flex: 9.8;
    min-width: 0;
`;

export const FixedSection = styled.div`
    flex: 0.2; /* This gives the section 10% of the container's width */
    position: fixed; /* Fix the section's position */
    top: 60px; /* Adjust this value to position the section vertically as you like */
    right: 10px; /* Adjust this value to position the section horizontally from the right as you like */
    overflow-y: auto; /* Add scroll if content overflows vertically */
`;

