import React from 'react';
import { Switch } from 'react-router-dom';

import ReportingContainer from 'containers/reporting/reporting-container';
import AuthenticatedRoute from 'components/authenticated-route';

import {
  contributionReportingPermissions,
  enrollmentReportingPermissions,
  utilizationReportingPermissions,
  ageChangeReportingPermissions,
  numberOfClaimsAdjudicatedReportingPermissions,
  claimsFilledByProvidersReportingPermissions,
  operationalReportOnReferralsReportingPermissions,
  claimsSummaryReportingPermissions,
  referredInReportingPermissions,
  referredOutReportingPermissions,
  beneficiaryHistoryReportingPermissions,
  owedAndReimbursedReportingPermissions,
  payeeWiseSummaryReportingPermissions,
  statusWiseSummaryReportingPermissions,
  aggregateReportsReportingPermissions,
  reportOnReimbursementsReportingPermissions,
  householdNumberReportingPermissions,
  enrollmentDashboard,
} from 'lib/config';

const ReportingView = () => (
  <Switch>
    <AuthenticatedRoute
      exact
      permissionScopes={[
        contributionReportingPermissions,
        enrollmentReportingPermissions,
        utilizationReportingPermissions,
        ageChangeReportingPermissions,
        numberOfClaimsAdjudicatedReportingPermissions,
        householdNumberReportingPermissions,
      ]}
      path="/reporting"
      component={ReportingContainer}
    />
    <AuthenticatedRoute
      exact
      permissionScopes={[contributionReportingPermissions]}
      path="/reporting/:report(contribution)"
      component={ReportingContainer}
    />
    <AuthenticatedRoute
      exact
      path="/reporting/:report(enrollment)"
      permissionScopes={[enrollmentReportingPermissions]}
      component={ReportingContainer}
    />
    <AuthenticatedRoute
      exact
      permissionScopes={[utilizationReportingPermissions]}
      path="/reporting/:report(utilization)"
      component={ReportingContainer}
    />
    <AuthenticatedRoute
      exact
      permissionScopes={[ageChangeReportingPermissions]}
      path="/reporting/:report(age-changes)"
      component={ReportingContainer}
    />
    <AuthenticatedRoute
      exact
      permissionScopes={[numberOfClaimsAdjudicatedReportingPermissions]}
      path="/reporting/:report(number-of-claims-adjudicated)"
      component={ReportingContainer}
    />
    <AuthenticatedRoute
      exact
      permissionScopes={[claimsFilledByProvidersReportingPermissions]}
      path="/reporting/:report(claims-filled-by-providers)"
      component={ReportingContainer}
    />
    <AuthenticatedRoute
      exact
      permissionScopes={[operationalReportOnReferralsReportingPermissions]}
      path="/reporting/:report(operational-report-on-referrals)"
      component={ReportingContainer}
    />
    <AuthenticatedRoute
      exact
      permissionScopes={[claimsSummaryReportingPermissions]}
      path="/reporting/:report(claims-summary)"
      component={ReportingContainer}
    />
    <AuthenticatedRoute
      exact
      permissionScopes={[referredInReportingPermissions]}
      path="/reporting/:report(referred-in)"
      component={ReportingContainer}
    />
    <AuthenticatedRoute
      exact
      permissionScopes={[referredOutReportingPermissions]}
      path="/reporting/:report(referred-out)"
      component={ReportingContainer}
    />
    <AuthenticatedRoute
      exact
      permissionScopes={[beneficiaryHistoryReportingPermissions]}
      path="/reporting/:report(beneficiary-history)"
      component={ReportingContainer}
    />
    <AuthenticatedRoute
      exact
      permissionScopes={[owedAndReimbursedReportingPermissions]}
      path="/reporting/:report(owed-and-reimbursed)"
      component={ReportingContainer}
    />
    <AuthenticatedRoute
      exact
      permissionScopes={[payeeWiseSummaryReportingPermissions]}
      path="/reporting/:report(payee-wise-summary)"
      component={ReportingContainer}
    />
    <AuthenticatedRoute
      exact
      permissionScopes={[statusWiseSummaryReportingPermissions]}
      path="/reporting/:report(status-wise-summary)"
      component={ReportingContainer}
    />
    <AuthenticatedRoute
      exact
      permissionScopes={[aggregateReportsReportingPermissions]}
      path="/reporting/:report(aggregate-reports)"
      component={ReportingContainer}
    />
    <AuthenticatedRoute
      exact
      permissionScopes={[reportOnReimbursementsReportingPermissions]}
      path="/reporting/:report(report-on-reimbursements)"
      component={ReportingContainer}
    />
    <AuthenticatedRoute
      exact
      permissionScopes={[householdNumberReportingPermissions]}
      path="/reporting/:report(household-number)"
      component={ReportingContainer}
    />
    <AuthenticatedRoute
      exact
      permissionScopes={[enrollmentDashboard]}
      path="/reporting/:report(enrollment-dashboard)"
      component={ReportingContainer}
    />
  </Switch>
);

export default ReportingView;
