import { keyBy } from 'lodash/fp';
import {
  FETCH_ENROLLMENT_PERIODS_FAILURE,
  FETCH_ENROLLMENT_PERIODS_REQUEST,
  FETCH_ENROLLMENT_PERIODS_SUCCESS,
  FETCH_ENROLLMENT_PERIODS_BY_ADMINISTRATIVE_DIVISION_FAILURE,
  FETCH_ENROLLMENT_PERIODS_BY_ADMINISTRATIVE_DIVISION_REQUEST,
  FETCH_ENROLLMENT_PERIODS_BY_ADMINISTRATIVE_DIVISION_SUCCESS,
  CLEAR_ENROLLMENT_PERIODS_BY_ADMINISTRATIVE_DIVISION,
} from './enrollment-actions';

export function initialState() {
  return {
    isLoadingEnrollmentPeriods: false,
    enrollmentPeriodsError: '',
    enrollmentPeriods: {},
    isLoadingEnrollmentPeriodsByAdministrativeDivision: false,
    enrollmentPeriodsByAdministrativeDivisionError: '',
    enrollmentPeriodsByAdministrativeDivision: {},
  };
}

export default function reducer(state = initialState(), action) {
  switch (action.type) {
    case FETCH_ENROLLMENT_PERIODS_REQUEST:
      return {
        ...state,
        isLoadingEnrollmentPeriods: true,
        enrollmentPeriodsError: '',
      };
    case FETCH_ENROLLMENT_PERIODS_SUCCESS:
      return {
        ...state,
        isLoadingEnrollmentPeriods: false,
        enrollmentPeriodsError: '',
        enrollmentPeriods: keyBy('id', action.response),
      };
    case FETCH_ENROLLMENT_PERIODS_FAILURE:
      return {
        ...state,
        isLoadingEnrollmentPeriods: false,
        enrollmentPeriodsError: action.errorMessage,
      };

    case FETCH_ENROLLMENT_PERIODS_BY_ADMINISTRATIVE_DIVISION_REQUEST:
      return {
        ...state,
        isLoadingEnrollmentPeriodsByAdministrativeDivision: true,
        enrollmentPeriodsByAdministrativeDivisionError: '',
      };
    case FETCH_ENROLLMENT_PERIODS_BY_ADMINISTRATIVE_DIVISION_SUCCESS:
      return {
        ...state,
        isLoadingEnrollmentPeriodsByAdministrativeDivision: false,
        enrollmentPeriodsByAdministrativeDivisionError: '',
        enrollmentPeriodsByAdministrativeDivision: keyBy('id', action.response),
      };
    case FETCH_ENROLLMENT_PERIODS_BY_ADMINISTRATIVE_DIVISION_FAILURE:
      return {
        ...state,
        isLoadingEnrollmentPeriodsByAdministrativeDivision: false,
        enrollmentPeriodsByAdministrativeDivisionError: action.errorMessage,
      };
    case CLEAR_ENROLLMENT_PERIODS_BY_ADMINISTRATIVE_DIVISION:
      return {
        ...state,
        isLoadingEnrollmentPeriodsByAdministrativeDivision: false,
        enrollmentPeriodsByAdministrativeDivision: {},
      };
    default:
      return state;
  }
}
