import React, { useState, useEffect, useCallback } from "react";
import { useGetAdministrativeDivisionsQuery, useImportMembersMutation } from "../../store/api/baseApiSlice";
import Select from "react-select";
import { Layout } from "components/layouts";
import { useDropzone } from "react-dropzone";
import EnrollmentPeriodSelector from "./EnrollmentPeriodSelector";
import Papa from "papaparse";
import { PROFESSIONS, RELATIONSHIPS } from "lib/config";
import ErrorAlert, { SuccessAlert } from "./ErrorAlert";
import { chain, compact, flatten, lowerCase, merge, upperCase, zip } from "lodash";
import GroupedMembersAccordion from "./GroupedMembersAccordion";
import LoadingIndicator from "components/loading-indicator";

const documentIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" height="60px" viewBox="0 -960 960 960" width="60px" fill="#5f6368">
    <path d="M320-240h320v-80H320v80Zm0-160h320v-80H320v80ZM240-80q-33 0-56.5-23.5T160-160v-640q0-33 23.5-56.5T240-880h320l240 240v480q0 33-23.5 56.5T720-80H240Zm280-520v-200H240v640h480v-440H520ZM240-800v200-200 640-640Z" />
  </svg>
);

function convertLettersToUpperCase(str) {
  return str.split('').map(char => {
      if (/[a-z]/i.test(char)) {
          return char.toUpperCase();
      } else {
          return char;
      }
  }).join('');
}

const memberColumns = ["FULL NAME", "DATE OF BIRTH", "GENDER", "LOCAL MEMBERSHIP NUMBER", "MEMBERSHIP TYPE", "PROFESSION", "RELATIONSHIP TO HEAD"];
const paymentColumns = ["ANNUAL CONTRIBUTION FEE", "REGISTRATION FEE", "QUALIFYING BENEFICIARY FEE", "OTHER FEE", "RECEIPT NUMBER"];
const validProfessions = Object.values(PROFESSIONS).map((profession) => lowerCase(profession));
const validRelationships = Object.values(RELATIONSHIPS).map((relationship) => lowerCase(relationship));
const validMembershipTypes = ["paying", "indigent"];

const ImportMembersContainer = () => {
  const [fileName, setFileName] = useState(null);
  const [fileErrors, setFileErrors] = useState([]);
  const [successMessage, setSuccessMessage] = useState(null);
  const [groupedMembers, setGroupedMembers] = useState([]);
  const [csvFile, setCsvFile] = useState(null);
  const [ungroupedMembers, setUngroupedMembers] = useState([]);
  const [validationErrorMembers, setValidationErrorMembers] = useState([]);
  const [membersWithoutErrors, setMembersWithoutErrors] = useState([]); // New state for members without errors
  const [hasPaymentInformation, setHasPaymentInformation] = useState(false);
  const { data, error: administrativeDivisionsFetchError, isLoading } = useGetAdministrativeDivisionsQuery();
  const [regions, setRegions] = useState([]);
  const [matchOffset, setMatchOffset] = useState(null);
  const [zones, setZones] = useState([]);
  const [woredas, setWoredas] = useState([]);
  const [kebeles, setKebeles] = useState([]);
  const [gotes, setGotes] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [selectedZone, setSelectedZone] = useState(null);
  const [selectedWoreda, setSelectedWoreda] = useState(null);
  const [selectedKebele, setSelectedKebele] = useState(null);
  const [selectedGote, setSelectedGote] = useState(null);
  const [selectedEnrollmentPeriod, setSelectedEnrollmentPeriod] = useState(null);
  const [importMembers, { isLoading: isImporting }] = useImportMembersMutation();

  const onDrop = useCallback(
    (acceptedFiles) => {
      setFileErrors([]);
      setGroupedMembers([]);
      setUngroupedMembers([]);
      setValidationErrorMembers([]);
      setMembersWithoutErrors([]);
      setSuccessMessage(null)
  
      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        if (file.type !== "text/csv") {
          setFileErrors(["File must be a CSV"]);
          return;
        }
        setCsvFile(file);
  
        const errors = [];
        let headerChecked = false;
        let headerValid = true;
        let rowIndex = 1;
        const validData = [];
        const validationErrors = [];
        const cleanData = []; // New array to store clean data without errors
        const seenIdentifiers = new Set(); // Set to track unique identifiers
        const duplicates = []; // Array to store duplicate rows
  
        Papa.parse(file, {
          header: true,
          skipEmptyLines: true,
          step: (results, parser) => {
            const row = results.data;
  
            // Check header columns only once
            if (!headerChecked) {
              const columns = results.meta.fields;
              const requiredColumns = hasPaymentInformation ? [...memberColumns, ...paymentColumns] : memberColumns;
  
              // Check for missing columns
              const missingColumns = requiredColumns.filter((col) => !columns.includes(col));
              if (missingColumns.length > 0) {
                errors.push(`Missing required columns: ${missingColumns.join(", ")}`);
                headerValid = false;
              }
  
              // Check for extra columns
              const extraColumns = compact(columns.filter((col) => !requiredColumns.includes(col)));
              if (extraColumns.length > 0) {
                errors.push(`Extra columns found: ${extraColumns.join(", ")}. Please remove them.`);
                headerValid = false;
              }
  
              headerChecked = true;
  
              // If header is not valid, stop further processing
              if (!headerValid) {
                parser.abort();
                return;
              }
            }
  
            row["LOCAL MEMBERSHIP NUMBER"] = convertLettersToUpperCase(row["LOCAL MEMBERSHIP NUMBER"]);
  
            const uniqueIdentifier = `${row["FULL NAME"].trim().toLowerCase()}-${row["GENDER"].trim().toLowerCase()}-${row["LOCAL MEMBERSHIP NUMBER"].trim().toLowerCase()}`;
  
            if (seenIdentifiers.has(uniqueIdentifier)) {
              duplicates.push(row);
              return;
            }
  
            seenIdentifiers.add(uniqueIdentifier);
  
            rowIndex++;
            const tempErrors = [];
  
            // Validate FULL NAME
            const fullNameParts = row["FULL NAME"] ? compact(row["FULL NAME"].split(" ")) : [];
            if (fullNameParts.length < 3) {
              tempErrors.push(`Line ${rowIndex}: FULL NAME should include First Name, Father Name, and Grand Father Name`);
            }

            // Validate DATE OF BIRTH
            const dateRegex = /^\d{4}(-\d{1,2}){2}$/;
            if (!dateRegex.test(row["DATE OF BIRTH"])) {
              tempErrors.push(`Line ${rowIndex}: DATE OF BIRTH should be in the format YYYY-MM-DD or YYYY-M-D`);
            }

            // Validate GENDER
            if (row["GENDER"] && !["M", "F"].includes(row["GENDER"])) {
              tempErrors.push(`Line ${rowIndex}: GENDER should be either 'M' or 'F'`);
            }

            // Validate PROFESSION and RELATIONSHIP TO HEAD values
            if (row["PROFESSION"] && !validProfessions.includes(lowerCase(row["PROFESSION"]))) {
              tempErrors.push(`Line ${rowIndex}: Invalid PROFESSION value: ${row["PROFESSION"]}`);
            }
            if (row["RELATIONSHIP TO HEAD"] && !validRelationships.includes(lowerCase(row["RELATIONSHIP TO HEAD"]))) {
              tempErrors.push(`Line ${rowIndex}: Invalid RELATIONSHIP TO HEAD value: ${row["RELATIONSHIP TO HEAD"]}`);
            }

            // Validate MEMBERSHIP TYPE if RELATIONSHIP TO HEAD is self
            if (lowerCase(row["RELATIONSHIP TO HEAD"]) === "self") {
              if (!row["MEMBERSHIP TYPE"] || !validMembershipTypes.includes(lowerCase(row["MEMBERSHIP TYPE"]))) {
                tempErrors.push(`Line ${rowIndex}: MEMBERSHIP TYPE should be either 'Paying' or 'Indigent' when RELATIONSHIP TO HEAD is 'Self'`);
              }

              // Validate payment columns for household head only
              if (hasPaymentInformation) {
                paymentColumns.forEach((col) => {
                  if (!row[col] || isNaN(row[col]) || parseInt(row[col], 10) < 0) {
                    tempErrors.push(`Line ${rowIndex}: ${col} should be an integer greater than or equal to 0 for household head`);
                  }
                });
              }
            }

            // Determine if the row should be added to cleanData  
            if (tempErrors.length === 0) {
              const householdNumber = row["LOCAL MEMBERSHIP NUMBER"].slice(0, parseInt(matchOffset)).split("/").join("");
              validData.push({
                ...row,
                householdNumber,
              });
  
              // Check if the household is not ungrouped before adding to cleanData
              const isUngrouped = !validData.some(
                (member) => member.householdNumber === householdNumber && lowerCase(member["RELATIONSHIP TO HEAD"]) === "self"
              );
  
              if (!isUngrouped) {
                cleanData.push(row); // Add clean row to cleanData array
              }
            } else {
              errors.push(...tempErrors);
              validationErrors.push(row);
            }
          },
          complete: () => {
            if (errors.length > 0) {
              setFileErrors(errors);
              setValidationErrorMembers(validationErrors);
              setMembersWithoutErrors(cleanData); // Store clean data when there are errors
            } else  if (duplicates.length > 0) {
              setFileErrors((prevErrors) => [...prevErrors, `Found ${duplicates.length} duplicate rows.`]);
              setValidationErrorMembers(duplicates);
              setMembersWithoutErrors(cleanData); // Store clean data when there are errors
            } else {
              setFileName(file.name);
  
              const grouped = chain(validData)
                .groupBy("householdNumber")
                .map((members, householdNumber) => ({
                  members,
                  householdNumber,
                }))
                .value();
  
              const ungrouped = grouped.filter((group) => !group.members.some((member) => member["RELATIONSHIP TO HEAD"].toLowerCase() === "self"));
  
              if (ungrouped.length > 0) {
                const tempUngroupedMembers = flatten(ungrouped.map((ungped) => ungped.members));
                setFileErrors([`Number of ungrouped members: ${tempUngroupedMembers.length}`]);
                setUngroupedMembers(tempUngroupedMembers);
              }
              setGroupedMembers(grouped);
              setMembersWithoutErrors(cleanData); // Store clean data when there are no errors
            }
          },
        });
      }
    },
    [matchOffset, hasPaymentInformation],
  );
  

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ".csv",
  });

  useEffect(() => {
    if (data) {
      const regionsData = data.filter((item) => item.level === "region");
      setRegions(regionsData);
    }
  }, [data]);

  const handleRegionChange = (selectedOption) => {
    setSelectedRegion(selectedOption);
    setSelectedZone(null);
    setSelectedWoreda(null);
    setSelectedKebele(null);
    setSelectedGote(null);
    if (selectedOption) {
      const zonesData = data.filter((item) => item.level === "zone" && item.parent_id === selectedOption.value);
      setZones(zonesData);
    } else {
      setZones([]);
    }
  };

  const handleZoneChange = (selectedOption) => {
    setSelectedZone(selectedOption);
    setSelectedWoreda(null);
    setSelectedKebele(null);
    setSelectedGote(null);
    if (selectedOption) {
      const woredasData = data.filter((item) => item.level === "woreda" && item.parent_id === selectedOption.value);
      setWoredas(woredasData);
    } else {
      setWoredas([]);
    }
  };

  const handleWoredaChange = (selectedOption) => {
    setSelectedWoreda(selectedOption);
    setSelectedKebele(null);
    setSelectedGote(null);
    if (selectedOption) {
      const kebelesData = data.filter((item) => item.level === "kebele" && item.parent_id === selectedOption.value);
      setKebeles(kebelesData);
    } else {
      setKebeles([]);
    }
  };

  const handleKebeleChange = (selectedOption) => {
    setSelectedKebele(selectedOption);
    setSelectedGote(null);
    if (selectedOption) {
      const gotesData = data.filter((item) => item.level === "gote" && item.parent_id === selectedOption.value);
      setGotes(gotesData);
    } else {
      setGotes([]);
    }
  };

  const handleGoteChange = (selectedOption) => {
    setSelectedGote(selectedOption);
  };

  const handleSubmit = async () => {
    setSuccessMessage(null)
    setFileErrors([]);
    const tempErrors = [];
    if (!csvFile || groupedMembers.length === 0) {
      tempErrors.push("No file selected or no valid data to submit");
    }
    if (!selectedGote) {
      tempErrors.push("Please select the appropriate Gote.");
    }
    if (!selectedEnrollmentPeriod) {
      tempErrors.push("Please select the Enrollment Period.");
    }
    if (!matchOffset) {
      tempErrors.push("Please enter match offset");
    }

    if (tempErrors.length > 0) {
      setFileErrors(tempErrors);
      return;
    }

    const formData = new FormData();
    formData.append("file", csvFile);
    formData.append("gote_id", selectedGote?.value);
    formData.append("enrollment_period_id", selectedEnrollmentPeriod?.value);
    formData.append("match_offset", matchOffset);
    formData.append("has_payment_information", hasPaymentInformation);
    setGroupedMembers([]);


    try {
      const response = await importMembers(formData);
      console.log({response});
      console.log(response.error?.data?.errors);
      setCsvFile(null);
      setFileName(null)
      if (response.error?.data?.errors) {
        setFileErrors(response.error.data?.errors);
      } else if (response.error?.data.status == 500) {
        setFileErrors([response.error?.data.error]);
      } else {
        setSuccessMessage("Member Imported Successfully");
      }
     
    } catch (error) {
      console.log(error);
      setFileErrors([error.message || "Error submitting data"]);
    }
  };

  const handleDownload = (data, fileName) => {
    const csv = Papa.unparse(data, {
      quotes: true, // Ensure all fields are quoted to treat everything as a string
      quoteChar: '"',
      escapeChar: '"',
    });
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  };
  

  return (
    <Layout pageTitle="Import Members">
      <div className="tw-mx-20 tw-my-10">
        <h1 className="tw-text-2xl tw-font-bold tw-mb-10">Import Members</h1>
        {isLoading && <p><LoadingIndicator noun={'Administrative Divisions'} /></p>}
        {administrativeDivisionsFetchError && <p>Error fetching data</p>}
        {data && (
          <>
            <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-4">
              <div>
                <Select
                  placeholder="Select Region"
                  options={regions.map((region) => ({
                    value: region.id,
                    label: region.name,
                  }))}
                  value={selectedRegion}
                  onChange={handleRegionChange}
                />
              </div>
              <div>
                <Select
                  placeholder="Select Zone"
                  options={zones.map((zone) => ({
                    value: zone.id,
                    label: zone.name,
                  }))}
                  value={selectedZone}
                  onChange={handleZoneChange}
                  isDisabled={!selectedRegion}
                />
              </div>
              <div>
                <Select
                  placeholder="Select Woreda"
                  options={woredas.map((woreda) => ({
                    value: woreda.id,
                    label: woreda.name,
                  }))}
                  value={selectedWoreda}
                  onChange={handleWoredaChange}
                  isDisabled={!selectedZone}
                />
              </div>
            </div>
            {selectedRegion && selectedZone && selectedWoreda && (
              <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-4 tw-mt-4">
                <div>
                  <Select
                    placeholder="Select Kebele"
                    options={kebeles.map((kebele) => ({
                      value: kebele.id,
                      label: kebele.name,
                    }))}
                    value={selectedKebele}
                    onChange={handleKebeleChange}
                    isDisabled={!selectedWoreda}
                  />
                </div>
                <div>
                  <Select
                    placeholder="Select Gote"
                    options={gotes.map((gote) => ({
                      value: gote.id,
                      label: gote.name,
                    }))}
                    value={selectedGote}
                    onChange={handleGoteChange}
                    isDisabled={!selectedKebele}
                  />
                </div>
                <div>{selectedGote && <EnrollmentPeriodSelector goteId={selectedGote.value} setSelectedEnrollmentPeriod={setSelectedEnrollmentPeriod} />}</div>
              </div>
            )}
            <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-3 tw-gap-4 tw-mt-4">
              <div>
                <label htmlFor="matchOffset" className="tw-block tw-m-2 tw-mt-4 tw-text-sm tw-font-medium tw-text-gray-900 dark:tw-text-white">
                  Match Offset For Local Membership Number
                </label>
                <input
                  type="number"
                  id="matchOffset"
                  value={matchOffset}
                  onChange={(e) => setMatchOffset(e.target.value)}
                  className="tw-bg-gray-50 tw-border tw-border-gray-300 tw-text-gray-900 tw-text-sm tw-rounded-lg focus:tw-ring-blue-500 focus:tw-border-blue-500 tw-block tw-w-full tw-p-2.5 dark:tw-bg-gray-700 dark:tw-border-gray-600 dark:tw-placeholder-gray-400 dark:tw-text-white dark:focus:tw-ring-blue-500 dark:focus:tw-border-blue-500"
                  required
                />

                <label className="tw-block tw-mt-4 tw-text-sm tw-font-medium tw-text-gray-900 dark:tw-text-white">Upload CSV File</label>
                <div
                  {...getRootProps()}
                  className={`tw-flex tw-mt-1 tw-items-center tw-justify-center tw-w-full tw-h-32 tw-border-2 tw-border-dashed tw-rounded-lg tw-cursor-pointer ${
                    isDragActive ? "tw-border-blue-500" : "tw-border-gray-300"
                  } tw-bg-gray-50 dark:tw-bg-gray-700 dark:tw-border-gray-600`}
                >
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <p className="tw-text-gray-900 dark:tw-text-white">Drop the files here ...</p>
                  ) : fileName ? (
                    <div className="tw-flex tw-items-center tw-text-gray-900 dark:tw-text-white">
                      {documentIcon}
                      <span className="tw-ml-2 tw-font-bold tw-text-base tw-text-green-600">Selected file: {fileName}</span>
                    </div>
                  ) : (
                    <p className="tw-text-gray-900 dark:tw-text-white">Drag 'n' drop a CSV file here, or click to select one</p>
                  )}
                </div>

                <div className="tw-mt-4">
                  <label htmlFor="checkAdditionalColumns" className="tw-text-sm tw-font-medium tw-text-gray-900 dark:tw-text-white">
                    Has Payment Information
                  </label>
                  <input
                    type="checkbox"
                    id="checkAdditionalColumns"
                    checked={hasPaymentInformation}
                    onChange={(e) => setHasPaymentInformation(e.target.checked)}
                    className="tw-ml-2"
                  />
                </div>

                <div className="tw-mt-4">
                  <button onClick={handleSubmit} className="tw-bg-blue-500 tw-text-white tw-px-4 tw-py-2 tw-rounded-lg hover:tw-bg-blue-700">
                    {isImporting ? "Importing ..." : "Submit"}
                  </button>
                </div>
              </div>
              <div className="tw-col-span-2">
                <div className="tw-my-4">
                  {ungroupedMembers.length > 0 && (
                    <button
                      onClick={() => handleDownload(ungroupedMembers, "ungrouped_members.csv")}
                      className="tw-focus:outline-none tw-text-white tw-bg-red-700 hover:tw-bg-red-800 tw-focus:ring-4 tw-focus:ring-red-300 tw-font-medium tw-rounded-lg tw-text-sm tw-px-5 tw-py-2.5 tw-me-2 tw-mb-2 dark:tw-bg-red-600 dark:tw-hover:bg-red-700 dark:tw-focus:ring-red-900"
                    >
                      Download Ungrouped Members
                    </button>
                  )}
                  {validationErrorMembers.length > 0 && (
                    <button
                      onClick={() => handleDownload(validationErrorMembers, "validation_error_members.csv")}
                      className="tw-focus:outline-none tw-text-white tw-bg-red-700 hover:tw-bg-red-800 tw-focus:ring-4 tw-focus:ring-red-300 tw-font-medium tw-rounded-lg tw-text-sm tw-px-5 tw-py-2.5 tw-me-2 tw-mb-2 dark:tw-bg-red-600 dark:tw-hover:bg-red-700 dark:tw-focus:ring-red-900"
                    >
                      Download Members with Validation Errors
                    </button>
                  )}
                  {membersWithoutErrors.length > 0 && (
                    <button
                      onClick={() => handleDownload(membersWithoutErrors, "members_without_errors.csv")}
                      className="tw-focus:outline-none tw-text-white tw-bg-green-700 hover:tw-bg-green-800 tw-focus:ring-4 tw-focus:ring-green-300 tw-font-medium tw-rounded-lg tw-text-sm tw-px-5 tw-py-2.5 tw-me-2 tw-mb-2 dark:tw-bg-green-600 dark:tw-hover:bg-green-700 dark:tw-focus:ring-green-900"
                    >
                      Download Members Without Errors
                    </button>
                  )}
                </div>
                {fileErrors.length > 0 && <ErrorAlert errors={fileErrors} />}
                {groupedMembers.length > 0 && ungroupedMembers.length === 0 && <GroupedMembersAccordion groupedMembers={groupedMembers} />}
                {successMessage && <SuccessAlert message={successMessage} />}
                {isImporting && <LoadingIndicator title={'Importing Members....'}  />}
              </div>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

export default ImportMembersContainer;
