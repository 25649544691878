import React from 'react';
import PropTypes from 'prop-types';

import { formatCardId } from 'lib/formatters';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withPagination, TextTableCell } from 'components/table';
import Icon from 'components/icon';
import { Tooltip } from '@material-ui/core';
import { ROLES } from '../../lib/config/roles-permissions';

const MemberSearchResultsTable = ({ data, handleRowClick }) => {
  const currentUser = JSON.parse(localStorage.getItem('authUser'));
  const cardRoomRole = ROLES.RECEPTIONIST === currentUser.role
                      || ROLES.CARD_ROOM_WORKER === currentUser.role
                      || ROLES.CLAIMS_PREPARER === currentUser.role
                      || ROLES.FACILITY_HEAD === currentUser.role;

  return (
    <Table>
      <colgroup>
        <col style={{ width: '25%' }} />
        <col style={{ width: '5%' }} />
        <col style={{ width: '25%' }} />
        <col style={{ width: '15%' }} />
        <col style={{ width: '12%' }} />
        <col style={{ width: '12%' }} />
        <col style={{ width: '6%' }} />
      </colgroup>
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">Full Name</TableCell>
          <TableCell padding="checkbox" />
          <TableCell padding="checkbox">CBHI ID</TableCell>
          <TableCell padding="checkbox">QR Code</TableCell>
          <TableCell padding="checkbox">MRN</TableCell>
          <TableCell padding="checkbox">Gender</TableCell>
          <TableCell padding="checkbox">Age</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map(r => !(cardRoomRole && r.archivedReason) && (
          <TableRow key={r.id} onClick={() => handleRowClick(r.id)} hover>
            <TextTableCell padding="checkbox" color="blue.5">{r.name}</TextTableCell>
            <TextTableCell padding="checkbox" color="red.5">
              {r.archivedReason && (
              <Tooltip title={r.archivedReason}>
                <Icon name="archived" size={15} iconSize={15} />
              </Tooltip>
              )}
            </TextTableCell>
            <TextTableCell padding="checkbox">{r.membershipNumber}</TextTableCell>
            <TextTableCell padding="checkbox">{formatCardId(r.cardId)}</TextTableCell>
            <TextTableCell padding="checkbox">{r.medicalRecordNumber}</TextTableCell>
            <TextTableCell padding="checkbox">{r.gender}</TextTableCell>
            <TextTableCell padding="checkbox">{r.age}</TextTableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};


export default withPagination(MemberSearchResultsTable);

MemberSearchResultsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleRowClick: PropTypes.func.isRequired,
};
