import styled from '@emotion/styled';

const Container = styled.div`
  max-width: ${props => (props.maxWidth ? `${props.maxWidth}px` : `${props.theme.layout.containerMaxWidth}px`)};
  min-width: ${props => (props.minWidth ? `${props.minWidth}px` : `${props.theme.layout.containerMinWidth}px`)};
  margin-left: auto;
  margin-right: auto;
`;

export default Container;
