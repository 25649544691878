import { getErrorMessage } from 'lib/utils';

export const FETCH_AGE_CHANGES_REQUEST = 'FETCH_AGE_CHANGES_REQUEST';
export const FETCH_AGE_CHANGES_SUCCESS = 'FETCH_AGE_CHANGES_SUCCESS';
export const FETCH_AGE_CHANGES_FAILURE = 'FETCH_AGE_CHANGES_FAILURE';

export const FETCH_NUMBER_OF_CLAIMS_ADJUDICATED_REQUEST = 'FETCH_NUMBER_OF_CLAIMS_ADJUDICATED_REQUEST';
export const FETCH_NUMBER_OF_CLAIMS_ADJUDICATED_SUCCESS = 'FETCH_NUMBER_OF_CLAIMS_ADJUDICATED_SUCCESS';
export const FETCH_NUMBER_OF_CLAIMS_ADJUDICATED_FAILURE = 'FETCH_NUMBER_OF_CLAIMS_ADJUDICATED_FAILURE';

export const FETCH_CLAIMS_FILLED_BY_PROVIDERS_REQUEST = 'FETCH_CLAIMS_FILLED_BY_PROVIDERS_REQUEST';
export const FETCH_CLAIMS_FILLED_BY_PROVIDERS_SUCCESS = 'FETCH_CLAIMS_FILLED_BY_PROVIDERS_SUCCESS';
export const FETCH_CLAIMS_FILLED_BY_PROVIDERS_FAILURE = 'FETCH_CLAIMS_FILLED_BY_PROVIDERS_FAILURE';

export const FETCH_OPERATIONAL_REPORT_ON_REFERRALS_REQUEST = 'FETCH_OPERATIONAL_REPORT_ON_REFERRALS_REQUEST';
export const FETCH_OPERATIONAL_REPORT_ON_REFERRALS_SUCCESS = 'FETCH_OPERATIONAL_REPORT_ON_REFERRALS_SUCCESS';
export const FETCH_OPERATIONAL_REPORT_ON_REFERRALS_FAILURE = 'FETCH_OPERATIONAL_REPORT_ON_REFERRALS_FAILURE';

export const FETCH_CLAIMS_SUMMARY_REQUEST = 'FETCH_CLAIMS_SUMMARY_REQUEST';
export const FETCH_CLAIMS_SUMMARY_SUCCESS = 'FETCH_CLAIMS_SUMMARY_SUCCESS';
export const FETCH_CLAIMS_SUMMARY_FAILURE = 'FETCH_CLAIMS_SUMMARY_FAILURE';

export const FETCH_REFERRED_IN_REQUEST = 'FETCH_REFERRED_IN_REQUEST';
export const FETCH_REFERRED_IN_SUCCESS = 'FETCH_REFERRED_IN_SUCCESS';
export const FETCH_REFERRED_IN_FAILURE = 'FETCH_REFERRED_IN_FAILURE';

export const FETCH_REFERRED_OUT_REQUEST = 'FETCH_REFERRED_OUT_REQUEST';
export const FETCH_REFERRED_OUT_SUCCESS = 'FETCH_REFERRED_OUT_SUCCESS';
export const FETCH_REFERRED_OUT_FAILURE = 'FETCH_REFERRED_OUT_FAILURE';

export const FETCH_BENEFICIARY_HISTORY_REQUEST = 'FETCH_BENEFICIARY_HISTORY_REQUEST';
export const FETCH_BENEFICIARY_HISTORY_SUCCESS = 'FETCH_BENEFICIARY_HISTORY_SUCCESS';
export const FETCH_BENEFICIARY_HISTORY_FAILURE = 'FETCH_BENEFICIARY_HISTORY_FAILURE';

export const FETCH_OWED_AND_REIMBURSED_REQUEST = 'FETCH_OWED_AND_REIMBURSED_REQUEST';
export const FETCH_OWED_AND_REIMBURSED_SUCCESS = 'FETCH_OWED_AND_REIMBURSED_SUCCESS';
export const FETCH_OWED_AND_REIMBURSED_FAILURE = 'FETCH_OWED_AND_REIMBURSED_FAILURE';

export const FETCH_PAYEE_WISE_SUMMARY_REQUEST = 'FETCH_PAYEE_WISE_SUMMARY_REQUEST';
export const FETCH_PAYEE_WISE_SUMMARY_SUCCESS = 'FETCH_PAYEE_WISE_SUMMARY_SUCCESS';
export const FETCH_PAYEE_WISE_SUMMARY_FAILURE = 'FETCH_PAYEE_WISE_SUMMARY_FAILURE';

export const FETCH_STATUS_WISE_SUMMARY_REQUEST = 'FETCH_STATUS_WISE_SUMMARY_REQUEST';
export const FETCH_STATUS_WISE_SUMMARY_SUCCESS = 'FETCH_STATUS_WISE_SUMMARY_SUCCESS';
export const FETCH_STATUS_WISE_SUMMARY_FAILURE = 'FETCH_STATUS_WISE_SUMMARY_FAILURE';

export const FETCH_AGGREGATE_REPORTS_REQUEST = 'FETCH_AGGREGATE_REPORTS_REQUEST';
export const FETCH_AGGREGATE_REPORTS_SUCCESS = 'FETCH_AGGREGATE_REPORTS_SUCCESS';
export const FETCH_AGGREGATE_REPORTS_FAILURE = 'FETCH_AGGREGATE_REPORTS_FAILURE';

export const FETCH_REPORT_ON_REIMBURSEMENTS_REQUEST = 'FETCH_REPORT_ON_REIMBURSEMENTS_REQUEST';
export const FETCH_REPORT_ON_REIMBURSEMENTS_SUCCESS = 'FETCH_REPORT_ON_REIMBURSEMENTS_SUCCESS';
export const FETCH_REPORT_ON_REIMBURSEMENTS_FAILURE = 'FETCH_REPORT_ON_REIMBURSEMENTS_FAILURE';

export const FETCH_HOUSEHOLD_NUMBER_REQUEST = 'FETCH_HOUSEHOLD_NUMBER_REQUEST';
export const FETCH_HOUSEHOLD_NUMBER_SUCCESS = 'FETCH_HOUSEHOLD_NUMBER_SUCCESS';
export const FETCH_HOUSEHOLD_NUMBER_FAILURE = 'FETCH_HOUSEHOLD_NUMBER_FAILURE';

export const FETCH_FACILITY_DASHBOARD_REQUEST = 'FETCH_FACILITY_DASHBOARD_REQUEST';
export const FETCH_FACILITY_DASHBOARD_SUCCESS = 'FETCH_FACILITY_DASHBOARD_SUCCESS';
export const FETCH_FACILITY_DASHBOARD_FAILURE = 'FETCH_FACILITY_DASHBOARD_FAILURE';

export const FETCH_BRANCH_DASHBOARD_REQUEST = 'FETCH_BRANCH_DASHBOARD_REQUEST';
export const FETCH_BRANCH_DASHBOARD_SUCCESS = 'FETCH_BRANCH_DASHBOARD_SUCCESS';
export const FETCH_BRANCH_DASHBOARD_FAILURE = 'FETCH_BRANCH_DASHBOARD_FAILURE';

export const FETCH_ENROLLMENT_DASHBOARD_REQUEST = 'FETCH_ENROLLMENT_DASHBOARD_REQUEST';
export const FETCH_ENROLLMENT_DASHBOARD_SUCCESS = 'FETCH_ENROLLMENT_DASHBOARD_SUCCESS';
export const FETCH_ENROLLMENT_DASHBOARD_FAILURE = 'FETCH_ENROLLMENT_DASHBOARD_FAILURE';

// REPORT 1
export const fetchAgeChanges = (startDate, endDate) => ({
  CALL_API: {
    call: api => api.fetchAgeChanges(startDate, endDate),
    transformError: err => getErrorMessage(err),
    types: [FETCH_AGE_CHANGES_REQUEST, FETCH_AGE_CHANGES_SUCCESS, FETCH_AGE_CHANGES_FAILURE],
  },
});


// REPORT 2
export const fetchNumberOfClaimsAdjudicated = (startDate, endDate) => ({
  CALL_API: {
    call: api => api.fetchNumberOfClaimsAdjudicated(startDate, endDate),
    transformError: err => getErrorMessage(err),
    types: [FETCH_NUMBER_OF_CLAIMS_ADJUDICATED_REQUEST, FETCH_NUMBER_OF_CLAIMS_ADJUDICATED_SUCCESS,
      FETCH_NUMBER_OF_CLAIMS_ADJUDICATED_FAILURE],
  },
});

// REPORT 3
export const fetchClaimsFilledByProviders = (startDate, endDate) => ({
  CALL_API: {
    call: api => api.fetchClaimsFilledByProviders(startDate, endDate),
    transformError: err => getErrorMessage(err),
    types: [FETCH_CLAIMS_FILLED_BY_PROVIDERS_REQUEST, FETCH_CLAIMS_FILLED_BY_PROVIDERS_SUCCESS,
      FETCH_CLAIMS_FILLED_BY_PROVIDERS_FAILURE],
  },
});

// REPORT 4
export const fetchOperationalReportOnReferrals = (startDate, endDate) => ({
  CALL_API: {
    call: api => api.fetchOperationalReportOnReferrals(startDate, endDate),
    transformError: err => getErrorMessage(err),
    types: [FETCH_OPERATIONAL_REPORT_ON_REFERRALS_REQUEST, FETCH_OPERATIONAL_REPORT_ON_REFERRALS_SUCCESS,
      FETCH_OPERATIONAL_REPORT_ON_REFERRALS_FAILURE],
  },
});

// REPORT 5
export const fetchClaimsSummary = (startDate, endDate) => ({
  CALL_API: {
    call: api => api.fetchClaimsSummary(startDate, endDate),
    transformError: err => getErrorMessage(err),
    types: [FETCH_CLAIMS_SUMMARY_REQUEST, FETCH_CLAIMS_SUMMARY_SUCCESS,
      FETCH_CLAIMS_SUMMARY_FAILURE],
  },
});


// REPORT 6 REFERRED IN
export const fetchReferredIn = (startDate, endDate) => ({
  CALL_API: {
    call: api => api.fetchReferredIn(startDate, endDate),
    transformError: err => getErrorMessage(err),
    types: [FETCH_REFERRED_IN_REQUEST, FETCH_REFERRED_IN_SUCCESS,
      FETCH_REFERRED_IN_FAILURE],
  },
});


// REPORT 6 REFERRED OUT
export const fetchReferredOut = (startDate, endDate) => ({
  CALL_API: {
    call: api => api.fetchReferredOut(startDate, endDate),
    transformError: err => getErrorMessage(err),
    types: [FETCH_REFERRED_OUT_REQUEST, FETCH_REFERRED_OUT_SUCCESS,
      FETCH_REFERRED_OUT_FAILURE],
  },
});


// REPORT 7 BENEFICIARY HISTORY
export const fetchBeneficiaryHistory = id => ({
  CALL_API: {
    call: api => api.fetchBeneficiaryHistory(id),
    transformError: err => getErrorMessage(err),
    types: [FETCH_BENEFICIARY_HISTORY_REQUEST, FETCH_BENEFICIARY_HISTORY_SUCCESS,
      FETCH_BENEFICIARY_HISTORY_FAILURE],
  },
});

// REPORT 8 OWED AND REIMBURSED
export const fetchOwedAndReimbursed = id => ({
  CALL_API: {
    call: api => api.fetchOwedAndReimbursed(id),
    transformError: err => getErrorMessage(err),
    types: [FETCH_OWED_AND_REIMBURSED_REQUEST, FETCH_OWED_AND_REIMBURSED_SUCCESS,
      FETCH_OWED_AND_REIMBURSED_FAILURE],
  },
});

// REPORT 9.1 PAYEE WISE SUMMARY
export const fetchPayeeWiseSummary = (startDate, endDate) => ({
  CALL_API: {
    call: api => api.fetchPayeeWiseSummary(startDate, endDate),
    transformError: err => getErrorMessage(err),
    types: [FETCH_PAYEE_WISE_SUMMARY_REQUEST, FETCH_PAYEE_WISE_SUMMARY_SUCCESS,
      FETCH_PAYEE_WISE_SUMMARY_FAILURE],
  },
});

// REPORT 9.2 STATUS WISE SUMMARY
export const fetchStatusWiseSummary = (startDate, endDate) => ({
  CALL_API: {
    call: api => api.fetchStatusWiseSummary(startDate, endDate),
    transformError: err => getErrorMessage(err),
    types: [FETCH_STATUS_WISE_SUMMARY_REQUEST, FETCH_STATUS_WISE_SUMMARY_SUCCESS,
      FETCH_STATUS_WISE_SUMMARY_FAILURE],
  },
});

// REPORT 9.3 STATUS WISE SUMMARY
export const fetchAggregateReports = (startDate, endDate) => ({
  CALL_API: {
    call: api => api.fetchAggregateReports(startDate, endDate),
    transformError: err => getErrorMessage(err),
    types: [FETCH_AGGREGATE_REPORTS_REQUEST, FETCH_AGGREGATE_REPORTS_SUCCESS,
      FETCH_AGGREGATE_REPORTS_FAILURE],
  },
});

// REPORT 10 REPORT ON REIMBURSEMENT
export const fetchReportOnReimbursements = (providerId, startDate, endDate) => ({
  CALL_API: {
    call: api => api.fetchReportOnReimbursements(providerId, startDate, endDate),
    transformError: err => getErrorMessage(err),
    types: [FETCH_REPORT_ON_REIMBURSEMENTS_REQUEST, FETCH_REPORT_ON_REIMBURSEMENTS_SUCCESS,
      FETCH_REPORT_ON_REIMBURSEMENTS_FAILURE],
  },
});

// REPORT 13 Household Number
export const fetchHouseholdNumber = (providerId, startDate, endDate) => ({
  CALL_API: {
    call: api => api.fetchHouseholdNumber(providerId, startDate, endDate),
    transformError: err => getErrorMessage(err),
    types: [FETCH_HOUSEHOLD_NUMBER_REQUEST, FETCH_HOUSEHOLD_NUMBER_SUCCESS,
      FETCH_HOUSEHOLD_NUMBER_FAILURE],
  },
});


// fetch facility dashboard
export const fetchFacilityDashboard = (startDate, endDate) => ({
  CALL_API: {
    call: api => api.fetchFacilityDashboard(startDate, endDate),
    transformError: err => getErrorMessage(err),
    types: [FETCH_FACILITY_DASHBOARD_REQUEST, FETCH_FACILITY_DASHBOARD_SUCCESS, FETCH_FACILITY_DASHBOARD_FAILURE],
  },
});


// fetch branch dashboard
export const fetchBranchDashboard = (startDate, endDate) => ({
  CALL_API: {
    call: api => api.fetchBranchDashboard(startDate, endDate),
    transformError: err => getErrorMessage(err),
    types: [FETCH_BRANCH_DASHBOARD_REQUEST, FETCH_BRANCH_DASHBOARD_SUCCESS, FETCH_BRANCH_DASHBOARD_FAILURE],
  },
});


// fetch enrollemnt dashboard
export const fetchEnrollmentDashboard = (enrollmentPeriodId, startDate, endDate) => ({
  CALL_API: {
    call: api => api.fetchEnrollmentDashboard(enrollmentPeriodId, startDate, endDate),
    transformError: err => getErrorMessage(err),
    types: [FETCH_ENROLLMENT_DASHBOARD_REQUEST, FETCH_ENROLLMENT_DASHBOARD_SUCCESS, FETCH_ENROLLMENT_DASHBOARD_FAILURE],
  },
});
