import React, { Fragment, useState } from 'react';
import NewLayoutWithHeader from 'components/layouts/new-layout-with-header';
import {
  useCreateHouseholdMutation,
  useCreateMemberMutation,
  useGetAdministrativeDivisionsQuery,
} from 'store/api/baseApiSlice';
import AdministrativeDivisionSelector from './AdministrativeDivisionSelector';
import { DatePicker } from 'components/inputs';
import { formatDate } from 'lib/formatters/date';
import { PROFESSIONS } from './constants';
import { upperCase } from 'lodash';
import Box from 'components/box';
import Spinner from 'components/spinner';
import { Text } from 'components/text';

const LoadingIndicator = ({ noun, }) => (
  <Box flex justifyContent="center" alignItems="center" >
    <Fragment>
      <Box paddingRight={4} color="gray.5">
        <Spinner small />
      </Box>
      <Text verticalAlign="middle">
        {noun}
      </Text>
    </Fragment>
  </Box>
);

const AlertMessage = ({ message }) => {
  return (
    <div className={`tw-bg-${message.includes('success') ? 'green' : 'red'}-500 tw-text-white tw-p-4 tw-rounded tw-mb-4`}>
      {message}
    </div>
  );
};

const HouseholdForm = ({ newHousehold, handleChange, handleDateChange, selectedGote, setSelectedGote, isFetchingAdministrativeDivision, error, administrativeDivisions = [] }) => (
  <>
    <div className="tw-mb-4">
      {!isFetchingAdministrativeDivision && (
        <AdministrativeDivisionSelector
          data={administrativeDivisions}
          onGoteChange={setSelectedGote}
          selectedGote={selectedGote}
        />
      )}
    </div>

    {/* Full Name */}
    <div className="tw-mb-4">
      <label className="tw-block tw-text-gray-700">Full Name</label>
      <input
        type="text"
        name="fullName"
        value={newHousehold.fullName}
        onChange={handleChange}
        className="tw-w-full tw-p-2 tw-border tw-border-gray-300 tw-rounded"
        required
      />
    </div>

    {/* Date of Birth */}
    <div className="tw-mb-4">
      <label className="tw-block tw-text-gray-700">Date of Birth</label>
      <DatePicker
        label="Date of Birth"
        onChange={(gregorianDate) => handleDateChange(gregorianDate)}
        defaultDate={formatDate(newHousehold.birthdate)}
        maxDate={formatDate(new Date())}
      />
    </div>

    {/* Gender */}
    <div className="tw-mb-4">
      <label className="tw-block tw-text-gray-700">Gender</label>
      <select
        name="gender"
        value={newHousehold.gender}
        onChange={handleChange}
        className="tw-w-full tw-p-2 tw-border tw-border-gray-300 tw-rounded"
        required
      >
        <option value="">Select Gender</option>
        <option value="M">Male</option>
        <option value="F">Female</option>
      </select>
    </div>

    {/* Local Membership Number */}
    <div className="tw-mb-4">
      <label className="tw-block tw-text-gray-700">Local Membership Number</label>
      <input
        type="text"
        name="localMembershipNumber"
        value={newHousehold.localMembershipNumber}
        onChange={handleChange}
        className="tw-w-full tw-p-2 tw-border tw-border-gray-300 tw-rounded"
        required
      />
    </div>

    {/* Profession */}
    <div className="tw-mb-4">
      <label className="tw-block tw-text-gray-700">Profession</label>
      <select
        name="profession"
        value={newHousehold.profession}
        onChange={handleChange}
        className="tw-w-full tw-p-2 tw-border tw-border-gray-300 tw-rounded"
        required
      >
        <option value="">Select Profession</option>
        {Object.values(PROFESSIONS).map((profession, index) => (
          <option key={index} value={upperCase(profession)}>
            {profession}
          </option>
        ))}
      </select>
    </div>
  </>
);

const PhotoUploader = ({ handleChange }) => (
  <div className="tw-mb-4">
    <label className="tw-block tw-text-gray-700">Upload Photo (Optional)</label>
    <input type="file" name="photo" onChange={handleChange} className="tw-w-full tw-p-2 tw-border tw-border-gray-300 tw-rounded" />
  </div>
);

const SubmitButton = ({ isCreating, onClick }) => (
  <button
    type="button"
    onClick={onClick}
    className="tw-bg-green-500 tw-text-white tw-px-4 tw-py-2 tw-rounded tw-w-full"
    disabled={isCreating}
  >
    {isCreating ? 'Creating Household...' : 'Create Household & Add Member'}
  </button>
);

const LoadingOverlay = ({ isFetching, isCreating, isRedirecting }) => (
  (isFetching || isCreating || isRedirecting) && (
    <div className="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center tw-bg-black tw-bg-opacity-50">
      <div className="tw-bg-white tw-p-6 tw-rounded tw-shadow-lg">
        {isFetching && <LoadingIndicator noun="Fetching Administrative Divisions" />}
        {isCreating && <LoadingIndicator noun="Creating Household..." />}
        {isRedirecting && <LoadingIndicator noun="Redirecting..." /> }
      </div>
    </div>
  )
);

const EnrollNewMemberForm = () => {
  const [newHousehold, setNewHousehold] = useState({
    fullName: '',
    birthdate: '',
    gender: '',
    localMembershipNumber: '',
    profession: '',
    photo: null,
    administrativeDivisionId: '',
  });

  const [selectedGote, setSelectedGote] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);

  const { data: administrativeDivisions = [], isLoading: isFetchingAdministrativeDivision, error } = useGetAdministrativeDivisionsQuery();
  const [createHousehold] = useCreateHouseholdMutation();
  const [createMember] = useCreateMemberMutation();

  // Form validation
  const validateForm = () => {
    const errors = {};
    if (!newHousehold.fullName.trim())
      errors.fullName = 'Full Name is required';
    if (!newHousehold.birthdate)
      errors.birthdate = 'Date of Birth is required';
    if (!newHousehold.gender) errors.gender = 'Gender is required';
    if (!newHousehold.localMembershipNumber.trim())
      errors.localMembershipNumber = 'Local Membership Number is required';
    if (!newHousehold.profession)
      errors.profession = 'Profession is required';
    if (!selectedGote)
      errors.administrativeDivisionId = 'Administrative Division is required';
    console.log(errors);
    return Object.keys(errors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'photo') {
      setNewHousehold({ ...newHousehold, photo: files[0] });
    } else {
      setNewHousehold({ ...newHousehold, [name]: value });
    }
  };

  const handleDateChange = (gregorianDate) => {
    setNewHousehold({ ...newHousehold, birthdate: gregorianDate });
  };

  const handleCreateHousehold = async () => {
    if (!validateForm()) {
      setAlertMessage('Please fill in all required fields.');
      return;
    }

    try {
      setIsCreating(true); // Set creating state
      const householdData = {
        administrative_division_id: selectedGote.value,
        address:selectedGote.label,
        enrolled_at: new Date(),
      };
      console.log({householdData});
      const householdResponse = await createHousehold(householdData).unwrap();
      const householdId = householdResponse.id;

      // Create member
      const memberData = new FormData();
      memberData.append('full_name', newHousehold.fullName);
      memberData.append(
        'birthdate',
        newHousehold.birthdate.toISOString()
      );
      memberData.append('gender', newHousehold.gender);
      memberData.append(
        'local_membership_number',
        newHousehold.localMembershipNumber
      );
      memberData.append('profession', newHousehold.profession);
      memberData.append('relationship_to_head', 'SELF');
      memberData.append('household_id', householdId);
      memberData.append('enrolled_at', new Date().toISOString());
      memberData.append('birthdate_accuracy', 'Y');

      if (newHousehold.photo) memberData.append('photo', newHousehold.photo);

      const memberResponse = await createMember(memberData).unwrap();
      const memberId = memberResponse.id;

      // After success
      setAlertMessage(
        'Household and member created successfully! Redirecting...'
      );
      setIsCreating(false);
      setIsRedirecting(true);
      setTimeout(() => {
        window.location.href = `/members/${memberId}/renew`;
      }, 1000);
    } catch (error) {
      setIsCreating(false);
      setAlertMessage('Failed to create household or member.');
      console.error(error);
    }
  };

  return (
    <div className="tw-flex tw-bg-gray-100">
      <div className="tw-w tw-mx-auto tw-bg-white tw-rounded-lg tw-shadow-lg tw-p-8 tw-relative">
        <h2 className="tw-text-2xl tw-font-bold tw-text-center tw-mb-4">Enroll New Member</h2>

        {alertMessage && <AlertMessage message={alertMessage} />}

        <HouseholdForm
          newHousehold={newHousehold}
          handleChange={handleChange}
          handleDateChange={handleDateChange}
          selectedGote={selectedGote}
          setSelectedGote={setSelectedGote}
          isFetchingAdministrativeDivision={isFetchingAdministrativeDivision}
          error={error}
          administrativeDivisions={administrativeDivisions}
        />

        <PhotoUploader handleChange={handleChange} />

        <SubmitButton isCreating={isCreating} onClick={handleCreateHousehold} />

        <LoadingOverlay isFetching={isFetching || isFetchingAdministrativeDivision} isCreating={isCreating} isRedirecting={isRedirecting} />
      </div>
    </div>
  );
};

// Container Component with Layout
const EnrollMemberContainer = () => {
  return (
    <NewLayoutWithHeader pageTitle="Enroll Members" steps={[{ title: "Enroll Members", href: "/enroll/members" }]}>
      <EnrollNewMemberForm />
    </NewLayoutWithHeader>
  );
};

export default EnrollMemberContainer;

