import {
    FETCH_ELIGIBLE_HOUSEHOLDS_REQUEST,
    FETCH_ELIGIBLE_HOUSEHOLDS_SUCCESS,
    FETCH_ELIGIBLE_HOUSEHOLDS_FAILURE,
    CREATE_ELIGIBLE_HOUSEHOLD_REQUEST,
    CREATE_ELIGIBLE_HOUSEHOLD_SUCCESS,
    CREATE_ELIGIBLE_HOUSEHOLD_FAILURE,
    UPDATE_ELIGIBLE_HOUSEHOLD_REQUEST,
    UPDATE_ELIGIBLE_HOUSEHOLD_SUCCESS,
    UPDATE_ELIGIBLE_HOUSEHOLD_FAILURE,
    DELETE_ELIGIBLE_HOUSEHOLD_REQUEST,
    DELETE_ELIGIBLE_HOUSEHOLD_SUCCESS,
    DELETE_ELIGIBLE_HOUSEHOLD_FAILURE,
} from './eligible-households-actions';

export function initialState() {
    return {
        isLoading: false,
        error: '',
        eligibleHouseholds: []
    };
}

export default function reducer(state = initialState(), action) {
    switch (action.type) {
        case FETCH_ELIGIBLE_HOUSEHOLDS_REQUEST:
        case CREATE_ELIGIBLE_HOUSEHOLD_REQUEST:
        case UPDATE_ELIGIBLE_HOUSEHOLD_REQUEST:
        case DELETE_ELIGIBLE_HOUSEHOLD_REQUEST:
            return {
                ...state,
                isLoading: true,
                error: '',
            };
        case FETCH_ELIGIBLE_HOUSEHOLDS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                eligibleHouseholds: action.response, // Assign the array directly without using keyBy
            };
        case CREATE_ELIGIBLE_HOUSEHOLD_SUCCESS:
            console.log("first", action.response)
            return {
                ...state,
                isLoading: false,
                eligibleHouseholds: [...state.eligibleHouseholds, action.response],
            };

        case UPDATE_ELIGIBLE_HOUSEHOLD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                eligibleHouseholds: [...state.eligibleHouseholds.filter(eligibleHousehold => eligibleHousehold.id !== action.response.id), action.response],
            };
        case DELETE_ELIGIBLE_HOUSEHOLD_SUCCESS:
            const newState = { ...state };
            delete newState.eligibleHouseholds[action.id];
            return {
                ...newState,
                isLoading: false,
            };
        case FETCH_ELIGIBLE_HOUSEHOLDS_FAILURE:
        case CREATE_ELIGIBLE_HOUSEHOLD_FAILURE:
        case UPDATE_ELIGIBLE_HOUSEHOLD_FAILURE:
        case DELETE_ELIGIBLE_HOUSEHOLD_FAILURE:
            return {
                ...state,
                isLoading: false,
                error: action.errorMessage,
            };
        default:
            return state;
    }
}
