import { createSelector } from 'reselect';
import { isNil } from 'lodash/fp';

import {
  REPORTS,
  ROLE_PERMISSIONS,
  contributionReportingPermissions,
  enrollmentReportingPermissions,
  utilizationReportingPermissions,
  ageChangeReportingPermissions,
  numberOfClaimsAdjudicatedReportingPermissions,
  claimsFilledByProvidersReportingPermissions,
  operationalReportOnReferralsReportingPermissions,
  claimsSummaryReportingPermissions,
  referredOutReportingPermissions,
  referredInReportingPermissions,
  beneficiaryHistoryReportingPermissions,
  owedAndReimbursedReportingPermissions,
  payeeWiseSummaryReportingPermissions,
  statusWiseSummaryReportingPermissions,
  aggregateReportsReportingPermissions,
  reportOnReimbursementsReportingPermissions,
  householdNumberReportingPermissions,
  enrollmentDashboard,

} from 'lib/config';
import { userHasAllPermissionsInSet } from 'lib/auth-utils';

export const userSelector = state => state.auth.user;

export const userAdjudicationLimitSelector = state => state.auth.user.adjudicationLimit;

export const userHasAdjudicationLimitSelector = state => state.auth.user.adjudicationLimit !== null;

export const isProviderUserSelector = state => !isNil(state.auth.user.providerId);

export const allowedReportsSelector = createSelector(
  userSelector,
  (user) => {
    const currentPermissions = ROLE_PERMISSIONS[user.role];
    const allowedReports = [];
    if (userHasAllPermissionsInSet(currentPermissions, contributionReportingPermissions)) {
      allowedReports.push(REPORTS.CONTRIBUTION);
    }
    if (userHasAllPermissionsInSet(currentPermissions, enrollmentReportingPermissions)) {
      allowedReports.push(REPORTS.ENROLLMENT);
    }
    if (userHasAllPermissionsInSet(currentPermissions, utilizationReportingPermissions)) {
      allowedReports.push(REPORTS.UTILIZATION);
    }
    if (userHasAllPermissionsInSet(currentPermissions, ageChangeReportingPermissions)) {
      allowedReports.push(REPORTS.AGE_CHANGES);
    }
    if (userHasAllPermissionsInSet(currentPermissions, numberOfClaimsAdjudicatedReportingPermissions)) {
      allowedReports.push(REPORTS.NUMBER_OF_CLAIMS_ADJUDICATED);
    }
    if (userHasAllPermissionsInSet(currentPermissions, claimsFilledByProvidersReportingPermissions)) {
      allowedReports.push(REPORTS.CLAIMS_FILLED_BY_PROVIDERS);
    }
    if (userHasAllPermissionsInSet(currentPermissions, operationalReportOnReferralsReportingPermissions)) {
      allowedReports.push(REPORTS.OPERATIONAL_REPORT_ON_REFERRALS);
    }
    if (userHasAllPermissionsInSet(currentPermissions, claimsSummaryReportingPermissions)) {
      allowedReports.push(REPORTS.CLAIMS_SUMMARY);
    }
    if (userHasAllPermissionsInSet(currentPermissions, referredInReportingPermissions)) {
      allowedReports.push(REPORTS.REFERRED_IN);
    }
    if (userHasAllPermissionsInSet(currentPermissions, referredOutReportingPermissions)) {
      allowedReports.push(REPORTS.REFERRED_OUT);
    }
    if (userHasAllPermissionsInSet(currentPermissions, beneficiaryHistoryReportingPermissions)) {
      allowedReports.push(REPORTS.BENEFICIARY_HISTORY);
    }
    if (userHasAllPermissionsInSet(currentPermissions, owedAndReimbursedReportingPermissions)) {
      allowedReports.push(REPORTS.OWED_AND_REIMBURSED);
    }
    if (userHasAllPermissionsInSet(currentPermissions, payeeWiseSummaryReportingPermissions)) {
      allowedReports.push(REPORTS.PAYEE_WISE_SUMMARY);
    }
    if (userHasAllPermissionsInSet(currentPermissions, statusWiseSummaryReportingPermissions)) {
      allowedReports.push(REPORTS.STATUS_WISE_SUMMARY);
    }
    if (userHasAllPermissionsInSet(currentPermissions, aggregateReportsReportingPermissions)) {
      allowedReports.push(REPORTS.AGGREGATE_REPORTS);
    }
    if (userHasAllPermissionsInSet(currentPermissions, reportOnReimbursementsReportingPermissions)) {
      allowedReports.push(REPORTS.REPORT_ON_REIMBURSEMENTS);
    }
    if (userHasAllPermissionsInSet(currentPermissions, householdNumberReportingPermissions)) {
      allowedReports.push(REPORTS.HOUSEHOLD_NUMBER);
    }
    if (userHasAllPermissionsInSet(currentPermissions, enrollmentDashboard)) {
      allowedReports.push(REPORTS.ENROLLMENT_DASHBOARD);
    }
    return allowedReports;
  },
);
