import { ROLES, ALLOWED_USER_ROLES } from './roles-permissions';

export * from './roles-permissions';
export * from './routes';

export const CONTACT_EMAIL = 'uhp@watsi.org';
export const TIMESTAMP_FORMAT = 'YYYY-MM-DD h:mm A';
export const TIME_FORMAT = 'h:mm A';
export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_PICKER_FORMAT = 'yyyy-mm-dd'; // Date picker requires lowercase
export const DATE_PICKER_ETH_FORMAT = 'dd-mm-yyyy'; // Date picker requires lowercase
export const YEAR_MONTH_FORMAT = 'YYYY-MM';
export const YEAR_FORMAT = 'YYYY';
export const MONTH_FORMAT = 'MM';
export const DAY_FORMAT = 'DD';

export const AGE_UNITS = {
  YEARS: 'Y',
  MONTHS: 'M',
  DAYS: 'D',
};

export const ACCOUNTING_CATEGORIES = {
  card_and_consultation: 'Card & Consultation',
  lab: 'Lab',
  imaging: 'Imaging',
  // TODO: should we change the string value here to Surgery?
  surgery: 'Procedure',
  drug_and_supply: 'Drug & Supply',
  bed_day_and_food: 'Bed Day',
  capitation: 'Capitation',
  other_services: 'Other',
};

// member search methods for identification events
export const SEARCH_METHODS = {
  membershipNumber: 'search_membership_number',
  cardId: 'search_card_id',
  medicalRecordNumber: 'search_medical_record_number',
  advanced: 'search_name',
  throughHousehold: 'through_household',
  unknown: 'unknown',
  manual: 'manual',
};

export const ADMIN_DIVISIONS = {
  GOTE: 'gote',
  KEBELE: 'kebele',
  WOREDA: 'woreda',
  ZONE: 'zone',
  REGION: 'region',
  COUNTRY: 'country',
};

export const CALENDAR_FORMATS = {
  GREGORIAN: 'gregorian',
  ETHIOPIAN: 'ethiopian',
};

export const CURRENCY_FORMATS = {
  UNSPECIFIED: 'unspecified',
  ETHIOPIAN: 'ethiopian',
  UGANDAN: 'ugandan',
};

export const CURRENCIES = {
  ETHIOPIAN: 'Birr',
  UGANDAN: 'UGX',
};

export const ADJUDICATION_STATES = {
  PENDING: 'pending',
  APPROVED: 'approved',
  RETURNED: 'returned',
  REJECTED: 'rejected',
  EXTERNAL: 'external',
};

export const BILLABLE_TYPES = {
  lab: {
    key: 'lab',
    label: 'lab',
  },
  imaging: {
    key: 'imaging',
    label: 'imaging',
  },
  service: {
    key: 'service',
    label: 'service',
  },
  procedure: {
    key: 'procedure',
    label: 'procedure',
  },
  drug: {
    key: 'drug',
    label: 'drug and supply',
  },
  bed_day: {
    key: 'bed_day',
    label: 'bed day',
  },
};

export const SUBMISSION_STATES = {
  STARTED: 'started',
  PREPARED: 'prepared',
  SUBMITTED: 'submitted',
  NEEDS_REVISION: 'needs_revision',
};

export const PROFESSIONS = {
  BABY: 'Baby',
  DAILY_LABORER: 'Daily laborer',
  DISABLED: 'Disabled',
  FARMER: 'Farmer',
  HOUSEWIFE: 'Housewife',
  JOB_SEEKER: 'Job seeker',
  MERCHANT: 'Merchant',
  NONE: 'None',
  OTHER: 'Other',
  PRIVATE: 'Private',
  STAY_HOME_HUSBAND: 'Stay at home husband',
  STUDENT: 'Student',
  TRADER_OR_MERCHANT: 'Trader or merchant',
};

export const RELATIONSHIPS = {
  SELF: 'Self',
  WIFE: 'Wife',
  HUSBAND: 'Husband',
  SON: 'Son',
  DAUGHTER: 'Daughter',
  OTHER: 'Other',
  PARENT: 'Parent',
};

export const HEALTH_CENTER_VISIT_TYPE_OPTIONS = [
  { name: 'OPD - New Visit', value: 'OPD - New Visit', isDischarge: false },
  { name: 'OPD - Repeat Visit', value: 'OPD - Repeat Visit', isDischarge: false },
  { name: 'Youth Friendly Services (YFS) - New Visit', value: 'Youth Friendly Services (YFS) - New Visit', isDischarge: false },
  { name: 'Youth Friendly Services (YFS) - Repeat Visit', value: 'Youth Friendly Services (YFS) - Repeat Visit', isDischarge: false },
  { name: '<5 OPD - New Visit', value: '<5 OPD - New Visit', isDischarge: false },
  { name: '<5 OPD - Repeat Visit', value: '<5 OPD - Repeat Visit', isDischarge: false },
  { name: 'Emergency OPD', value: 'Emergency OPD', isDischarge: false },
  { name: 'Inpatient (IPD)', value: 'Inpatient (IPD)', isDischarge: false },
  { name: 'ART - New Patient', value: 'ART - New Patient', isDischarge: false },
  { name: 'ART - Repeat Visit', value: 'ART - Repeat Visit', isDischarge: false },
  { name: 'TB - New Patient', value: 'TB - New Patient', isDischarge: false },
  { name: 'TB - Repeat Visit', value: 'TB - Repeat Visit', isDischarge: false },
  { name: 'Family Planning (FP) - New Visit', value: 'Family Planning (FP) - New Visit', isDischarge: false },
  { name: 'Family Planning (FP) - Repeat Visit', value: 'Family Planning (FP) - Repeat Visit', isDischarge: false },
  { name: 'Antenatal Care (ANC)  - 1st Visit', value: 'Antenatal Care (ANC)  - 1st Visit', isDischarge: false },
  { name: 'Antenatal Care (ANC)  - 2nd Visit', value: 'Antenatal Care (ANC)  - 2nd Visit', isDischarge: false },
  { name: 'Antenatal Care (ANC)  - 3rd Visit', value: 'Antenatal Care (ANC)  - 3rd Visit', isDischarge: false },
  { name: 'Antenatal Care (ANC)  - 4th Visit', value: 'Antenatal Care (ANC)  - 4th Visit', isDischarge: false },
  { name: 'EPI', value: 'EPI', isDischarge: false },
  { name: 'Delivery (DR)', value: 'Delivery (DR)', isDischarge: false },
  { name: 'Abortion', value: 'Abortion', isDischarge: false },
  { name: 'Growth Monitoring & Promotion (GMP) - New Visit', value: 'Growth Monitoring & Promotion (GMP) - New Visit', isDischarge: false },
  { name: 'Growth Monitoring & Promotion (GMP) - Repeat Visit', value: 'Growth Monitoring & Promotion (GMP) - Repeat Visit', isDischarge: false },
  { name: 'Dental', value: 'Dental', isDischarge: false },
];

export const HOSPITAL_VISIT_TYPE_OPTIONS = [
  { name: 'OPD - New Visit', value: 'OPD - New Visit', isDischarge: false },
  { name: 'OPD - Repeat Visit', value: 'OPD - Repeat Visit', isDischarge: false },
  { name: 'ART - New Patient', value: 'ART - New Patient', isDischarge: false },
  { name: 'ART - Repeat Visit', value: 'ART - Repeat Visit', isDischarge: false },
  { name: 'TB - New Patient', value: 'TB - New Patient', isDischarge: false },
  { name: 'TB - Repeat Visit', value: 'TB - Repeat Visit', isDischarge: false },
  { name: 'Youth Friendly Services (YFS) - New Visit', value: 'Youth Friendly Services (YFS) - New Visit', isDischarge: false },
  { name: 'Youth Friendly Services (YFS) - Repeat Visit', value: 'Youth Friendly Services (YFS) - Repeat Visit', isDischarge: false },
  { name: 'Family Planning (FP) - New Visit', value: 'Family Planning (FP) - New Visit', isDischarge: false },
  { name: 'Family Planning (FP) - Repeat Visit', value: 'Family Planning (FP) - Repeat Visit', isDischarge: false },
  { name: 'Antenatal Care (ANC)  - 1st Visit', value: 'Antenatal Care (ANC)  - 1st Visit', isDischarge: false },
  { name: 'Antenatal Care (ANC)  - 2nd Visit', value: 'Antenatal Care (ANC)  - 2nd Visit', isDischarge: false },
  { name: 'Antenatal Care (ANC)  - 3rd Visit', value: 'Antenatal Care (ANC)  - 3rd Visit', isDischarge: false },
  { name: 'Antenatal Care (ANC)  - 4th Visit', value: 'Antenatal Care (ANC)  - 4th Visit', isDischarge: false },
  { name: 'Postnatal Care (PNC) - 1st Visit', value: 'Postnatal Care (PNC) - 1st Visit', isDischarge: false },
  { name: 'Postnatal Care (PNC) - 2nd Visit', value: 'Postnatal Care (PNC) - 2nd Visit', isDischarge: false },
  { name: 'EPI', value: 'EPI', isDischarge: false },
  { name: '<5 OPD - New Visit', value: '<5 OPD - New Visit', isDischarge: false },
  { name: '<5 OPD - Repeat Visit', value: '<5 OPD - Repeat Visit', isDischarge: false },
  { name: 'Inpatient (IPD)', value: 'Inpatient (IPD)', isDischarge: true },
  { name: 'Emergency OPD', value: 'Emergency OPD', isDischarge: false },
  { name: 'Delivery (DR)', value: 'Delivery (DR)', isDischarge: true },
  { name: 'Abortion', value: 'Abortion', isDischarge: false },
  { name: 'Growth Monitoring & Promotion (GMP) - New Visit', value: 'Growth Monitoring & Promotion (GMP) - New Visit', isDischarge: false },
  { name: 'Growth Monitoring & Promotion (GMP) - Repeat Visit', value: 'Growth Monitoring & Promotion (GMP) - Repeat Visit', isDischarge: false },
  { name: 'Dental', value: 'Dental', isDischarge: false },
  { name: 'Mental ( physcatric ) Heath care New', value: 'Mental ( physcatric ) Heath care New', isDischarge: true },
  { name: 'Mental ( physcatric ) Heath care Repeat', value: 'Mental ( physcatric ) Heath care Repeat', isDischarge: true },
  { name: 'Surgical referral clinic New', value: 'Surgical referral clinic New', isDischarge: true },
  { name: 'Surgical referral clinic Repeat', value: 'Surgical referral clinic Repeat', isDischarge: true },
  { name: 'Medical  referral clinic New', value: 'Medical  referral clinic New', isDischarge: true },
  { name: 'Medical  referral clinic Repeat', value: 'Medical  referral clinic Repeat', isDischarge: true },
  { name: 'Pediatrics  referral clinic New', value: 'Pediatrics  referral clinic New', isDischarge: true },
  { name: 'Pediatrics  referral clinic Repeat', value: 'Pediatrics  referral clinic Repeat', isDischarge: true },
  { name: 'Gynecology and Obstetrics referral clinic New', value: 'Gynecology and Obstetrics referral clinic New', isDischarge: true },
  { name: 'Gynecology and Obstetrics referral clinic Repeat', value: 'Gynecology and Obstetrics referral clinic Repeat', isDischarge: true },
  { name: 'Major Operation Room (Major OR) for Surgery', value: 'Major Operation Room (Major OR) for Surgery', isDischarge: true },
  { name: 'Major Operation Room (Major OR) for Gynecology & obstetrics', value: 'Major Operation Room (Major OR) for Gynecology & obstetrics', isDischarge: true },
  { name: 'IPD Laboratory', value: 'IPD Laboratory', isDischarge: true },
  { name: 'IPD Pharmacy', value: 'IPD Pharmacy', isDischarge: true },
  { name: 'Delivery', value: 'Delivery', isDischarge: true },
  { name: 'Neonatal Intensive Care Unit (NICU)', value: 'Neonatal Intensive Care Unit (NICU)', isDischarge: true },
  { name: 'Intensive Care Unit (ICU)', value: 'Intensive Care Unit (ICU)', isDischarge: true },
  { name: 'Medical inpatient department', value: 'Medical inpatient department', isDischarge: true },
  { name: 'Surgical inpatient department', value: 'Surgical inpatient department', isDischarge: true },
  { name: 'Pediatric inpatient department', value: 'Pediatric inpatient department', isDischarge: true },
  { name: 'Gynecology inpatient department', value: 'Gynecology inpatient department', isDischarge: true },
  { name: 'Other (Unspecified)', value: 'Other (Unspecified)', isDischarge: true },
];

export const PATIENT_OUTCOMES = {
  cured_or_discharged: 'Cured / Discharged',
  referred: 'Referred',
  follow_up: 'Follow-up',
  refused_service: 'Refused Service',
  deceased: 'Deceased',
  other: 'Other',
};

export const PROVIDER_TYPES = {
  HEALTH_CENTER: 'health_center',
  HEALTH_CENTER_EXTENDED: 'health_center_extended',
  PHARMACY: 'pharmacy',
  PRIMARY_HOSPITAL: 'primary_hospital',
  GENERAL_HOSPITAL: 'general_hospital',
  TERTIARY_HOSPITAL: 'tertiary_hospital',
  UNCLASSIFIED: 'unclassified',
};

export const PROVIDER_TYPE_FILTERS = [
  { value: 'none', name: 'All provider levels' },
  { value: 'health_center', name: 'Health Center' },
  { value: 'primary_hospital', name: 'Primary Hospital' },
  { value: 'general_hospital', name: 'General Hospital' },
  { value: 'tertiary_hospital', name: 'Tertiary Hospital' },
];

export const ADJUDICATION_REJECT_REASONS = [
  { value: 'inactive_beneficiary', name: 'Inactive Beneficiary' },
  { value: 'service_not_covered', name: 'Service not covered' },
  { value: 'referral_bypass', name: 'Referral bypass' },
  { value: 'duplicate_claim', name: 'Duplicate claim' },
  { value: 'expired_claim', name: 'Expired claim' },
  { value: 'previously_returned', name: 'Previously returned' },
  { value: 'other', name: 'Other (requires comment)' },
];

export const ADJUDICATION_RETURN_REASONS = [
  { value: 'unknown_beneficiary', name: 'Unknown beneficiary' },
  { value: 'price_discrepancy', name: 'Price Descrepancy' },
  { value: 'missing_diagnosis', name: 'Missing Dx' },
  { value: 'not_as_per_standard_treatment_guidelines', name: 'Not as per standard treatment guidelines' },
  { value: 'provider_not_contracted', name: 'Provider not contracted' },
  { value: 'other', name: 'Other (requires comment)' },
];

// Right now in reasons for visit only apply to hospitals, there are primary hospitals
// indicated by 'primary', and secondary and tertiary hospitals, indicated by 'other'.
// Set it up this way so that in the future if we need to differeniate between other types we can
export const REASONS_FOR_VISIT = {
  referral: {
    value: 'referral',
    label: 'Referral',
    types: [
      PROVIDER_TYPES.PRIMARY_HOSPITAL,
      PROVIDER_TYPES.GENERAL_HOSPITAL,
      PROVIDER_TYPES.TERTIARY_HOSPITAL,
    ],
  },
  no_referral: {
    value: 'no_referral',
    label: 'No referral (catchment)',
    types: [
      PROVIDER_TYPES.PRIMARY_HOSPITAL,
    ],
  },
  self_referral: {
    value: 'self_referral',
    label: 'Self-referral (bypass fee)',
    types: [
      PROVIDER_TYPES.PRIMARY_HOSPITAL,
      PROVIDER_TYPES.GENERAL_HOSPITAL,
      PROVIDER_TYPES.TERTIARY_HOSPITAL,
    ],
  },
  follow_up: {
    value: 'follow_up',
    label: 'Follow-up',
    types: [
      PROVIDER_TYPES.PRIMARY_HOSPITAL,
      PROVIDER_TYPES.GENERAL_HOSPITAL,
      PROVIDER_TYPES.TERTIARY_HOSPITAL,
    ],
  },
  emergency: {
    value: 'emergency',
    label: 'Emergency',
    types: [
      PROVIDER_TYPES.PRIMARY_HOSPITAL,
      PROVIDER_TYPES.GENERAL_HOSPITAL,
      PROVIDER_TYPES.TERTIARY_HOSPITAL,
    ],
  },
};

export const CLAIM_AMOUNT_FILTERS = [
  { value: 'none', name: 'Any amount' },
  { value: 20000, name: 'Under 200.00 Birr' },
  { value: 10000, name: 'Under 100.00 Birr' },
  { value: 7000, name: 'Under 70.00 Birr' },
  { value: 4000, name: 'Under 40.00 Birr' },
];

export const CLAIM_STATUS_FILTERS = [
  { value: 'none', name: 'All pending' },
  { value: 'resubmissions', name: 'Resubmissions' },
  { value: 'no_resubmissions', name: 'All pending (no resubmissions)' },
  { value: 'audits', name: 'Audits (only)' },
  { value: 'no_audits', name: 'All pending (no audits)' },
];

export const PAID_STATUS_FILTERS = [
  { value: 'none', name: 'All paid and unpaid' },
  { value: 'unpaid', name: 'Unpaid (only)' },
  { value: 'paid', name: 'Paid (only)' },
];

export const FLAG_STATUS_FILTERS = [
  { value: 'none', name: 'None' },
  { value: 'all', name: 'All flagged' },
  { value: 'no_flags', name: 'Hide all flagged' },
  { value: 'unconfirmed_member', name: 'Unconfirmed members' },
  { value: 'inactive_member', name: 'Inactive members' },
  { value: 'unlinked_inbound_referral', name: 'Inbound referral' },
  { value: 'bypass_fee_override', name: 'Bypass fee' },
];

export const MEMBERSHIP_STATUS_STATES = {
  ACTIVE: 'Active',
  EXPIRED: 'Expired',
  DELETED: 'Deleted',
  UNKNOWN: 'Unknown',
};

export const apiConfig = {
  ALLOWED_USER_ROLES,
  BASE_URL: process.env.REACT_APP_COVERAGE_API_BASE,
  ENV_NAME: (() => {
    const baseUrl = process.env.REACT_APP_COVERAGE_API_BASE;

    if (/uhp.watsi.org/i.test(baseUrl)) {
      return 'production';
    } if (/uhp-demo.watsi.org/i.test(baseUrl)) {
      return 'demo';
    } if (/uhp-sandbox.watsi.org/i.test(baseUrl)) {
      return 'sandbox';
    }

    return 'unknown';
  })(),
};

export const REPORTS = {
  UTILIZATION: 'utilization',
  ENROLLMENT: 'enrollment',
  CONTRIBUTION: 'contribution',
  AGE_CHANGES: 'age-changes',
  NUMBER_OF_CLAIMS_ADJUDICATED: 'number-of-claims-adjudicated',
  CLAIMS_FILLED_BY_PROVIDERS: 'claims-filled-by-providers',
  OPERATIONAL_REPORT_ON_REFERRALS: 'operational-report-on-referrals',
  CLAIMS_SUMMARY: 'claims-summary',
  REFERRED_IN: 'referred-in',
  REFERRED_OUT: 'referred-out',
  BENEFICIARY_HISTORY: 'beneficiary-history',
  OWED_AND_REIMBURSED: 'owed-and-reimbursed',
  PAYEE_WISE_SUMMARY: 'payee-wise-summary',
  STATUS_WISE_SUMMARY: 'status-wise-summary',
  AGGREGATE_REPORTS: 'aggregate-reports',
  REPORT_ON_REIMBURSEMENTS: 'report-on-reimbursements',
  HOUSEHOLD_NUMBER: 'household-number',
  ENROLLMENT_DASHBOARD: 'enrollment-dashboard',
};

export const FOLLOW_UP_RECEIVING_FACILITY = 'SELF';
export const FOLLOW_UP_REASON = 'follow_up';
export const REFERRAL_FACILITY_OTHER = 'Other';

export const REFERRAL_REASONS = {
  further_consultation: 'Further Consultation',
  drug_stockout: 'Drug Stockout',
  investigative_tests: 'Investigative Tests',
  inpatient_care: 'Inpatient care',
  bed_shortage: 'Bed Shortage',
  follow_up: 'Follow-up',
  other: 'Other',
};

export const REFERRAL_FACILITIES = [
  'Mehoni Hospital',
  'Adi Shihu Hospital',
  'Adi Gudem Hospital',
  'Samre Hospital',
  'Hagere Selam Hospital',
  'Mulu Asfeha (Atsibi) Hospital',
  'Fire Semaetat (Hawzen) Hospital',
  'Frewoini Hospital',
  'Fatsi Hospital',
  'Dewhan Hospital',
  'Enticho Hospital',
  'Rama Hospital',
  'Edaga Arbi Hospital',
  'Yechila Hospital',
  'Semema Hospital',
  'Wukro Marai Hospital',
  'Selekleka Hospital',
  'Endabaguna Hospital',
  'Mai Tsebri Hospital',
  'Adi Daero',
  'Adi Remets',
  'Ketema Nigus (Tsegede)',
  'Alamata Hospital',
  'Korem Hospital',
  'Lemlem Karl (Maichew) Hospital',
  'Quiha Hospital',
  'Mekelle Hospital',
  'Wukro General Hospital',
  'Abi Adi Hospital',
  'Adigrat Hospital',
  'Adwa Hospital',
  'Kidist Mariam (Aksum) Hospital',
  'Suhul (Shire) Hospital',
  'Mayani (Sheraro) Hospital',
  'Mearig (Dansha) Hospital',
  'Kahsay Abera (Humera) Hospital',
  'Ayder Hospital',
  'Aksum Hospita',
  REFERRAL_FACILITY_OTHER,
];

export const title = 'Admin';
export const titleTemplate = '%s — Admin';
export const DEFAULT_PATHS_FOR_ROLE = {
  [ROLES.PROVIDER]: process.env.REACT_APP_DEFAULT_PROVIDER_ROUTE,
  [ROLES.CARD_ROOM_WORKER]: process.env.REACT_APP_DEFAULT_RECEPTION_ROUTE,
  [ROLES.CLAIMS_PREPARER]: process.env.REACT_APP_DEFAULT_SUBMISSION_ROUTE,
  [ROLES.ENROLLMENT_WORKER]: process.env.REACT_APP_DEFAULT_ENROLLMENT_ROUTE,
  [ROLES.FACILITY_HEAD]: process.env.REACT_APP_DEFAULT_SUMMARY_ROUTE,
  // new roles
  [ROLES.MEMBER_MANAGER]: process.env.REACT_APP_DEFAULT_ENROLLMENT_ROUTE,
  [ROLES.CLAIMS_MANAGER]: process.env.REACT_APP_DEFAULT_CLAIMS_ROUTE,
  [ROLES.FINANCE_MANAGER]: process.env.REACT_APP_DEFAULT_FINANCE_ROUTE,
  [ROLES.REPORT_MANAGER]: process.env.REACT_APP_DEFAULT_REPORT_ROUTE,
  [ROLES.FACILITY_FINANCE_MANAGER]: process.env.REACT_APP_DEFAULT_FINANCE_ROUTE,
  [ROLES.BRANCH_MANAGER]: process.env.REACT_APP_DEFAULT_ENROLLMENT_ROUTE,
  [ROLES.CLAIMS_REVIEWER]: process.env.REACT_APP_DEFAULT_CLAIMS_ROUTE,
  [ROLES.FACILITY_DIRECTOR]: process.env.REACT_APP_DEFAULT_CLAIMS_ROUTE,
  [ROLES.CLAIMS_OFFICER]: process.env.REACT_APP_DEFAULT_SUBMISSION_ROUTE,
  [ROLES.RECEPTIONIST]: process.env.REACT_APP_DEFAULT_RECEPTION_ROUTE,
  [ROLES.ENROLLMENT_MANAGER]: process.env.REACT_APP_DEFAULT_ENROLLMENT_ROUTE,
  [ROLES.MEMBER_IMPORTER]: 'import/members',
};

export const featureFlags = {
  ENABLE_CLAIMS: process.env.REACT_APP_ENABLE_CLAIMS === 'true',
  ENABLE_FACILITY_REPORTING: process.env.REACT_APP_ENABLE_FACILITY_REPORTING === 'true',
  ENABLE_MEMBER_EDITING: process.env.REACT_APP_ENABLE_MEMBER_EDITING === 'true',
  ENABLE_ENROLLMENT_REPORTING: process.env.REACT_APP_ENABLE_ENROLLMENT_REPORTING === 'true',
  ENABLE_REIMBURSEMENTS: process.env.REACT_APP_ENABLE_REIMBURSEMENTS === 'true',
  ENABLE_SUBMISSION: process.env.REACT_APP_ENABLE_SUBMISSION === 'true',
  ENABLE_CHECK_IN: process.env.REACT_APP_ENABLE_CHECK_IN === 'true',
  ENABLE_ENROLL_MEMBERS: process.env.REACT_APP_ENABLE_ENROLL_MEMBERS === 'true',
  ENABLE_STATUS: process.env.REACT_APP_ENABLE_STATUS === 'true',
  LOCALE_CODE: process.env.REACT_APP_LOCALE_CODE,
  BRANDED_LOGO_URL: process.env.REACT_APP_BRANDED_LOGO_URL,
  EXPERIMENTAL_FEATURES: process.env.REACT_APP_EXPERIMENTAL_FEATURES === 'true',
  ENABLE_FACILITY_DASHBOARD: process.env.REACT_APP_ENABLE_FACILITY_DASHBOARD === 'true',
  ENABLE_BRANCH_DASHBOARD: process.env.REACT_APP_ENABLE_BRANCH_DASHBOARD === 'true',
  ENABLE_IMPORT_CLAIMS: process.env.REACT_APP_IMPORT_CLAIMS === 'true',
  ENABLE_CHANGE_PASSWORD: process.env.REACT_APP_CHANGE_PASSWORD === 'true',
  ENABLE_MEMBERS_LIST: process.env.REACT_APP_MEMBERS_LIST === 'true',
  ENABLE_CLAIMS_REPORTS: process.env.REACT_APP_CLAIMS_REPORTS === 'true',
  ENABLE_INDIGENTS_PAYMENTS: process.env.REACT_APP_ENABLE_INDIGENTS_PAYMENTS === 'true',
  ENABLE_IMPORT_MEMBERS: process.env.REACT_APP_ENABLE_IMPORT_MEMBERS === 'true',
};

const localeConstsMap = {
  INT: {
    CAL: CALENDAR_FORMATS.GREGORIAN,
    CURRENCY: CURRENCY_FORMATS.UNSPECIFIED,
  },
  ETH: {
    CAL: CALENDAR_FORMATS.ETHIOPIAN,
    CURRENCY: CURRENCY_FORMATS.ETHIOPIAN,
  },
  UGX: {
    CAL: CALENDAR_FORMATS.GREGORIAN,
    CURRENCY: CURRENCY_FORMATS.UGANDAN,
  },
};

export const localeConsts = {
  GLOBAL_DATE_FORMAT: localeConstsMap[featureFlags.LOCALE_CODE].CAL,
  CURRENCY_FORMAT: localeConstsMap[featureFlags.LOCALE_CODE].CURRENCY,
};
