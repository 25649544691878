// src/components/MemberRenewContainer/HouseholdHead.jsx
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { formatDate } from 'lib/formatters/date';
import CardIdInput from './CardIdInput';
import { useUpdateMemberMutation } from 'store/api/baseApiSlice';

const DEFAULT_PROFILE_IMAGE = 'https://via.placeholder.com/100'; // Replace with your default image URL

/**
 * HouseholdHead Component
 * Displays comprehensive information about the household head.
 */
const HouseholdHead = ({ householdHead }) => {
  const [cardId, setCardId] = useState(householdHead?.cardId || '');
  const [updateMember] = useUpdateMemberMutation();
  

   /**
   * Handles the update of Card ID by calling the backend API.
   * @param {string} newCardId - The validated and formatted Card ID.
   * @returns {Promise<void>}
   */
   const handleCardIdUpdate = async (newCardId) => {
    try {
      const formData = new FormData();
      formData.append('id', householdHead.id);
      formData.append('card_id', newCardId);
      const response = await updateMember(formData)
      if (response.data.error) {
        throw new Error('Card ID has already been taken or Invalid');
      }
    } catch (error) {
      console.error('Error updating Card ID:', error);
      throw error;
    }
  };

  if (!householdHead) {
    return (
      <div className="tw-text-red-500 tw-mb-6">
        Household head not found.
      </div>
    );
  }

  const {
    photoUrl,
    fullName,
    age,
    gender,
    birthdate,
    membershipNumber,
    localMembershipNumber,
    memberAddress,
    phoneNumber,
    profession,
    membershipType,
    enrolledAt,
    status,
    coverageEndDate,
    renewedAt,
    needsRenewal,
    unpaid,
    absentee,
    isCardInvalid,
  } = householdHead;

  const profileImageUrl = photoUrl
    ? `${process.env.REACT_APP_COVERAGE_API_BASE}${photoUrl}`
    : DEFAULT_PROFILE_IMAGE;

  /**
   * Determine Membership Status Badge
   */
  const getMembershipBadge = () => {
    if (!coverageEndDate) return null;

    const today = new Date();
    const endDate = new Date(coverageEndDate);

    if (endDate >= today) {
      // Membership is active
      return (
        <span
          className="
            tw-inline-block 
            tw-ml-2 
            tw-px-3 
            tw-py-1 
            tw-text-sm 
            tw-font-semibold 
            tw-rounded-full 
            tw-bg-green-100 
            tw-text-green-800
          "
          aria-label="Membership Active"
        >
          Membership Active
        </span>
      );
    } else {
      // Membership has expired
      return (
        <span
          className="
            tw-inline-block 
            tw-ml-2 
            tw-px-3 
            tw-py-1 
            tw-text-sm 
            tw-font-semibold 
            tw-rounded-full 
            tw-bg-red-100 
            tw-text-red-800
          "
          aria-label="Membership Expired"
        >
          Membership Expired
        </span>
      );
    }
  };

  const membershipBadge = getMembershipBadge();

  return (
    <div className="tw-bg-white tw-shadow tw-rounded-lg tw-p-6 tw-mb-6">
      <h2 className="tw-text-xl tw-font-semibold tw-mb-4">
        Household Head
      </h2>
      <div className="tw-flex tw-flex-col md:tw-flex-row tw-items-center tw-gap-6">
        {/* Profile Image */}
        <img
          src={profileImageUrl}
          alt={fullName}
          className="tw-w-32 tw-h-32 tw-rounded-full tw-object-cover tw-shadow"
        />
        {/* Member Details */}
        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-4 tw-w-full">
          <div>
            <span className="tw-font-medium">Full Name:</span> {fullName}
          </div>
          <div>
            <span className="tw-font-medium">Age:</span> {age}
          </div>
          <div>
            <span className="tw-font-medium">Gender:</span> {gender === 'F' ? 'Female' : gender === 'M' ? 'Male' : 'Other'}
          </div>
          <div>
            <span className="tw-font-medium">Birthdate:</span> {formatDate(birthdate)}
          </div>
          <div>
            <span className="tw-font-medium">Membership Number:</span> {membershipNumber}
          </div>
          <div>
            <span className="tw-font-medium">Local Membership Number:</span> {localMembershipNumber}
          </div>
          <div>
            <span className="tw-font-medium">Address:</span> {memberAddress || 'N/A'}
          </div>
          <div>
            <span className="tw-font-medium">Phone Number:</span> {phoneNumber || 'N/A'}
          </div>
          <div>
            <span className="tw-font-medium">Profession:</span> {profession || 'N/A'}
          </div>
          <div>
            <span className="tw-font-medium">Membership Type:</span> {membershipType || 'N/A'}
          </div>
          <div>
            <span className="tw-font-medium">Enrollment Date:</span> {formatDate(enrolledAt)}
          </div>
          <div>
            <span className="tw-font-medium">Coverage End Date:</span> {coverageEndDate ? formatDate(coverageEndDate) : 'N/A'}
          </div>
          <div>
            <span className="tw-font-medium">Renewed At:</span> {renewedAt ? formatDate(renewedAt) : 'N/A'}
          </div>
          <div className="tw-flex tw-items-center">
            <span className="tw-font-medium">Status:</span>
            {membershipBadge}
          </div>
          <div className="tw-col-span-1 md:tw-col-span-2 lg:tw-col-span-3">
            <CardIdInput
              initialCardId={cardId}
              onCardIdUpdate={handleCardIdUpdate}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

HouseholdHead.propTypes = {
  householdHead: PropTypes.shape({
    photoUrl: PropTypes.string,
    fullName: PropTypes.string.isRequired,
    age: PropTypes.number.isRequired,
    gender: PropTypes.string.isRequired,
    birthdate: PropTypes.string.isRequired,
    membershipNumber: PropTypes.string.isRequired,
    localMembershipNumber: PropTypes.string.isRequired,
    memberAddress: PropTypes.string,
    phoneNumber: PropTypes.string,
    profession: PropTypes.string,
    membershipType: PropTypes.string,
    enrolledAt: PropTypes.string.isRequired,
    status: PropTypes.string,
    coverageEndDate: PropTypes.string,
    renewedAt: PropTypes.string,
    needsRenewal: PropTypes.bool,
    unpaid: PropTypes.bool,
    absentee: PropTypes.bool,
    isCardInvalid: PropTypes.bool,
  }),
};

export default HouseholdHead;
