import { merge } from 'lodash/fp';
import { normalize } from 'normalizr';

import identificationEventWithMemberAndEncounterSchema from 'store/schemas/identification-event-schema';
import householdSchema from 'store/schemas/household-schema';

import { FETCH_OPEN_ID_EVENTS_SUCCESS } from 'store/identification-events/identification-events-actions';
import {
  FETCH_HOUSEHOLD_MEMBERS_FAILURE,
  FETCH_HOUSEHOLD_MEMBERS_REQUEST,
  FETCH_HOUSEHOLD_MEMBERS_SUCCESS,
  UPDATE_MEMBER_FAILURE,
  UPDATE_MEMBER_REQUEST,
  UPDATE_MEMBER_SUCCESS,
  POST_MEMBER_REQUEST,
  POST_MEMBER_SUCCESS,
  POST_MEMBER_FAILURE,
  FETCH_MEMBERS_REQUEST,
  FETCH_MEMBERS_SUCCESS,
  FETCH_MEMBERS_FAILURE,
  CREATE_MEMBER,
  STORE_MEMBERS,
  JSON_UPDATE_MEMBER,
  MEMBER_LIST_REQUEST,
  MEMBER_LIST_SUCCESS,
  MEMBER_LIST_FAILURE,
  MEMBER_LIST_CLEAR,
  FETCH_MEMBER_LIST_CSV_REQUEST,
  FETCH_MEMBER_LIST_CSV_SUCCESS,
  FETCH_MEMBER_LIST_CSV_FAILURE,
  FETCH_PREMIA_REQUEST,
  FETCH_PREMIA_SUCCESS,
  FETCH_PREMIA_FAILURE,
  REPLACE_CARD_REQUEST,
  REPLACE_CARD_SUCCESS, REPLACE_CARD_FAILURE,
} from 'store/members/members-actions';

export const omittableFields = [
  'isLoadingMembers',
  'membersError',
  'isPerformingMemberAction',
  'memberActionError',
];

export const initialState = {
  isLoadingMembers: false,
  membersError: '',
  members: {},
  isPerformingMemberAction: false,
  memberActionError: '',
  isLoadingMemberList: false,
  memberListError: '',
  memberList: {},
  isLoadingMemberListCSV: false,
  memberListCSVError: '',
  memberListCSV: {},
  premia: [],
  householdEnrollmentRecord: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case CREATE_MEMBER: {
      const newMember = action.member;
      return {
        ...state,
        members: {
          ...state.members,
          [newMember.id]: newMember,
        },
      };
    }
    case FETCH_MEMBERS_REQUEST: {
      return {
        ...state,
        isLoadingMembers: true,
        membersError: '',
      };
    }
    case FETCH_MEMBERS_SUCCESS: {
      return {
        ...state,
        membersError: '',
      };
    }
    case STORE_MEMBERS: {
      return {
        ...state,
        isLoadingMembers: false,
        members: merge(state.members)(action.payload),
      };
    }
    case FETCH_MEMBERS_FAILURE: {
      return {
        ...state,
        isLoadingMembers: false,
        membersError: action.errorMessage,
      };
    }
    case FETCH_HOUSEHOLD_MEMBERS_REQUEST: {
      return {
        ...state,
        isLoadingMembers: true,
        membersError: '',
        householdEnrollmentRecord: {},
      };
    }
    case FETCH_HOUSEHOLD_MEMBERS_SUCCESS: {
      // TODO: normalize in transform response
      const normalizedResponse = normalize(action.response, [householdSchema]);
      const householdEnrollmentRecord = action.response[0]?.householdEnrollmentRecord;

      if (normalizedResponse.entities && normalizedResponse.entities.members) {
        return {
          ...state,
          isLoadingMembers: false,
          membersError: '',
          members: merge(state.members)(normalizedResponse.entities.members),
          householdEnrollmentRecord,
        };
      }
      return {
        ...state,
        isLoadingMembers: false,
        membersError: '',
        householdEnrollmentRecord: {},
      };
    }
    case FETCH_HOUSEHOLD_MEMBERS_FAILURE: {
      return {
        ...state,
        isLoadingMembers: false,
        membersError: action.errorMessage,
        householdEnrollmentRecord: {},
      };
    }
    case REPLACE_CARD_REQUEST:
      return {
        ...state,
        isPerformingMemberAction: true,
        memberActionError: '',
      };
    case REPLACE_CARD_SUCCESS:
      return {
        ...state,
        isPerformingMemberAction: false,
        memberActionError: '',
      };
    case REPLACE_CARD_FAILURE:
      return {
        ...state,
        isPerformingMemberAction: false,
        memberActionError: action.errorMessage,
      };
    case POST_MEMBER_REQUEST:
      return {
        ...state,
        isPerformingMemberAction: true,
        memberActionError: '',
      };
    case POST_MEMBER_SUCCESS:
      return {
        ...state,
        isPerformingMemberAction: false,
        memberActionError: '',
        members: {
          ...state.members,
          [action.response.id]: action.response,
        },
      };
    case POST_MEMBER_FAILURE:
      return {
        ...state,
        isPerformingMemberAction: false,
        memberActionError: action.errorMessage,
      };
    case FETCH_PREMIA_REQUEST:
      return {
        ...state,
        isPerformingMemberAction: true,
        memberActionError: '',
      };
    case FETCH_PREMIA_SUCCESS: {
      return {
        ...state,
        isPerformingMemberAction: false,
        memberActionError: '',
        premia: action.response,
      };
    }
    case FETCH_PREMIA_FAILURE:
      return {
        ...state,
        isPerformingMemberAction: false,
        memberActionError: action.errorMessage,
      };
    case UPDATE_MEMBER_REQUEST: {
      return {
        ...state,
        isPerformingMemberAction: true,
        memberActionError: '',
      };
    }
    case UPDATE_MEMBER_SUCCESS: {
      return {
        ...state,
        isPerformingMemberAction: false,
        memberActionError: '',
        members: {
          ...state.members,
          [action.response.id]: action.response,
        },
      };
    }
    case UPDATE_MEMBER_FAILURE: {
      return {
        ...state,
        isPerformingMemberAction: false,
        memberActionError: action.errorMessage,
      };
    }
    case FETCH_OPEN_ID_EVENTS_SUCCESS: {
      const normalizedResponse = normalize(action.response, [identificationEventWithMemberAndEncounterSchema]);

      if (normalizedResponse.entities && normalizedResponse.entities.members) {
        return {
          ...state,
          members: merge(state.members)(normalizedResponse.entities.members),
        };
      }
      return state;
    }
    case JSON_UPDATE_MEMBER: {
      const newMember = action.member;
      return {
        ...state,
        members: {
          ...state.members,
          [newMember.id]: newMember,
        },
      };
    }
    // FETCH MEMBER LIST
    case MEMBER_LIST_REQUEST:
      return {
        ...state,
        isLoadingMemberList: true,
        memberList: '',
      };
    case MEMBER_LIST_SUCCESS:
      return {
        ...state,
        isLoadingMemberList: false,
        memberListError: '',
        memberList: action.response,
      };
    case MEMBER_LIST_FAILURE:
      return {
        ...state,
        isLoadingMemberList: false,
        memberListError: action.errorMessage,
      };
    case MEMBER_LIST_CLEAR:
      return {
        ...state,
        isLoadingMemberList: false,
        memberListError: action.errorMessage,
        memberList: {},
      };

      // FETCH MEMBER LIST CSV
    case FETCH_MEMBER_LIST_CSV_REQUEST:
      return {
        ...state,
        isLoadingMemberListCSV: true,
        memberListCSV: '',
      };
    case FETCH_MEMBER_LIST_CSV_SUCCESS:
      return {
        ...state,
        isLoadingMemberListCSV: false,
        memberListCSVError: '',
        memberListCSV: action.response,
      };
    case FETCH_MEMBER_LIST_CSV_FAILURE:
      return {
        ...state,
        isLoadingMemberListCSV: false,
        memberListCSVError: action.errorMessage,
      };
    default: {
      return state;
    }
  }
}
