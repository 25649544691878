/* eslint-disable no-param-reassign */
/* eslint-disable padded-blocks */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-unused-vars */
/* eslint-disable arrow-parens */
/* eslint-disable max-len */
import { createSelector } from 'reselect';
import { pickBy, filter, map, some, values } from 'lodash/fp';
import moment from 'moment';
import { formatDate } from 'lib/formatters/date';

import { memberByIdSelector } from 'store/members/members-selectors';
import { administrativeDivisionsByIdSelector } from 'store/administrative-divisions/administrative-divisions-selectors';
import { gregorianToEthiopianYYYYMMDD } from '../../lib/utils';

export const enrollmentPeriodsKeyedByIdSelector = state => state.enrollment.enrollmentPeriods;

const EMPTY_VALUE = -1;

export const pastAndPresentEnrollmentPeriodsKeyedByIdSelector = (state) => {
  const currentTime = moment();
  return pickBy(period => currentTime.isAfter(moment(period.startDate)))(state.enrollment.enrollmentPeriods);
};

export const futureEnrollmentPeriodsKeyedByIdSelector = (state) => {
  const currentTime = moment();
  return pickBy(period => currentTime.isBefore(moment(period.endDate)))(state.enrollment.enrollmentPeriods);
};

export const enrollmentPeriodOptionsSelector = createSelector(
  pastAndPresentEnrollmentPeriodsKeyedByIdSelector,
  administrativeDivisionsByIdSelector,
  (enrollmentPeriods, adminDivisions) => {
    const emptyValue = { value: EMPTY_VALUE, name: 'Select an enrollment period...', disabled: true };
    return [
      emptyValue,
      ...map(ep => ({
        value: ep.id,
        name: `${adminDivisions[ep.administrativeDivisionId].name} ${formatDate(ep.startDate)}`,
      }))(enrollmentPeriods),
    ];
  },
);

export const futureEnrollmentPeriodOptionsSelector = createSelector(
  futureEnrollmentPeriodsKeyedByIdSelector,
  administrativeDivisionsByIdSelector,
  (enrollmentPeriods, adminDivisions) => {
    // Convert Gregorian dates to Ethiopian dates
    Object.values(enrollmentPeriods).forEach(ep => {
      ['startDate', 'endDate', 'coverageStartDate', 'coverageEndDate'].forEach(dateKey => {
        const [year, month, day] = ep[dateKey].split('-');
        const ethDate = gregorianToEthiopianYYYYMMDD(year, month, day);
        const ethDateKey = dateKey.replace('Date', 'EthDate');
        ep[ethDateKey] = ethDate;
      });
    });

    // Format the options with Ethiopian dates
    return [
      ...map(ep => ({
        value: ep.id,
        name: `${adminDivisions[ep.administrativeDivisionId].name} ${ep.startEthDate} - ${ep.endEthDate}`,
      }))(enrollmentPeriods),
    ];
  },
);


export const activeEnrollmentPeriodsSelector = createSelector(
  enrollmentPeriodsKeyedByIdSelector,
  (enrollmentPeriodsKeyedById) => {
    const currentTime = moment();
    return filter(enrollmentPeriod => currentTime.isBetween(
      moment(enrollmentPeriod.startDate),
      moment(enrollmentPeriod.coverageEndDate),
    ))(values(enrollmentPeriodsKeyedById));
  },
);

export const upcomingEnrollmentPeriodsSelector = createSelector(
  [enrollmentPeriodsKeyedByIdSelector, administrativeDivisionsByIdSelector],
  (enrollmentPeriodsKeyedById, adminDivisions) => {

    Object.keys(enrollmentPeriodsKeyedById).forEach(key => {
      const period = enrollmentPeriodsKeyedById[key];

      ['startDate', 'endDate', 'coverageStartDate', 'coverageEndDate'].forEach(dateKey => {
        const [year, month, day] = period[dateKey].split('-');
        const ethDate = gregorianToEthiopianYYYYMMDD(year, month, day);
        const ethDateKey = dateKey.replace('Date', 'EthDate');
        period[ethDateKey] = ethDate;
      });
    });

    const currentTime = moment();
    const upcomingEnrollmentPeriods = values(enrollmentPeriodsKeyedById).filter(enrollmentPeriod =>
      currentTime.isBefore(moment(enrollmentPeriod.startDate)),
    );

    const formattedEnrollmentPeriods = upcomingEnrollmentPeriods.map(ep => ({
      value: ep.id,
      name: `${ep.startEthDate} - ${ep.endEthDate}`,
    }));

    return [...formattedEnrollmentPeriods];
  },
);

export const memberCanRenewSelector = createSelector(
  memberByIdSelector,
  activeEnrollmentPeriodsSelector,
  // If the member's coverageEndDate matches the coverageEndDate of any active enrollment period
  // we can assume they have enrolled. This avoids having to do a complex look-up using
  // administrative divisions.
  (member, activeEnrollmentPeriods) => {
    if (!member || !activeEnrollmentPeriods) {
      return null;
    }
    return !some(
      enrollmentPeriod => member.coverageEndDate === enrollmentPeriod.coverageEndDate,
    )(activeEnrollmentPeriods);
  },
);


export const pastAndPresentEnrollmentPeriodsByAdministrativeDivisionKeyedByIdSelector = (state) => {
  const currentTime = moment();
  return pickBy(period => currentTime.isAfter(moment(period.startDate)))(state.enrollment.enrollmentPeriodsByAdministrativeDivision);
};

export const enrollmentPeriodByAdministrativeDivisionOptionsSelector = createSelector(
  pastAndPresentEnrollmentPeriodsByAdministrativeDivisionKeyedByIdSelector,
  administrativeDivisionsByIdSelector,
  (enrollmentPeriods, adminDivisions) => {
    const emptyValue = { value: EMPTY_VALUE, name: 'Select an enrollment period...', disabled: true };
    return [
      emptyValue,
      ...map(ep => ({
        value: ep.id,
        name: `${adminDivisions[ep.administrativeDivisionId].name} ${formatDate(ep.startDate)}`,
      }))(enrollmentPeriods),
    ];
  },
);
