import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { countUnsyncedDeltasByModelType } from 'store/deltas/deltas-selectors';
import { exportData as exportDataAction } from 'store/export/export-actions';
import { jsonUploadData as jsonUploadDataAction } from 'store/upload/json-upload-actions';
import {
  encounterErrorSelector,
} from 'store/encounters/encounters-selectors';
import Box from 'components/box';
import Button from 'components/button';
import { Grid } from '@material-ui/core';
import SyncStatusIndicator from '../containers/submissions/overview/components/sync-status-indicator';


class ImportClaimsStatus extends Component {
  static mapStateToProps = (state) => {
    const { billables, isLoadingBillables } = state.billables;
    const unsyncedClaimCount = countUnsyncedDeltasByModelType(state, 'Encounter');
    return {
      billables,
      isLoadingBillables,
      isLoadingClaims: state.claims.isLoadingClaims,
      isLoadingDiagnoses: state.diagnoses.isLoadingDiagnoses,
      isLoadingIdentificationEvents: state.identificationEvents.isLoadingIdentificationEvents,
      unsyncedClaimCount,
      error: encounterErrorSelector(state),

    };
  }

  static mapDispatchToProps = dispatch => ({
    exportData: () => dispatch(exportDataAction()),
    jsonUploadData: () => dispatch(jsonUploadDataAction()),
  });

  handleExportClick = () => {
    const { exportData } = this.props;
    exportData();
  }

  handleJsonUploadData = () => {
    const { jsonUploadData } = this.props;
    jsonUploadData();
  }

  componentDidMount = () => {
    const { jsonUploadData } = this.props;
    jsonUploadData();
  }


  render() {
    const {
      isLoadingBillables,
      isLoadingClaims,
      isLoadingDiagnoses,
      isLoadingIdentificationEvents,
      error,
      unsyncedClaimCount,
    } = this.props;
    return (
      <div>
        <Box flex alignItems="center">
          <SyncStatusIndicator
            isLoading={isLoadingBillables || isLoadingDiagnoses
        || isLoadingIdentificationEvents || isLoadingClaims}
            fetchingError={error}
            unsyncedClaimCount={unsyncedClaimCount}
            handleExportClick={this.handleExportClick}
          />
        </Box>
        {unsyncedClaimCount > 0 && (
        <Grid container>
          <Grid item xs={3}>
            <Box paddingTop={30} paddingBottom={5}>
              <Button small primary type="submit" onClick={this.handleJsonUploadData}>
                Upload now
              </Button>
            </Box>
          </Grid>
        </Grid>
        )}
      </div>
    );
  }
}

ImportClaimsStatus.propTypes = {
  user: PropTypes.shape({}).isRequired,
  diagnoses: PropTypes.shape({}).isRequired,
  providers: PropTypes.shape({}).isRequired,
  error: PropTypes.string.isRequired,
  exportData: PropTypes.func.isRequired,
  jsonUploadData: PropTypes.func.isRequired,
  history: PropTypes.shape({}).isRequired,
  unsyncedClaimCount: PropTypes.number.isRequired,
  isLoadingBillables: PropTypes.bool.isRequired,
  isLoadingDiagnoses: PropTypes.bool.isRequired,
  isLoadingClaims: PropTypes.bool.isRequired,
  isLoadingIdentificationEvents: PropTypes.bool.isRequired,
};

export default connect(ImportClaimsStatus.mapStateToProps, ImportClaimsStatus.mapDispatchToProps)(ImportClaimsStatus);
