import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { size, sum, compact } from 'lodash/fp';
import Box from 'components/box';
import Button from 'components/button';
import { Text } from 'components/text';
import Modal from 'components/modal';
import { formatCurrency } from 'lib/formatters/currency';

class MassApprovalConfirmModal extends Component {
  // eslint-disable-next-line class-methods-use-this
  totalAmount(selectedClaimIds, allClaims) {
    let claims = [];
    let total = 0;
    claims = allClaims.map(claim => (selectedClaimIds.includes(claim.id) ? claim : null));
    claims = compact(claims);
    claims = claims.map(claim => parseFloat(formatCurrency(claim.reimbursalAmount)));
    total = sum(claims);
    return total.toFixed(2);
  }

  renderFooter() {
    const { onClose, onSubmit } = this.props;
    return (
      <Box flex alignItems="space-between" justifyContent="space-between">
        <Box flex>
          <Box marginRight={2}>
            <Button small inline onClick={onClose}>
              Cancel
            </Button>
          </Box>
        </Box>
        <Box flex>
          <Box marginRight={2}>
            <Button small inline primary onClick={onSubmit}>
              Confirm
            </Button>
          </Box>
        </Box>
      </Box>
    );
  }

  render() {
    const { onClose, selectedClaimIds, allClaims } = this.props;

    return (
      <Modal
        title="Confirm Mass Approval"
        onRequestClose={onClose}
        footer={this.renderFooter()}
      >
        <Box flex marginTop={-4} flexDirection="column">
          <Box marginBottom={2}>
            <Text>
              Selected Claims:
              <b>{size(selectedClaimIds)}</b>
            </Text>
            <br />
            <br />
            <Text>
              Total Amount:
              {' '}
              <b>
                {this.totalAmount(selectedClaimIds, allClaims)}
                 Br.
              </b>
            </Text>
            <br />
            <br />
            <Text fontWeight="semibold">
              Are you sure you want to approve the selected claims?
            </Text>
          </Box>
        </Box>
      </Modal>
    );
  }
}

export default MassApprovalConfirmModal;

MassApprovalConfirmModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  selectedClaimIds: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  allClaims: PropTypes.array.isRequired,
};
