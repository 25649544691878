/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import LogoutModal from 'components/logout-modal';
import { userSelector, isProviderUserSelector } from 'store/auth/auth-selectors';
import { exportData as exportDataAction } from 'store/export/export-actions';
import { countUnsyncedDeltasByModelType } from 'store/deltas/deltas-selectors';
import Icon from './icon';

const styles = {
  root: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
};

class AccountMenu extends Component {
  static mapStateToProps = state => ({
    user: userSelector(state),
    isProviderUser: isProviderUserSelector(state),
    unsyncedClaimCount: countUnsyncedDeltasByModelType(state, 'Encounter'),
  });

  static mapDispatchToProps = dispatch => ({
    exportData: () => dispatch(exportDataAction()),
  });

  state = {
    anchorEl: null,
    showLogoutModal: false,
  };

  handleCloseModal = () => {
    this.setState({ showLogoutModal: false });
  }

  handleExport = () => {
    const { exportData } = this.props;
    exportData();
  }

  handleMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { classes, unsyncedClaimCount, user } = this.props;
    const { role } = user;
    const { anchorEl, showLogoutModal } = this.state;
    const open = Boolean(anchorEl);

    const roleLinks = {
      'receptionist': `${process.env.REACT_APP_DOC_API_BASE}/claims_submission.html`,
      'card_room_worker': `${process.env.REACT_APP_DOC_API_BASE}/claims_submission.html`,
      'claims_preparer': `${process.env.REACT_APP_DOC_API_BASE}/claims_submission.html`,
      'facility_head': `${process.env.REACT_APP_DOC_API_BASE}/claims_submission.html`,
      'facility_director': `${process.env.REACT_APP_DOC_API_BASE}/claims_submission.html`,
      'claims_officer': `${process.env.REACT_APP_DOC_API_BASE}/claims_submission.html`,
      'provider': `${process.env.REACT_APP_DOC_API_BASE}/claims_submission.html`,
      'claims_reviewer': `${process.env.REACT_APP_DOC_API_BASE}/claims_adjudication.html`,
      'claims_manager': `${process.env.REACT_APP_DOC_API_BASE}/claims_adjudication.html`,
      'enrollment_worker': `${process.env.REACT_APP_DOC_API_BASE}/membership_enrollment.html`,
      'enrollment_officer': `${process.env.REACT_APP_DOC_API_BASE}/membership_enrollment.html`,
      'enrollment_manager': `${process.env.REACT_APP_DOC_API_BASE}/membership_enrollment.html`,
      'member_manager': `${process.env.REACT_APP_DOC_API_BASE}/membership_enrollment.html`
    };

    const helpLink = roleLinks[role] || 'http://test.doc.etnhis.org';

    return (
      <div className={classes.root}>
        <div>
          <Icon
            aria-owns={open ? 'menu-appbar' : undefined}
            aria-haspopup="true"
            onClick={this.handleMenu}
            color="inherit"
            name="user"
            cursor="pointer"
          />
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={open}
            onClose={this.handleClose}
          >
            <MenuItem>
              <a
                style={{ width: "100%" }}
                rel="noopener noreferrer"
                target="_blank"
                href={helpLink}
                onClick={this.handleClose}
              >
                Help
              </a>
            </MenuItem>
            <MenuItem onClick={this.handleClose}>
              <Link to="/change_password">Change Password</Link>
            </MenuItem>
            <MenuItem onClick={() => {
              this.handleClose();
              this.setState({ showLogoutModal: true });
            }}
            >
              Log Out
            </MenuItem>
          </Menu>
          {showLogoutModal && (
            <LogoutModal
              onClose={this.handleCloseModal}
              onExport={this.handleExport}
              unsyncedClaimCount={unsyncedClaimCount}
            />
          )}
        </div>
      </div>
    );
  }
}

AccountMenu.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  classes: PropTypes.object.isRequired,
  unsyncedClaimCount: PropTypes.number,
};

AccountMenu.defaultProps = {
  unsyncedClaimCount: 0,
};

export default withStyles(styles)(AccountMenu);
