import React from 'react';

import { ErrorLayout, LayoutWithHeader } from 'components/layouts';
import { ViewTitle, Paragraph } from 'components/text';
import { UnderlinedLink } from 'components/links';


const NotFoundView = () => (
  <LayoutWithHeader pageTitle="Change Password" steps={[{ title: 'Home', href: '/' }]}>
    <ErrorLayout pageTitle="Not Found">
      <ViewTitle>Sorry! We couldn&rsquo;t find that page!</ViewTitle>
      <br />
      <br />
      <Paragraph marginVertical={4}>
        {'You may want to head back '}
        <UnderlinedLink to="/">to the homepage</UnderlinedLink>
      .
      </Paragraph>
    </ErrorLayout>
  </LayoutWithHeader>
);

export default NotFoundView;
