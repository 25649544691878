/* eslint-disable camelcase */
/* eslint-disable no-console */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty, toLower } from 'lodash';
import {
  createEncounterWithDelta as createEncounterWithDeltaAction,
  resubmitEncounterWithDelta as resubmitEncounterWithDeltaAction,
} from 'store/encounters/encounters-actions';
import { createMemberWithDelta as createMemberWithDeltaAction, updateMemberWithDelta as updateMemberWithDeltaAction } from 'store/members/members-actions';
import { createIdentificationEventWithDelta as createIdentificationEventWithDeltaAction } from 'store/identification-events/identification-events-actions';
import { createPriceSchedulesWithDeltas as createPriceSchedulesWithDeltasAction } from 'store/price-schedules/price-schedules-actions';
import { uploadData as uploadDataAction } from 'store/upload/upload-actions';


class UploadClaimsView extends React.Component {
  static mapDispatchToProps = dispatch => ({
    // eslint-disable-next-line max-len
    createEncounterWithDelta: (encounter, newEncounter) => dispatch(createEncounterWithDeltaAction(encounter, newEncounter)),
    resubmitEncounterWithDelta: encounter => dispatch(resubmitEncounterWithDeltaAction(encounter)),
    createIdentificationEventWithDelta: idEvent => dispatch(createIdentificationEventWithDeltaAction(idEvent)),
    createMemberWithDelta: member => dispatch(createMemberWithDeltaAction(member)),
    createPriceSchedulesWithDeltas: priceSchedules => dispatch(createPriceSchedulesWithDeltasAction(priceSchedules)),
    uploadData: () => dispatch(uploadDataAction()),
    updateMemberWithDelta: memberChanges => dispatch(updateMemberWithDeltaAction(memberChanges)),
  });

  constructor(props) {
    super(props);
    this.state = { data: 'false' };
  }

  handleChange = (event) => {
    const fileReader = new FileReader();
    fileReader.readAsText(event.target.files[0], 'UTF-8');
    fileReader.onload = (e) => {
      this.setState({ data: JSON.parse(e.target.result) });
    };
    fileReader.onloadend = () => {
      this.upload();
    };
  };

  upload = () => {
    const { data } = this.state;
    const {
      createEncounterWithDelta,
      createPriceSchedulesWithDeltas,
      createMemberWithDelta,
      updateMemberWithDelta,
      resubmitEncounterWithDelta,
      createIdentificationEventWithDelta,
    } = this.props;

    for (let i = 0; i < data.length; i += 1) {
      const obj = data[i];
      const { encounter } = obj;
      const { member } = obj;
      const { identification_event } = obj;
      const newPriceSchedules = [];
      const { encounter_item_relations } = obj;
      const revised = encounter.revised_encounter_id != null; // if revised_encounter_item then it's  revised.
      const manualSubmission = member.relationship_to_head === null; // check whether its manual submitted or not.

      // encounter may  have one or more referrals and/or encounterItems;
      encounter.referrals = [];
      encounter.encounterItems = [];


      // encounter_item_relations contains array of encounter_items and  billable with price schedule
      encounter_item_relations.forEach((encounterItem) => {
        const temporaryEncounterItem = {}; // temporary single encounter item
        const temporaryPriceSchedule = {}; // temporary single new price schedule
        Object.assign(temporaryEncounterItem, encounterItem.encounter_item);
        Object.assign(temporaryEncounterItem, encounterItem.billable_with_price_schedule);
        encounter.encounterItems.push(temporaryEncounterItem);
        // if there is edited billable item push it to the newPriceSchedules
        if (encounterItem.encounter_item.price_schedule_issued) {
          Object.assign(temporaryPriceSchedule, encounterItem.billable_with_price_schedule.price_schedule);
          newPriceSchedules.push(temporaryPriceSchedule);
        }
      });

      // if the claim has referral format the referral reason
      if (!isEmpty(obj.referral)) {
        obj.referral.reason = toLower(obj.referral.reason);
        encounter.referrals.push(obj.referral);
      }

      // if idEvent exists it create new idEvenet.
      if (!isEmpty(identification_event) && !revised) {
        createIdentificationEventWithDelta(identification_event);
      }
      // if member isn't manually submitted then it must update member
      if (!isEmpty(member) && !manualSubmission && !revised) {
        updateMemberWithDelta(member);
      }
      // if member is manually submitted then it must create member
      if (!isEmpty(member) && manualSubmission && !revised) {
        createMemberWithDelta(member);
      }
      if (revised) {
        encounter.adjudication_state = toLower(encounter.adjudication_state);
        resubmitEncounterWithDelta(encounter);
      }
      // the claim exported from the app is always for POST = true
      if (!revised) {
        createEncounterWithDelta(encounter, true);
      }

      // if new price schedules exists then CREATE /POST
      if (!isEmpty(newPriceSchedules)) {
        createPriceSchedulesWithDeltas(newPriceSchedules);
      }
    }
  };

  render() {
    return (
      <>
        <input type="file" onChange={this.handleChange} />
      </>
    );
  }
}

export default connect(
  UploadClaimsView.mapStateToProps,
  UploadClaimsView.mapDispatchToProps,
)(UploadClaimsView);

UploadClaimsView.propTypes = {
  createEncounterWithDelta: PropTypes.func.isRequired,
  resubmitEncounterWithDelta: PropTypes.func.isRequired,
  createPriceSchedulesWithDeltas: PropTypes.func.isRequired,
  createMemberWithDelta: PropTypes.func.isRequired,
  createIdentificationEventWithDelta: PropTypes.func.isRequired,
  updateMemberWithDelta: PropTypes.func.isRequired,
};
