import styled from '@emotion/styled';

export const SearchAndButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const StackVertical = styled.div`
  display: flex;
  flex-direction: column;
  
  & > * + * {
    margin-top: ${props => props.spaceY || '0.5rem'}; // Default to 0.5rem if no spaceY prop is provided
  }
`;

export const StackHorizontal = styled.div`
  display: flex;
  flex-direction: row;
  align-items: ${props => props.alignItems || 'flex-start'}; // Default to flex-start if no alignItems prop is provided
  justify-content: ${props => props.justifyContent || 'flex-start'}; // Default to flex-start if no justifyContent prop is provided

  & > * + * {
    margin-left: ${props => props.spaceX || '0.5rem'}; // Default to 0.5rem if no spaceX prop is provided
  }
`;
