// src/components/MemberRenewContainer/DependentsTable.jsx
import React from 'react';
import PropTypes from 'prop-types';
import LoadingIndicator from 'components/loading-indicator';
import MemberFormModal from './MemberFormModal';
import { lowerCase } from 'lodash';

const DEFAULT_PROFILE_IMAGE = 'https://via.placeholder.com/100'; // Replace with your default image URL

const DependentsTable = ({ dependents, setDependents, updateMember: callParent, renewalProgress }) => {
  // State for editing
  const [showEditModal, setShowEditModal] = React.useState(false);
  const [isEditing, setIsEditing] = React.useState(false);
  const [currentEditMember, setCurrentEditMember] = React.useState({
    fullName: '',
    birthdate: '',
    gender: '',
    membershipNumber: '',
    localMembershipNumber: '',
    relationshipToHead: '',
    membershipType: '',
    profession: '',
    photo: null,
    id: null,
  });

  // Handler to open edit modal
  const handleEditClick = (member) => {
    setIsEditing(true);
    setCurrentEditMember({
      fullName: member.fullName,
      birthdate: member.birthdate,
      gender: member.gender,
      membershipNumber: member.membershipNumber || '',
      localMembershipNumber: member.localMembershipNumber,
      relationshipToHead: member.relationshipToHead,
      membershipType: member.membershipType || '',
      profession: member.profession,
      photo: null,
      id: member.id,
    });
    setShowEditModal(true);
  };

  // Handler for form field changes in Edit Modal
  const handleEditChange = (e) => {
    const { name, value, files } = e.target;
    setCurrentEditMember((prev) => ({
      ...prev,
      [name]: files ? files[0] : value,
    }));
  };

  // Handler for date changes in Edit Modal
  const handleEditDateChange = (gregorianDate, field) => {
    setCurrentEditMember((prev) => ({
      ...prev,
      [field]: gregorianDate,
    }));
  };

  // Handler to save edited member
  const saveEditedMember = async (memberData) => {
    await callParent(memberData);
    // Optionally, you can update the local state here if needed
  };

  // Handler to toggle the 'enabled' status
  const handleToggleEnabled = async (depId) => {
    const updatedDependents = dependents.map((dep) => {
      if (dep.id === depId) {
        const updatedDep = { ...dep, enabled: !dep.enabled };
        return updatedDep;
      }
      return dep;
    });
    setDependents(updatedDependents);
  };

  // Helper function to render enrollment status badge
  const renderEnrollmentStatus = (isActive) => {
    if (isActive) {
      return (
        <span className="tw-bg-green-100 tw-text-green-800 tw-text-xs tw-font-semibold tw-mr-2 tw-px-2 tw-py-1 tw-rounded-full">
          Active
        </span>
      );
    } else {
      return (
        <span className="tw-bg-red-100 tw-text-red-800 tw-text-xs tw-font-semibold tw-mr-2 tw-px-2 tw-py-1 tw-rounded-full">
          Expired
        </span>
      );
    }
  };

  // Helper function to render renewal progress
  const renderRenewalProgress = (status) => {
    switch (status) {
      case 'loading':
        return <LoadingIndicator noun="Renewing" />;
      case 'success':
        return <span className="tw-text-green-600 tw-font-semibold">Renewed</span>;
      case 'error':
        return <span className="tw-text-red-600 tw-font-semibold">Error</span>;
      default:
        return null;
    }
  };

  const showSwitchButton = dependent => {
    const relationship = lowerCase(dependent.relationshipToHead);
    const isAdultChild = dependent.age >= 18 && (relationship === 'son' || relationship === 'daughter');
    const isMinorChild = dependent.age < 18 && (relationship === 'son' || relationship === 'daughter');
    const isSpouseOrSelf = relationship === 'husband' || relationship === 'wife' || relationship === 'self';
  
    if (dependent.active || isSpouseOrSelf || isMinorChild) {
      return false;
    }
  
    return isAdultChild || true;
  };
  

  return (
    <div className="tw-overflow-x-auto">
      <table className="tw-w-full tw-border tw-min-w-[1200px]"> {/* Increased min-width to accommodate new column */}
        <thead>
          <tr className="tw-bg-gray-200">
            <th className="tw-px-4 tw-py-2 tw-border">Member Details</th>
            <th className="tw-px-4 tw-py-2 tw-border">Enrollment Status</th>
            <th className="tw-px-4 tw-py-2 tw-border">Progress</th> {/* Existing Column */}
            <th className="tw-px-4 tw-py-2 tw-border">Enabled</th> {/* New Column Header */}
            <th className="tw-px-4 tw-py-2 tw-border">Actions</th>
          </tr>
        </thead>
        <tbody>
          {dependents.map((dep) => {
            // Construct the full photo URL
            const photoUrl = dep.photoUrl
              ? `${process.env.REACT_APP_COVERAGE_API_BASE}${dep.photoUrl}`
              : DEFAULT_PROFILE_IMAGE;

            return (
              <tr key={dep.id} className="tw-text-center tw-border-t hover:tw-bg-gray-100">
                {/* Member Details Column */}
                <td className="tw-px-4 tw-py-2 tw-border">
                  <div className="tw-flex tw-items-center">
                    <img
                      src={photoUrl}
                      alt={`${dep.fullName}'s profile`}
                      className="tw-w-16 tw-h-16 tw-rounded-full tw-object-cover tw-mr-4"
                    />
                    <div className="tw-flex tw-flex-col tw-items-start">
                      <span className="tw-font-semibold">{dep.fullName}</span>
                      <span className="tw-text-sm tw-text-gray-600">Sex: {dep.gender === 'M' ? 'Male' : 'Female'}</span>
                      <span className="tw-text-sm tw-text-gray-600">Age: {dep.age}</span>
                      <span className="tw-text-sm tw-text-gray-600">Membership No.: {dep.membershipNumber || 'N/A'}</span>
                      <span className="tw-text-sm tw-text-gray-600">Profession: {dep.profession || 'N/A'}</span>
                      <span className="tw-text-sm tw-text-gray-600">Relationship: {dep.relationshipToHead}</span>
                    </div>
                  </div>
                </td>
                {/* Enrollment Status Column */}
                <td className="tw-px-4 tw-py-2 tw-border">
                  <div className="tw-flex tw-justify-center tw-items-center">
                    {renderEnrollmentStatus(dep.activeEnollment)}
                  </div>
                </td>
                {/* Progress Column */}
                <td className="tw-px-4 tw-py-2 tw-border">
                  <div className="tw-flex tw-justify-center tw-items-center">
                    {renderRenewalProgress(renewalProgress[dep.id])}
                  </div>
                </td>
                {/* Enabled Column */}
                <td className="tw-px-4 tw-py-2 tw-border">
                  {showSwitchButton(dep) ? (
                    <div className="tw-flex tw-justify-center tw-items-center">
                      <label className="tw-relative tw-inline-flex tw-flex-shrink-0 tw-border-2 tw-border-transparent tw-rounded-full tw-w-10 tw-h-6 cursor-pointer focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-indigo-500">
                        <input
                          type="checkbox"
                          checked={dep.enabled}
                          onChange={() => handleToggleEnabled(dep.id)}
                          className="tw-absolute tw-opacity-0 tw-rounded-full tw-h-6 tw-w-10"
                          aria-label={`Toggle enabled status for ${dep.fullName}`}
                        />
                        <span
                          className={`tw-absolute tw-bg-gray-200 tw-rounded-full tw-h-6 tw-w-10 tw-transition ${
                            dep.enabled ? 'tw-bg-indigo-600' : 'tw-bg-gray-200'
                          }`}
                        ></span>
                        <span
                          className={`tw-absolute tw-left-0.5 tw-top-0.5 tw-bg-white tw-rounded-full tw-h-5 tw-w-5 tw-transform tw-transition ${
                            dep.enabled ? 'tw-translate-x-4' : 'tw-translate-x-0'
                          }`}
                        ></span>
                      </label>
                    </div>
                  ) : null}
                </td>
                {/* Actions */}
                <td className="tw-px-4 tw-py-2 tw-border">
                  <div className="tw-flex tw-space-x-3 tw-items-center">
                    <button
                      onClick={() => handleEditClick(dep)}
                      className="tw-bg-blue-500 tw-text-white tw-text-xs tw-px-2 tw-py-1 tw-rounded hover:tw-bg-blue-600 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-blue-400"
                      aria-label={`Edit ${dep.fullName}`}
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => window.open(`/members/${dep.id}`, '_blank')}
                      className="tw-bg-green-500 tw-text-white tw-text-xs tw-px-2 tw-py-1 tw-rounded hover:tw-bg-green-600 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-green-400 tw-mt-2"
                      aria-label={`View Details for ${dep.fullName}`}
                    >
                      Details
                    </button>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      {/* Edit Member Modal */}
      <MemberFormModal
        showModal={showEditModal}
        toggleModal={() => setShowEditModal(false)}
        newMember={currentEditMember}
        handleChange={handleEditChange}
        saveMember={saveEditedMember}
        isEditing={isEditing}
        hasHouseholdHead={false}
        handleDateChange={handleEditDateChange}
        errors={{}}
      />

      {/* Optional: Display mutation error */}
      {/* Since archive logic is removed, and assuming updateMember mutation was solely for archiving,
          you might want to remove this section as well if it's no longer applicable. */}
    </div>
  );
};

DependentsTable.propTypes = {
  dependents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      photoUrl: PropTypes.string,
      fullName: PropTypes.string.isRequired,
      gender: PropTypes.string.isRequired,
      age: PropTypes.number.isRequired,
      birthdate: PropTypes.string.isRequired,
      membershipNumber: PropTypes.string,
      localMembershipNumber: PropTypes.string.isRequired,
      relationshipToHead: PropTypes.string.isRequired,
      membershipType: PropTypes.string,
      profession: PropTypes.string,
      active: PropTypes.bool.isRequired, // Existing field
      enabled: PropTypes.bool, // Added 'enabled' field
      // Removed archive-related props
    })
  ).isRequired,
  setDependents: PropTypes.func.isRequired, // Ensure setDependents is required
  updateMember: PropTypes.func.isRequired, // Assuming this is still needed for saving edits
  renewalProgress: PropTypes.object, // New prop to track renewal progress
};

DependentsTable.defaultProps = {
  renewalProgress: {}, // Default to empty object if not provided
  // enabled is handled per dependent; no default needed here
};

export default DependentsTable;
