/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  BrowserRouter as Router,
  Route as PublicRoute,
  Redirect,
  Switch,
  Route,
} from 'react-router-dom';

import AuthenticatedRoute from 'components/authenticated-route';

import LogInView from 'views/login-view';
import LogOutView from 'views/logout-view';
import ClaimsView from 'views/claims-view';
import CheckInView from 'views/check-in-view';
import ClaimsSubmissionView from 'views/claims-submission-view';
import FacilityDashboardView from 'views/facility-dashboard-view';
import BranchDashboardView from 'views/branch-dashboard-view';

import NotFoundView from 'views/not-found-view';
import ProviderReportingStatsView from 'views/provider-reporting-view';
import ImportClaimsView from 'views/import-claims-view';
import MemberView from 'views/member-view';
import EnrollmentView from 'views/enrollment-view';
import ReimbursementView from 'views/reimbursement-view';
import StatusView from 'views/status-view';
import ReportingView from 'views/reporting-view';
import ChangePasswordForm from 'views/change-password-form';
import ClaimsReports from 'views/claims_reports';
import IndigentsPaymentsView from 'views/indigents-payments-view';


import {
  DEFAULT_PATHS_FOR_ROLE,
  ROUTES,
  ROLE_PERMISSIONS,
  enrollmentPermissions,
  membershipPermissions,
  claimsPreparationPermissions,
  claimsReimbursementPermissions,
  claimsAdjudicationPermissions,
  claimsApprovalPermissions,
  enrollmentStatisticsPermissions,
  claimsStatisticsPermissions,
  identificationPermissions,
  memberViewOnlyPermissions,
  claimsViewOnlyPermissions,
  reimbursementsViewOnlyPermissions,
  reimbursementPaymentPermissions,
  externalClaimsViewPermissions,
  facilityDashboard,
  branchDashboard,
  importClaimsPermissions,
  changePasswordPermissions,
  claimsReportsPermissions,
  indigentsPaymentsPermissions,
} from 'lib/config';
import { userHasAllPermissionsInSet } from 'lib/auth-utils';

import App from './app';
import EligibleHouseholdsView from './views/eligible-households-view';
import MemberImportView from './views/member-import-view';
import EnrollMemberView from 'views/enroll-member-view';

class ResolveRouter extends Component {
  static mapStateToProps = state => ({
    user: state.auth.user,
  })

  render() {
    const { user } = this.props;

    return (
      <Router>
        <App>
          <Switch>
            <AuthenticatedRoute
              permissionScopes={[identificationPermissions]}
              path={ROUTES.CHECK_IN.route_match}
              component={CheckInView}
            />
            <AuthenticatedRoute
              permissionScopes={[
                claimsAdjudicationPermissions,
                claimsPreparationPermissions,
                claimsViewOnlyPermissions,
                externalClaimsViewPermissions]}
              path={ROUTES.CLAIMS.route_match}
              render={props => (
                <ClaimsView
                  {...props}
                  viewOnly={!userHasAllPermissionsInSet(ROLE_PERMISSIONS[user.role], claimsAdjudicationPermissions)}
                  showExternal={userHasAllPermissionsInSet(ROLE_PERMISSIONS[user.role], externalClaimsViewPermissions)}
                />
              )}
            />
            <AuthenticatedRoute
              permissionScopes={[enrollmentStatisticsPermissions]}
              path={ROUTES.ENROLLMENT_REPORTING.route_match}
              component={EnrollmentView}
            />
            <AuthenticatedRoute
              permissionScopes={[enrollmentStatisticsPermissions]}
              path={ROUTES.ENROLL_MEMBERS.route_match}
              component={EnrollMemberView}
            />
            <AuthenticatedRoute
              permissionScopes={[
                memberViewOnlyPermissions,
                membershipPermissions,
                enrollmentPermissions,
              ]}
              path={ROUTES.MEMBERS.route_match}
              render={props => (
                <MemberView
                  {...props}
                  viewOnly={userHasAllPermissionsInSet(ROLE_PERMISSIONS[user.role], memberViewOnlyPermissions)}
                />
              )}
            />
            <AuthenticatedRoute
              permissionScopes={[claimsReimbursementPermissions]}
              path={ROUTES.REIMBURSEMENTS.route_match}
              render={props => (
                <ReimbursementView
                  {...props}
                  viewOnly={userHasAllPermissionsInSet(ROLE_PERMISSIONS[user.role], reimbursementsViewOnlyPermissions)}
                  canCompletePayment={
                    userHasAllPermissionsInSet(ROLE_PERMISSIONS[user.role], reimbursementPaymentPermissions)
                  }
                />
              )}
            />
            <AuthenticatedRoute
              permissionScopes={[claimsApprovalPermissions, claimsPreparationPermissions]}
              path={ROUTES.SUBMISSIONS.route_match}
              component={ClaimsSubmissionView}
            />
            <AuthenticatedRoute
              permissionScopes={[claimsStatisticsPermissions]}
              providerOnly
              supportsMobile
              path={ROUTES.SUMMARY.route_match}
              component={ProviderReportingStatsView}
            />
            <AuthenticatedRoute
              permissionScopes={[facilityDashboard]}
              providerOnly
              supportsMobile
              path={ROUTES.FACILITY_DASHBOARD.route_match}
              component={FacilityDashboardView}
            />
            <AuthenticatedRoute
              permissionScopes={[branchDashboard]}
              providerOnly
              supportsMobile
              path={ROUTES.BRANCH_DASHBOARD.route_match}
              component={BranchDashboardView}
            />
            <AuthenticatedRoute
              permissionScopes={[claimsPreparationPermissions]}
              path={ROUTES.STATUS.route_match}
              component={StatusView}
            />
            <AuthenticatedRoute
              permissionScopes={[importClaimsPermissions]}
              path={ROUTES.IMPORT_CLAIMS.route_match}
              component={ImportClaimsView}
            />
            <AuthenticatedRoute
              permissionScopes={[indigentsPaymentsPermissions]}
              path={ROUTES.INDIGENTS_PAYMENTS.route_match}
              component={IndigentsPaymentsView}
            />
            <AuthenticatedRoute
              permissionScopes={[changePasswordPermissions]}
              path={ROUTES.CHANGE_PASSWORD.route_match}
              component={ChangePasswordForm}
            />
            <AuthenticatedRoute
              permissionScopes={[claimsReportsPermissions]}
              path={ROUTES.CLAIMS_REPORTS.route_match}
              component={ClaimsReports}
            />
            <Route
              path={ROUTES.REPORTING.route_match}
              component={ReportingView}
            />
            <Route
              path={ROUTES.ELIGIBLE_HOUSEHOLDS.route_match}
              component={EligibleHouseholdsView}
            />
            <Route
              path={ROUTES.IMPORT_MEMBERS.route_match}
              component={MemberImportView}
            />
            <PublicRoute exact path={ROUTES.LOGIN.route_match} component={LogInView} />
            <PublicRoute exact path={ROUTES.LOGOUT.route_match} component={LogOutView} />
            {user && <Redirect exact path="/" to={`/${DEFAULT_PATHS_FOR_ROLE[user.role]}`} />}
            {!user && <Redirect exact path="/" to={ROUTES.LOGIN.base_url} />}
            <PublicRoute component={NotFoundView} />
          </Switch>
        </App>
      </Router>
    );
  }
}

export default connect(
  ResolveRouter.mapStateToProps,
)(ResolveRouter);

ResolveRouter.propTypes = {
  user: PropTypes.shape({}),
};

ResolveRouter.defaultProps = {
  user: null,
};
