import React from 'react';
import PropTypes from 'prop-types';
import Container from 'components/container';
import Header from 'components/header';

import Layout from './layout';
import LayoutContent from './layout-content';

const NewLayoutWithHeader = ({ children, steps, minWidth, maxWidth, backgroundColor, ...props }) => (
  <Layout {...props}>
    <Header steps={steps} />
    {children}
  </Layout>
);

export default NewLayoutWithHeader;

NewLayoutWithHeader.propTypes = {
  children: PropTypes.node.isRequired,
  steps: PropTypes.arrayOf(PropTypes.shape({})),
  minWidth: PropTypes.number, // New prop types
  maxWidth: PropTypes.number, // New prop types
  backgroundColor: PropTypes.string, // New prop type
};

NewLayoutWithHeader.defaultProps = {
  steps: null,
  minWidth: null,
  maxWidth: null,
  backgroundColor: '#fff', // Default background color
};
