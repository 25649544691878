import {
  FETCH_AGE_CHANGES_FAILURE,
  FETCH_AGE_CHANGES_REQUEST,
  FETCH_AGE_CHANGES_SUCCESS,
  FETCH_NUMBER_OF_CLAIMS_ADJUDICATED_FAILURE,
  FETCH_NUMBER_OF_CLAIMS_ADJUDICATED_REQUEST,
  FETCH_NUMBER_OF_CLAIMS_ADJUDICATED_SUCCESS,
  FETCH_CLAIMS_FILLED_BY_PROVIDERS_FAILURE,
  FETCH_CLAIMS_FILLED_BY_PROVIDERS_REQUEST,
  FETCH_CLAIMS_FILLED_BY_PROVIDERS_SUCCESS,
  FETCH_OPERATIONAL_REPORT_ON_REFERRALS_FAILURE,
  FETCH_OPERATIONAL_REPORT_ON_REFERRALS_REQUEST,
  FETCH_OPERATIONAL_REPORT_ON_REFERRALS_SUCCESS,
  FETCH_CLAIMS_SUMMARY_FAILURE,
  FETCH_CLAIMS_SUMMARY_REQUEST,
  FETCH_CLAIMS_SUMMARY_SUCCESS,
  FETCH_REFERRED_OUT_REQUEST,
  FETCH_REFERRED_OUT_SUCCESS,
  FETCH_REFERRED_OUT_FAILURE,
  FETCH_REFERRED_IN_REQUEST,
  FETCH_REFERRED_IN_SUCCESS,
  FETCH_REFERRED_IN_FAILURE,
  FETCH_BENEFICIARY_HISTORY_REQUEST,
  FETCH_BENEFICIARY_HISTORY_SUCCESS,
  FETCH_BENEFICIARY_HISTORY_FAILURE,
  FETCH_OWED_AND_REIMBURSED_FAILURE,
  FETCH_OWED_AND_REIMBURSED_REQUEST,
  FETCH_OWED_AND_REIMBURSED_SUCCESS,
  FETCH_PAYEE_WISE_SUMMARY_FAILURE,
  FETCH_PAYEE_WISE_SUMMARY_SUCCESS,
  FETCH_PAYEE_WISE_SUMMARY_REQUEST,
  FETCH_STATUS_WISE_SUMMARY_REQUEST,
  FETCH_STATUS_WISE_SUMMARY_SUCCESS,
  FETCH_STATUS_WISE_SUMMARY_FAILURE,
  FETCH_AGGREGATE_REPORTS_FAILURE,
  FETCH_AGGREGATE_REPORTS_SUCCESS,
  FETCH_AGGREGATE_REPORTS_REQUEST,
  FETCH_REPORT_ON_REIMBURSEMENTS_FAILURE,
  FETCH_REPORT_ON_REIMBURSEMENTS_SUCCESS,
  FETCH_REPORT_ON_REIMBURSEMENTS_REQUEST,
  FETCH_BRANCH_DASHBOARD_FAILURE,
  FETCH_BRANCH_DASHBOARD_REQUEST,
  FETCH_BRANCH_DASHBOARD_SUCCESS,
  FETCH_FACILITY_DASHBOARD_FAILURE,
  FETCH_FACILITY_DASHBOARD_REQUEST,
  FETCH_FACILITY_DASHBOARD_SUCCESS,
  FETCH_HOUSEHOLD_NUMBER_FAILURE,
  FETCH_HOUSEHOLD_NUMBER_REQUEST,
  FETCH_HOUSEHOLD_NUMBER_SUCCESS,
  FETCH_ENROLLMENT_DASHBOARD_FAILURE,
  FETCH_ENROLLMENT_DASHBOARD_REQUEST,
  FETCH_ENROLLMENT_DASHBOARD_SUCCESS,
} from './metabase-actions';

export const initialState = {
  isLoadingAgeChanges: false,
  ageChangesError: '',
  ageChanges: {},
  isLoadingNumberOfClaimsAdjudicated: false,
  numberOfClaimsAdjudicatedError: '',
  numberOfClaimsAdjudicated: {},
  isLoadingClaimsFilledByProviders: false,
  claimsFilledByProvidersError: '',
  claimsFilledByProviders: {},
  isLoadingOperationalReportOnReferrals: false,
  operationalReportOnReferralsError: '',
  operationalReportOnReferrals: {},
  isLoadingClaimsSummary: false,
  claimsSummaryError: '',
  claimsSummary: {},
  isLoadingReferredIn: false,
  referredInError: '',
  referredIn: {},
  isLoadingReferredOut: false,
  referredOutError: '',
  referredOut: {},
  isLoadingBeneficiaryHistory: false,
  beneficiaryHistoryError: '',
  beneficiaryHistory: {},
  isLoadingOwedAndReimbursed: false,
  owedAndReimbursedError: '',
  owedAndReimbursed: {},
  isLoadingPayeeWiseSummary: false,
  payeeWiseSummaryError: '',
  payeeWiseSummary: {},
  isLoadingStatusWiseSummary: false,
  statusWiseSummaryError: '',
  statusWiseSummary: {},
  isLoadingAggregateReports: false,
  aggregateReportsError: '',
  aggregateReports: {},
  isLoadingReportOnReimbursements: false,
  reportOnReimbursementsError: '',
  reportOnReimbursements: {},
  isLoadingHouseholdNumber: false,
  householdNumberError: '',
  householdNumber: {},
  isLoadingFacilityDashboard: false,
  facilityDashboardError: '',
  facilityDashboard: {},
  isLoadingBranchDashboard: false,
  branchDashboardError: '',
  branchDashboard: {},
  isLoadingEnrollmentDashboard: false,
  enrollmentDashboardError: '',
  enrollmentDashboard: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_AGE_CHANGES_REQUEST:
      return {
        ...state,
        isLoadingAgeChanges: true,
        ageChangesError: '',
      };
    case FETCH_AGE_CHANGES_SUCCESS:
      return {
        ...state,
        isLoadingAgeChanges: false,
        ageChangesError: '',
        ageChanges: action.response,
      };
    case FETCH_AGE_CHANGES_FAILURE:
      return {
        ...state,
        isLoadingAgeChanges: false,
        ageChangesError: action.errorMessage,
      };
    case FETCH_NUMBER_OF_CLAIMS_ADJUDICATED_REQUEST:
      return {
        ...state,
        isLoadingNumberOfClaimsAdjudicated: true,
        numberOfClaimsAdjudicatedErrorError: '',
      };
    case FETCH_NUMBER_OF_CLAIMS_ADJUDICATED_SUCCESS:
      return {
        ...state,
        isLoadingNumberOfClaimsAdjudicated: false,
        numberOfClaimsAdjudicatedError: '',
        numberOfClaimsAdjudicated: action.response,
      };
    case FETCH_NUMBER_OF_CLAIMS_ADJUDICATED_FAILURE:
      return {
        ...state,
        isLoadingNumberOfClaimsAdjudicated: false,
        numberOfClaimsAdjudicatedError: action.errorMessage,
      };

    // REPORT 3 => FETCH CLAIMS FILLED BY PROVIDERS
    case FETCH_CLAIMS_FILLED_BY_PROVIDERS_REQUEST:
      return {
        ...state,
        isLoadingClaimsFilledByProviders: true,
        claimsFilledByProviders: '',
      };
    case FETCH_CLAIMS_FILLED_BY_PROVIDERS_SUCCESS:
      return {
        ...state,
        isLoadingClaimsFilledByProviders: false,
        claimsFilledByProvidersError: '',
        claimsFilledByProviders: action.response,
      };
    case FETCH_CLAIMS_FILLED_BY_PROVIDERS_FAILURE:
      return {
        ...state,
        isLoadingClaimsFilledByProviders: false,
        claimsFilledByProvidersError: action.errorMessage,
      };


      // REPORT 4 => FETCH OPERATION REPORT ON REFERRALS
    case FETCH_OPERATIONAL_REPORT_ON_REFERRALS_REQUEST:
      return {
        ...state,
        isLoadingOperationalReportOnReferrals: true,
        operationalReportOnReferrals: '',
      };
    case FETCH_OPERATIONAL_REPORT_ON_REFERRALS_SUCCESS:
      return {
        ...state,
        isLoadingOperationalReportOnReferrals: false,
        operationalReportOnReferralsError: '',
        operationalReportOnReferrals: action.response,
      };
    case FETCH_OPERATIONAL_REPORT_ON_REFERRALS_FAILURE:
      return {
        ...state,
        isLoadingOperationalReportOnReferrals: false,
        operationalReportOnReferralsError: action.errorMessage,
      };

    // REPORT 5 => CLAIMS SUMMARY
    case FETCH_CLAIMS_SUMMARY_REQUEST:
      return {
        ...state,
        isLoadingClaimsSummary: true,
        claimsSummary: '',
      };
    case FETCH_CLAIMS_SUMMARY_SUCCESS:
      return {
        ...state,
        isLoadingClaimsSummary: false,
        claimsSummaryError: '',
        claimsSummary: action.response,
      };
    case FETCH_CLAIMS_SUMMARY_FAILURE:
      return {
        ...state,
        isLoadingClaimsSummary: false,
        claimsSummaryError: action.errorMessage,
      };

      // REPORT 6 => REFERRED IN
    case FETCH_REFERRED_IN_REQUEST:
      return {
        ...state,
        isLoadingReferredIn: true,
        referredIn: '',
      };
    case FETCH_REFERRED_IN_SUCCESS:
      return {
        ...state,
        isLoadingReferredIn: false,
        referredInError: '',
        referredIn: action.response,
      };
    case FETCH_REFERRED_IN_FAILURE:
      return {
        ...state,
        isLoadingReferredIn: false,
        referredInError: action.errorMessage,
      };

      // REPORT 6 => REFERRED OUT
    case FETCH_REFERRED_OUT_REQUEST:
      return {
        ...state,
        isLoadingReferredOut: true,
        referredOut: '',
      };
    case FETCH_REFERRED_OUT_SUCCESS:
      return {
        ...state,
        isLoadingReferredOut: false,
        referredOutError: '',
        referredOut: action.response,
      };
    case FETCH_REFERRED_OUT_FAILURE:
      return {
        ...state,
        isLoadingReferredOut: false,
        referredOutError: action.errorMessage,
      };


      // REPORT 7 => BENEFICIARY HISTORY
    case FETCH_BENEFICIARY_HISTORY_REQUEST:
      return {
        ...state,
        isLoadingBeneficiaryHistory: true,
        beneficiaryHistory: '',
      };
    case FETCH_BENEFICIARY_HISTORY_SUCCESS:
      return {
        ...state,
        isLoadingBeneficiaryHistory: false,
        beneficiaryHistoryError: '',
        beneficiaryHistory: action.response,
      };
    case FETCH_BENEFICIARY_HISTORY_FAILURE:
      return {
        ...state,
        isLoadingBeneficiaryHistory: false,
        beneficiaryHistoryError: action.errorMessage,
      };


      // REPORT 8 => OWED AND REIMBURSED
    case FETCH_OWED_AND_REIMBURSED_REQUEST:
      return {
        ...state,
        isLoadingOwedAndReimbursed: true,
        owedAndReimbursed: '',
      };
    case FETCH_OWED_AND_REIMBURSED_SUCCESS:
      return {
        ...state,
        isLoadingOwedAndReimbursed: false,
        owedAndReimbursedError: '',
        owedAndReimbursed: action.response,
      };
    case FETCH_OWED_AND_REIMBURSED_FAILURE:
      return {
        ...state,
        isLoadingOwedAndReimbursed: false,
        owedAndReimbursedError: action.errorMessage,
      };

      // REPORT 9.1 => PAYEE_WISE_SUMMARY
    case FETCH_PAYEE_WISE_SUMMARY_REQUEST:
      return {
        ...state,
        isLoadingPayeeWiseSummary: true,
        payeeWiseSummary: '',
      };
    case FETCH_PAYEE_WISE_SUMMARY_SUCCESS:
      return {
        ...state,
        isLoadingPayeeWiseSummary: false,
        payeeWiseSummaryError: '',
        payeeWiseSummary: action.response,
      };
    case FETCH_PAYEE_WISE_SUMMARY_FAILURE:
      return {
        ...state,
        isLoadingPayeeWiseSummary: false,
        payeeWiseSummaryError: action.errorMessage,
      };

      // REPORT 9.2 => STATUS_WISE_SUMMARY
    case FETCH_STATUS_WISE_SUMMARY_REQUEST:
      return {
        ...state,
        isLoadingStatusWiseSummary: true,
        statusWiseSummary: '',
      };
    case FETCH_STATUS_WISE_SUMMARY_SUCCESS:
      return {
        ...state,
        isLoadingStatusWiseSummary: false,
        statusWiseSummaryError: '',
        statusWiseSummary: action.response,
      };
    case FETCH_STATUS_WISE_SUMMARY_FAILURE:
      return {
        ...state,
        isLoadingStatusWiseSummary: false,
        statusWiseSummaryError: action.errorMessage,
      };

      // REPORT 9.3 => AGGREGATE_REPORTS
    case FETCH_AGGREGATE_REPORTS_REQUEST:
      return {
        ...state,
        isLoadingAggregateReports: true,
        aggregateReports: '',
      };
    case FETCH_AGGREGATE_REPORTS_SUCCESS:
      return {
        ...state,
        isLoadingAggregateReports: false,
        aggregateReportsError: '',
        aggregateReports: action.response,
      };
    case FETCH_AGGREGATE_REPORTS_FAILURE:
      return {
        ...state,
        isLoadingAggregateReports: false,
        aggregateReportsError: action.errorMessage,
      };

    // REPORT 10 => REPORT_ON_REIMBURSEMENTS
    case FETCH_REPORT_ON_REIMBURSEMENTS_REQUEST:
      return {
        ...state,
        isLoadingReportOnReimbursements: true,
        reportOnReimbursements: '',
      };
    case FETCH_REPORT_ON_REIMBURSEMENTS_SUCCESS:
      return {
        ...state,
        isLoadingReportOnReimbursements: false,
        reportOnReimbursementsError: '',
        reportOnReimbursements: action.response,
      };
    case FETCH_REPORT_ON_REIMBURSEMENTS_FAILURE:
      return {
        ...state,
        isLoadingReportOnReimbursements: false,
        reportOnReimbursementsError: action.errorMessage,
      };

    // REPORT 13 => HOUSEHOLD NUMBER
    case FETCH_HOUSEHOLD_NUMBER_REQUEST:
      return {
        ...state,
        isLoadingHouseholdNumber: true,
        householdNumber: '',
      };
    case FETCH_HOUSEHOLD_NUMBER_SUCCESS:
      return {
        ...state,
        isLoadingHouseholdNumber: false,
        householdNumberError: '',
        householdNumber: action.response,
      };
    case FETCH_HOUSEHOLD_NUMBER_FAILURE:
      return {
        ...state,
        isLoadingHouseholdNumber: false,
        householdNumberError: action.errorMessage,
      };

    // FETCH FACILTY DASHBOARD
    case FETCH_FACILITY_DASHBOARD_REQUEST:
      return {
        ...state,
        isLoadingFacilityDashboard: true,
        facilityDashboard: '',
      };
    case FETCH_FACILITY_DASHBOARD_SUCCESS:
      return {
        ...state,
        isLoadingFacilityDashboard: false,
        facilityDashboardError: '',
        facilityDashboard: action.response,
      };
    case FETCH_FACILITY_DASHBOARD_FAILURE:
      return {
        ...state,
        isLoadingFacilityDashboard: false,
        facilityDashboardError: action.errorMessage,
      };

      // FETCH BRANCH DASHBOARD
    case FETCH_BRANCH_DASHBOARD_REQUEST:
      return {
        ...state,
        isLoadingBranchDashboard: true,
        branchDashboard: '',
      };
    case FETCH_BRANCH_DASHBOARD_SUCCESS:
      return {
        ...state,
        isLoadingBranchDashboard: false,
        branchDashboardError: '',
        branchDashboard: action.response,
      };
    case FETCH_BRANCH_DASHBOARD_FAILURE:
      return {
        ...state,
        isLoadingBranchDashboard: false,
        branchDashboardError: action.errorMessage,
      };

      // REPORT 11 FETCH ENROLLMENT DASHBOARD
    case FETCH_ENROLLMENT_DASHBOARD_REQUEST:
      return {
        ...state,
        isLoadingEnrollmentDashboard: true,
        enrollmentDashboard: '',
      };
    case FETCH_ENROLLMENT_DASHBOARD_SUCCESS:
      return {
        ...state,
        isLoadingEnrollmentDashboard: false,
        enrollmentDashboardError: '',
        enrollmentDashboard: action.response,
      };
    case FETCH_ENROLLMENT_DASHBOARD_FAILURE:
      return {
        ...state,
        isLoadingEnrollmentDashboard: false,
        enrollmentDashboardError: action.errorMessage,
      };

    default:
      return state;
  }
}
