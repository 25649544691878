import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { AGE_UNITS } from 'lib/config';

import Box from 'components/box';
import { TextField, SelectField } from 'components/inputs';
import { Text } from 'components/text';

import GenderField from 'containers/edit-object-form/components/gender-field';
import ClaimAdministrativeDivisionPicker from 'components/inputs/claim-administrative-division-picker';


const ageUnitOptions = [
  { value: AGE_UNITS.YEARS, name: 'Years' },
  { value: AGE_UNITS.MONTHS, name: 'Months' },
  { value: AGE_UNITS.DAYS, name: 'Days' },
];

const MemberForm = ({
  membershipNumber,
  fullName,
  age,
  handleFieldChange,
  errors,
  membershipNumberEditable = true,
  administrativeDivisions,
  handleChange,
  administrativeDivisionId,
}) => (
  <Fragment>
    <Box marginBottom={5}>
      <ClaimAdministrativeDivisionPicker
        handleChange={handleChange}
        administrativeDivisions={administrativeDivisions}
        administrativeDivisionId={administrativeDivisionId}
      />
      {errors.administrativeDivisionId && (
        <Text color="red.6">{errors.administrativeDivisionId}</Text>
      )}
    </Box>
    <Box marginBottom={5}>
      <TextField
        value={membershipNumber}
        label="CBHI ID"
        name="membershipNumber"
        onChange={handleFieldChange}
        disabled={!membershipNumberEditable}
        error={errors.membershipNumber && (
          membershipNumberEditable
            ? errors.membershipNumber
            : `${errors.membershipNumber}. Must cancel and start a new search.`
        )}
      />
    </Box>
    <Box marginBottom={5}>
      <GenderField
        label="Gender"
        errors={errors}
        handleChange={handleFieldChange}
      />
    </Box>
    <Box marginBottom={5}>
      <TextField
        value={fullName}
        label="Name"
        name="fullName"
        onChange={handleFieldChange}
        error={errors.fullName}
      />
    </Box>
    <Box flex marginBottom={5}>
      <Box width="100%" marginRight="10px">
        <TextField
          value={age}
          label="Age"
          name="age"
          onChange={handleFieldChange}
          error={errors.age}
        />
      </Box>
      <Box width="100%">
        <SelectField
          name="ageUnit"
          error={errors.ageUnit}
          options={ageUnitOptions}
          onChange={handleFieldChange}
        />
      </Box>
    </Box>
  </Fragment>
);

MemberForm.propTypes = {
  membershipNumber: PropTypes.string.isRequired,
  fullName: PropTypes.string.isRequired,
  age: PropTypes.string.isRequired,
  handleFieldChange: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    membershipNumber: PropTypes.string,
    gender: PropTypes.string,
    name: PropTypes.string,
    age: PropTypes.string,
    ageUnit: PropTypes.string,
  }).isRequired,
  membershipNumberEditable: PropTypes.bool,
  administrativeDivisions: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    level: PropTypes.string.isRequired,
    code: PropTypes.string,
  }),
  administrativeDivisionId: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
};

MemberForm.defaultProps = {
  membershipNumberEditable: true,
  administrativeDivisionId: null,
  administrativeDivisions: [],
};

export default MemberForm;
