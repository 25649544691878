/* eslint-disable max-len */
/* eslint-disable prefer-destructuring */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { find } from 'lodash/fp';
import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import Select from 'react-select';

import { ADMIN_DIVISIONS } from 'lib/config';
import { userSelector } from 'store/auth/auth-selectors';

import Box from 'components/box';

const EMPTY_VALUE = '-1';

class AdministrativeDivisionPicker extends Component {
  handleAdministrativeDivisionChange = (e) => {
    const { handleChange } = this.props;
    let value = e.value === EMPTY_VALUE ? undefined : e.value;
    if (!value) {
      const divisionLevel = e.level;
      const selectedDivisions = this.selectedDivisions();
      switch (divisionLevel) {
        case ADMIN_DIVISIONS.COUNTRY:
          value = selectedDivisions[0];
          break;
        case ADMIN_DIVISIONS.REGION:
          value = selectedDivisions[1];
          break;
        case ADMIN_DIVISIONS.ZONE:
          value = selectedDivisions[2];
          break;
        case ADMIN_DIVISIONS.WOREDA:
          value = selectedDivisions[3];
          break;
        case ADMIN_DIVISIONS.KEBELE:
          value = selectedDivisions[4];
          break;
        case ADMIN_DIVISIONS.GOTE:
          value = selectedDivisions[5];
          break;
        default:
          break;
      }
    }
    handleChange(this.addNumberToArray(this.selectedDivisions(), value));
  }

  addNumberToArray = (numbers, newNumber) => {
    if (_.includes(numbers, newNumber)) {
      const index = numbers.indexOf(newNumber);
      if (index !== -1) {
        // Return the number before the found number, if it exists
        return index > 0 ? numbers[index - 1] : null;
      }
      return newNumber;
    }
    return newNumber;
  }

  selectedDivisions = () => {
    const { administrativeDivisions, administrativeDivisionId } = this.props;
    const ancestors = [];
    let ancestor = find(ad => ad.id === administrativeDivisionId)(administrativeDivisions);
    const findParent = find(ad => ad.id === ancestor?.parentId);
    while (ancestor) {
      ancestors.unshift(ancestor.id);
      ancestor = findParent(administrativeDivisions);
    }
    return ancestors;
  }

  render() {
    const { currentUser, administrativeDivisions } = this.props;
    const { administrativeDivisionLevel } = currentUser;

    let selectedDivisions = this.selectedDivisions();
    // eslint-disable-next-line no-unused-vars
    const countryId = selectedDivisions.shift();
    const regionId = selectedDivisions.shift();
    const zoneId = selectedDivisions.shift();
    const woredaId = selectedDivisions.shift();
    const kebeleId = selectedDivisions.shift();
    const goteId = selectedDivisions.shift();
    selectedDivisions = this.selectedDivisions();

    // Function to filter out the 'All' option based on the user's level
    const filterAllOption = (options, level, disableAllFilter = false) => (administrativeDivisionLevel === level ? options : [{ isDisabled: (options.length === 0) || disableAllFilter, value: EMPTY_VALUE, label: `All ${level}s`, level }, ...options]);

    const regionOptions = filterAllOption(
      administrativeDivisions
        .filter(adminDivision => adminDivision.level === ADMIN_DIVISIONS.REGION)
        .map(division => ({
          value: division.id,
          label: division.name,
          level: division.level,
        })),
      ADMIN_DIVISIONS.REGION,
      selectedDivisions.length === 1,
    );

    const zoneOptions = filterAllOption(
      administrativeDivisions
        .filter(adminDivision => adminDivision.parentId === regionId && adminDivision.level === ADMIN_DIVISIONS.ZONE)
        .map(division => ({
          value: division.id,
          label: division.name,
          level: division.level,
        })),
      ADMIN_DIVISIONS.ZONE,
      selectedDivisions.length === 2,
    );

    const woredaOptions = filterAllOption(
      administrativeDivisions
        .filter(adminDivision => adminDivision.parentId === zoneId && adminDivision.level === ADMIN_DIVISIONS.WOREDA)
        .map(division => ({
          value: division.id,
          label: division.name,
          level: division.level,
        })),
      ADMIN_DIVISIONS.WOREDA,
      selectedDivisions.length === 3,
    );

    const kebeleOptions = filterAllOption(
      administrativeDivisions
        .filter(adminDivision => adminDivision.parentId === woredaId && adminDivision.level === ADMIN_DIVISIONS.KEBELE)
        .map(division => ({
          value: division.id,
          label: division.name,
          level: division.level,
        })),
      ADMIN_DIVISIONS.KEBELE,
      selectedDivisions.length === 4,
    );

    const goteOptions = filterAllOption(
      administrativeDivisions
        .filter(adminDivision => adminDivision.parentId === kebeleId && adminDivision.level === ADMIN_DIVISIONS.GOTE)
        .map(division => ({
          value: division.id,
          label: division.name,
          level: division.level,
        })),
      ADMIN_DIVISIONS.GOTE,
      selectedDivisions.length === 5,
    );

    const findRegion = id => regionOptions.find(z => z.value === id);
    const findZone = id => zoneOptions.find(z => z.value === id);
    const findWoreda = id => woredaOptions.find(z => z.value === id);
    const findKebele = id => kebeleOptions.find(z => z.value === id);
    const findGote = id => goteOptions.find(z => z.value === id);

    // Control the enabled/disabled state of each dropdown based on the user's administrative division level
    const isRegionDisabled = administrativeDivisionLevel !== ADMIN_DIVISIONS.COUNTRY;
    const isZoneDisabled = _.includes([ADMIN_DIVISIONS.ZONE, ADMIN_DIVISIONS.WOREDA, ADMIN_DIVISIONS.KEBELE, ADMIN_DIVISIONS.GOTE], administrativeDivisionLevel);
    const isWoredaDisabled = _.includes([ADMIN_DIVISIONS.WOREDA, ADMIN_DIVISIONS.KEBELE, ADMIN_DIVISIONS.GOTE], administrativeDivisionLevel);
    const isKebeleDisabled = _.includes([ADMIN_DIVISIONS.KEBELE, ADMIN_DIVISIONS.GOTE], administrativeDivisionLevel);
    const isGoteDisabled = _.includes([ADMIN_DIVISIONS.GOTE], administrativeDivisionLevel) || (_.includes([ADMIN_DIVISIONS.KEBELE], administrativeDivisionLevel) && goteOptions.length === 1);

    return (
      <Grid container>
        <Grid item xs={3}>
          <Box paddingRight={3} paddingBottom={3}>
            <Select
              key={ADMIN_DIVISIONS.REGION}
              value={[findRegion(regionId || EMPTY_VALUE)]}
              options={regionOptions}
              isClearable={false}
              onChange={this.handleAdministrativeDivisionChange}
              placeholder="Member Region *"
              isDisabled={isRegionDisabled}
            />
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Box paddingRight={3} paddingBottom={3}>
            <Select
              key={ADMIN_DIVISIONS.ZONE}
              value={[findZone(zoneId || EMPTY_VALUE)]}
              options={zoneOptions}
              isClearable={false}
              onChange={this.handleAdministrativeDivisionChange}
              placeholder="Member Zone *"
              isDisabled={isZoneDisabled}
            />
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box paddingRight={3} paddingBottom={3}>
            <Select
              key={ADMIN_DIVISIONS.WOREDA}
              value={[findWoreda(woredaId || EMPTY_VALUE)]}
              options={woredaOptions}
              isClearable={false}
              onChange={this.handleAdministrativeDivisionChange}
              placeholder="Member Woreda *"
              isDisabled={isWoredaDisabled}
            />
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box paddingRight={3} paddingBottom={3}>
            <Select
              key={ADMIN_DIVISIONS.ZONE}
              value={[findKebele(kebeleId || EMPTY_VALUE)]}
              options={kebeleOptions}
              isClearable={false}
              onChange={this.handleAdministrativeDivisionChange}
              placeholder="Member Kebele *"
              isDisabled={isKebeleDisabled}
            />
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box paddingBottom={3}>
            <Select
              key={ADMIN_DIVISIONS.GOTE}
              value={[findGote(goteId || EMPTY_VALUE)]}
              options={goteOptions}
              isClearable={false}
              onChange={this.handleAdministrativeDivisionChange}
              placeholder="Member Gote *"
              isDisabled={isGoteDisabled}
            />
          </Box>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => ({
  currentUser: userSelector(state),
});

AdministrativeDivisionPicker.propTypes = {
  handleChange: PropTypes.func.isRequired,
  administrativeDivisions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  administrativeDivisionId: PropTypes.number,
  currentUser: PropTypes.shape({
    administrativeDivisionLevel: PropTypes.string,
  }).isRequired,
};

AdministrativeDivisionPicker.defaultProps = {
  administrativeDivisionId: undefined,
};

export default connect(mapStateToProps)(AdministrativeDivisionPicker);
