import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/modal';
import { TextInput } from 'components/inputs';
import Button from 'components/button';
import Box from 'components/box';
import { Text } from 'components/text';
import { StackHorizontal, StackVertical } from '../../../components/eligible-household-elements';
import { connect } from 'react-redux';
import {
    updateEligibleHousehold,
} from 'store/eligible-households/eligible-households-actions';
import { addToast as addToastAction } from 'store/toasts/toasts-actions';
import { convertGregorianToEthiopian, convertIsoToEthiopian } from '../../../lib/utils';

class EditEligibleHouseholdModal extends Component {
    state = {
        expectedHouseholds: this.props.householdToEdit.expectedHouseholds || '',
        errors: {},
    };


    handleInputChange = (event) => {
        let value = event.target.value;
        if (isNaN(value)) return;
        // Check if the value contains non-digit characters, and if so, return immediately
        if (value.match(/[^0-9]/)) return;

        if (value < 0) {
            value = 0; // or you can set it to the previous value or whatever you see fit
        }
        this.setState({ expectedHouseholds: value });

    };

    validate = () => {
        const { expectedHouseholds } = this.state;
        let errors = {};
        if (!expectedHouseholds) errors.expectedHouseholds = 'Expected Households is required';
        this.setState({ errors });
        return Object.keys(errors).length === 0;
    };


    handleEditEligibleHousehold = (params) => {
        const { updateEligibleHousehold, householdToEdit, addToast, onRequestClose } = this.props;
        const data = { id: householdToEdit?.id, eligibleHousehold: params };

        updateEligibleHousehold(data).then((action) => {
            if (action?.errorMessage) {
                this.setState({ serverError: true });
                addToast({ message: `Eligible household could not be updated. Check your internet connection and try again.` });
            } else {
                onRequestClose();
                addToast({ message: `Eligible household updated successfully.` });
            }
        });
    };

    handleSubmit = () => {
        if (!this.validate()) return;
        const { expectedHouseholds } = this.state;
        this.handleEditEligibleHousehold({ expected_households: expectedHouseholds });
    };

    handleCancel = () => {
        const { onRequestClose } = this.props;
        onRequestClose();
    };

    render() {
        const { onRequestClose, isLoading, householdToEdit } = this.props;
        const { administrativeDivision, enrollmentPeriod, createdAt, updatedAt } = householdToEdit;

        const { expectedHouseholds, errors } = this.state;
        const isSubmitDisabled = isLoading || !expectedHouseholds || expectedHouseholds === householdToEdit?.expectedHouseholds;
        const isCancelDisabled = isLoading;
        const { startDate, endDate, coverageStartDate, coverageEndDate } = enrollmentPeriod;

        const startDateEth = convertGregorianToEthiopian(startDate);
        const endDateEth = convertGregorianToEthiopian(endDate);
        const coverageStartDateEth = convertGregorianToEthiopian(coverageStartDate);
        const coverageEndDateEth = convertGregorianToEthiopian(coverageEndDate);
        const createdAtEth = convertIsoToEthiopian(createdAt);
        const updatedAtEth = convertIsoToEthiopian(updatedAt);


        return (
            <Modal
                maxWidth="400px"
                title="Edit Eligible Household"
                onRequestClose={onRequestClose}
            >
                <StackVertical spaceY="1.5rem">
                    <StackHorizontal spaceX="0.5rem" alignItems="center">
                        <Text>Admin Division Level:</Text>
                        <Text>{administrativeDivision?.name}</Text>
                    </StackHorizontal>
                    <StackHorizontal spaceX="0.5rem" alignItems="center">
                        <Text>Admin Division Name:</Text>
                        <Text>{administrativeDivision?.level}</Text>
                    </StackHorizontal>
                    <StackVertical spaceY="0.5rem" alignItems="center">
                        <Text> Start Date - End Date:</Text>
                        <Text>{`${startDateEth} - ${endDateEth}`}</Text>
                    </StackVertical>
                    <StackVertical spaceY="0.5rem" alignItems="center">
                        <Text> Coverage Start Date - Coverage End Date:</Text>
                        <Text>{`${coverageStartDateEth} - ${coverageEndDateEth}`}</Text>
                    </StackVertical>
                    <StackHorizontal spaceX="0.3rem" alignItems="center">
                        <Text>Created At:</Text>
                        <Text>{`${createdAtEth}`}</Text>
                    </StackHorizontal>
                    <StackHorizontal spaceX="0.3rem" alignItems="center">
                        <Text>Last Updated At:</Text>
                        <Text>{`${updatedAtEth}`}</Text>
                    </StackHorizontal>

                    <StackVertical spaceY="0.5rem">
                        <Text>Expected Households</Text>
                        <TextInput
                            type="number"
                            label="Expected Households"
                            placeholder="Enter Expected Households"
                            value={expectedHouseholds}
                            onChange={this.handleInputChange}
                            error={errors.expectedHouseholds}
                        />
                    </StackVertical>
                    <Box marginTop="medium">
                        <StackHorizontal spaceX="0.5rem">
                            <Button onClick={this.handleCancel} disabled={isCancelDisabled}>
                                Cancel
                            </Button>
                            <Button primary onClick={this.handleSubmit} disabled={isSubmitDisabled}>
                                {
                                    isLoading ? "Submitting..." : "Submit"
                                }
                            </Button>
                        </StackHorizontal>
                    </Box>
                </StackVertical>
            </Modal>
        );
    }
}

const mapDispatchToProps = dispatch => ({
    updateEligibleHousehold: household => dispatch(updateEligibleHousehold(household)),
    addToast: (message, type) => dispatch(addToastAction(message, type)),
});

export default connect(null, mapDispatchToProps)(EditEligibleHouseholdModal);

EditEligibleHouseholdModal.propTypes = {
    onRequestClose: PropTypes.func.isRequired,
    updateEligibleHousehold: PropTypes.func.isRequired,
    addToast: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    householdToEdit: PropTypes.object.isRequired,
};
