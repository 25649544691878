import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Layout } from 'components/layouts';
import { fetchBranchDashboard } from 'store/metabase/metabase-actions';
import LoadingIndicator from 'components/loading-indicator';
import Box from 'components/box';
import { Alert } from 'components/alerts';
import { formatDate } from 'lib/formatters/date';
import Container from 'components/container';
import { Text } from 'components/text';
import { DatePicker } from 'components/inputs';
import styled from '@emotion/styled';


class BranchDashboardView extends Component {
  static mapDispatchToProps = dispatch => ({
    loadBranchDashboard: (startDate, endDate) => dispatch(fetchBranchDashboard(startDate, endDate)),
  })

  static mapStateToProps = state => ({
    branchDashboard: state.metabase.branchDashboard,
    isLoadingBranchDashboard: state.metabase.isLoadingBranchDashboard,
    branchDashboardError: state.metabase.branchDashboardError,
  })

  constructor(props) {
    super(props);

    this.state = { startDate: null, endDate: null };
  }

  componentDidMount() {
    const { loadBranchDashboard } = this.props;
    const { startDate, endDate } = this.state;

    loadBranchDashboard(startDate, endDate);
  }

  handleDateChange = (formattedDate, ethiopianDate, isStart) => {
    if (isStart) {
      this.setState({ startDate: formattedDate }, () => this.fetchStats());
    } else {
      this.setState({ endDate: formattedDate }, () => this.fetchStats());
    }
  }

  fetchStats() {
    const { loadBranchDashboard } = this.props;
    const { startDate, endDate } = this.state;

    const formattedStartDate = startDate ? startDate.format() : null;
    const formattedEndDate = endDate ? endDate.format() : null;
    loadBranchDashboard(formattedStartDate, formattedEndDate);
  }

  renderDashboard() {
    const { branchDashboard, isLoadingBranchDashboard, branchDashboardError } = this.props;

    if (isLoadingBranchDashboard) return <LoadingIndicator noun="Metabase Dashboard" />;

    if (branchDashboardError) {
      return (
        <Container>
          <Box marginBottom={4}><Alert>{branchDashboardError}</Alert></Box>
        </Container>
      );
    }

    return (
      <Container>
        <iframe
          // eslint-disable-next-line react/prop-types
          src={branchDashboard.iframeUrl}
          frameBorder="0"
          width="1000"
          height="500"
          title="Metabase Dashboard"
        />
      </Container>

    );
  }

  render() {
    const { isLoadingBranchDashboard } = this.props;
    const { startDate, endDate } = this.state;
    let reportHeaderText;

    if (!startDate && !endDate) {
      reportHeaderText = 'Last three months';
    } else {
      reportHeaderText = `${startDate ? formatDate(startDate) : 'Before three months'} to ${endDate ? formatDate(endDate) : 'End Of Time'}`;
    }

    return (
      <Layout pageTitle="Metabase" supportsMobile>
        <DropdownBar>
          <Box flex justifyContent="flex-end" alignItems="center">
            <Box marginRight={4}>
              <DatePicker
                label="Start Date"
                onChange={(formattedDate, rawDate) => this.handleDateChange(formattedDate, rawDate, true)}
                name="start-date"
                disabled={isLoadingBranchDashboard}
              />
            </Box>
            <Box marginRight={4}>
              <DatePicker
                label="End Date"
                onChange={(formattedDate, rawDate) => this.handleDateChange(formattedDate, rawDate, false)}
                name="end-date"
                disabled={isLoadingBranchDashboard}
              />
            </Box>
          </Box>
        </DropdownBar>
        <Container>
          <ReportHeader>
            <Text fontSize={6}>{reportHeaderText}</Text>
            <Box marginTop={2}>
              <Text fontSize={2}>Report Period</Text>
            </Box>
          </ReportHeader>
        </Container>
        {this.renderDashboard()}
      </Layout>
    );
  }
}


export default connect(
  BranchDashboardView.mapStateToProps,
  BranchDashboardView.mapDispatchToProps,
)(BranchDashboardView);

BranchDashboardView.propTypes = {
  loadBranchDashboard: PropTypes.func.isRequired,
  branchDashboard: PropTypes.shape({}),
  branchDashboardError: PropTypes.string.isRequired,
  isLoadingBranchDashboard: PropTypes.bool.isRequired,
};

BranchDashboardView.defaultProps = {
  branchDashboard: {},
};


const DropdownBar = styled.div`
  background-color: ${props => props.theme.colors.gray[0]};
  border-bottom: 1px #f2f2f2 solid;
  padding: 16px;
`;

const ReportHeader = styled.div`
  border-bottom: 1px #f2f2f2 solid;
  padding-top: 16px;
  padding-bottom: 16px;
  margin-left: 16px;
  margin-right: 16px;
`;
