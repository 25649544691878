/* eslint-disable max-len */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { filter, flow, map, sortBy } from 'lodash/fp';
import Grid from '@material-ui/core/Grid';

import { ADMIN_DIVISIONS } from 'lib/config';

import Box from 'components/box';
import { SelectField } from 'components/inputs';

const EMPTY_VALUE = '-1';

class ClaimAdministrativeDivisionPicker extends Component {
  handleAdministrativeDivisionChange = (e) => {
    const { handleChange } = this.props;
    const value = e.target.value === EMPTY_VALUE ? undefined : parseInt(e.target.value, 10);
    handleChange(value);
  }


  render() {
    const { administrativeDivisions, administrativeDivisionId } = this.props;
    let regionId = EMPTY_VALUE;
    let zoneId = EMPTY_VALUE;
    let woredaId = EMPTY_VALUE;

    if (administrativeDivisionId) {
      const selectedDivision = filter(adminDivision => adminDivision.id === administrativeDivisionId)(administrativeDivisions)[0];
      if (selectedDivision.level === ADMIN_DIVISIONS.REGION) {
        regionId = selectedDivision.id;
      } else if (selectedDivision.level === ADMIN_DIVISIONS.ZONE) {
        regionId = selectedDivision.parentId;
        zoneId = selectedDivision.id;
      } else if (selectedDivision.level === ADMIN_DIVISIONS.WOREDA) {
        const selectedDivisionParent = filter(adminDivision => adminDivision.id === selectedDivision.parentId)(administrativeDivisions)[0];
        woredaId = selectedDivision.id;
        zoneId = selectedDivisionParent.id;
        regionId = selectedDivisionParent.parentId;
      }
    }

    const regionOptions = flow(
      filter(adminDivision => adminDivision.level === ADMIN_DIVISIONS.REGION),
      map(division => ({
        value: division.id,
        name: division.name,
      })),
      sortBy(adminDivision => adminDivision.value),
    )(administrativeDivisions);

    const zoneOptions = flow(
      filter(adminDivision => adminDivision.level === ADMIN_DIVISIONS.ZONE && adminDivision.parentId === regionId),
      map(division => ({
        value: division.id,
        name: division.name,
      })),
      sortBy(adminDivision => adminDivision.value),
    )(administrativeDivisions);

    const woredaOptions = flow(
      filter(adminDivision => adminDivision.level === ADMIN_DIVISIONS.WOREDA && adminDivision.parentId === zoneId),
      map(division => ({
        value: division.id,
        name: division.name,
      })),
      sortBy(adminDivision => adminDivision.value),
    )(administrativeDivisions);


    const regionDropdownOptions = [
      { value: EMPTY_VALUE, name: 'Select Region' },
      ...regionOptions,
    ];

    const zoneDropdownOptions = [
      { value: EMPTY_VALUE, name: 'Select Zone' },
      ...zoneOptions,
    ];

    const woredaDropdownOptions = [
      { value: EMPTY_VALUE, name: 'Select Woreda' },
      ...woredaOptions,
    ];


    return (
      <Grid container>
        <Grid item xs={4}>
          <Box paddingRight={3} paddingBottom={3}>
            <SelectField
              key={ADMIN_DIVISIONS.REGION}
              name={ADMIN_DIVISIONS.REGION}
              label="Member Region *"
              options={regionDropdownOptions}
              value={regionId}
              onChange={this.handleAdministrativeDivisionChange}
            />
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box paddingRight={3} paddingBottom={3}>
            <SelectField
              key={ADMIN_DIVISIONS.ZONE}
              name={ADMIN_DIVISIONS.ZONE}
              label="Member Zone *"
              options={zoneDropdownOptions}
              value={zoneId}
              onChange={this.handleAdministrativeDivisionChange}
            />
          </Box>
        </Grid>
        <Grid item xs={4}>
          <Box paddingRight={3} paddingBottom={3}>
            <SelectField
              key={ADMIN_DIVISIONS.WOREDA}
              name={ADMIN_DIVISIONS.WOREDA}
              label="Member woreda"
              options={woredaDropdownOptions}
              value={woredaId || EMPTY_VALUE}
              onChange={this.handleAdministrativeDivisionChange}
            />
          </Box>
        </Grid>
      </Grid>
    );
  }
}

export default ClaimAdministrativeDivisionPicker;

ClaimAdministrativeDivisionPicker.propTypes = {
  handleChange: PropTypes.func.isRequired,
  administrativeDivisions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  administrativeDivisionId: PropTypes.number,
};

ClaimAdministrativeDivisionPicker.defaultProps = {
  administrativeDivisionId: undefined,
};
