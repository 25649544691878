// src/components/MemberRenewContainer/DependentsSection.jsx
import React from 'react';
import DependentsTable from './DependentsTable';

const DependentsSection = ({ dependents, setDependents, handleAddMember, updateMember, renewalProgress }) => {
  return (
    <div className="tw-bg-white tw-shadow tw-rounded tw-p-6 tw-mb-6">
      <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
        <h2 className="tw-text-xl tw-font-semibold">Beneficiaries</h2>
        <button
          onClick={handleAddMember}
          className="tw-bg-green-500 tw-text-white tw-px-4 tw-py-2 tw-rounded"
        >
          + Add Beneficiary
        </button>
      </div>
      <DependentsTable 
        renewalProgress={renewalProgress}
        dependents={dependents}
        setDependents={setDependents}
        updateMember={updateMember} 
      />
    </div>
  );
};

export default DependentsSection;
