import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import ImportMembersContainers from '../containers/member-import/import-members-containers';

class MemberImportView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Component state
      members: [],
      file: null,
    };
  }

  render() {
    return (
      <Switch>
        <Route
          exact
          path="/import/members"
          render={props => (<ImportMembersContainers />)}
        />

      </Switch>
    );
  }
}

export default MemberImportView;
