import React, { Fragment, Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { capitalize, compact, concat, size } from 'lodash/fp';
import _ from 'lodash';

import { ADJUDICATION_STATES } from 'lib/config';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import { withFilter, withApiPagination, withApiSorting, TextTableCell } from 'components/table';
import { Text } from 'components/text';
import { formatShortId } from 'lib/formatters';
import { formatCurrency } from 'lib/formatters/currency';
import { formatDate } from 'lib/formatters/date';
import ClaimIcon from 'components/claim-icon';
import Icon from 'components/icon';
import Box from 'components/box';
import { Alert } from 'components/alerts';
import { connect } from 'react-redux';
import Button from 'components/button';
import { Grid, Checkbox } from '@material-ui/core';
import { massClaimApproval, resetMassClaimApprovalStatus } from 'store/claims/claims-actions';
import MassApprovalConfirmModal from 'components/mass-approval-confirm-modal';
import ApprovingIndicator from 'components/approving-indicator';


class ClaimsTable extends Component {
  static mapStateToProps = (state, ownProps) => ({
    user: state.auth.user,
    isApprovingMassClaim: state.claims.isApprovingMassClaim,
    massClaimApprovalSuccess: state.claims.massClaimApprovalSuccess,
    massClaimApprovalError: state.claims.massClaimApprovalError,
    refetchPendingClaims: ownProps.refetch,
  })

  static mapDispatchToProps = dispatch => ({
    approve: claimsIds => dispatch(massClaimApproval(claimsIds)),
    resetStatus: () => dispatch(resetMassClaimApprovalStatus()),

  });

  constructor(props) {
    super(props);
    this.state = {
      claimsIds: [],
      pending: window.location.pathname.split('/').length === 2,
      showMassApprovalConfirmModal: false,
    };
  }

  handleClose = () => {
    this.setState({ showMassApprovalConfirmModal: false });
  }

  refetchClaims = () => {
    const { refetchPendingClaims } = this.props;
    const { resetStatus } = this.props;
    resetStatus();
    refetchPendingClaims();
  }

  handleSubmit = () => {
    const { claimsIds } = this.state;
    const { approve } = this.props;
    this.setState({ showMassApprovalConfirmModal: false });
    approve(claimsIds);
  }

  // Select/Unselect all claims
   handleAllClaims = (event) => {
     const { data, user } = this.props;
     const { checked } = document.getElementById(event.target.id);

     if (checked) {
       this.setState({ claimsIds:
        compact(
          data.map(claim => (user.adjudicationLimit > claim.reimbursalAmount
            ? claim.id
            : null),
          ),
        ) });
     } else {
       this.setState({ claimsIds: [] });
     }
   };

   // Select/Unselect individual claim
   handleClaim = (event) => {
     const { claimsIds } = this.state;
     const checkbox = document.getElementById(event.currentTarget.id);
     const claimId = event.currentTarget.id;
     if (checkbox.checked) {
       this.setState({ claimsIds: concat(claimsIds, claimId) });
     } else {
       const newClaimsIds = claimsIds.map(claim => (claimId === claim ? null : claim),
       );
       this.setState({ claimsIds: compact(newClaimsIds) });
     }
   };

   render() {
     const { user, data, adjudicationState, onClickRow, onSortChange, sortField, sortDirection,
       massClaimApprovalSuccess, massClaimApprovalError, isApprovingMassClaim } = this.props;
     const { pending, claimsIds, showMassApprovalConfirmModal } = this.state;
     const hasData = data && data.length > 0;

     const cols = compact([
       { id: 'claimId', label: 'Claim ID', alignLeft: true, padding: false, sortable: true },
       (pending || window.location.pathname.split('/')[2] === 'returned') ? { id: 'mrn', label: 'MRN', alignLeft: false, padding: false, sortable: true } : null,
       (pending || window.location.pathname.split('/')[2] === 'returned') ? { id: 'cbhiId', label: 'CBHI ID', alignLeft: false, padding: false, sortable: true } : null,
       { id: 'reimbursalAmount', label: 'Amount', alignLeft: false, padding: false, sortable: true },
       { id: 'visitType', label: 'Visit Type', alignLeft: false, padding: false, sortable: true },
       {
         id: `${adjudicationState === ADJUDICATION_STATES.PENDING ? 'occurredAt' : 'adjudicatedAt'}`,
         label: `${adjudicationState === ADJUDICATION_STATES.PENDING ? 'Serviced' : capitalize(adjudicationState)}`,
         alignLeft: false,
         padding: false,
         sortable: true,
       },
       window.location.pathname.split('/')[2] === 'returned' ? { id: 'returned', label: 'Reason', alignLeft: false, padding: false, sortable: true } : null,
       { id: 'flag', label: '', alignLeft: false, padding: false, sortable: false },
       { id: 'submittedAt', label: 'Submitted', alignLeft: false, padding: false, sortable: true },
     ]);


     return (
       <Fragment>
         {massClaimApprovalSuccess ? this.refetchClaims() : null}
         {massClaimApprovalError ? <Box marginBottom={4}><Alert>{massClaimApprovalError}</Alert></Box> : null}
         {isApprovingMassClaim ? <ApprovingIndicator custom="Approving claims" /> : ''}
         {(!isApprovingMassClaim && pending) && size(claimsIds) > 0 ? (
           <>
             <Grid container>
               <Grid item xs={10}>
                 <Alert type="info">
                   {size(claimsIds)}
                   {' '}
                    claims selected
                 </Alert>
               </Grid>
               <Grid item xs={2}>
                 <Box paddingLeft={3}>
                   <Button primary onClick={() => this.setState({ showMassApprovalConfirmModal: true })}>
                  Approve Selected
                   </Button>
                 </Box>
               </Grid>
             </Grid>
           </>

         ) : (
           ''
         )}
         {showMassApprovalConfirmModal && (
         <MassApprovalConfirmModal
           onClose={this.handleClose}
           onSubmit={this.handleSubmit}
           allClaims={data}
           selectedClaimIds={claimsIds}
         />
         )}
         <Table style={{ overflowX: 'scroll' }}>
           <TableHead>
             <TableRow>
               {pending && (
               <TableCell
                 padding="checkbox"
                 align="left"
               >
                 <Checkbox
                   id="select-all"
                   color="primary"
                   onChange={event => this.handleAllClaims(event)}
                   inputProps={{ 'aria-label': 'primary checkbox' }}
                 />
               </TableCell>
               )}
               {cols.map(col => (
                 <TableCell
                   key={col.id}
                   padding="checkbox"
                   sortDirection={sortField === col.id ? sortDirection : false}
                   align={col.alignLeft ? 'left' : 'right'}
                 >
                   {col.sortable && (
                   <TableSortLabel
                     active={sortField === col.id}
                     direction={sortDirection}
                     onClick={() => onSortChange(col.id)}
                   >
                     <Text fontSize={3} fontFamily="sans">{col.label}</Text>
                   </TableSortLabel>
                   )}
                   {!col.sortable && <Text fontSize={3} fontFamily="sans">{col.label}</Text>}
                 </TableCell>
               ))}
             </TableRow>
           </TableHead>
           <TableBody>
             {!hasData && <TableRow><TextTableCell align="center" colSpan={6}>No data available</TextTableCell></TableRow>}
             {hasData && data.map((r) => {
               const hasFlags = r.flags.length > 0;
               const toolTipTitle = (r.originallySubmittedAt && r.originallySubmittedAt !== r.submittedAt) ? `Originally submitted: ${formatDate(r.originallySubmittedAt)}` : null;
               return (
                 <TableRow key={r.id} style={{ height: 'auto', paddingTop: 8, paddingBottom: 8 }}>
                   { pending && (
                   <TableCell padding="checkbox">
                     {user.adjudicationLimit > r.reimbursalAmount ? (
                       <Checkbox
                         checked={(!!claimsIds.includes(r.id))}
                         name={r.id}
                         id={r.id}
                         size="small"
                         onChange={this.handleClaim}
                         color="default"
                         inputProps={{ 'aria-label': 'checkbox with default color' }}
                       />
                     ) : (
                       <Checkbox
                         disabled
                         size="small"
                         inputProps={{ 'aria-label': 'checkbox with small size' }}
                       />
                     )}
                   </TableCell>
                   )}
                   <TableCell padding="checkbox" onClick={() => onClickRow(`/claims/${r.id}`)} component={Link}>
                     <ClaimIcon claim={{ ...r, shortClaimId: formatShortId(r.claimId) }} indicator={r.indicator} />
                   </TableCell>
                   {(pending || window.location.pathname.split('/')[2] === 'returned')
                   && (
                   <>
                     <TextTableCell padding="checkbox" align="right">{r.mrn}</TextTableCell>
                   </>
                   ) }
                   {(pending || window.location.pathname.split('/')[2] === 'returned')
                   && (
                   <>
                     <TextTableCell padding="checkbox" align="right">{r.membershipNumber}</TextTableCell>
                   </>
                   ) }
                   <TextTableCell padding="checkbox" align="right">{formatCurrency(r.reimbursalAmount)}</TextTableCell>
                   <TextTableCell style={{ height: 40, paddingTop: 8, paddingBottom: 8 }} align="right" padding="checkbox">{r.visitType && (`${r.visitType.substring(0, 8)}...`)}</TextTableCell>
                   <TextTableCell padding="checkbox" align="right">{adjudicationState === ADJUDICATION_STATES.PENDING ? formatDate(r.occurredAt) : formatDate(r.adjudicatedAt)}</TextTableCell>
                   {window.location.pathname.split('/')[2] === 'returned'
                   && (
                   <>
                     <TextTableCell padding="checkbox" align="right">{r.reason}</TextTableCell>
                   </>
                   ) }
                   <TableCell padding="checkbox">
                     <Box flex flexDirection="row" alignItems="center" justifyContent="flex-end">
                       { hasFlags && (
                       <Box paddingLeft={10}>
                         <Tooltip title={JSON.stringify(_.map(r.flags, 'name'))}>
                           <Icon name="flag-blue" size={15} iconSize={15} />
                         </Tooltip>
                       </Box>
                       )}
                     </Box>
                   </TableCell>
                   {toolTipTitle ? (
                     <Tooltip title={toolTipTitle} placement="right">
                       <TextTableCell padding="checkbox" align="right">{formatDate(r.submittedAt)}</TextTableCell>
                     </Tooltip>
                   ) : (

                     <TextTableCell padding="checkbox" align="right">{formatDate(r.submittedAt)}</TextTableCell>
                   )}
                 </TableRow>
               );
             })}
           </TableBody>
         </Table>
       </Fragment>
     );
   }
}

export default connect(ClaimsTable.mapStateToProps, ClaimsTable.mapDispatchToProps)(
  withFilter(withApiSorting((withApiPagination(ClaimsTable)))));
// NB: order here matters pagination should always be inner most and filter outer most

ClaimsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired, // TODO make shape
  adjudicationState: PropTypes.string.isRequired,
  sortField: PropTypes.string.isRequired,
  sortDirection: PropTypes.string.isRequired,
  onClickRow: PropTypes.func.isRequired,
  onSortChange: PropTypes.func.isRequired,
  user: PropTypes.shape({}).isRequired,
  refetchPendingClaims: PropTypes.func.isRequired,
  massClaimApprovalSuccess: PropTypes.bool.isRequired,
  massClaimApprovalError: PropTypes.string.isRequired,
  isApprovingMassClaim: PropTypes.bool.isRequired,
  approve: PropTypes.func.isRequired,
  resetStatus: PropTypes.func.isRequired,
};
