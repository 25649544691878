import {
  featureFlags,
  ROUTES,
  claimsAdjudicationPermissions,
  claimsApprovalPermissions,
  claimsPreparationPermissions,
  claimsReimbursementPermissions,
  claimsStatisticsPermissions,
  claimsResubmissionPermissions,
  claimsViewOnlyPermissions,
  enrollmentPermissions,
  enrollmentStatisticsPermissions,
  identificationPermissions,
  reimbursementsViewOnlyPermissions,
  memberViewOnlyPermissions,
  membershipPermissions,
  externalClaimsViewPermissions,
  contributionReportingPermissions,
  enrollmentReportingPermissions,
  utilizationReportingPermissions,
  ageChangeReportingPermissions,
  numberOfClaimsAdjudicatedReportingPermissions,
  claimsFilledByProvidersReportingPermissions,
  operationalReportOnReferralsReportingPermissions,
  claimsSummaryReportingPermissions,
  referredInReportingPermissions,
  referredOutReportingPermissions,
  beneficiaryHistoryReportingPermissions,
  owedAndReimbursedReportingPermissions,
  payeeWiseSummaryReportingPermissions,
  statusWiseSummaryReportingPermissions,
  aggregateReportsReportingPermissions,
  reportOnReimbursementsReportingPermissions,
  householdNumberReportingPermissions,
  facilityDashboard,
  branchDashboard,
  enrollmentDashboard,
  importClaimsPermissions,
  changePasswordPermissions,
  memberListPermissions,
  claimsReportsPermissions, indigentsPaymentsPermissions,
} from 'lib/config';

const {
  ENABLE_CLAIMS,
  ENABLE_FACILITY_REPORTING,
  ENABLE_MEMBER_EDITING,
  ENABLE_ENROLLMENT_REPORTING,
  ENABLE_REIMBURSEMENTS,
  ENABLE_SUBMISSION,
  ENABLE_CHECK_IN,
  ENABLE_STATUS,
  ENABLE_FACILITY_DASHBOARD,
  ENABLE_BRANCH_DASHBOARD,
  ENABLE_IMPORT_CLAIMS,
  ENABLE_CHANGE_PASSWORD,
  ENABLE_MEMBERS_LIST,
  ENABLE_CLAIMS_REPORTS,
  ENABLE_INDIGENTS_PAYMENTS,
  ENABLE_IMPORT_MEMBERS,
} = featureFlags;

// Checks that a user has a single permission
export const userHasPermission = (currentUserPermissions, permission) => currentUserPermissions.includes(permission);

// Checks that a user has all permissions in set
export const userHasAllPermissionsInSet = (currentUserPermissions, neededPermissions) => {
  if (!currentUserPermissions) {
    return false;
  }
  return neededPermissions.every(permission => userHasPermission(currentUserPermissions, permission));
};

// Checks that a user has full set of permissions from a list of sets
// only needs to match one set
export const userHasPermissionSetFromList = (currentUserPermissions, permissionSetList) => {
  if (!currentUserPermissions) {
    return false;
  }

  return permissionSetList.some(set => userHasAllPermissionsInSet(currentUserPermissions, set));
};

export const showRoute = (currentPermissions, routeName, isProviderUser) => {
  let permissions = [];
  let hasFlagsSet = true;

  switch (routeName) {
    case ROUTES.CHECK_IN.route_match:
      hasFlagsSet = ENABLE_CHECK_IN;
      permissions = [identificationPermissions];
      break;
    case ROUTES.ENROLL_MEMBERS.route_match:
      hasFlagsSet = ENABLE_CHECK_IN;
      permissions = [enrollmentPermissions];
      break;
    case ROUTES.IMPORT_MEMBERS.route_match:
      hasFlagsSet = ENABLE_IMPORT_MEMBERS;
      permissions = [];
      break;
    case ROUTES.CLAIMS.route_match:
      hasFlagsSet = ENABLE_CLAIMS;
      permissions = [
        claimsPreparationPermissions,
        claimsApprovalPermissions,
        claimsAdjudicationPermissions,
        claimsReimbursementPermissions,
        claimsResubmissionPermissions,
        claimsViewOnlyPermissions,
      ];
      break;
    case ROUTES.CLAIMS_EXTERNAL.route_match:
      hasFlagsSet = ENABLE_CLAIMS;
      permissions = [
        externalClaimsViewPermissions,
      ];
      break;
    case ROUTES.ENROLLMENT_REPORTING.route_match:
      hasFlagsSet = ENABLE_ENROLLMENT_REPORTING;
      permissions = [enrollmentStatisticsPermissions];
      break;
    case ROUTES.MEMBERS.route_match:
      hasFlagsSet = ENABLE_MEMBER_EDITING;
      permissions = [enrollmentPermissions, membershipPermissions, memberViewOnlyPermissions];
      break;
    case ROUTES.REIMBURSEMENTS.route_match:
      hasFlagsSet = ENABLE_REIMBURSEMENTS;
      permissions = [claimsReimbursementPermissions, reimbursementsViewOnlyPermissions];
      break;
    case ROUTES.SUBMISSIONS.route_match:
      hasFlagsSet = ENABLE_SUBMISSION;
      // TODO: see if having the role is necessary here
      permissions = [
        claimsApprovalPermissions,
        claimsPreparationPermissions,
      ];
      break;
    // TODO: check if this falls inline with 'resubmissionPermission'
    case ROUTES.SUBMISSIONS_EDIT.route_match:
      hasFlagsSet = ENABLE_SUBMISSION;
      permissions = [
        claimsPreparationPermissions,
        claimsResubmissionPermissions,
      ];
      break;
    case ROUTES.SUMMARY.route_match:
      hasFlagsSet = ENABLE_FACILITY_REPORTING && isProviderUser;

      permissions = [claimsStatisticsPermissions];
      break;
    case ROUTES.STATUS.route_match:
      hasFlagsSet = ENABLE_STATUS;
      permissions = [claimsPreparationPermissions];
      break;
    case ROUTES.REPORTING.route_match:
      hasFlagsSet = ENABLE_FACILITY_REPORTING;
      permissions = [
        utilizationReportingPermissions,
        contributionReportingPermissions,
        enrollmentReportingPermissions,
      ];
      break;
    case ROUTES.REPORTING_UTILIZATION.route_match:
      hasFlagsSet = ENABLE_FACILITY_REPORTING;
      permissions = [
        utilizationReportingPermissions,
      ];
      break;
    case ROUTES.REPORTING_CONTRIBUTION.route_match:
      hasFlagsSet = ENABLE_FACILITY_REPORTING;
      permissions = [
        contributionReportingPermissions,
      ];
      break;
    case ROUTES.REPORTING_ENROLLMENT.route_match:
      hasFlagsSet = ENABLE_FACILITY_REPORTING;
      permissions = [
        enrollmentReportingPermissions,
      ];
      break;
    case ROUTES.ELIGIBLE_HOUSEHOLDS.route_match:
      permissions = [
        enrollmentPermissions,
      ];
    case ROUTES.AGE_CHANGES.route_match:
      hasFlagsSet = ENABLE_FACILITY_REPORTING;
      permissions = [
        ageChangeReportingPermissions,
      ];
      break;
    case ROUTES.NUMBER_OF_CLAIMS_ADJUDICATED.route_match:
      hasFlagsSet = ENABLE_FACILITY_REPORTING;
      permissions = [
        numberOfClaimsAdjudicatedReportingPermissions,
      ];
      break;
    case ROUTES.CLAIMS_FILLED_BY_PROVIDERS.route_match:
      hasFlagsSet = ENABLE_FACILITY_REPORTING;
      permissions = [
        claimsFilledByProvidersReportingPermissions,
      ];
      break;
    case ROUTES.OPERATIONAL_REPORT_ON_REFERRALS.route_match:
      hasFlagsSet = ENABLE_FACILITY_REPORTING;
      permissions = [
        operationalReportOnReferralsReportingPermissions,
      ];
      break;
    case ROUTES.CLAIMS_SUMMARY.route_match:
      hasFlagsSet = ENABLE_FACILITY_REPORTING;
      permissions = [
        claimsSummaryReportingPermissions,
      ];
      break;
    case ROUTES.REFERRED_IN.route_match:
      hasFlagsSet = ENABLE_FACILITY_REPORTING;
      permissions = [
        referredInReportingPermissions,
      ];
      break;
    case ROUTES.REFERRED_OUT.route_match:
      hasFlagsSet = ENABLE_FACILITY_REPORTING;
      permissions = [
        referredOutReportingPermissions,
      ];
      break;
    case ROUTES.BENEFICIARY_HISTORY.route_match:
      hasFlagsSet = ENABLE_FACILITY_REPORTING;
      permissions = [
        beneficiaryHistoryReportingPermissions,
      ];
      break;
    case ROUTES.OWED_AND_REIMBURSED.route_match:
      hasFlagsSet = ENABLE_FACILITY_REPORTING;
      permissions = [
        owedAndReimbursedReportingPermissions,
      ];
      break;
    case ROUTES.PAYEE_WISE_SUMMARY.route_match:
      hasFlagsSet = ENABLE_FACILITY_REPORTING;
      permissions = [
        payeeWiseSummaryReportingPermissions,
      ];
      break;
    case ROUTES.STATUS_WISE_SUMMARY.route_match:
      hasFlagsSet = ENABLE_FACILITY_REPORTING;
      permissions = [
        statusWiseSummaryReportingPermissions,
      ];
      break;
    case ROUTES.AGGREGATE_REPORTS.route_match:
      hasFlagsSet = ENABLE_FACILITY_REPORTING;
      permissions = [
        aggregateReportsReportingPermissions,
      ];
      break;
    case ROUTES.REPORT_ON_REIMBURSEMENTS.route_match:
      hasFlagsSet = ENABLE_FACILITY_REPORTING;
      permissions = [
        reportOnReimbursementsReportingPermissions,
      ];
      break;
    case ROUTES.HOUSEHOLD_NUMBER.route_match:
      hasFlagsSet = ENABLE_FACILITY_REPORTING;
      permissions = [
        householdNumberReportingPermissions,
      ];
      break;
    case ROUTES.FACILITY_DASHBOARD.route_match:
      hasFlagsSet = ENABLE_FACILITY_DASHBOARD;
      permissions = [
        facilityDashboard,
      ];
      break;
    case ROUTES.BRANCH_DASHBOARD.route_match:
      hasFlagsSet = ENABLE_BRANCH_DASHBOARD;
      permissions = [
        branchDashboard,
      ];
      break;
    case ROUTES.ENROLLMENT_DASHBOARD.route_match:
      hasFlagsSet = ENABLE_FACILITY_REPORTING;
      permissions = [
        enrollmentDashboard,
      ];
      break;
    case ROUTES.IMPORT_CLAIMS.route_match:
      hasFlagsSet = ENABLE_IMPORT_CLAIMS;
      permissions = [
        importClaimsPermissions,
      ];
      break;
    case ROUTES.CHANGE_PASSWORD.route_match:
      hasFlagsSet = ENABLE_CHANGE_PASSWORD;
      permissions = [
        changePasswordPermissions,
      ];
      break;
    case ROUTES.MEMBERS_LIST.route_match:
      hasFlagsSet = ENABLE_MEMBERS_LIST;
      permissions = [
        memberListPermissions,
      ];
      break;
    case ROUTES.CLAIMS_REPORTS.route_match:
      hasFlagsSet = ENABLE_CLAIMS_REPORTS;
      permissions = [
        claimsReportsPermissions,
      ];
      break;
    case ROUTES.INDIGENTS_PAYMENTS.route_match:
      hasFlagsSet = ENABLE_INDIGENTS_PAYMENTS;
      permissions = [
        indigentsPaymentsPermissions,
      ];
      break;
    default:
      return false;
  }

  return currentPermissions && hasFlagsSet && userHasPermissionSetFromList(currentPermissions, permissions);
};
