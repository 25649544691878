import { getErrorMessage } from 'lib/utils';

export const POST_INDIGENT_PAYMENTS_REQUEST = 'POST_INDIGENT_PAYMENTS_REQUEST';
export const POST_INDIGENT_PAYMENTS_SUCCESS = 'POST_INDIGENT_PAYMENTS_SUCCESS';
export const POST_INDIGENT_PAYMENTS_FAILURE = 'POST_INDIGENT_PAYMENTS_FAILURE';

export const postIndigentPayments = params => ({
  CALL_API: {
    call: api => api.postIndigentPayments(params),
    transformError: err => getErrorMessage(err),
    types: [POST_INDIGENT_PAYMENTS_REQUEST, POST_INDIGENT_PAYMENTS_SUCCESS, POST_INDIGENT_PAYMENTS_FAILURE],
  },
});

export const FETCH_INDIGENT_PAYMENTS_REQUEST = 'FETCH_INDIGENT_PAYMENTS_REQUEST';
export const FETCH_INDIGENT_PAYMENTS_SUCCESS = 'FETCH_INDIGENT_PAYMENTS_SUCCESS';
export const FETCH_INDIGENT_PAYMENTS_FAILURE = 'FETCH_INDIGENT_PAYMENTS_FAILURE';

export const fetchIndigentPayments = () => ({
  CALL_API: {
    call: api => api.fetchIndigentPayments(),
    transformError: err => getErrorMessage(err),
    types: [FETCH_INDIGENT_PAYMENTS_REQUEST, FETCH_INDIGENT_PAYMENTS_SUCCESS, FETCH_INDIGENT_PAYMENTS_FAILURE],
  },
});
