/* eslint-disable react/forbid-prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/modal';
import Button from 'components/button';
import Box from 'components/box';
import { Text } from 'components/text';
import { connect } from 'react-redux';
import { StackHorizontal, StackVertical } from '../../../components/eligible-household-elements';
import { convertGregorianToEthiopian, convertIsoToEthiopian } from '../../../lib/utils';

class DetailEligibleHouseholdModal extends Component {
    handleCancel = () => {
      const { onRequestClose } = this.props;
      onRequestClose();
    };

    render() {
      const { onRequestClose, householdToDetail } = this.props;
      const {
        administrativeDivision, enrollmentPeriod, updatedAt, versions,
      } = householdToDetail;
      const { startDate, endDate, coverageStartDate, coverageEndDate } = enrollmentPeriod;

      const startDateEth = convertGregorianToEthiopian(startDate);
      const endDateEth = convertGregorianToEthiopian(endDate);
      const coverageStartDateEth = convertGregorianToEthiopian(coverageStartDate);
      const coverageEndDateEth = convertGregorianToEthiopian(coverageEndDate);
      const updatedAtEth = convertIsoToEthiopian(updatedAt);
      return (
        <Modal
          maxWidth="400px"
          title="Eligible Household Detail"
          onRequestClose={onRequestClose}
        >
          <StackVertical spaceY="1.5rem">
            <StackHorizontal spaceX="0.5rem" alignItems="center">
              <Text>Admin Division Level:</Text>
              <Text>{administrativeDivision?.name}</Text>
            </StackHorizontal>
            <StackHorizontal spaceX="0.5rem" alignItems="center">
              <Text>Admin Division Name:</Text>
              <Text>{administrativeDivision?.level}</Text>
            </StackHorizontal>
            <StackVertical spaceY="0.5rem" alignItems="center">
              <Text> Start Date - End Date:</Text>
              <Text>{`${startDateEth} - ${endDateEth}`}</Text>
            </StackVertical>
            <StackVertical spaceY="0.5rem" alignItems="center">
              <Text> Coverage Date:</Text>
              <Text>{`${coverageStartDateEth} - ${coverageEndDateEth}`}</Text>
            </StackVertical>
            <StackHorizontal spaceX="0.3rem" alignItems="center">
              <Text>Current Expected Households:</Text>
              <Text>{`${householdToDetail?.expectedHouseholds}`}</Text>
            </StackHorizontal>
            <StackVertical spaceX="0.3rem" alignItems="center">
              <Text>Versions</Text>
              <ul style={{ listStyleType: 'none', padding: 0 }}>
                {versions.map(version => (
                  <li key={version.id} style={{ marginBottom: '10px', border: '1px solid #ccc', padding: '5px' }}>
                    <div style={{ fontWeight: 'bold', marginBottom: '5px' }}>
                      {`Old Value: ${version.objectChanges.expectedHouseholds[0]}`}
                    </div>
                    <div style={{ fontWeight: 'normal' }}>
                      {` Updated At:  ${new Date(version.objectChanges.updatedAt[1]).toLocaleString()}`}
                    </div>
                  </li>
                ))}
              </ul>
            </StackVertical>

            <StackHorizontal spaceX="0.3rem" alignItems="center">
              <Text>Last Updated At:</Text>
              <Text>{`${updatedAtEth}`}</Text>
            </StackHorizontal>

            <Box marginTop="medium">
              <StackHorizontal spaceX="0.5rem">
                <Button onClick={this.handleCancel}>
                  Go Back
                </Button>
              </StackHorizontal>
            </Box>
          </StackVertical>
        </Modal>
      );
    }
}

export default connect(null)(DetailEligibleHouseholdModal);

DetailEligibleHouseholdModal.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
  householdToDetail: PropTypes.object.isRequired,
};
