import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { calculateClaimPrice, calculateStockoutTotal } from 'store/encounters/encounters-utils';
import { formatCurrency, formatCurrencyWithLabel } from 'lib/formatters/currency';

import { TextTableCell } from 'components/table';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Box from 'components/box';
import { CurrencyInput } from 'components/inputs';
import { UnderlinedAnchor } from 'components/links';
import NegativeValue from 'components/negative-value';
import { ErrorLabel } from 'components/alerts';

class AmountSummary extends Component {
  bypassInputRef = React.createRef();

  state = {
    editingBypass: false,
  };

  focusBypassInput = () => {
    this.setState({ editingBypass: true }, () => {
      this.bypassInputRef.current.handleFocus();
    });
  }

  updateBypassFee = (bypassFee) => {
    this.setState({ editingBypass: false });
    const { handleChange } = this.props;
    handleChange(bypassFee, 'customBypassFee');
  }

  render() {
    const {
      encounterItemsWithBillable,
      bypassMultiplier,
      customBypassFee,
      claimTotalError,
      showBypassFee,
    } = this.props;
    const { editingBypass } = this.state;

    const claimPrice = calculateClaimPrice(encounterItemsWithBillable);
    // policy is to have patient pay less if bypass fee requires rounding
    const bypassFee = Math.floor(customBypassFee !== null ? customBypassFee : (claimPrice) * bypassMultiplier);
    const claimTotal = claimPrice - bypassFee;

    const stockoutTotal = calculateStockoutTotal(encounterItemsWithBillable);
    return (
      <Table>
        <TableBody>
          <TableRow>
            <TextTableCell color="gray.6">Total Services</TextTableCell>
            <TextTableCell align="right">{formatCurrency(claimPrice + stockoutTotal)}</TextTableCell>
          </TableRow>
          <TableRow>
            <TextTableCell color="gray.6">Stockout</TextTableCell>
            {stockoutTotal > 0 ? (
              <TextTableCell align="right" color="red.6">
                <NegativeValue color="red" padding={4} />
                {formatCurrency(stockoutTotal)}
              </TextTableCell>
            ) : (
              <TextTableCell align="right" color="gray.6">
                {formatCurrency(0)}
              </TextTableCell>
            )}
          </TableRow>
          {showBypassFee && (
            <TableRow>
              <TextTableCell color="gray.6">Bypass Fee</TextTableCell>
              {editingBypass && (
                <TableCell align="right">
                  <Box flex flexDirection="column">
                    <Box flex flexDirection="row" alignItem="flex-end" justifyContent="flex-end">
                      <CurrencyInput
                        ref={this.bypassInputRef}
                        name="bypass-fee"
                        onChange={value => this.updateBypassFee(value)}
                        value={bypassFee}
                        defaultValue={bypassFee}
                      />
                    </Box>
                  </Box>
                </TableCell>
              )}
              {!editingBypass && (
                <TextTableCell onClick={this.focusBypassInput} align="right">
                  <Box flex flexDirection="column">
                    <Box>
                      {bypassFee > 0 && (
                        <NegativeValue color="gray" padding={4} />
                      )}
                      {formatCurrency(bypassFee)}
                    </Box>
                    <Box>
                      <UnderlinedAnchor onClick={() => this.setState({ editingBypass: true })}>
                        Enter custom amount
                      </UnderlinedAnchor>
                    </Box>
                  </Box>
                </TextTableCell>
              )}
            </TableRow>
          )}
          <TableRow>
            <TextTableCell style={{ borderBottom: 'none' }}>Total Claimed</TextTableCell>
            <TextTableCell style={{ borderBottom: 'none' }} align="right" fontSize={4} fontWeight="semibold">
              {formatCurrencyWithLabel(claimTotal)}
              {claimTotalError && (
                <Box>
                  <ErrorLabel>{claimTotalError}</ErrorLabel>
                </Box>
              )}
            </TextTableCell>
          </TableRow>
        </TableBody>
      </Table>

    );
  }
}

export default AmountSummary;

AmountSummary.propTypes = {
  encounterItemsWithBillable: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleChange: PropTypes.func.isRequired,
  customBypassFee: PropTypes.number,
  bypassMultiplier: PropTypes.number.isRequired,
  claimTotalError: PropTypes.string,
  showBypassFee: PropTypes.bool.isRequired,
};

AmountSummary.defaultProps = {
  customBypassFee: null,
  claimTotalError: null,
};
