import React, { useState, useEffect } from "react";
import Select from "react-select";

const AdministrativeDivisionSelector = ({
  data,
  onGoteChange,
  selectedGote
}) => {
  const [gotes, setGotes] = useState([]);

  useEffect(() => {
    if (data) {
      const gotesData = data.filter((item) => item.level === "gote");
      setGotes(gotesData);
    }
  }, [data]);

  

  return (
    <div className="tw-col-span-6">
          <span className="tw-block tw-text-gray-700 tw-font-medium tw-mb-2">Select Gote </span>
    <Select
      placeholder="Select Gote"
      options={gotes.map((gote) => ({
        value: gote.id,
        label: gote.name,
      }))}
      value={selectedGote}
      onChange={onGoteChange}
    />
    </div>
  );
};

export default AdministrativeDivisionSelector;
