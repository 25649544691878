/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TextTableCell } from 'components/table';
import { connect } from 'react-redux';
import { TablePagination, TableSortLabel, Tooltip } from '@material-ui/core';
import { fetchMembersByUrl, fetchMembersList } from 'store/members/members-actions';
import Icon from 'components/icon';


class MemberListResultsTable extends Component {
  static mapDispatchToProps = dispatch => ({
    fetchMoreMembers: url => dispatch(fetchMembersByUrl(url)),
    loadMembersList: filters => dispatch(fetchMembersList(filters)),

  });

  state = {
    members: null,
  }

  componentDidMount() {
    // eslint-disable-next-line react/destructuring-assignment
    const { members } = this.props.data;
    this.setState({ members });
  }

  handleChangePage = (event, newPage) => {
    const { fetchMoreMembers, data } = this.props;
    const { page, prevUrl, nextUrl } = data;
    const pageToBeFetched = newPage + 1;

    if (pageToBeFetched > page) {
      fetchMoreMembers(nextUrl);
    }

    if (pageToBeFetched < page) {
      fetchMoreMembers(prevUrl);
    }
  }

  onSortChange = (direction) => {
    const { loadMembersList } = this.props;
    const localFilters = localStorage.getItem('filters') ? JSON.parse(localStorage.getItem('filters')) : null;
    if (direction === 'desc') {
      localFilters.sort = 'asc';
    } else {
      localFilters.sort = 'desc';
    }
    localStorage.setItem('filters', JSON.stringify(localFilters));
    loadMembersList(localFilters);
  }

  handleRowClick = (memberId) => {
    const { history } = this.props;
    const authUser = JSON.parse(localStorage.getItem('authUser'));
    if (authUser.role === 'card_room_worker') {
      history.push(`/check-in/members/${memberId}`);
    } else {
      history.push(`/members/${memberId}`);
    }
  }

  render() {
    const { data } = this.props;
    const { total, page } = data;
    const { members } = this.state;
    const localFilters = localStorage.getItem('filters') ? JSON.parse(localStorage.getItem('filters')) : null;


    return (
      <>
        <Table>
          <colgroup>
            <col style={{ width: '22%' }} />
            <col style={{ width: '3%' }} />
            <col style={{ width: '23%' }} />
            <col style={{ width: '10%' }} />
            <col style={{ width: '10%' }} />
            <col style={{ width: '10%' }} />
            <col style={{ width: '10%' }} />
            <col style={{ width: '10%' }} />
            <col style={{ width: '7%' }} />
          </colgroup>
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">Full Name</TableCell>
              <TableCell padding="checkbox" />
              <TableCell padding="checkbox">Membership Number</TableCell>
              <TableCell padding="checkbox">Gender</TableCell>
              <TableCell padding="checkbox">Membership Status</TableCell>
              <TableCell padding="checkbox">Membership Type</TableCell>
              <TableCell padding="checkbox">Enrollment Type</TableCell>
              <TableCell padding="checkbox">Relationship</TableCell>
              <TableCell padding="checkbox">
                <TableSortLabel
                  active
                  direction={localFilters ? localFilters.sort : 'desc'}
                  onClick={() => this.onSortChange(localFilters.sort)}
                >
                  Household Size
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {members && members.map(member => (
              <TableRow
                key={member.memberId}
                hover
                component={Link}
                onClick={() => this.handleRowClick(member.memberId)}
              >
                <TextTableCell padding="checkbox" color="blue.5">{member.fullName}</TextTableCell>
                <TextTableCell padding="checkbox" color="red.5">
                  {member.archivedReason && (
                  <Tooltip title={member.archivedReason}>
                    <Icon name="archived" size={15} iconSize={15} />
                  </Tooltip>
                  )}
                </TextTableCell>
                <TextTableCell padding="checkbox">{member.membershipNumber}</TextTableCell>
                <TextTableCell padding="checkbox">{member.gender }</TextTableCell>
                <TextTableCell padding="checkbox">{member.membershipStatus }</TextTableCell>
                <TextTableCell padding="checkbox">{member.paying }</TextTableCell>
                <TextTableCell padding="checkbox">{member.renewal }</TextTableCell>
                <TextTableCell padding="checkbox">{member.relationshipToHead}</TextTableCell>
                <TextTableCell padding="checkbox">{member.householdSize }</TextTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {members && (
        <TablePagination
          rowsPerPageOptions={false}
          component="div"
          count={total}
          rowsPerPage={25}
          page={page - 1}
          onChangePage={this.handleChangePage}
        />
        )}

      </>
    );
  }
}

export default connect(null, MemberListResultsTable.mapDispatchToProps)(withRouter(MemberListResultsTable));

MemberListResultsTable.propTypes = {
  history: PropTypes.shape({}).isRequired,
};
