import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash/fp';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';

import { formatCurrencyWithLabel } from 'lib/formatters/currency';
import { formatDate } from 'lib/formatters/date';

import Box from 'components/box';
import ClaimIcon from 'components/claim-icon';
import { TextTableCell, withPagination } from 'components/table';
import { Text } from 'components/text';

function renderDiagnosesNames(diagnosesNames) {
  return (
    <ul>
      {diagnosesNames && diagnosesNames.map(name => <li>{name}</li>)}
    </ul>
  );
}
const RecentClaimsList = ({ data, onClickRow }) => (
  <Box flex>
    {isEmpty(data)
      ? <Text colSpan={3} align="center" color="gray.6">No recent claims</Text>
      : (
        <Table>
          <colgroup>
            <col style={{ width: '15%' }} />
            <col style={{ width: '15%' }} />
            <col style={{ width: '25%' }} />
            <col style={{ width: '30%' }} />
            <col style={{ width: '15%' }} />
          </colgroup>
          <TableBody>
            {data.map((e, i) => {
              const lastRow = i === data.length - 1;
              const { diagnosesNames } = data[i];

              return (
                <TableRow component={Link} style={{ height: 'auto', paddingTop: 8, paddingBottom: 8 }} onClick={() => onClickRow(`/claims/${e.id}`)} key={e.id} hover>
                  <TextTableCell style={{ borderBottom: lastRow && 'none', height: 40 }} padding="checkbox"><ClaimIcon claim={e} /></TextTableCell>
                  <TextTableCell style={{ borderBottom: lastRow && 'none', height: 40 }} padding="checkbox" align="center">{formatCurrencyWithLabel(e.price)}</TextTableCell>
                  <TextTableCell style={{ borderBottom: lastRow && 'none', height: 40 }} padding="checkbox">{e.providerName}</TextTableCell>
                  <TextTableCell style={{ borderBottom: lastRow && 'none', height: 40 }} padding="checkbox">{renderDiagnosesNames(diagnosesNames)}</TextTableCell>
                  <TextTableCell style={{ borderBottom: lastRow && 'none', height: 40 }} padding="checkbox" align="right">{formatDate(e.occurredAt)}</TextTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
  </Box>
);

RecentClaimsList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onClickRow: PropTypes.func.isRequired,
};

export default withPagination(RecentClaimsList);
