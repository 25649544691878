import { getErrorMessage } from 'lib/utils';

export const FETCH_FLAGS_REQUEST = 'FETCH_FLAGS_REQUEST';
export const FETCH_FLAGS_SUCCESS = 'FETCH_FLAGS_SUCCESS';
export const FETCH_FLAGS_FAILURE = 'FETCH_FLAGS_FAILURE';

export const fetchFlags = (startDate, endDate) => ({
  CALL_API: {
    call: api => api.fetchFlags(startDate, endDate),
    transformError: err => getErrorMessage(err),
    types: [FETCH_FLAGS_REQUEST, FETCH_FLAGS_SUCCESS, FETCH_FLAGS_FAILURE],
  },
});
