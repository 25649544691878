import session from 'lib/session';
import {
  LOG_IN_FAILURE,
  LOG_IN_REQUEST,
  LOG_IN_SUCCESS,
  LOG_OUT_REQUEST,
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
} from './auth-actions';

export const initialState = {
  isAuthenticated: session.getTokenWithExpiry() !== null,
  isAuthenticating: false,
  errorMessage: '',
  user: session.getUser(),
  isChangingPassword: false,
  changePasswordError: '',
  changePasswordMessage: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOG_IN_REQUEST:
      return {
        ...state,
        isAuthenticating: true,
        errorMessage: '',
      };
    case LOG_OUT_REQUEST:
      return {
        ...state,
        isAuthenticated: false,
        isAuthenticating: false,
        errorMessage: '',
        user: null,
      };
    case LOG_IN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        isAuthenticating: false,
        errorMessage: '',
        user: action.response,
      };
    case LOG_IN_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        isAuthenticating: false,
        errorMessage: action.errorMessage,
        user: null,
      };

      // CHANGE PASSWORD
    case CHANGE_PASSWORD_REQUEST:
      return {
        ...state,
        isChangingPassword: true,
        changePasswordMessage: '',
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        isChangingPassword: false,
        changePasswordError: '',
        changePasswordMessage: action.response,
      };
    case CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        isChangingPassword: false,
        changePasswordError: action.errorMessage,
      };
    default:
      return state;
  }
}
