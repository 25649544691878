import { getErrorMessage } from 'lib/utils';

export const FETCH_ENROLLMENT_REPORT_REQUEST = 'FETCH_ENROLLMENT_REPORT_REQUEST';
export const FETCH_ENROLLMENT_REPORT_SUCCESS = 'FETCH_ENROLLMENT_REPORT_SUCCESS';
export const FETCH_ENROLLMENT_REPORT_FAILURE = 'FETCH_ENROLLMENT_REPORT_FAILURE';

export const fetchEnrollmentReport = (startDate, endDate, enrollmentPeriodId, isBranch = false) => ({
  CALL_API: {
    call: api => api.fetchEnrollmentReportCSV(startDate, endDate, enrollmentPeriodId, isBranch),
    transformError: err => getErrorMessage(err),
    types: [
      FETCH_ENROLLMENT_REPORT_REQUEST,
      FETCH_ENROLLMENT_REPORT_SUCCESS,
      FETCH_ENROLLMENT_REPORT_FAILURE,
    ],
  },
});

export const FETCH_REIMBURSEMENT_REPORT_REQUEST = 'FETCH_REIMBURSEMENT_REPORT_REQUEST';
export const FETCH_REIMBURSEMENT_REPORT_SUCCESS = 'FETCH_REIMBURSEMENT_REPORT_SUCCESS';
export const FETCH_REIMBURSEMENT_REPORT_FAILURE = 'FETCH_REIMBURSEMENT_REPORT_FAILURE';

export const fetchReimbursementReport = reimbursementId => ({
  CALL_API: {
    call: api => api.fetchReimbursementReportCSV(reimbursementId),
    transformError: err => getErrorMessage(err),
    types: [
      FETCH_REIMBURSEMENT_REPORT_REQUEST,
      FETCH_REIMBURSEMENT_REPORT_SUCCESS,
      FETCH_REIMBURSEMENT_REPORT_FAILURE,
    ],
  },
});

export const FETCH_ENROLLMENT_STATS_REPORT_REQUEST = 'FETCH_ENROLLMENT_STATS_REPORT_REQUEST';
export const FETCH_ENROLLMENT_STATS_REPORT_SUCCESS = 'FETCH_ENROLLMENT_STATS_REPORT_SUCCESS';
export const FETCH_ENROLLMENT_STATS_REPORT_FAILURE = 'FETCH_ENROLLMENT_STATS_REPORT_FAILURE';

export const fetchEnrollmentReportingDetail = filters => ({
  CALL_API: {
    call: api => api.fetchEnrollmentReportingDetailCSV(filters),
    transformError: err => getErrorMessage(err),
    types: [
      FETCH_ENROLLMENT_STATS_REPORT_REQUEST,
      FETCH_ENROLLMENT_STATS_REPORT_SUCCESS,
      FETCH_ENROLLMENT_STATS_REPORT_FAILURE,
    ],
  },
});

export const FETCH_CONTRIBUTION_REPORT_REQUEST = 'FETCH_CONTRIBUTION_REPORT_REQUEST';
export const FETCH_CONTRIBUTION_REPORT_SUCCESS = 'FETCH_CONTRIBUTION_REPORT_SUCCESS';
export const FETCH_CONTRIBUTION_REPORT_FAILURE = 'FETCH_CONTRIBUTION_REPORT_FAILURE';

export const fetchContributionReport = (startDate, endDate, isBranch = false) => ({
  CALL_API: {
    call: api => api.fetchContributionReportCSV(startDate, endDate, isBranch),
    transformError: err => getErrorMessage(err),
    types: [
      FETCH_CONTRIBUTION_REPORT_REQUEST,
      FETCH_CONTRIBUTION_REPORT_SUCCESS,
      FETCH_CONTRIBUTION_REPORT_FAILURE,
    ],
  },
});

export const FETCH_UTILIZATION_REPORT_REQUEST = 'FETCH_UTILIZATION_REPORT_REQUEST';
export const FETCH_UTILIZATION_REPORT_SUCCESS = 'FETCH_UTILIZATION_REPORT_SUCCESS';
export const FETCH_UTILIZATION_REPORT_FAILURE = 'FETCH_UTILIZATION_REPORT_FAILURE';

export const fetchUtilizationReport = (startDate, endDate) => ({
  CALL_API: {
    call: api => api.fetchUtilizationReportCSV(startDate, endDate),
    transformError: err => getErrorMessage(err),
    types: [
      FETCH_UTILIZATION_REPORT_REQUEST,
      FETCH_UTILIZATION_REPORT_SUCCESS,
      FETCH_UTILIZATION_REPORT_FAILURE,
    ],
  },
});

export const FETCH_FACILITY_REPORT_REQUEST = 'FETCH_FACILITY_REPORT_REQUEST';
export const FETCH_FACILITY_REPORT_SUCCESS = 'FETCH_FACILITY_REPORT_SUCCESS';
export const FETCH_FACILITY_REPORT_FAILURE = 'FETCH_FACILITY_REPORT_FAILURE';

export const fetchFacilityReimbursementReport = (providerId, startDate, endDate) => ({
  CALL_API: {
    call: api => api.fetchFacilityReimbursementReportCSV(providerId, startDate, endDate),
    transformError: err => getErrorMessage(err),
    types: [
      FETCH_FACILITY_REPORT_REQUEST,
      FETCH_FACILITY_REPORT_SUCCESS,
      FETCH_FACILITY_REPORT_FAILURE,
    ],
  },
});

export const FETCH_CLAIMS_REPORT_REQUEST = 'FETCH_CLAIMS_REPORT_REQUEST';
export const FETCH_CLAIMS_REPORT_SUCCESS = 'FETCH_CLAIMS_REPORT_SUCCESS';
export const FETCH_CLAIMS_REPORT_FAILURE = 'FETCH_CLAIMS_REPORT_FAILURE';

export const fetchClaimsReport = params => ({
  CALL_API: {
    call: api => api.fetchClaimsReportCSV(params),
    transformError: err => getErrorMessage(err),
    types: [
      FETCH_CLAIMS_REPORT_REQUEST,
      FETCH_CLAIMS_REPORT_SUCCESS,
      FETCH_CLAIMS_REPORT_FAILURE,
    ],
  },
});
