/* eslint-disable max-len */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import { filter } from 'lodash/fp';
import moment from 'moment';
import { formatDate } from 'lib/formatters/date';
import Container from 'components/container';
import Box from 'components/box';
import { SelectField, DatePicker, SearchInput } from 'components/inputs';
import Icon from 'components/icon';
import Button from 'components/button';
import { UnderlinedAnchor } from 'components/links';
import AdministrativeDivisionPicker from 'components/inputs/administrative-division-picker';
import { fetchAdministrativeDivisions } from 'store/administrative-divisions/administrative-divisions-actions';
import { fetchEnrollmentPeriods, fetchEnrollmentPeriodsByAdministrativeDivision } from 'store/enrollment/enrollment-actions';
import { fetchMembersList, fetchMemberListCSV as fetchMemberListCSVAction } from 'store/members/members-actions';


import { administrativeDivisionsByIdSelector, viewableAdminDivisionIdsSelector } from 'store/administrative-divisions/administrative-divisions-selectors';
import { enrollmentPeriodByAdministrativeDivisionOptionsSelector } from 'store/enrollment/enrollment-selectors';
import MemberListResult from 'views/member-list-result';

const EMPTY_VALUE = '-1';
const EMPTY_FILTER = {
  startDate: undefined,
  endDate: undefined,
  administrativeDivisionId: undefined,
  paying: undefined,
  renewal: undefined,
  gender: undefined,
  beneficiaries: true,
  enrollmentPeriod: EMPTY_VALUE,
  name: undefined,
  sort: 'desc',
};

class MemberList extends Component {
  static mapStateToProps = (state) => {
    const viewableAdminDivisionIds = viewableAdminDivisionIdsSelector(state);
    const filteredAdministrativeDivisions = filter(
      ad => viewableAdminDivisionIds.includes(ad.id),
    )(administrativeDivisionsByIdSelector(state));

    return {
      memberList: state.members.memberList,
      administrativeDivisions: filteredAdministrativeDivisions,
      administrativeDivisionsError: state.enrollmentReportingStats.administrativeDivisionsError,
      isLoadingAdministrativeDivisions: state.administrativeDivisions.isLoadingAdministrativeDivisions,
      enrollmentPeriodByAdministrativeDivisionOptions: enrollmentPeriodByAdministrativeDivisionOptionsSelector(state),
      enrollmentPeriods: state.enrollment.enrollmentPeriods,
      enrollmentPeriodsByAdministrativeDivision: state.enrollment.enrollmentPeriodsByAdministrativeDivision,
    };
  }

  static mapDispatchToProps = dispatch => ({
    loadAdministrativeDivisions() {
      dispatch(fetchAdministrativeDivisions());
      dispatch({ type: 'MEMBER_LIST_CLEAR' });
    },
    loadEnrollmentPeriods() {
      dispatch(fetchEnrollmentPeriods());
    },
    loadEnrollmentPeriodsByAdministrativeDivision(administrativeDivisionId) {
      dispatch(fetchEnrollmentPeriodsByAdministrativeDivision(administrativeDivisionId));
    },
    clearEnrollmentPeriodsByAdministrativeDivision() {
      dispatch({ type: 'CLEAR_ENROLLMENT_PERIODS_BY_ADMINISTRATIVE_DIVISION' });
    },
    loadMembersList: filters => dispatch(fetchMembersList(filters)),
    fetchMemberListCSV: filters => dispatch(fetchMemberListCSVAction(filters)),
  })

  startDateRef = React.createRef();

  endDateRef = React.createRef();

  constructor(props) {
    super(props);
    const savedFilters = localStorage.getItem('filters') ? JSON.parse(localStorage.getItem('filters')) : null;
    this.state = { filters: savedFilters || EMPTY_FILTER };
  }

  componentDidMount() {
    const { administrativeDivisions, loadAdministrativeDivisions, loadEnrollmentPeriods, loadEnrollmentPeriodsByAdministrativeDivision } = this.props;
    const { filters: { administrativeDivisionId } } = this.state;
    loadAdministrativeDivisions();
    loadEnrollmentPeriods();

    let selectedAdminDivisionId = administrativeDivisionId; // Default to the state's administrativeDivisionId
    if (administrativeDivisions.length > 0 && !selectedAdminDivisionId) {
      const authUser = JSON.parse(localStorage.getItem('authUser'));
      if (authUser.role === 'card_room_worker' || authUser.role === 'enrollment_worker' || authUser.role === 'enrollment_manager') {
        const selectedAdministrativeDivision = _.find(administrativeDivisions, { id: authUser.administrativeDivisionId });
        if (selectedAdministrativeDivision) {
          selectedAdminDivisionId = selectedAdministrativeDivision.id; // Update the id to be passed to the picker
        }
      }
    }
    const { filters } = this.state;
    this.updateFilters({ ...filters, administrativeDivisionId: selectedAdminDivisionId });
    if (selectedAdminDivisionId) loadEnrollmentPeriodsByAdministrativeDivision(selectedAdminDivisionId);
  }

  handleAdministrativeDivisionChange = (administrativeDivisionId) => {
    const { loadEnrollmentPeriodsByAdministrativeDivision } = this.props;
    if (administrativeDivisionId) loadEnrollmentPeriodsByAdministrativeDivision(administrativeDivisionId);

    const { filters } = this.state;
    this.updateFilters({ ...filters, administrativeDivisionId });
  }

  updateFilters = (filters) => {
    localStorage.setItem('filters', JSON.stringify(filters));
    this.setState({ filters });
  }

  loadMembers = () => {
    const { loadMembersList } = this.props;
    const { filters } = this.state;
    loadMembersList(filters);
  }

  handleSelectChange = (e) => {
    const { filters } = this.state;
    const value = e.target.value === EMPTY_VALUE ? undefined : e.target.value;
    this.updateFilters({ ...filters, [e.target.name]: value });
  }

  handleDateChange = (momentDate, isStartDate) => {
    const { filters } = this.state;
    const filterKey = isStartDate ? 'startDate' : 'endDate';
    const isoDateString = momentDate.format('YYYY-MM-DD');
    this.updateFilters({ ...filters, [filterKey]: isoDateString });
  }

  handleInputChange = (e) => {
    const { filters } = this.state;
    const value = e.target.value === '' ? undefined : e.target.value;

    this.updateFilters({ ...filters, [e.target.name]: value });
  }

  handleClearFilter = () => {
    const { clearEnrollmentPeriodsByAdministrativeDivision } = this.props;
    this.updateFilters(EMPTY_FILTER);
    localStorage.removeItem('filters');
    if (this.startDateRef.current) {
      this.startDateRef.current.clear();
    }

    if (this.endDateRef.current) {
      this.endDateRef.current.clear();
    }
    clearEnrollmentPeriodsByAdministrativeDivision();
  }

  handleExport = () => {
    const { fetchMemberListCSV } = this.props;
    const { filters } = this.state;
    fetchMemberListCSV(filters);
  }


  render() {
    // eslint-disable-next-line react/prop-types
    const { administrativeDivisions, memberList, enrollmentPeriodByAdministrativeDivisionOptions } = this.props;
    const { filters: { startDate, endDate, paying, renewal, gender, beneficiaries, administrativeDivisionId, enrollmentPeriod, name, membershipStatus } } = this.state;
    const ethiopianCurrentDate = formatDate(moment());
    const ethiopianStartDate = startDate ? formatDate(startDate) : undefined;
    const ethiopianEndDate = endDate ? formatDate(endDate) : undefined;
    const disabled = !!((administrativeDivisionId === undefined || enrollmentPeriod === '-1'));
    const payingDisabled = membershipStatus === 'Inactive';
    const renewalDisabled = membershipStatus === 'Inactive';
    const startDateDisabled = membershipStatus === 'Inactive';
    const endDateDisabled = membershipStatus === 'Inactive';

    const payingOptions = [
      { value: EMPTY_VALUE, name: 'Paying & Indigent' },
      { value: true, name: 'Paying' },
      { value: false, name: 'Indigent' },
    ];

    const renewalOptions = [
      { value: EMPTY_VALUE, name: 'New & Renewal' },
      { value: false, name: 'New' },
      { value: true, name: 'Renewal' },
    ];

    const genderOptions = [
      { value: EMPTY_VALUE, name: 'Female & Male' },
      { value: 'F', name: 'Female' },
      { value: 'M', name: 'Male' },
    ];
    const beneficiariesOptions = [
      { value: true, name: 'With' },
      { value: false, name: 'With Out' },
    ];
    const membershipStatusOptions = [
      { value: 'Active', name: 'Active Only' },
      { value: 'Inactive', name: 'Inactive Only' },
    ];


    return (
      <Container>
        <AdministrativeDivisionPicker
          handleChange={this.handleAdministrativeDivisionChange}
          administrativeDivisions={administrativeDivisions}
          administrativeDivisionId={administrativeDivisionId}
        />
        <Grid container>
          <Grid item xs={4}>
            <Box paddingRight={3} paddingBottom={3}>
              <SelectField
                label="Enrollment period *"
                key="enrollmentPeriod"
                name="enrollmentPeriod"
                options={enrollmentPeriodByAdministrativeDivisionOptions}
                value={enrollmentPeriod}
                onChange={this.handleSelectChange}
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box paddingRight={3} paddingBottom={3}>
              <DatePicker
                disabled={startDateDisabled}
                ref={this.startDateRef}
                defaultDate={ethiopianStartDate}
                label="Start Date"
                name="start-date"
                maxDate={ethiopianEndDate || ethiopianCurrentDate}
                onChange={momentDate => this.handleDateChange(momentDate, true)}
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box paddingBottom={3}>
              <DatePicker
                disabled={endDateDisabled}
                ref={this.endDateRef}
                defaultDate={ethiopianEndDate}
                label="End Date"
                minDate={ethiopianStartDate}
                maxDate={ethiopianCurrentDate}
                name="end-date"
                onChange={momentDate => this.handleDateChange(momentDate, false)}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={4}>
            <Box paddingRight={3} paddingBottom={3}>
              <SelectField
                disabled={payingDisabled}
                key="paying"
                name="paying"
                label="Membership type"
                options={payingOptions}
                value={paying || EMPTY_VALUE}
                onChange={this.handleSelectChange}
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box paddingRight={3} paddingBottom={3}>
              <SelectField
                disabled={renewalDisabled}
                key="renewal"
                name="renewal"
                label="Enrollment type"
                options={renewalOptions}
                value={renewal || EMPTY_VALUE}
                onChange={this.handleSelectChange}
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box paddingBottom={3}>
              <SelectField
                key="gender"
                name="gender"
                label="Gender"
                options={genderOptions}
                value={gender || EMPTY_VALUE}
                onChange={this.handleSelectChange}
              />
            </Box>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={4}>
            <Box paddingRight={3} paddingBottom={3}>
              <SelectField
                key="membershipStatus"
                name="membershipStatus"
                label="Membership Status"
                options={membershipStatusOptions}
                value={membershipStatus || EMPTY_VALUE}
                onChange={this.handleSelectChange}
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box paddingRight={3} paddingBottom={3}>
              <SelectField
                key="beneficiaries"
                name="beneficiaries"
                label="Beneficiaries"
                options={beneficiariesOptions}
                value={beneficiaries || EMPTY_VALUE}
                onChange={this.handleSelectChange}
              />
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box paddingRight={3} paddingBottom={3}>
              <SearchInput
                name="name"
                key="name"
                value={name}
                placeholder="E.g Abebe"
                onChange={this.handleInputChange}
              />
            </Box>
          </Grid>
        </Grid>
        <Box flex justifyContent="space-between">
          <Box flex flexDirection="column">
            <Button small onClick={this.handleClearFilter}>
              <Icon name="clear" size={18} iconSize={18} />
              {' Clear all filters'}
            </Button>
          </Box>
          {memberList.members && (
            memberList.members.length > 0 && (
              <Box flex flexDirection="column" paddingTop={10}>
                <UnderlinedAnchor onClick={this.handleExport}>
                  <Icon name="download" size={18} iconSize={18} />
                  {' Export'}
                </UnderlinedAnchor>
              </Box>
            )
          )}
          <Box flex flexDirection="column">
            <Button small primary onClick={this.loadMembers} disabled={disabled}>Search</Button>
          </Box>
        </Box>
        <Box paddingTop={30}>
          <MemberListResult />
        </Box>
      </Container>
    );
  }
}

MemberList.propTypes = {
  administrativeDivisions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  enrollmentPeriodByAdministrativeDivisionOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  loadAdministrativeDivisions: PropTypes.func.isRequired,
  loadEnrollmentPeriods: PropTypes.func.isRequired,
  loadEnrollmentPeriodsByAdministrativeDivision: PropTypes.func.isRequired,
  clearEnrollmentPeriodsByAdministrativeDivision: PropTypes.func.isRequired,
  loadMembersList: PropTypes.func.isRequired,
  fetchMemberListCSV: PropTypes.func.isRequired,
  memberList: PropTypes.shape({
    members: PropTypes.array.isRequired,
  }).isRequired,
};

export default connect(MemberList.mapStateToProps, MemberList.mapDispatchToProps)(MemberList);
