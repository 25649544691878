import { createSelector } from 'reselect';
import { flow, map } from 'lodash/fp';

export const flagOptionsSelector = state => state.flags.flags.map(flag => ({ value: flag.id, name: flag.name }));

export const flagSelector = state => state.flags.flags;

export const flagsOptionsSelector = createSelector(
  flagSelector,
  (flags) => {
    const tempFlags = flow(
      map(flag => ({ value: flag.id, name: flag.name })),
    )(flags);
    return [
      { value: 'none', name: 'Select Flag' },
      ...tempFlags,
    ];
  },
);
