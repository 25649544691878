import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { LayoutWithHeader } from 'components/layouts';
import Container from 'components/container';
import UploadClaimsView from 'views/upload-claims-view';
import Box from 'components/box';
import Pill from 'components/pill';
import { TabLinks, TabLink } from 'components/tabs';
import { Grid } from '@material-ui/core';
import ImportClaimsStatus from 'views/import-claims-status';


class ImportClaimsView extends Component {
  state = {
    tab: 'upload',
  }

  renderTab = () => {
    const { tab } = this.state;
    if (tab === 'upload') return <UploadClaimsView />;
    if (tab === 'import') return 'Status';
    return 'hello';
  }

  handleTabClick = (event) => {
    this.setState({ tab: event });
  }

  render() {
    const { tab } = this.state;
    return (
      <LayoutWithHeader pageTitle="Import Claims" steps={[{ title: 'Claims', href: '/claims' }]}>
        <Container>
          <TabLinks activeTabKey={tab} onTabLinkClick={this.handleTabClick}>
            <Grid container>
              <Grid item xs={6}>
                <TabLink key="upload" tabKey="upload"><Pill>Upload</Pill></TabLink>
                <Box paddingTop={30} paddingBottom={5}>
                  <UploadClaimsView />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <TabLink key="status" tabKey="status"><Pill>Status</Pill></TabLink>
                <Box paddingTop={30} paddingBottom={5}>
                  <ImportClaimsStatus />
                </Box>
              </Grid>
            </Grid>
          </TabLinks>

        </Container>
      </LayoutWithHeader>
    );
  }
}

ImportClaimsView.propTypes = {
  history: PropTypes.shape({}).isRequired,
};

export default ImportClaimsView;
