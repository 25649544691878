import { createSelector } from 'reselect';
import { filter, findIndex, flow, isEmpty, values } from 'lodash/fp';
import moment from 'moment';

import { objectHasProp } from 'lib/utils';
import { ADMIN_DIVISIONS, MEMBERSHIP_STATUS_STATES } from 'lib/config';
import { administrativeDivisionsByIdSelector, viewableAdminDivisionIdsSelector } from 'store/administrative-divisions/administrative-divisions-selectors';

export const membersKeyedByIdSelector = state => state.members.members;

export const memberByIdSelector = createSelector(
  (_, memberId) => memberId,
  membersKeyedByIdSelector,
  (memberId, members) => ((members && objectHasProp(members, memberId))
    ? members[memberId]
    : null));

// for the time being do not check if member.administrativeDivisionId is in viewableDivisions
export const viewableMembersSelector = createSelector(
  membersKeyedByIdSelector,
  viewableAdminDivisionIdsSelector,
  membersKeyedById => filter(
    // member => includes(member.administrativeDivisionId)(viewableDivisions),
    () => true,
  )(membersKeyedById),
);

export const getMemberIndexById = id => findIndex(member => member.id === id);

// this takes in the memberId and returns a list of members in the associated household
export const householdByMemberIdSelector = createSelector(
  memberByIdSelector,
  membersKeyedByIdSelector,
  (currentMember, members) => (currentMember
    ? flow(
      filter(m => (m.householdId === currentMember.householdId && m.id !== currentMember.id)),
      filter(m => (m.householdId !== null)))(values(members))
    : []),
);

export const memberWoredaSelector = createSelector(
  memberByIdSelector,
  administrativeDivisionsByIdSelector,
  (member, adminDivisionsById) => {
    if (!member || !adminDivisionsById) {
      return null;
    }
    const adminDivisionId = member.administrativeDivisionId;
    if (!adminDivisionId || !objectHasProp(adminDivisionsById, adminDivisionId)) {
      return null;
    }

    const adminDivision = adminDivisionsById[adminDivisionId];
    switch (adminDivision.level) {
      case ADMIN_DIVISIONS.WOREDA:
        return adminDivision;
      case ADMIN_DIVISIONS.KEBELE:
        return adminDivisionsById[adminDivision.parentId];
      case ADMIN_DIVISIONS.GOTE: {
        const kebele = adminDivisionsById[adminDivision.parentId];
        return adminDivisionsById[kebele.parentId];
      }
      default:
        return null;
    }
  },
);

export const curriedMemberWoredaSelectorCreator = state => memberId => memberWoredaSelector(state, memberId);

export const memberKebeleSelector = createSelector(
  memberByIdSelector,
  administrativeDivisionsByIdSelector,
  (member, adminDivisionsById) => {
    if (!member || !adminDivisionsById) {
      return null;
    }
    const adminDivisionId = member.administrativeDivisionId;
    if (!adminDivisionId || !objectHasProp(adminDivisionsById, adminDivisionId)) {
      return null;
    }

    const adminDivision = adminDivisionsById[adminDivisionId];
    switch (adminDivision.level) {
      case ADMIN_DIVISIONS.WOREDA:
        return null;
      case ADMIN_DIVISIONS.KEBELE:
        return adminDivision;
      case ADMIN_DIVISIONS.GOTE:
        return adminDivisionsById[adminDivision.parentId];
      default:
        return null;
    }
  },
);

export const cardReplacementFeeSelector = state => state.members.premia && state.members.premia.length && state.members.premia[0].paymentTypes.find(pt => pt.paymentName === 'card_replacement_fee');

const enrollmentPeriodsSelector = state => state.enrollment.enrollmentPeriods;

export const membershipStatusByMemberIdSelector = createSelector(
  memberByIdSelector,
  enrollmentPeriodsSelector,
  (member, enrollmentPeriodsById) => {
    if (!member || isEmpty(enrollmentPeriodsById)) {
      return null;
    }
    const { archivedAt, archivedReason, householdId, relationshipToHead, coverageEndDate, renewedAt } = member;
    const membershipStatus = {
      memberStatusEnum: null,
      memberStatusDate: null,
      beneficiaryStatusEnum: null,
      beneficiaryStatusDate: null,
    };

    // no enrollment information, so status is unknown
    if (householdId === null) {
      membershipStatus.memberStatusEnum = MEMBERSHIP_STATUS_STATES.UNKNOWN;
      return membershipStatus;
    }

    const withinCoveragePeriod = moment().diff(coverageEndDate, 'days') <= 0;
    const lastRenewedAt = renewedAt;
    const isHeadOfHousehold = relationshipToHead === 'SELF';
    if (withinCoveragePeriod) {
      membershipStatus.memberStatusEnum = MEMBERSHIP_STATUS_STATES.ACTIVE;
      membershipStatus.memberStatusDate = lastRenewedAt;
    } else {
      membershipStatus.memberStatusEnum = MEMBERSHIP_STATUS_STATES.EXPIRED;
      membershipStatus.memberStatusDate = lastRenewedAt;
    }

    if (isHeadOfHousehold && archivedReason) {
      membershipStatus.memberStatusEnum = MEMBERSHIP_STATUS_STATES.DELETED;
      membershipStatus.memberStatusDate = archivedAt;
      return membershipStatus;
    }

    if (!isHeadOfHousehold) {
      if (archivedReason === 'UNPAID') {
        membershipStatus.beneficiaryStatusEnum = MEMBERSHIP_STATUS_STATES.EXPIRED;
        // we can't computer when the individual beneficiary last renewed so leave the date blank
      } else if (archivedReason) {
        membershipStatus.beneficiaryStatusEnum = MEMBERSHIP_STATUS_STATES.DELETED;
        membershipStatus.beneficiaryStatusDate = archivedAt;
      } else {
        membershipStatus.beneficiaryStatusEnum = membershipStatus.memberStatusEnum;
        membershipStatus.beneficiaryStatusDate = membershipStatus.memberStatusDate;
      }
    }

    return membershipStatus;
  },
);
