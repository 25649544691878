import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Box from 'components/box';
import Spinner from 'components/spinner';
import { Text } from 'components/text';

/**
 * Component
 */

const ApprovingIndicator = ({ custom, ...props }) => (
  <Box flex justifyContent="center" alignItems="center" {...props}>
    <Fragment>
      <Box paddingRight={4} color="gray.5">
        <Spinner small />
      </Box>
      <Text verticalAlign="middle">
        {custom}
      </Text>
    </Fragment>

  </Box>
);

ApprovingIndicator.propTypes = {
  custom: PropTypes.string,
};

ApprovingIndicator.defaultProps = {
  custom: null,
};

/**
 * Exports
 */

export default ApprovingIndicator;
