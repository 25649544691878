/* eslint-disable max-len */
/* eslint-disable arrow-body-style */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/prop-types */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import { formatDate } from 'lib/formatters/date';
import { LayoutWithHeader } from 'components/layouts';
import Container from 'components/container';
import Box from 'components/box';
import Button from 'components/button';
import { toGregorian } from 'ethiopian-date';
import Icon from 'components/icon';
import { DatePicker, SelectField, DrugPicker } from 'components/inputs';
import { fetchClaimsReportsCSV as fetchClaimsReportsCSVAction } from 'store/claims/claims-actions';
import { fetchBillablesDrug as fetchBillablesDrugAction } from 'store/billables/billables-actions';


class ClaimsReports extends Component {
  static mapStateToProps = state => ({
    isLoadingBillablesDrug: state.billables.isLoadingBillablesDrug,
    billablesDrugError: state.billables.billablesDrugError,
    billablesDrug: state.billables.billablesDrug,
  })

  static mapDispatchToProps = dispatch => ({
    fetchClaimsReportsCSV: filters => dispatch(fetchClaimsReportsCSVAction(filters)),
    fetchBillablesDrug: () => dispatch(fetchBillablesDrugAction()),

  });

  startDateRef = React.createRef();

  endDateRef = React.createRef();

  state = {
    startDate: null,
    endDate: null,
    topOption: 'frequency',
    billableName: '',
    startDateError: null,
  };

  componentDidMount() {
    const { fetchBillablesDrug } = this.props;
    fetchBillablesDrug();
  }

  handleDateChange = (gregorianDate, ethiopianDate, isStart) => {
    if (isStart) {
      this.setState({ startDate: ethiopianDate });
    } else {
      this.setState({ endDate: ethiopianDate });
    }
  }

  handleExport = () => {
    const { fetchClaimsReportsCSV } = this.props;

    // minimum startDate is needed
    const { startDate, endDate, topOption, billableName } = this.state;
    let tempStartDate = '';
    let tempEndDate = '';

    if (!startDate) {
      this.setState({ startDateError: 'Start date is required.' });
      return;
    }

    if (endDate) {
      const date = endDate.split('-');
      tempStartDate = startDate;
      const m = Number(date[1]);
      const y = Number(date[2]);
      if (m === 13) {
        tempEndDate = `05-13-${y}`;
      } else {
        tempEndDate = `30-${m}-${y}`;
      }
    } else {
      const date = startDate.split('-');
      tempStartDate = startDate;
      const m = Number(date[1]);
      const y = Number(date[2]);
      if (m === 13) {
        tempEndDate = `05-13-${y}`;
      } else {
        tempEndDate = `30-${m}-${y}`;
      }
    }

    const [dayStart, monthStart, yearStart] = tempStartDate.split('-');
    let gregorianStartDate = toGregorian(
      parseInt(yearStart, 10),
      parseInt(monthStart, 10),
      parseInt(dayStart, 10),
    );
    gregorianStartDate = moment.utc(gregorianStartDate, 'YYYY-MM-DD').format('YYYY-MM-DD');

    const [dayEnd, monthEnd, yearEnd] = tempEndDate.split('-');
    let gregorianEndDate = toGregorian(
      parseInt(yearEnd, 10),
      parseInt(monthEnd, 10),
      parseInt(dayEnd, 10),
    );
    gregorianEndDate = moment.utc(gregorianEndDate, 'YYYY-MM-DD').format('YYYY-MM-DD');
    const name = billableName === '' ? null : billableName.substring(0, billableName.length - 1);
    const params = { start_date: gregorianStartDate, end_date: gregorianEndDate, name, top_by: topOption };
    fetchClaimsReportsCSV(params);
  }

  handleSelectChange = (e) => {
    this.setState({ topOption: e.target.value });
  }

  handleInputChange = (name) => {
    this.setState({ billableName: name });
  }

  handleClearFilter = () => {
    this.setState({
      startDate: null,
      endDate: null,
      topOption: 'frequency',
      billableName: '',
      startDateError: null,
    });
    if (this.startDateRef.current) {
      this.startDateRef.current.clear();
    }
    if (this.endDateRef.current) {
      this.endDateRef.current.clear();
    }
  }

  render() {
    const { startDate, endDate, billableName, topOption, startDateError } = this.state;
    const disabled = billableName.length !== 0;
    const { billablesDrug } = this.props;
    const ethiopianCurrentDate = formatDate(moment());
    const ethiopianStartDate = startDate ? formatDate(startDate) : undefined;
    const ethiopianEndDate = endDate ? formatDate(endDate) : undefined;

    const topOptions = [
      { value: 'frequency', name: 'Frequency' },
      { value: 'value', name: 'Value' },
    ];

    const billables = [];
    if (billablesDrug.billables && billablesDrug.billables.length > 0) {
      billablesDrug.billables.forEach((b) => {
        billables.push({ value: `${b.name}${b.composition ? `(${b.composition})` : ''} ${b.unit ? `(${b.unit})` : ''}` });
      });
    }


    return (
      <LayoutWithHeader pageTitle="Claims Report" steps={[{ title: 'Claims Reports', href: '/claims_reports' }]}>
        <Container>
          <Grid container>
            <Grid item xs={4}>
              <Box paddingRight={3} paddingBottom={3}>
                <DatePicker
                  ref={this.startDateRef}
                  label="Start Date"
                  name="start-date"
                  maxDate={ethiopianEndDate}
                  onChange={(gregorianDate, ethiopianDate) => this.handleDateChange(gregorianDate, ethiopianDate, true)}
                  pick="month"
                  error={startDateError}
                />
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box paddingBottom={3}>
                <DatePicker
                  ref={this.endDateRef}
                  // defaultDate={ethiopianEndDate}
                  label="End Date"
                  minDate={ethiopianStartDate}
                  maxDate={ethiopianCurrentDate}
                  name="end-date"
                  onChange={(gregorianDate, ethiopianDate) => {
                    return this.handleDateChange(gregorianDate, ethiopianDate, false);
                  }}
                  pick="month"
                />
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box paddingLeft={3} paddingBottom={3}>
                <SelectField
                  key="topOption"
                  name="topOption"
                  label="Top 10 By"
                  options={topOptions}
                  value={topOption}
                  disabled={disabled}
                  onChange={this.handleSelectChange}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={6}>
              <Box paddingRight={3} paddingBottom={3}>
                <DrugPicker items={billables} handleInputChange={this.handleInputChange} inputValue={billableName} />
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box flex flexDirection="column" paddingLeft={0} paddingTop={3}>
                <Button primary small onClick={this.handleExport}>
                  <Icon name="download" size={18} iconSize={18} />
                  {' Export Excel'}
                </Button>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box flex flexDirection="column" paddingLeft={3} paddingTop={3}>
                <Button small onClick={this.handleClearFilter}>
                  <Icon name="clear" size={18} iconSize={18} />
                  {' Clear all filters'}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </LayoutWithHeader>
    );
  }
}

ClaimsReports.propTypes = {
  history: PropTypes.shape({}).isRequired,
  fetchClaimsReportsCSV: PropTypes.func.isRequired,
  fetchBillablesDrug: PropTypes.func.isRequired,
};

export default connect(ClaimsReports.mapStateToProps, ClaimsReports.mapDispatchToProps)(ClaimsReports);
