import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { filterByPredicate } from 'lib/utils';
import { formatDate } from 'lib/formatters/date';
import { formatShortId } from 'lib/formatters';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

import Box from 'components/box';
import NullValue from 'components/null-value';
import { SearchInput } from 'components/inputs';
import { Text } from 'components/text';
import { withFilter, withApiPagination, withApiSorting, TextTableCell } from 'components/table';
import ClaimIcon from 'components/claim-icon';

class DraftClaimsTable extends Component {
  state = {
    searchValue: null,
  };

  handleSearchInputChange = (e) => {
    const searchValue = e.target.value;
    this.setState({ searchValue });
  }

  render() {
    const { data, onClickRow, onSortChange, sortDirection, sortField, providerId } = this.props;
    const { searchValue } = this.state;
    let filteredData = data;
    if (searchValue) {
      filteredData = filterByPredicate(searchValue, ['claimId', 'member.membershipNumber', 'member.medicalRecordNumber', 'visitType'])(data);
    }
    const hasData = filteredData && filteredData.length > 0;
    const cols = [
      { id: 'claimId', label: 'Claim ID', alignLeft: true, padding: false },
      { id: 'name', label: 'Full Name', alignLeft: true, padding: false },
      { id: 'age', label: 'Age', alignLeft: true, padding: false },
      { id: 'gender', label: 'Gender', alignLeft: true, padding: false },
      { id: 'member.membershipNumber', label: 'CBHI ID', alignLeft: true, padding: false },
      { id: 'member.medicalRecordNumber', label: 'MRN', alignLeft: true, padding: false },
      { id: 'visitType', label: 'Visit Type', alignLeft: true, padding: false },
      { id: 'occurredAt', label: 'Serviced', alignLeft: false, padding: false },
    ];
    return (
      <Fragment>
        <Box width="30%">
          <SearchInput
            placeholder="Search"
            onChange={this.handleSearchInputChange}
          />
        </Box>
        <Box flex justifyContent="center">
          <Table>
            <TableHead>
              <TableRow>
                {cols.map(col => (
                  <TableCell
                    key={col.id}
                    padding="checkbox"
                    sortDirection={sortField === col.id ? sortDirection : false}
                    align={col.alignLeft ? 'left' : 'right'}
                  >
                    <TableSortLabel
                      active={sortField === col.id}
                      direction={sortDirection}
                      onClick={() => onSortChange(col.id)}
                    >
                      <Text fontSize={3} fontFamily="sans">{col.label}</Text>
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {!hasData && <TableRow><TextTableCell align="center" colSpan={6}>No data available</TextTableCell></TableRow>}
              {hasData && filteredData.map(c => (
                <TableRow key={c.id} style={{ height: 40, paddingTop: 8, paddingBottom: 8 }} onClick={() => onClickRow(`/submissions/${c.claimId}/edit`)} hover>
                  <TableCell padding="checkbox">
                    <ClaimIcon claim={{ ...c, shortClaimId: formatShortId(c.claimId) }} />
                  </TableCell>
                  <TextTableCell padding="checkbox">{c.member && c.member.fullName}</TextTableCell>
                  <TextTableCell padding="checkbox">{c.member && c.member.age}</TextTableCell>
                  <TextTableCell padding="checkbox">{c.member && c.member.gender}</TextTableCell>
                  <TextTableCell padding="checkbox">{c.member && c.member.membershipNumber}</TextTableCell>
                  <TextTableCell TextTableCell padding="checkbox">{c?.member && c?.member?.medicalRecordNumbers?.[providerId]}</TextTableCell>
                  <TextTableCell padding="checkbox">{c.visitType ? c.visitType : <NullValue />}</TextTableCell>
                  <TextTableCell padding="checkbox" align="right">{formatDate(c.occurredAt)}</TextTableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </Fragment>
    );
  }
}

export default withFilter(withApiSorting((withApiPagination(DraftClaimsTable))));

DraftClaimsTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired, // TODO make shape
  sortDirection: PropTypes.string.isRequired,
  sortField: PropTypes.string.isRequired,
  onClickRow: PropTypes.func.isRequired,
  onSortChange: PropTypes.func.isRequired,
  providerId: PropTypes.number.isRequired,
};

DraftClaimsTable.defaultProps = {
};
