import {
  FETCH_FLAGS_FAILURE,
  FETCH_FLAGS_SUCCESS,
  FETCH_FLAGS_REQUEST,
} from './flag-actions';

export const initialState = {
  isLoadingFlags: false,
  flagsError: '',
  flags: [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_FLAGS_REQUEST:
      return {
        ...state,
        isLoadingFlags: true,
        flagsError: '',
      };
    case FETCH_FLAGS_SUCCESS:
      return {
        ...state,
        isLoadingFlags: false,
        flagsError: '',
        flags: action.response,
      };
    case FETCH_FLAGS_FAILURE:
      return {
        ...state,
        isLoadingFlags: false,
        flagsError: action.errorMessage,
      };
    default:
      return state;
  }
}
