import { camelCaseObject, getErrorMessage } from 'lib/utils';
import { createDelta } from 'store/deltas/deltas-actions';
import uuid from 'uuid/v4';
import { normalize } from 'normalizr';
import memberSchema from 'store/schemas/member-schema';

/**
 * Fetch household members
 */

export const STORE_MEMBERS = 'STORE_MEMBERS';
export const FETCH_MEMBERS_REQUEST = 'FETCH_MEMBERS_REQUEST';
export const FETCH_MEMBERS_SUCCESS = 'FETCH_MEMBERS_SUCCESS';
export const FETCH_MEMBERS_FAILURE = 'FETCH_MEMBERS_FAILURE';

export const fetchMembers = params => ({
  CALL_API: {
    call: api => api.fetchMembers(params),
    transformError: err => getErrorMessage(err),
    afterResponse: (response, dispatch) => {
      const normalizedResponse = normalize(response, [memberSchema]);
      dispatch({ type: STORE_MEMBERS, payload: normalizedResponse.entities.members });
    },
    types: [FETCH_MEMBERS_REQUEST, FETCH_MEMBERS_SUCCESS, FETCH_MEMBERS_FAILURE],
  },
});

export const FETCH_HOUSEHOLD_MEMBERS_REQUEST = 'FETCH_HOUSEHOLD_MEMBERS_REQUEST';
export const FETCH_HOUSEHOLD_MEMBERS_SUCCESS = 'FETCH_HOUSEHOLD_MEMBERS_SUCCESS';
export const FETCH_HOUSEHOLD_MEMBERS_FAILURE = 'FETCH_HOUSEHOLD_MEMBERS_FAILURE';

export const fetchHouseholdMembers = params => ({
  CALL_API: {
    call: api => api.fetchHouseholdMembers(params),
    transformError: err => getErrorMessage(err),
    types: [FETCH_HOUSEHOLD_MEMBERS_REQUEST, FETCH_HOUSEHOLD_MEMBERS_SUCCESS, FETCH_HOUSEHOLD_MEMBERS_FAILURE],
  },
});

/**
 * POST member
 */

export const POST_MEMBER_REQUEST = 'POST_MEMBER_REQUEST';
export const POST_MEMBER_SUCCESS = 'POST_MEMBER_SUCCESS';
export const POST_MEMBER_FAILURE = 'POST_MEMBER_FAILURE';

export const postMember = member => ({
  CALL_API: {
    call: api => api.postMember(member),
    transformError: err => getErrorMessage(err),
    types: [POST_MEMBER_REQUEST, POST_MEMBER_SUCCESS, POST_MEMBER_FAILURE],
  },
});

/**
 * GET premia
 */

export const FETCH_PREMIA_REQUEST = 'FETCH_PREMIA_REQUEST';
export const FETCH_PREMIA_SUCCESS = 'FETCH_PREMIA_SUCCESS';
export const FETCH_PREMIA_FAILURE = 'FETCH_PREMIA_FAILURE';

export const fetchPremia = enrollmentPeriodId => ({
  CALL_API: {
    call: api => api.fetchPremia(enrollmentPeriodId),
    transformError: err => getErrorMessage(err),
    types: [FETCH_PREMIA_REQUEST, FETCH_PREMIA_SUCCESS, FETCH_PREMIA_FAILURE],
  },
});

/**
 * Member action: Replace Card
 */

export const REPLACE_CARD_REQUEST = 'REPLACE_CARD_REQUEST';
export const REPLACE_CARD_SUCCESS = 'REPLACE_CARD_SUCCESS';
export const REPLACE_CARD_FAILURE = 'REPLACE_CARD_FAILURE';

export const replaceCard = (
  householdEnrollmentRecordId, receiptNumber, paymentDate, premiumId, cardReplacementFee,
) => ({
  CALL_API: {
    call: api => api.replaceCard(
      householdEnrollmentRecordId, receiptNumber, paymentDate, premiumId, cardReplacementFee,
    ),
    transformError: err => getErrorMessage(err),
    types: [
      REPLACE_CARD_REQUEST,
      REPLACE_CARD_SUCCESS,
      REPLACE_CARD_FAILURE,
    ],
  },
});

/**
 * Member action: Update Member
 */

export const UPDATE_MEMBER_REQUEST = 'UPDATE_MEMBER_REQUEST';
export const UPDATE_MEMBER_SUCCESS = 'UPDATE_MEMBER_SUCCESS';
export const UPDATE_MEMBER_FAILURE = 'UPDATE_MEMBER_FAILURE';

function formatServerError(err) {
  const serverError = err.response;

  if (serverError && serverError.data) {
    return { 422: { validationErrors: camelCaseObject(serverError.data.errors) } };
  }

  return {};
}

export const updateMember = member => ({
  CALL_API: {
    call: api => api.updateMember(member),
    transformError: err => getErrorMessage(err, formatServerError(err)),
    types: [UPDATE_MEMBER_REQUEST, UPDATE_MEMBER_SUCCESS, UPDATE_MEMBER_FAILURE],
  },
});

export const updateMemberPhoto = (memberId, photo) => ({
  CALL_API: {
    call: api => api.updateMemberPhoto(memberId, photo),
    transformError: err => getErrorMessage(err, formatServerError(err)),
    types: [UPDATE_MEMBER_REQUEST, UPDATE_MEMBER_SUCCESS, UPDATE_MEMBER_FAILURE],
  },
});

export const CREATE_MEMBER = 'CREATE_MEMBER';

export const createMember = member => ({
  type: CREATE_MEMBER,
  member,
});

export const createMemberWithDelta = member => (
  (dispatch) => {
    dispatch(createMember(member));
    const delta = {
      id: uuid(),
      modelId: member.id,
      modelType: 'Member',
      action: 'POST',
      synced: false,
    };
    dispatch(createDelta(delta));
  }
);


export const JSON_UPDATE_MEMBER = 'JSON_UPDATE_MEMBER';

export const jsonUpdateMember = member => ({
  type: JSON_UPDATE_MEMBER,
  member,
});

export const updateMemberWithDelta = member => (
  (dispatch) => {
    dispatch(jsonUpdateMember(member));
    const delta = {
      id: uuid(),
      modelId: member.id,
      modelType: 'Member',
      action: 'PATCH',
      synced: false,
    };
    dispatch(createDelta(delta));
  }
);

// Fetch filterable member lists
export const MEMBER_LIST_REQUEST = 'MEMBER_LIST_REQUEST';
export const MEMBER_LIST_SUCCESS = 'MEMBER_LIST_SUCCESS';
export const MEMBER_LIST_FAILURE = 'MEMBER_LIST_FAILURE';
export const MEMBER_LIST_CLEAR = 'MEMBER_LIST_CLEAR';

export const fetchMembersList = filters => ({
  CALL_API: {
    call: api => api.fetchMembersList(filters),
    transformError: err => getErrorMessage(err),
    types: [
      MEMBER_LIST_REQUEST,
      MEMBER_LIST_SUCCESS,
      MEMBER_LIST_FAILURE,
    ],
  },
});

export const fetchMembersByUrl = url => ({
  CALL_API: {
    call: api => api.fetchMembersByUrl(url),
    transformError: err => getErrorMessage(err),
    types: [
      MEMBER_LIST_REQUEST,
      MEMBER_LIST_SUCCESS,
      MEMBER_LIST_FAILURE,
    ],
  },
});


export const FETCH_MEMBER_LIST_CSV_REQUEST = 'FETCH_MEMBER_LIST_CSV_REQUEST';
export const FETCH_MEMBER_LIST_CSV_SUCCESS = 'FETCH_MEMBER_LIST_CSV_SUCCESS';
export const FETCH_MEMBER_LIST_CSV_FAILURE = 'FETCH_MEMBER_LIST_CSV_FAILURE';

export const fetchMemberListCSV = filters => ({
  CALL_API: {
    call: api => api.fetchMemberListCSV(filters),
    transformError: err => getErrorMessage(err),
    types: [
      FETCH_MEMBER_LIST_CSV_REQUEST,
      FETCH_MEMBER_LIST_CSV_SUCCESS,
      FETCH_MEMBER_LIST_CSV_FAILURE,
    ],
  },
});
