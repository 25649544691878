import React, { Component } from 'react';
import { LayoutWithHeader } from 'components/layouts';

import PropTypes from 'prop-types';
import { connect } from 'react-redux';

// Import necessary actions
import { fetchEligibleHouseholds, createEligibleHousehold } from 'store/eligible-households/eligible-households-actions';
import { fetchAdministrativeDivisions } from 'store/administrative-divisions/administrative-divisions-actions';
import { fetchEnrollmentPeriods } from 'store/enrollment/enrollment-actions';
import EligibleHouseholdsContainer from '../containers/eligible-households/eligible-households-container';

class EligibleHouseholdsView extends Component {
  static mapDispatchToProps = dispatch => ({
    loadEligibleHouseholds() {
      dispatch(fetchEligibleHouseholds());
    },
    loadAdministrativeDivisions() {
      dispatch(fetchAdministrativeDivisions());
    },
    loadEnrollmentPeriods() {
      dispatch(fetchEnrollmentPeriods());
    },
    createEligibleHousehold(params) {
      dispatch(createEligibleHousehold(params)).then(() => dispatch(fetchEligibleHouseholds()));
    },
    // Similarly, add methods for update and delete
  })

  static mapStateToProps = (state) => {
    const administrativeDivisionOptions = Object.values(state.administrativeDivisions.administrativeDivisions);
    const enrollmentPeriodOptions = Object.values(state.enrollment.enrollmentPeriods);

    return {
      eligibleHouseholds: state.eligibleHouseholds.eligibleHouseholds,
      enrollmentPeriodOptions: enrollmentPeriodOptions.map(({ id, coverageStartDate, coverageEndDate }) => ({ value: id, name: `${coverageStartDate} - ${coverageEndDate}` })),
      administrativeDivisionOptions: administrativeDivisionOptions.map(({ id, name }) => ({ value: id, name })),
    };
  }

  componentDidMount() {
    const { loadEligibleHouseholds, loadAdministrativeDivisions, loadEnrollmentPeriods } = this.props;
    loadEligibleHouseholds();
    loadAdministrativeDivisions();
    loadEnrollmentPeriods();
  }


  render() {
    const { enrollmentPeriodOptions, administrativeDivisionOptions, eligibleHouseholds } = this.props;

    return (
      <LayoutWithHeader minWidth={1300} maxWidth={1300} pageTitle="Eligible Households" steps={[{ title: 'Eligible Households', href: '/eligible_households' }]}>
        {eligibleHouseholds.length > 0 && administrativeDivisionOptions.length > 0 && (
          <EligibleHouseholdsContainer
            eligibleHouseholds={eligibleHouseholds}
            enrollmentPeriodOptions={enrollmentPeriodOptions}
            administrativeDivisionOptions={administrativeDivisionOptions}
          />
        )}
      </LayoutWithHeader>
    );
  }
}

export default connect(
  EligibleHouseholdsView.mapStateToProps,
  EligibleHouseholdsView.mapDispatchToProps,
)(EligibleHouseholdsView);

EligibleHouseholdsView.propTypes = {
  enrollmentPeriodOptions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  administrativeDivisionOptions: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  eligibleHouseholds: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    enrollmentPeriodId: PropTypes.number,
    administrativeDivisionId: PropTypes.number,
    expectedHouseholds: PropTypes.string, // or PropTypes.number if it's a number
    versions: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
    })),
    enrollmentPeriod: PropTypes.shape({
      administrativeDivisionId: PropTypes.number.isRequired,
      coverageEndDate: PropTypes.string.isRequired,
      coverageStartDate: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      updatedAt: PropTypes.string.isRequired,
      endDate: PropTypes.string.isRequired,
      startDate: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    }).isRequired,
    administrativeDivision: PropTypes.shape({
      branchId: PropTypes.any,
      code: PropTypes.string,
      name: PropTypes.string.isRequired,
      level: PropTypes.string.isRequired,
      createdAt: PropTypes.string.isRequired,
      updatedAt: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
      parentId: PropTypes.number.isRequired,
    }).isRequired,
    // ... other properties
  })).isRequired,
  loadEligibleHouseholds: PropTypes.func.isRequired,
  loadAdministrativeDivisions: PropTypes.func.isRequired,
  loadEnrollmentPeriods: PropTypes.func.isRequired,
};
