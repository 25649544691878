// src/components/CardIdInput/CardIdInput.jsx
import React, { useState } from 'react';
import PropTypes from 'prop-types';

/**
 * CardIdInput Component
 * Handles the input, formatting, and validation of the Card ID.
 */
const CardIdInput = ({ initialCardId, onCardIdUpdate, updateError = '' }) => {
  const [inputValue, setInputValue] = useState(formatWithSpaces(initialCardId || ''));
  const [error, setError] = useState(updateError);

  /**
   * Formats the input by inserting spaces after every three characters.
   * E.g., "AAA123456" => "AAA 123 456"
   */
  function formatWithSpaces(value) {
    // Remove all non-alphanumeric characters
    const cleaned = value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
    const parts = [];

    for (let i = 0; i < cleaned.length; i += 3) {
      parts.push(cleaned.substring(i, i + 3));
    }

    return parts.join(' ');
  }

  /**
   * Removes spaces from the formatted input.
   */
  function removeSpaces(value) {
    return value.replace(/\s+/g, '');
  }

  /**
   * Validates the Card ID according to the specified rules:
   * 1. Exactly 9 characters.
   * 2. First 3 characters are alphabets.
   * 3. Next 6 characters are digits.
   */
  const validateCardId = (value) => {
    const regex = /^[A-Z]{3}\d{6}$/;
    return regex.test(value);
  };

  /**
   * Handles input changes by formatting the value.
   */
  const handleChange = (e) => {
    const formatted = formatWithSpaces(e.target.value);
    setInputValue(formatted);
    setError(''); // Reset error on change
  };

  /**
   * Handles input blur by validating the Card ID.
   * If valid, calls the onCardIdUpdate prop with the unformatted Card ID.
   */
  const handleBlur = () => {
    const unformatted = removeSpaces(inputValue);
    if (unformatted.length !== 9) {
      setError('Card ID must be exactly 9 characters.');
      return;
    }
    if (!validateCardId(unformatted)) {
      setError(
        'Invalid Card ID format. It should start with 3 letters followed by 6 digits.'
      );
      return;
    }

    // If validation passes, update the backend
    onCardIdUpdate(unformatted)
      .then(() => {
        setError(''); // Clear any existing errors
      })
      .catch((err) => {
        setError('Failed to update Card ID. Please try again.');
        console.error(err);
      });
  };

  return (
    <div className="tw-mt-4">
      <label htmlFor="cardId" className="tw-block tw-text-sm tw-font-medium tw-text-gray-700">
        QR Code
      </label>
      <input
        type="text"
        id="cardId"
        name="cardId"
        value={inputValue}
        onChange={handleChange}
        onBlur={handleBlur}
        maxLength={11} // 9 characters + 2 spaces
        className={`tw-mt-1 tw-block tw-w-full tw-rounded-md tw-border ${
          error ? 'tw-border-red-500' : 'tw-border-gray-300'
        } tw-shadow-sm tw-px-3 tw-py-2 focus:tw-outline-none focus:tw-ring-blue-500 focus:tw-border-blue-500`}
        placeholder="AAA 123 456"
        aria-describedby="cardId-description"
      />
      {error && (
        <p className="tw-mt-2 tw-text-sm tw-text-red-600" id="cardId-error">
          {error}
        </p>
      )}
    </div>
  );
};

CardIdInput.propTypes = {
  initialCardId: PropTypes.string,
  onCardIdUpdate: PropTypes.func.isRequired,
};

export default CardIdInput;
