import {
  FETCH_INDIGENT_PAYMENTS_FAILURE,
  FETCH_INDIGENT_PAYMENTS_REQUEST,
  FETCH_INDIGENT_PAYMENTS_SUCCESS,
} from './indigent-payments-actions';


export const initialState = {
  indigentPayments: [],
  isLoadingIndigentPayments: false,
  error: null,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_INDIGENT_PAYMENTS_REQUEST: {
      return {
        ...state,
        isLoadingIndigentPayments: true,
        error: null,
      };
    }
    case FETCH_INDIGENT_PAYMENTS_SUCCESS: {
      return {
        ...state,
        indigentPayments: action.response,
        isLoadingIndigentPayments: false,
        error: null,
      };
    }
    case FETCH_INDIGENT_PAYMENTS_FAILURE: {
      return {
        ...state,
        indigentPayments: [],
        isLoadingIndigentPayments: false,
        error: action.errorMessage,
      };
    }
    default: {
      return state;
    }
  }
}
