/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Grid from '@material-ui/core/Grid';
import { isEmpty } from 'lodash/fp';
import { LayoutWithHeader } from 'components/layouts';
import Container from 'components/container';
import Box from 'components/box';
import { Alert } from 'components/alerts';
import Button from 'components/button';
import { TextField } from 'components/inputs';
import { changePassword as changePasswordAction } from 'store/auth/auth-actions';

class ChangePasswordForm extends Component {
  static mapStateToProps = state => ({
    isChangingPassword: state.auth.isChangingPassword,
    changePasswordError: state.auth.changePasswordError,
    changePasswordMessage: state.auth.changePasswordMessage,
  });

  static mapDispatchToProps = dispatch => ({
    changePassword: (currentPassword, newPassword) => dispatch(changePasswordAction(currentPassword, newPassword)),
  });

  state = {
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
    error: '',
    submitted: false,
    errors: {},
  };


  handleStateChange = (value, key) => {
    this.setState({ [key]: value });
    this.validateOnKeyUp(key, value);
  }

  validateOnKeyUp = (key, value) => {
    const { newPassword } = this.state;
    const newErrors = {};
    if (key === 'newPassword' && value.length < 8) {
      newErrors.newPassword = 'Password must be 8 characters long.';
    }
    if (key === 'confirmPassword' && newPassword !== value) {
      newErrors.confirmPassword = 'Password confirm doesn\'t match.';
    }
    this.setState({ errors: newErrors });
  }

  handleChangePassword = () => {
    const { currentPassword, newPassword, confirmPassword } = this.state;
    const { changePassword } = this.props;
    const newErrors = {};

    this.setState({ error: null });
    this.setState({ submitted: false });


    if (isEmpty(currentPassword)) newErrors.currentPassword = 'Current password can\'t be empty.';
    if (newPassword.length < 8) newErrors.newPassword = 'Password must be 8 characters long.';
    if (isEmpty(newPassword)) newErrors.newPassword = 'New password can\'t be empty.';
    if (isEmpty(confirmPassword)) newErrors.confirmPassword = 'Confirm password can\'t be empty.';
    if (newPassword !== confirmPassword) newErrors.confirmPassword = 'Password confirm doesn\'t match.';

    if (Object.keys(newErrors).length) return this.setState({ errors: newErrors }); // return if object has any properties

    this.setState({ submitted: true });
    changePassword(currentPassword, newPassword);
  };

  renderPasswordChangeMessage = () => {
    const { changePasswordMessage, changePasswordError } = this.props;
    // const { currentPassword, newPassword, confirmPassword } = this.state;
    if (changePasswordError) {
      return (
        <Box marginBottom={4}>
          <Alert type="error">{changePasswordError}</Alert>
        </Box>
      );
    }

    if (changePasswordMessage.status === false) {
      return (
        <Box marginBottom={4}>
          <Alert type="error">{changePasswordMessage.message}</Alert>
        </Box>
      );
    }
    if (changePasswordMessage.status === true) {
      // this.setState({ currentPassword: '', newPassword: '', confirmPassword: '' });
      return (
        <Box marginBottom={4}>
          <Alert type="success">{changePasswordMessage.message}</Alert>
        </Box>
      );
    }
  }

  render() {
    const { currentPassword, newPassword, confirmPassword, error, submitted, errors } = this.state;
    const { isChangingPassword } = this.props;

    if (!navigator.onLine) {
      return (
        <LayoutWithHeader pageTitle="Change Password" steps={[{ title: 'Passwords', href: '/change_password' }]}>
          <Container>
            <Box marginBottom={4}>
              <Alert type="error">There is no internet connection, please try later.</Alert>
            </Box>
          </Container>
        </LayoutWithHeader>
      );
    }
    return (
      <LayoutWithHeader pageTitle="Change Password" steps={[{ title: 'Passwords', href: '/change_password' }]}>
        <Container>
          <h3>Change Password</h3>
          <Grid item xs={6}>
            <Box marginTop={4} marginBottom={4}>
              {(isEmpty(error) && submitted) && (
                this.renderPasswordChangeMessage()
              )}
              {!isEmpty(error) && (
                <Box marginBottom={4}>
                  <Alert type="error">{error}</Alert>
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box marginTop={4} marginBottom={4}>
              <TextField
                onChange={e => this.handleStateChange(e.target.value, e.target.name)}
                name="currentPassword"
                type="password"
                value={currentPassword}
                label="Current Password"
                error={errors.currentPassword}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box marginTop={4} marginBottom={4}>
              <TextField
                onChange={e => this.handleStateChange(e.target.value, e.target.name)}
                name="newPassword"
                type="password"
                value={newPassword}
                label="New Password"
                error={errors.newPassword}
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box marginTop={4} marginBottom={4}>
              <TextField
                onChange={e => this.handleStateChange(e.target.value, e.target.name)}
                name="confirmPassword"
                type="password"
                value={confirmPassword}
                label="Confirm Password"
                error={errors.confirmPassword}
              />
            </Box>
          </Grid>
          <Grid item xs={2}>
            <Button primary type="submit" onClick={this.handleChangePassword} disabled={isChangingPassword}>
              {isChangingPassword ? 'Changing...' : 'Change Password'}
            </Button>
          </Grid>

        </Container>
      </LayoutWithHeader>
    );
  }
}

ChangePasswordForm.propTypes = {
  history: PropTypes.shape({}).isRequired,
  changePassword: PropTypes.func.isRequired,

};

export default connect(ChangePasswordForm.mapStateToProps, ChangePasswordForm.mapDispatchToProps)(ChangePasswordForm);
