import { merge } from 'lodash/fp';

import {
  FETCH_CLAIMS_PAGE_REQUEST,
  FETCH_CLAIMS_PAGE_SUCCESS,
  FETCH_CLAIMS_PAGE_FAILURE,
  FETCH_CLAIM_REQUEST,
  FETCH_CLAIM_SUCCESS,
  FETCH_CLAIM_FAILURE,
  FETCH_MEMBER_CLAIMS_REQUEST,
  FETCH_MEMBER_CLAIMS_SUCCESS,
  FETCH_MEMBER_CLAIMS_FAILURE,
  STORE_CLAIMS,
  MASS_CLAIM_APPROVAL_REQUEST,
  MASS_CLAIM_APPROVAL_SUCCESS,
  MASS_CLAIM_APPROVAL_FAILURE,
  RESET_MASS_APPROVAL_STATUS,
  FIND_DUPLICATE_CLAIM_REQUEST,
  FIND_DUPLICATE_CLAIM_SUCCESS,
  FIND_DUPLICATE_CLAIM_FAILURE,
} from 'store/claims/claims-actions';

export const omittableFields = [
  'isLoadingClaims',
  'claimsError',
];

export const initialState = {
  isLoadingClaims: false,
  isApprovingMassClaim: false,
  massClaimApprovalSuccess: false,
  massClaimApprovalError: '',
  claimsError: '',
  claims: {},
  pagination: {
    total: null,
    prevUrl: null,
    nextUrl: null,
  },
  isLoadingDuplicateClaim: false,
  duplicateClaimError: '',
  duplicateClaim: { },
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_MEMBER_CLAIMS_REQUEST:
    case FETCH_CLAIMS_PAGE_REQUEST:
    case FETCH_CLAIM_REQUEST:
      return {
        ...state,
        isLoadingClaims: true,
        claimsError: '',
      };
    case FETCH_MEMBER_CLAIMS_SUCCESS:
    case FETCH_CLAIM_SUCCESS: {
      return {
        ...state,
        isLoadingClaims: false,
        claimsError: '',
      };
    }
    case FETCH_CLAIMS_PAGE_SUCCESS:
      return {
        ...state,
        isLoadingClaims: false,
        claimsError: '',
        pagination: {
          total: action.response.total,
          prevUrl: action.response.prevUrl,
          nextUrl: action.response.nextUrl,
        },
      };
    case STORE_CLAIMS: {
      return {
        ...state,
        claims: merge(state.claims)(action.payload),
      };
    }
    case FETCH_MEMBER_CLAIMS_FAILURE:
    case FETCH_CLAIMS_PAGE_FAILURE:
    case FETCH_CLAIM_FAILURE:
      return {
        ...state,
        isLoadingClaims: false,
        claimsError: action.errorMessage,
      };
    case MASS_CLAIM_APPROVAL_REQUEST:
      return {
        ...state,
        isApprovingMassClaim: true,
        massClaimApprovalSuccess: false,
        massClaimApprovalError: '',
      };
    case MASS_CLAIM_APPROVAL_SUCCESS:
      return {
        ...state,
        isApprovingMassClaim: false,
        massClaimApprovalError: '',
        massClaimApprovalSuccess: true,
      };
    case MASS_CLAIM_APPROVAL_FAILURE:
      return {
        ...state,
        isApprovingMassClaim: false,
        massClaimApprovalSuccess: false,
        massClaimApprovalError: action.errorMessage,
      };
    case RESET_MASS_APPROVAL_STATUS:
      return {
        ...state,
        isApprovingMassClaim: false,
        massClaimApprovalSuccess: false,
        massClaimApprovalError: '',
      };

      // FIND DUPLICATE CLAIM
    case FIND_DUPLICATE_CLAIM_REQUEST:
      return {
        ...state,
        isLoadingDuplicateClaim: true,
        duplicateClaim: '',
      };
    case FIND_DUPLICATE_CLAIM_SUCCESS:
      return {
        ...state,
        isLoadingDuplicateClaim: false,
        duplicateClaimError: '',
        duplicateClaim: action.response,
      };
    case FIND_DUPLICATE_CLAIM_FAILURE:
      return {
        ...state,
        isLoadingDuplicateClaim: false,
        duplicateClaimError: action.errorMessage,
      };
    default:
      return state;
  }
}
