import React, { useState } from 'react';
import PropTypes from 'prop-types';

const GroupedMembersAccordion = ({ groupedMembers }) => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleToggle = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <div id="accordion-collapse" data-accordion="collapse">
      {groupedMembers.map((group, index) => (
        <div key={group.householdNumber} className="tw-border tw-border-gray-200 tw-mb-2 tw-rounded-lg">
          <div className="tw-bg-gray-100 tw-p-3 tw-rounded-t-lg">
            <h2 id={`accordion-collapse-heading-${index}`}>
              <button
                type="button"
                className="tw-flex tw-items-center tw-justify-between tw-w-full tw-text-sm tw-font-medium tw-text-gray-500 hover:tw-bg-gray-200"
                data-accordion-target={`#accordion-collapse-body-${index}`}
                aria-expanded={expandedIndex === index}
                aria-controls={`accordion-collapse-body-${index}`}
                onClick={() => handleToggle(index)}
              >
                <span>{`Household: ${group.householdNumber} (${group.members.length} members)`}</span>
                <svg
                  data-accordion-icon
                  className={`tw-w-3 tw-h-3 tw-shrink-0 ${expandedIndex === index ? 'tw-rotate-180' : ''}`}
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 5 5 1 1 5"
                  />
                </svg>
              </button>
            </h2>
          </div>
          <div
            id={`accordion-collapse-body-${index}`}
            className={`${expandedIndex === index ? 'tw-block' : 'tw-hidden'}`}
            aria-labelledby={`accordion-collapse-heading-${index}`}
          >
            <div className="tw-p-4 tw-border-t tw-border-gray-200">
              <table className="tw-min-w-full tw-border tw-border-gray-300">
                <thead className="tw-bg-gray-50">
                  <tr>
                    <th className="tw-px-4 tw-py-2 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">Full Name</th>
                    <th className="tw-px-4 tw-py-2 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">Date of Birth</th>
                    <th className="tw-px-4 tw-py-2 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">Gender</th>
                    <th className="tw-px-4 tw-py-2 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">Local Membership Number</th>
                    <th className="tw-px-4 tw-py-2 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">Profession</th>
                    <th className="tw-px-4 tw-py-2 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">Relationship to Head</th>
                    <th className="tw-px-4 tw-py-2 tw-text-left tw-text-xs tw-font-medium tw-text-gray-500 tw-uppercase tw-tracking-wider">Membership Type</th>
                  </tr>
                </thead>
                <tbody className="tw-bg-white">
                  {group.members.map((member, memberIndex) => (
                    <tr key={memberIndex}>
                      <td className="tw-px-4 tw-py-2 tw-text-sm tw-text-gray-900">{member['FULL NAME']}</td>
                      <td className="tw-px-4 tw-py-2 tw-text-sm tw-text-gray-500">{member['DATE OF BIRTH']}</td>
                      <td className="tw-px-4 tw-py-2 tw-text-sm tw-text-gray-500">{member['GENDER']}</td>
                      <td className="tw-px-4 tw-py-2 tw-text-sm tw-text-gray-500">{member['LOCAL MEMBERSHIP NUMBER']}</td>
                      <td className="tw-px-4 tw-py-2 tw-text-sm tw-text-gray-500">{member['PROFESSION']}</td>
                      <td className="tw-px-4 tw-py-2 tw-text-sm tw-text-gray-500">{member['RELATIONSHIP TO HEAD']}</td>
                      <td className="tw-px-4 tw-py-2 tw-text-sm tw-text-gray-500">{member['MEMBERSHIP TYPE']}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

GroupedMembersAccordion.propTypes = {
  groupedMembers: PropTypes.arrayOf(
    PropTypes.shape({
      householdNumber: PropTypes.string.isRequired,
      members: PropTypes.arrayOf(
        PropTypes.shape({
          'FULL NAME': PropTypes.string.isRequired,
          'DATE OF BIRTH': PropTypes.string.isRequired,
          'GENDER': PropTypes.string.isRequired,
          'LOCAL MEMBERSHIP NUMBER': PropTypes.string.isRequired,
          'PROFESSION': PropTypes.string.isRequired,
          'RELATIONSHIP TO HEAD': PropTypes.string.isRequired,
          'MEMBERSHIP TYPE': PropTypes.string.isRequired,
        })
      ).isRequired,
    })
  ).isRequired,
};

export default GroupedMembersAccordion;
