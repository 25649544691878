import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { userSelector, isProviderUserSelector } from 'store/auth/auth-selectors';

import { featureFlags, CONTACT_EMAIL, ROLE_PERMISSIONS, ROUTES } from 'lib/config';
import { showRoute } from 'lib/auth-utils';

import { countUnsyncedDeltasByModelType } from 'store/deltas/deltas-selectors';
import ehaiLogo from 'assets/images/ehai-logo.png';

import Box from 'components/box';
import AccountMenu from 'components/account-menu';
import BrandedLogo from 'components/branded-logo';
import { PillLink } from 'components/links';

import { Text } from 'components/text';

const MenuContainer = styled.header`
  height: ${props => props.theme.layout.menuHeight}px;
  ${props => !props.supportsMobile && css`
    min-width: ${props.theme.layout.menuMinWidth}px;
  `}
  ${props => props.withBackground && css`
    background: ${props.theme.colors.black};
    color: white;
  `};
`;

const MenuLink = ({ to, children }) => (
  <Box marginHorizontal={3}>
    <PillLink to={to} inverse>
      <Text fontSize={2}>{children}</Text>
    </PillLink>
  </Box>
);

MenuLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

const CurrentUser = styled.span`
  font-size: ${props => props.theme.font.size[2]};
  opacity: 0.6;
  margin-right: ${props => props.theme.space[4]};
  line-height: 26px;
`;

const MenuNavigation = styled(Box)`
  ${props => props.supportsMobile && `
    @media only screen and (max-width: 768px) {
      display: none;
    }
  `}
`;

class Menu extends Component {
  static mapStateToProps = state => ({
    user: userSelector(state),
    isProviderUser: isProviderUserSelector(state),
    unsyncedClaimCount: countUnsyncedDeltasByModelType(state, 'Encounter'),
  });

  render() {
    const { isErrorMenu, supportsMobile, user, isProviderUser } = this.props;
    const { BRANDED_LOGO_URL } = featureFlags;
    const currentPermissions = ROLE_PERMISSIONS[user.role];
    return (
      <MenuContainer withBackground={!isErrorMenu} supportsMobile={supportsMobile}>
        {isErrorMenu ? (
          <Box padding={4} flex alignItems="center" justifyContent="space-between">
            <a href={`mailto:${CONTACT_EMAIL}`}>Contact Admin</a>
          </Box>
        ) : (
          <Box flex flexDirection="row" alignItems="center" justifyContent="space-between">
            <Box>
              {BRANDED_LOGO_URL && <BrandedLogo url={ehaiLogo} height={54} />}
            </Box>
            <Box padding={3} flex alignItems="center" justifyContent="space-between" marginTop="3">
              <MenuNavigation flex supportsMobile={supportsMobile}>
                {showRoute(currentPermissions, ROUTES.CHECK_IN.route_match, isProviderUser) && (
                  <MenuLink to={ROUTES.CHECK_IN.base_url}>Check In</MenuLink>
                )}
                {showRoute(currentPermissions, ROUTES.ENROLL_MEMBERS.route_match, isProviderUser) && (
                  <MenuLink to={ROUTES.ENROLL_MEMBERS.base_url}>Enroll Members</MenuLink>
                )}
                {showRoute(currentPermissions, ROUTES.CLAIMS.route_match, isProviderUser) && (
                  <MenuLink to={ROUTES.CLAIMS.base_url}>Claims</MenuLink>
                )}
                {showRoute(currentPermissions, ROUTES.ENROLLMENT_REPORTING.route_match, isProviderUser) && (
                  <MenuLink to={ROUTES.ENROLLMENT_REPORTING.base_url}>Enrollment</MenuLink>
                )}
                {showRoute(currentPermissions, ROUTES.MEMBERS.route_match, isProviderUser) && (
                  <MenuLink to={ROUTES.MEMBERS.base_url}>Members</MenuLink>
                )}
                {showRoute(currentPermissions, ROUTES.REIMBURSEMENTS.route_match, isProviderUser) && (
                  <MenuLink to={ROUTES.REIMBURSEMENTS.base_url}>Reimbursements</MenuLink>
                )}
                {showRoute(currentPermissions, ROUTES.SUBMISSIONS.route_match, isProviderUser) && (
                  <MenuLink to={ROUTES.SUBMISSIONS.base_url}>Submissions</MenuLink>
                )}
                {showRoute(currentPermissions, ROUTES.SUMMARY.route_match, isProviderUser) && (
                  <MenuLink to={ROUTES.SUMMARY.base_url}>Summary</MenuLink>
                )}
                {showRoute(currentPermissions, ROUTES.USERS.route_match, isProviderUser) && (
                  <MenuLink to={ROUTES.USERS.base_url}>Users</MenuLink>
                )}
                {showRoute(currentPermissions, ROUTES.STATUS.route_match, isProviderUser) && (
                  <MenuLink to={ROUTES.STATUS.base_url}>Status</MenuLink>
                )}
                {showRoute(currentPermissions, ROUTES.FACILITY_DASHBOARD.route_match, isProviderUser) && (
                  <MenuLink to={ROUTES.FACILITY_DASHBOARD.base_url}>Facility Dashboard</MenuLink>
                )}
                {showRoute(currentPermissions, ROUTES.BRANCH_DASHBOARD.route_match, isProviderUser) && (
                  <MenuLink to={ROUTES.BRANCH_DASHBOARD.base_url}>Branch Dashboard</MenuLink>
                )}
                {showRoute(currentPermissions, ROUTES.ELIGIBLE_HOUSEHOLDS.route_match, isProviderUser) && (
                  <MenuLink to={ROUTES.ELIGIBLE_HOUSEHOLDS.base_url}>Eligible Households</MenuLink>
                )}
                {showRoute(currentPermissions, ROUTES.REPORTING.route_match, isProviderUser) && (
                  <MenuLink to={ROUTES.REPORTING.base_url}>Reporting</MenuLink>
                )}
                {showRoute(currentPermissions, ROUTES.INDIGENTS_PAYMENTS.route_match, isProviderUser) && (
                  <MenuLink to={ROUTES.INDIGENTS_PAYMENTS.base_url}>Indigents Payments</MenuLink>
                )}
              </MenuNavigation>
            </Box>
            <Box flex padding={3} flexDirection="row" alignItems="center" marginTop="2">
              <Box>
                <CurrentUser>{user.name}</CurrentUser>
              </Box>
              <AccountMenu user={user} />
            </Box>
          </Box>
        )}
      </MenuContainer>
    );
  }
}

export default connect(
  Menu.mapStateToProps,
  null,
)(Menu);

Menu.propTypes = {
  isProviderUser: PropTypes.bool.isRequired,
  isErrorMenu: PropTypes.bool,
  supportsMobile: PropTypes.bool,
  user: PropTypes.shape({
    name: PropTypes.string,
    role: PropTypes.string,
  }).isRequired,
};

Menu.defaultProps = {
  isErrorMenu: false,
  supportsMobile: false,
};
