import React, { useEffect, useState } from "react";
import Select from "react-select";
import { formatDate } from "lib/formatters/date";
import { useGetEnrollmentPeriodsQuery, useGetPremiaByEnrollmentPeriodQuery } from "store/api/baseApiSlice";
import { useSelector } from "react-redux";
import { capitalize, startCase } from "lodash";

const PremiumDetailsCard = ({ premium }) => {
  if (!premium) return null;

  return (
    <div className="tw-bg-white tw-shadow-lg tw-rounded-lg tw-overflow-hidden tw-mt-4">
      {/* Card Header with Premium Name and Enrollment Period */}
      <div className="tw-bg-blue-500 tw-text-white tw-p-4">
        <h2 className="tw-text-xl tw-font-bold">
          {premium.name}
        </h2>
        <p className="tw-text-sm">
          {premium.enrollment_period.administrative_division.name} - {formatDate(premium.enrollment_period.start_date)} to {formatDate(premium.enrollment_period.end_date)}
        </p>
      </div>

      {/* Payment Details */}
      <div className="tw-p-6 tw-bg-gray-50">
        {premium.payment_types.map((payment) => (
          <div key={payment.id} className="tw-flex tw-justify-between tw-items-center tw-py-2 tw-border-b tw-border-gray-200">
            <span className="tw-font-semibold tw-text-gray-700">
              {startCase(payment.payment_name.split('_',).join(' '))}
            </span>
            <span className="tw-text-blue-600 tw-font-semibold">{(payment.amount / 100).toLocaleString()} ETB</span>
          </div>
        ))}
      </div>
    </div>
  );
};



// PremiumSelector component
const PremiumSelector = ({ setSelectedEnrollmentPeriod, setSelectedPremium }) => {
  const state = useSelector(state => state);
  const enrollerKebeleId = state.auth.user.administrativeDivisionId;
  const [activeEnrollmentPeriodId, setActiveEnrollmentPeriodId] = useState(null);
  const { data: enrollmentPeriods = [], error: enrollmentPeriodsError, isLoading: isLoadingEnrollmentPeriods } = useGetEnrollmentPeriodsQuery(enrollerKebeleId, { skip: !enrollerKebeleId });
  const { data: premia = [], error: premiaError, isLoading: isLoadingPremia } = useGetPremiaByEnrollmentPeriodQuery(activeEnrollmentPeriodId, { skip: !activeEnrollmentPeriodId });
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    if (premia && premia.length > 0) {
      const defaultPremium = premia[0];
      setSelectedPremium(defaultPremium);
      setSelectedOption({
        value: defaultPremium?.id,
        label: `${defaultPremium.name} - (${defaultPremium.enrollment_period?.administrative_division?.name} ${formatDate(defaultPremium.enrollment_period.start_date)})`
      });
    }
  }, [premia, setSelectedPremium]);

  useEffect(() => {
    if (enrollmentPeriods.length > 0) {
      setSelectedEnrollmentPeriod(enrollmentPeriods.find(ep => ep.active))
      setActiveEnrollmentPeriodId(enrollmentPeriods.find(ep => ep.active)?.id);
    }
  }, [enrollmentPeriods]);

  return (
    <>
      {isLoadingPremia || isLoadingEnrollmentPeriods ? (
        <p>Loading Premium Types...</p>
      ) : premiaError || enrollmentPeriodsError ? (
        <p>Error loading premia</p>
      ) : (
        <div className="tw-col-span-6 tw-mt-6 tw-mb-6">
          <span className="tw-block tw-text-gray-700 tw-font-medium tw-mb-2">Select Premium </span>
          <Select
            placeholder="Select a Premium"
            options={premia.map((premium) => ({
              value: premium.id,
              label: `${premium?.name} - (${premium.enrollment_period?.administrative_division?.name} ${formatDate(premium.enrollment_period?.start_date)})`,
            }))}
            value={selectedOption} // Set the first premium as the default selected option
            onChange={(selectedOption) => {
              setSelectedPremium(premia.find(premium => premium?.id === selectedOption.value));
              setSelectedOption(selectedOption);
            }}
          />
        </div>
      )}
    </>
  );
};


export { PremiumSelector, PremiumDetailsCard };
